// Imports from packages
import React from 'react';
import Table from 'react-bootstrap/Table';
// Imports for APIs
// - none
// Imports of our custom components
import DynamicInputItem from './DynamicInputItem';
// Imports of styles
import '../../App.css';
// Imports of contants
// - none

export default function DynamicInputGrid(props) {

    // Map properties from props
    const propItems = props.items.map(property => {
        return (
            <DynamicInputItem
                key={property.id} property={property}
                creatingNewEntity = {props.creatingNewEntity}
                canDeleteItem={props.canDeleteItem}
                addProperty={formatedProp => props.addProperty(formatedProp)}
                deleteProperty={formatedProp => props.deleteProperty(formatedProp)}
                deleteItem={property => props.deleteItem(property)}
            />
        )
    })
    
    return (
        <Table hover>
            <tbody>
                {propItems}
            </tbody>
        </Table>
    );
}