// Imports from packages
import React, { useEffect } from 'react';
import Toast from 'react-bootstrap/Toast';
// styles
import './Common.css';

export default function CustomToast(props){
    // Destructuring assignment from the props
    const { showToast, setShowToast, iconColor, header, headerTime, body, setDisableButtonViaToast } = props

    useEffect(() => {
        if(showToast === true){
            setDisableButtonViaToast(true)
        }
        else {
            setDisableButtonViaToast(false)
        }
    }, [showToast])

    const closeToast = () => {
        setDisableButtonViaToast(false)
        setShowToast(false)
    }

    return (
        <Toast show = {showToast} className = "toast-position" onClose = {() => closeToast()}>
            <Toast.Header className = "py-2 toast-header-background">
                <div className = "rounded mr-2 toast-icon-style" style = {{ backgroundColor: iconColor }}/>
                <strong className = "mr-auto">{header}</strong>
                <small>{headerTime}</small>
            </Toast.Header>
            <Toast.Body className = "p-3">
                {body}
            </Toast.Body>
        </Toast>
    )
}