// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Table from 'react-bootstrap/Table';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
// Imports of our custom components 
import NoDistributorTablerow from './NoDistributorTablerow';
import ConditionallyRenderButton from './ConditionallyRenderButton';
import NewBlankPage from '../CommonComponents/NewBlankPage';
import getRange from './utils/deviceFunctions';
// Imports of styles
import './Device.css';
// Imports of constants
import { HADColors } from '../../constants/color'

function DeviceDistributors(props) {
    // Destructuring assignment from the props
    const { deviceId } = props

    const [distributors, setDistributors] = useState([]);
    const [showDistributors, setShowDistributors] = useState(false);

    useEffect(() => {
        // Fetch distributors
        const fetchDistributors = async() => {
            try {
                const result = await axios(DeviceApi('Distributors', deviceId));

                setDistributors(result.data);
                setShowDistributors(true);
            }
            catch (error) {
                // Distributor not found
                console.warn('Error during fetchDistributors process', error.response.status)
            }
        }

        // Call functions
        if(deviceId !== undefined) {
            fetchDistributors();
        }
    }, [deviceId])

    return (
        <div className ="d-flex justify-content-center flex-column text-center pt-3">
            <Table className = "w-100" hover = {distributors.length !== 0} style={{ backgroundColor: 'white' }}>
                <thead>
                    <tr>
                        <th colSpan="3" style={{ fontSize: 20 }}>Disztribútorok</th>
                    </tr>
                </thead>
                <tbody>
                { showDistributors && distributors.length !== 0 ? 
                    (
                        distributors.map((element,idx) => {
                            return (
                                <tr key={`${element.companyId}-${idx}`} style = {{ color: HADColors.Blue}}>
                                    <td className = "m-auto">
                                        {element.companyShortName}
                                    </td>
                                    <td className = "p-auto text-center align-middle">
                                        <NewBlankPage
                                            link = {element.directLink}
                                            content = {element.partNumber}
                                        />
                                    </td>
                                    <td style={{ position: 'relative', width: 110 }}>
                                        <div className='price-div d-flex justify-content-center align-items-center'>
                                            {getRange(element.price)}
                                        </div>
                                    </td>
                                </tr>
                            )
                        })
                    ) : (
                        <NoDistributorTablerow/>
                    )
                }
                </tbody>
            </Table>
            <ConditionallyRenderButton setDistributors = {newList => setDistributors(newList)} deviceId = {deviceId}/>
        </div>
    );
}

export default DeviceDistributors;
