import axios from "axios";
import { useMutation } from "react-query";

export const CreateBomFromFileQueryKey = "create-bom-from-file";

const useCreateBomFromFile = () => {
    return useMutation(
        CreateBomFromFileQueryKey,
        async ({ bomId: billOfMaterialId, file }) => {
            // Create multipart/form-data config
            let formData = new FormData();
            formData.append("File", file)
            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            const result = await axios.post(`/File/BillOfMaterial/${billOfMaterialId}/Items`, formData, config);
            return result.data;
        },
        {
            retry: 0,
        }
    );
};

export default useCreateBomFromFile;