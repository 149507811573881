// Imports from packages
import React from 'react';
import { connect } from 'react-redux';
// Import from 'react-bootstrap'
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row'; 
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants
// - none

function EditorBox(props) {
  // Destructuring assignment from the props
  const { showButton, toggleMode, title, body, buttonText } = props

  // Destructuring assignment from redux props
  const { user } = props

  const editorBox = (
    <div 
        className = "d-flex justify-content-center flex-column p-3 mb-3"
        style={{ backgroundColor: 'white'}}
      >
        <Row className="m-1 d-flex justify-content-center">
          <h4>{title}</h4>
        </Row>
        <Row className="m-1">
          {body}
        </Row>
        {
          showButton &&
          <div className = "pt-2">
            <Button 
              variant = "outline-info" 
              className="m-1 w-100"
              onClick={toggleMode}>
                {buttonText}
            </Button>
          </div>
        }
      </div>
  )

  return ( user !== '' && editorBox );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
  const { user} = state.auth;

  return {user};
};

export default connect(mapStateToProps)(EditorBox);
