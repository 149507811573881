import React from "react";
import Logo from "../new-logo.png";
import "../index.css";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";

const NotFoundPage = () => {
  const history = useHistory();
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <div>
        <div className="d-flex justify-content-center align-items-center">
          <img src={Logo} alt="HADocument logó" width="400px" />
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <h3>Hupsz! A keresett oldal nem található!</h3>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-4">
          <Button variant="primary" onClick={() => history.goBack()}>
            Vissza
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
