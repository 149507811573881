import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomModal from './CustomModal';
import '../../App.scss';
import { Alert } from 'react-bootstrap';
import { useEffect } from 'react';
import Select from 'react-select';

const customStyles = {
    // For the options
    option: (styles, { data }) => {
        const isMainCategory = !data.label.includes(">>");
        return {
            ...styles,
            backgroundColor: isMainCategory ? 'lightGray' : 'white',
            color: 'black',
            padding: '5px 0 5px 10px'
        };
    },
};

function EditDeviceBasicInformationModal(props) {

    const { device, handleSubmit, error, categories, currentCategory } = props;

    const [name, setName] = useState(device.name || "");
    const [description, setDescription] = useState(device.description || "");
    const [manufacturerName, setManufacturerName] = useState(device.manufacturerName || "");
    const [itemNumber, setItemNumber] = useState(device.itemNumber || "");
    const [categoryId, setCategoryId] = useState(currentCategory);

    useEffect(() => {
        setName(device.name);
        setDescription(device.description);
        setManufacturerName(device.manufacturerName);
        setItemNumber(device.itemNumber);
    }, [device])

    useEffect(() => {
        if (currentCategory) {
            setCategoryId(currentCategory.id)
        }
    }, [currentCategory])

    const onChangeSelectedElement = (type, selected) => {
        if (type === 'category') {
            if (selected === null) {
                setCategoryId('');
            }
            else {
                // [0] does the array flatting so ID is a string not an array
                setCategoryId(selected.value.split(" | ", 1)[0]);
            }
        }
    }

    const body = (
        <div>
            <Form>
                {(categories && currentCategory) && (
                    <>
                        <Form.Label>Kategória</Form.Label>
                        <Select
                            options={categories}
                            styles={customStyles}
                            isClearable={true}
                            placeholder="Kategória kiválasztása"
                            defaultValue={{ value: currentCategory.id, label: currentCategory.name }}
                            onChange={(selected) => onChangeSelectedElement('category', selected)}
                        />
                    </>
                )}
                <Form.Label>Eszköznév</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Eszköznév"
                    value={name}
                    onChange={event => setName(event.target.value)}
                    style={{ color: "#24282b", fontWeight: 500 }}
                />
                <Form.Label>Leírás</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Leírás"
                    value={description}
                    onChange={event => setDescription(event.target.value)}
                    style={{ color: "#24282b", fontWeight: 500 }}
                />
                <Form.Label>Gyártó</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Gyártó"
                    value={manufacturerName}
                    onChange={event => setManufacturerName(event.target.value)}
                    style={{ color: "#24282b", fontWeight: 500 }}
                />
                <Form.Label>Típus szám</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Típus szám"
                    value={itemNumber}
                    onChange={event => setItemNumber(event.target.value)}
                    style={{ color: "#24282b", fontWeight: 500 }}
                />
            </Form>
        </div>
    );

    const footer = (
        <>
            {error && (
                <div className="w-100 text-center">
                    <Alert variant="warning" className="mb-4 mt-4 text-center mx-4">Hiba történt! Kérlek, próbáld újra!<br /><i>{error}</i></Alert>
                </div>
            )}
            <Button
                variant="success"
                onClick={() => handleSubmit(name, description, manufacturerName, itemNumber, categoryId)}
            >
                Módosítás
            </Button>
            <Button
                variant="danger"
                onClick={() => props.handleClose()}
            >
                Mégse
            </Button>
        </>
    );

    return (
        <>
            <CustomModal
                show={props.show}
                size="md"
                dropback="static"
                keyboard={false}
                handleClose={props.handleClose}
                title={<h3>Alap információk módosítása</h3>}
                body={body}
                footer={footer}
            />
        </>
    );
}

export default EditDeviceBasicInformationModal;
