// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
// react-bootstrap
import Spinner from 'react-bootstrap/Spinner';
// Imports for APIs
import DeviceApi from '../../../Api/DeviceApi';
// Imports of our custom components 
import DeviceImageThumbnail from '../../CommonComponents/DeviceImageThumbnail';
import CompanyDeviceReview from './CompanyDeviceReview';
// Imports of styles
import '../Company.css';
// Imports of contants
// - none

function SingleCompanyDevice(props) {
    // Destructuring assignment from the props
    const { device, setDeviceId, setReviewMethod } = props;
    const basicDevice = device.basicDevice;

    const [deviceImage, setDeviceImage] = useState('');
    const [fetchedPhoto, setFetchedPhoto] = useState(false)

    useEffect(() => {
        let mounted = true;
        
        const fetchDeviceImageAsBlob = async () => {
            try {
                const result = await axios(DeviceApi('Photo', basicDevice.id), {responseType: 'blob'});
                const deviceImageUrl = URL.createObjectURL(result.data)
                setDeviceImage(deviceImageUrl);

                setFetchedPhoto(true)
            }
            catch(error) {
                console.error("Error during SingleCompanyDevice's device photo fetching process", error)

                setFetchedPhoto(true)
            }
        };

        // Fetch photo, if device has photo
        if(mounted){
            fetchDeviceImageAsBlob();
        }
        
        return () => mounted = false;
    }, []);

    const deviceMainProperties = (
        <>
            <div>
                <small className="text-muted">
                    {<b>{basicDevice.manufacturerName ? basicDevice.manufacturerName : "-" }</b>}
                    {" | "}
                    { Object.prototype.hasOwnProperty.call(basicDevice, 'category') ? basicDevice.category.name : "-" }
                </small>
            </div>
            <div>
                <small className="text-muted">
                    {<b>{basicDevice.itemNumber ? basicDevice.itemNumber : "-" }</b>}
                    {" | "}
                    {basicDevice.hadId ? basicDevice.hadId : "-" }
                </small>
            </div>
        </>
    )

    
    // If device does not have any image show a dummyImage
    const showImage = deviceImage === '' ? (
        <DeviceImageThumbnail />
    ) : (
        <Link to = {`/device/${device.id}`}>
            <img 
                src = {deviceImage}
                className = "linkStyle" 
                alt = {`${device.name} termék`} 
            />  
        </Link>
    )

    // While we are waiting for the image to load show a red spinner
    const imageOrSpinner = fetchedPhoto ? (
        showImage
    ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: 150, width: 150}}>
            <Spinner animation="border" variant="danger"/>
        </div>
    )

    return (
        <div className = "text-center m-2 mb-4" style = {{ width: '18%' }} key = {basicDevice.id}>
            <div
                className = "position-relative m-auto mb-3 d-flex justify-content-center align-items-center"
                style = {{ height: "200px" }}
            >
                {imageOrSpinner}
            </div>
            <div>
                <Link to= {`/device/${basicDevice.id}`}>
                    {basicDevice.name}
                </Link>
            </div>
            <div>
                {deviceMainProperties}
            </div>
            <CompanyDeviceReview
                review = {device.summarizedReviews}
                id = {basicDevice.id}
                setDeviceId={setDeviceId}
                setReviewMethod={setReviewMethod}
            />
        </div>
    );
}

export default SingleCompanyDevice;
