// Imports from packages
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
// Imports for APIs
// - none
// Imports of our custom components
import AddPropertyModal from '../Modals/AddPropertyModal';
import AddFileReferenceModal from '../Modals/AddFileReferenceModal';
import AddLinkModal from '../Modals/AddLinkModal';
import AddNewDeviceConnection from '../Modals/AddNewDeviceConnection';
// Imports of styles
import '../../App.css';
// Imports of contants
// - none

function PropertyList(props) {
  // Destructuring assignment from the props
  const { deviceId, hadId, documentTypes, entity, handleAddProperty, setRefreshing, setRefreshConnectedDevices } = props

  // Own variables
  const [isPropertyModalOpen, setIsPropertyModalOpen] = useState(false)
  const [isFileReferenceModalOpen, setIsFileReferenceModalOpen] = useState(false)
  const [isLinkModalOpen, setIsLinkModalOpen] = useState(false)
  const [isContactDeviceModalOpen, setIsContactDeviceModalOpen] = useState(false)

  const handlePropertySave = (propertyId, propertyValue) => {
    setIsPropertyModalOpen(false)
    handleAddProperty(propertyId, propertyValue)
  }

  return (
    <div>
        <AddPropertyModal
          show = {isPropertyModalOpen}
          handleClose = {() => setIsPropertyModalOpen(false)}
          handlePropertySave = {(propertyId, propertyValue) => handlePropertySave(propertyId, propertyValue)}
        />
        <AddLinkModal
          show = {isLinkModalOpen}
          handleClose = {() => setIsLinkModalOpen(false)}
          deviceId = {deviceId}
          setRefreshing = {result => setRefreshing(result)}
        />
        <AddFileReferenceModal
          show = {isFileReferenceModalOpen}
          handleClose = {() => setIsFileReferenceModalOpen(false)}
          profileId = {deviceId}
          documentTypes = {documentTypes}
          entity = {entity}
          setRefreshing = {result => setRefreshing(result)}
        />
        <AddNewDeviceConnection
          show = {isContactDeviceModalOpen}
          handleClose = {() => setIsContactDeviceModalOpen(false)}
          deviceId = {deviceId}
          hadId = {hadId}
          setRefreshing = {result => setRefreshConnectedDevices(result)}
        />
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            Tulajdonság hozzáadása
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item as="button" onClick={() => setIsPropertyModalOpen(true)}>Normál tulajdonság</Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => setIsLinkModalOpen(true)}>Link</Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => setIsFileReferenceModalOpen(true)}>Fájl</Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => setIsContactDeviceModalOpen(true)}>Kapcsolódó termék</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

    </div>
  );
}

export default PropertyList;
