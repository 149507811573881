function sortByDeviceNameOrItemNumber(deviceA, deviceB, sorting, isDistributedPage) {
    // If value contains ASC the order should be alphabetic order
    const sortFactor = sorting.includes('ASC') ? -1 : 1;
    // If value contains 'DeviceName', then elements should be compared by name
    // If not, then elements should be compared by itemNumber 
    let itemA, itemB
    if (isDistributedPage) { // We are in distributed Devices page
        if (sorting.includes('DeviceName')) {
            itemA = deviceA.deviceName.toUpperCase(); // ignore upper and lowercase
            itemB = deviceB.deviceName.toUpperCase(); // ignore upper and lowercase
        }
        else if (sorting.includes('ItemNumber')) {
            itemA = deviceA.deviceItemNumber.toUpperCase(); // ignore upper and lowercase
            itemB = deviceB.deviceItemNumber.toUpperCase(); // ignore upper and lowercase
        }
    }
    else { // We are in owned Devices page
        if (sorting.includes('DeviceName')) {
            itemA = deviceA.basicDevice.name.toUpperCase(); // ignore upper and lowercase
            itemB = deviceB.basicDevice.name.toUpperCase(); // ignore upper and lowercase
        }
        else if (sorting.includes('ItemNumber')) {
            itemA = deviceA.basicDevice.itemNumber.toUpperCase(); // ignore upper and lowercase
            itemB = deviceB.basicDevice.itemNumber.toUpperCase(); // ignore upper and lowercase
        }
        else if (sorting.includes('NumberOfRecommendations')) {
            itemA = deviceA.summarizedReviews.numberOfChangeRequests;
            itemB = deviceB.summarizedReviews.numberOfChangeRequests;
        }
        else if (sorting.includes('NumberOfRemovals')) {
            itemA = deviceA.summarizedReviews.numberOfRemovalRequests;
            itemB = deviceB.summarizedReviews.numberOfRemovalRequests;
        }
    }

    if (itemA < itemB) {
        return sortFactor;
    }
    if (itemA > itemB) {
        return -1 * sortFactor;
    }

    // itemNumbers must be equal
    return 0;
}

export default function sortingDevices(devices, sorting, isDistributedPage) {
    if (sorting) {
        // WARNING: .sort(a, b) sorts an array in place
        const arrayToSort = [...devices]
        return arrayToSort.sort((a, b) => sortByDeviceNameOrItemNumber(a, b, sorting, isDistributedPage))
    }
    else {
        return devices
    }
}