// Imports from packages
import React from 'react';
import { Link } from 'react-router-dom';
// react-bootstrap
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
import '../../App.css';
import DeviceProperties from '../Device/DeviceProperties';
// Imports of contants
// - none

const getFormattedPropertyValue = (type, property) => {
  switch (type) {
    case 'basic': return `${property.value}`
    case 'link': return property.value
    case 'connectedDevices': return (
      <Link to={`/device/${property.meta}`} className="ml-2">
        {property.value}
      </Link>
    )
    case 'subProjects': return property.value
  }
}

export default function CustomAccordion(props) {
  // Destructuring assignment from the props
  const { title, type, items, id, refresh, editMode, isOwner, acceptReview, declineReview, isAdministrator, disableReviewButtons, setDisableReviewButtons } = props

  let content
  switch (type) {
    case 'link': content = 'linkek'; break;
    case 'basic': content = 'alapinformációk'; break;
    case 'connectedDevices': content = 'kapcsolódó termékek'; break;
    case 'subProjects': content = 'alprojektek'; break;
  }

  // Check if there is any item
  let itemsArray
  if (items.length === 0) {
    itemsArray = `Nem találhatóak ${content} ehhez a termékhez.`
  }
  else {
    itemsArray = items.map((property, index) => {
      const propertyName = type === "subProjects" ? (
        <Link to={property.linkToPage} className="ml-2">
          {`${property.propertyName}`}
        </Link>
      ) : (
        property.id
      )

      return (
        <div
          className='each-property-div'
          key={`${property.id}-${index}`}
          style={{ paddingLeft: `${property.id === '' ? '15px' : '0px'}` }}
        >
          <span><b>{getFormattedPropertyValue(type, { value: propertyName, meta: property.meta })}</b>: {property.value}</span>
        </div>
      )
    })
  }

  return (
    <Accordion defaultActiveKey="0" key={type} className='accordion-style'>
      <Card>
        <Accordion.Toggle
          as={Card.Header}
          className='accordion-toggle-style'
          variant="link"
          eventKey='0'
        >
          {title}
        </Accordion.Toggle>
        <Accordion.Collapse eventKey='0'>
          <Card.Body className='card-body-style'>
            {type !== "connectedDevices" && itemsArray}
            {type === "connectedDevices" && items.map(property => {
              const { value, meta, propertyId } = property;
              const connectedDeviceName = property.id;
              return (
                <div
                  className='each-property-div'
                  key={value}
                >
                  <div className="d-flex justify-content-start align-items-center">
                    <Link
                      to={`/device/${meta}`}
                      className="ml-2"
                    >
                      {connectedDeviceName}
                    </Link>
                    : <span>{value}</span>
                  </div>
                  {
                    editMode &&
                    <DeviceProperties
                      id={propertyId}
                      value={value}
                      name={property.propertyName}
                      measurementUnit={null}
                      disableReviewButtons={disableReviewButtons}
                      setDisableReviewButtons={newValue => setDisableReviewButtons(newValue)}
                      refresh={refresh}
                      isOwner={isOwner}
                      isAdministrator={isAdministrator}
                      deviceId={id}
                      enumTypeId={null}
                      acceptReview={(devicePropertyReviewId) => acceptReview(devicePropertyReviewId)}
                      declineReview={(devicePropertyReviewId) => declineReview(devicePropertyReviewId)}
                    />
                  }
                </div>
              )
            })}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion >
  )
}