// Imports from packages
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaCloudUploadAlt, FaGlassCheers, FaUsers, FaSearch } from 'react-icons/fa';
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of contants
import { HADColors } from '../../constants/color'

function Statistics(props) {

  // Common properties
  const commonIconSize = "3em"
  const statisticsItemStyle = {
    position:'relative',
    top: 'calc(50% - 24px)'
  }

  // Items for rendering
  const statisticsItems = [
    {
      label: 'Feltöltött termék',
      value: props.numberOfDevices || 0,
      icon: <FaCloudUploadAlt size = {commonIconSize} style = {statisticsItemStyle} />,
      color: HADColors.Green
    },
    {
      label: 'Regisztrált felhasználó',
      value: props.numberOfUsers || 0,
      icon: <FaUsers size = {commonIconSize} style = {statisticsItemStyle} />,
      color: HADColors.Yellow
    },
    {
      label: 'Új termék naponta',
      value: props.createdDevices || 0,
      icon: <FaGlassCheers size = {commonIconSize} style = {statisticsItemStyle} />,
      color: HADColors.Red
    },
    {
      label: 'Keresés naponta',
      value: props.madeSearches || 0,
      icon: <FaSearch size = {commonIconSize} style = {statisticsItemStyle} />,
      color: HADColors.Blue
    },
  ]

  return (
    <Row className="m-4" style={{
      backgroundColor: 'white',
      paddingBottom: 10,
      paddingTop: 10,
      marginTop: 10,
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center'
    }}>
      {statisticsItems.map((item, index) => {
        return (
      <Col lg="3" style = {{ display: 'flex', justifyContent: 'center' }} key = {`statistics-item-${index}`}>
        <div 
          className = "d-flex justify-content-center align-item-center rounded-circle"
          style = {{
            height: '100px',
            width: '100px',
            backgroundColor: item.color,
          }}
        >
          {item.icon}
        </div>
        <div style = {{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 20
        }}>
          <div style = {{
            order: 1,
            fontFamily: '"Open Sans", sans-serif',
            fontWeight: 600,
            fontSize: '30px'
          }}>
            {item.value}
            </div>
          <div style = {{
            order: 2,
            textAlign: 'left'
          }}>
            {item.label}
          </div>
        </div>
      </Col>
      )})}
    </Row>
  )
}

export default Statistics;
