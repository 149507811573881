// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
// Imports of our custom components
import DeviceProperties from './DeviceProperties';
// Imports of styles
import '../../App.css';
import CustomPropertyDisplayComponent from './CustomPropertyDisplayComponent';
// Imports of contants
// - none

function DeviceCategories(props) {
    // Destructuring assignment from the props
    const { id, refresh, editMode, isOwner, acceptReview, declineReview, isAdministrator } = props

    const [categories, setCategories] = useState([]);
    const [connections, setConnections] = useState([]);

    // Global disable for review "buttons"
    const [disableReviewButtons, setDisableReviewButtons] = useState(false);

    const fetchConnections = async () => {
        const result = await axios(DeviceApi('Properties/Connections', id));
        if (Object.prototype.hasOwnProperty.call(result.data.properties, 'Kiegészítő eszköz')) {
            setConnections(result.data.properties['Kiegészítő eszköz']);
        }
    };

    const fetchProperties = async () => {
        const result = await axios(DeviceApi('Properties/Normal', id));
        setCategories(result.data.categories);
    };

    useEffect(() => {
        fetchConnections();
        fetchProperties();
    }, [id, refresh]);

    if (connections.length > 0) {
        categories['Kapcsolódó termékek'] = connections.map(connection => {
            return {
                'propertyName': connection.name,
                'values': [
                    {
                        value: connection.manufacturerName,
                        deviceId: connection.deviceId
                    }
                ],
                'measurementUnit': 'Unknown'
            }
        })
    }

    // Sorting categories alphabetically by name 
    const categoryEntries = Object.entries(categories);
    const mappedCategories = categoryEntries.map((category, idx) => {
        return {
            id: idx,
            categoryName: category[0],
            properties: category[1],
        }
    })
    const sortedCategoriesByNameAsc = mappedCategories.sort((categoryA, categoryB) => {
        const lowerCaseCategoryNameA = categoryA.categoryName.toLocaleLowerCase();
        const lowerCaseCategoryNameB = categoryB.categoryName.toLocaleLowerCase();
        return lowerCaseCategoryNameA.localeCompare(lowerCaseCategoryNameB);
    })

    const categoriAccordions = sortedCategoriesByNameAsc.map(category => {
        const { id, categoryName, properties } = category;
        const sortedPropertiesByNameAsc = properties.sort((propertyA, propertyB) => {
            const lowerCasePropertyNameA = propertyA.propertyName.toLocaleLowerCase();
            const lowerCasePropertyNameB = propertyB.propertyName.toLocaleLowerCase();
            return lowerCasePropertyNameA.localeCompare(lowerCasePropertyNameB);
        })
        return (
            <div key={`device-categories-${id}`}>
                {categoryName !== 'Kapcsolódó termékek' &&
                    <Accordion defaultActiveKey="0" key={categoryName} className='accordion-style'>
                        <Card>
                            <Accordion.Toggle
                                as={Card.Header}
                                className='accordion-toggle-style'
                                variant="link"
                                eventKey='0'
                            >
                                {categoryName || 'Általános'}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey='0'>
                                <Card.Body className='card-body-style'>
                                    {sortedPropertiesByNameAsc.map(property => {
                                        const eachValueInsideValues = property.values.map((eachValue, index) => {
                                            return (
                                                <div
                                                    className='each-property-div'
                                                    key={`${property.propertyName}-${index}`}
                                                >
                                                    <div className="d-flex justify-content-start align-items-center">
                                                        <div style={{ color: `${index === 0 ? "black" : "white"}`, marginRight: "0.5em" }}>
                                                            <b>{property.propertyName}</b>:
                                                        </div>
                                                        <CustomPropertyDisplayComponent contentType={property.contentType} value={eachValue.value} />
                                                        {(
                                                            property.measurementUnit !== 'Unknown' &&
                                                            property.measurementUnit !== 'NotApplicable'
                                                        ) && <span className="ml-1">(mértékegység: {property.measurementUnit})</span>
                                                        }
                                                    </div>
                                                    {editMode &&
                                                        <DeviceProperties
                                                            id={eachValue.id}
                                                            value={eachValue.value}
                                                            name={property.propertyName}
                                                            measurementUnit={property.measurementUnit}
                                                            disableReviewButtons={disableReviewButtons}
                                                            setDisableReviewButtons={newValue => setDisableReviewButtons(newValue)}
                                                            refresh={refresh}
                                                            isOwner={isOwner}
                                                            isAdministrator={isAdministrator}
                                                            deviceId={id}
                                                            enumTypeId={property.enumTypeId}
                                                            acceptReview={(devicePropertyReviewId) => acceptReview(devicePropertyReviewId)}
                                                            declineReview={(devicePropertyReviewId) => declineReview(devicePropertyReviewId)}
                                                        />
                                                    }
                                                </div>
                                            )
                                        })

                                        return eachValueInsideValues;
                                    })}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                }
            </div>
        )
    })

    return categoriAccordions;
}

export default DeviceCategories;
