// Imports from packages
import React from 'react';
import Select from 'react-select';

const mapEnumsForSelect = enumArray => {
    return enumArray.map(element => {
        return {
            value: element.id,
            label: element.name
        }
    })
}

export default function EnumSelector(props) {
    // Input data from parent component
    const { enumList, selectEnum } = props
    // Component-scoded logic
    const mappedEnums = mapEnumsForSelect(enumList)
    const handleSelectChange = (selectedEnum) => selectEnum(selectedEnum)
    return (
        <Select
            options={mappedEnums}
            onChange={selectedEnum => handleSelectChange(selectedEnum)}
            placeholder="Válassz egy enumot!"
            isClearable={true}
            isSearchable={true}
        />
    )
}