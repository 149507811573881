import React from 'react';
import CreatableSelect from 'react-select/creatable';
import DatePropertyEditor from './DatePropertyEditor';
import TimePropertyEditor from './TimePropertyEditor';
import DateTimePropertyEditor from './DateTimePropertyEditor';
import AddressPropertyEditor from './AddressPropertyEditor';

export default function AddPropertyInputComponent(props) {
    const { isEnum, contentType, options, value, onChange, placeholder } = props;
    if (isEnum) {
        return (
            <CreatableSelect
                options={options}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
                formatCreateLabel={(inputValue) => `Új javaslat: "${inputValue}"`}
            />
        )
    }
    else if (contentType === "Time") {
        return <TimePropertyEditor value={value.value} onChange={onChange} />
    }
    else if (contentType === "Date") {
        return <DatePropertyEditor value={value.value} onChange={onChange} />
    }
    else if (contentType === "DateTime") {
        return <DateTimePropertyEditor value={value.value} onChange={onChange} />
    }
    else if (contentType === "Address") {
        return <AddressPropertyEditor value={value.value} onChange={onChange} />
    }
    else {
        return (
            <CreatableSelect
                options={options}
                onChange={onChange}
                placeholder={placeholder}
                value={value}
            />
        )
    }
}