// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from "react-router-dom";
// Imports for APIs
import CompanyApi from '../../Api/CompanyApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import ThreeColumnPageTemplate from '../Pages/ThreeColumnPageTemplate';
import EditorBox from '../CommonComponents/EditorBox';
import LeftColumn from '../CommonComponents/LeftColumn';
import EmployeeModul from './EmployeeModul';
import ProfileCentralColumn from "../Profile/ProfileCentralColumn";
import { Button } from 'react-bootstrap';
import EditCompanyBasicProfileModal from '../Modals/EditCompanyBasicProfileModal';
// Imports of styles
// - none
// Imports of contants
// - none

function CompanyPage() {
  // Handle ID of the device (from URL parameter)
  let { id } = useParams();
  const companyId = id;

  // Company's basic information
  const [hasData, setHasData] = useState(false)
  // Profile
  const [profile, setProfile] = useState()
  const [refreshing, setRefreshing] = useState({})

  const [files, setFiles] = useState({})
  const [documentTypes, setDocumentTypes] = useState([]);

  const [links, setLinks] = useState({})

  // Other
  const [editMode, setEditMode] = useState(false)
  const [noSuchCompanyWithThisId, setNoSuchCompanyWithThisId] = useState(false)
  const [showLeftColumn, setShowLeftColumn] = useState(false)
  const [showEditBasicProfileDialog, setShowEditBasicProfileDialog] = useState(false)
  const [apiResponse, setApiResponse] = useState("")
  const [currentUser, setCurrentUser] = useState()
  const [isCompanyOwner, setIsCompanyOwner] = useState(false)

  const fetchFileInformation = async () => {
    const filesFromApi = await axios(CompanyApi(`${companyId}/Properties/Files`));
    setFiles(filesFromApi.data.properties)
  };

  const fetchLinkInformation = async () => {
    const linksFromApi = await axios(CompanyApi(`${companyId}/Properties/Links`));
    setLinks(linksFromApi.data.properties)
  };

  const fetchFileTypes = async () => {
    const result = await axios(PropertyApi(`List?propertyType=Company&typeOf=FileReference`));
    const fileOptions = result.data;

    setDocumentTypes(fileOptions)
  };

  const fetchCompanyInformation = async () => {
    try {
      const companyInformation = await axios(CompanyApi(`${companyId}/Profile`));
      setProfile(companyInformation.data);
      setHasData(true);
      setShowLeftColumn(true)

      // There is an authorized user so download further informations
      fetchFileInformation();
      fetchFileTypes();
      fetchLinkInformation();
    }
    catch (error) {
      setHasData(true);
      setNoSuchCompanyWithThisId(true);
      setShowLeftColumn(false)
    }
  };

  const fetchCurrentUser = async () => {
    try {
      const userResponse = await axios("User");
      setCurrentUser(userResponse.data.authenticatedUser);
    }
    catch (error) {
      console.error(error.response);
    }
  };

  useEffect(() => {
    // Fetch data
    if (companyId !== '') {
      fetchCurrentUser();
      fetchCompanyInformation();
    }
  }, []);

  useEffect(() => {
    if (currentUser && profile) {
      setIsCompanyOwner(currentUser.nickname === profile.creatorNickname)
    }
  }, [currentUser, profile]);

  // Fetch profile information because of new prop
  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(refreshing, "shortName")) {
      setProfile(refreshing)
    }
    else if (Object.prototype.hasOwnProperty.call(refreshing, "fileReferenceId")) {
      fetchFileInformation()
    }
  }, [refreshing]);

  const toggleMode = () => {
    setEditMode(!editMode);
  }

  const openProfileBasicEditorModal = () => {
    setShowEditBasicProfileDialog(true);
  }

  const putBasicCompanyProfile = async (shortName, longName) => {
    try {
      await axios.put(`Company/${id}/Profile/Basic`, {
        name: longName,
        shortName: shortName
      })
      setApiResponse("");
      fetchCompanyInformation();
      setShowEditBasicProfileDialog(false);
    }
    catch (error) {
      const statusText = Object.entries(error)[2][1].statusText
      setApiResponse(statusText);
    }
  }

  const handleEditBasicProfile = (shortName, longName) => {
    putBasicCompanyProfile(shortName, longName);
  }

  const leftColumn = (
    <LeftColumn
      toggleMode={toggleMode}
      editMode={editMode}
      id={companyId}
      borderRadius="0%"
      postType='company'
      formDataType='profilePicture'
      links={links}
      files={files}
      entityName="céghez"
    />
  )

  const centralColumn = (
    <div className="h-100">
      <ProfileCentralColumn
        containerTitle="Cég profil"
        profile={profile}
        editMode={editMode}
        setRefreshing={result => setRefreshing(result)}
        profileId={companyId}
        documentTypes={documentTypes}
        entity="company"
        loadingText="Adatok lekérdezése"
        hasData={hasData}
        noSuchEntityWithThisId={noSuchCompanyWithThisId}
        notRequestedUsername={true}
      />
    </div>
  )

  const employeeList = hasData && !noSuchCompanyWithThisId && showLeftColumn && (
    <EmployeeModul
      companyId={companyId}
      editMode={editMode}
      isCompanyOwner={isCompanyOwner}
    />
  )

  const rightColumn = (
    <>
      {isCompanyOwner && (
        <EditorBox
          showButton={true}
          toggleMode={toggleMode}
          title="Profil módosítása"
          body={
            <div>
              <p>
                Jogosult vagy a cég kezelésére, mint cég adminisztrátor.
              </p>
              <Button
                variant="outline-info"
                className="m-1 w-100"
                onClick={() => openProfileBasicEditorModal()}
              >
                Profil tulajdonságok módosítása
              </Button>
            </div>
          }
          buttonText={editMode ? "Módosítás befejezése" : "Cégtulajdonságok módosítása"}
        />
      )}
      <EditorBox
        title="Eszközök"
        body={
          <div className="d-flex w-100">
            <ul>
              <li><Link to={`${companyId}/owned`}>Cég tulajdonában lévő eszközök</Link></li>
              <li><Link to={`${companyId}/distributed`}>Cég által forgalmazott eszközök</Link></li>
            </ul>
          </div>
        }
      />
      {employeeList}
    </>
  )

  return (
    <>
      <ThreeColumnPageTemplate
        leftColumn={showLeftColumn && leftColumn}
        centralColumn={centralColumn}
        rightColumn={rightColumn}
      />
      {profile && (
        <EditCompanyBasicProfileModal
          show={showEditBasicProfileDialog}
          handleClose={() => setShowEditBasicProfileDialog(false)}
          handleEditBasicProfile={(shortName, longName) => handleEditBasicProfile(shortName, longName)}
          profile={profile}
          error={apiResponse}
        />
      )}

    </>
  );
}

export default CompanyPage;
