// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Spinner from 'react-bootstrap/Spinner';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
import CompanyApi from '../../Api/CompanyApi';
import UserApi from '../../Api/UserApi';
// Imports of our custom components
import ImageUploader from './ImageUploader';
import DeviceImageThumbnail from './DeviceImageThumbnail';
// Imports of styles
// - none 
// Imports of contants
// - none

function ImageBox(props) {
    // Destructuring assignment from the props
    const { toggleMode, editMode, id, postType, formDataType, borderRadius } = props

    // Variable 
    const [photoPath, setPhotoPath] = useState('')
    const [fetchedPhoto, setFetchedPhoto] = useState(false)

    // Depending on type different APIs, and value names are called
    let urlAddress
    let imageAlt

    switch(postType) {
        case 'device':
            urlAddress = DeviceApi('Photo', id);
            imageAlt = "Device picture";
            break;
        case 'company':
            urlAddress = CompanyApi(`${id}/Picture`, id, 'GET');
            imageAlt = "Company profile picture";
            break;
        case 'user':
            urlAddress = UserApi('Picture', 'GET');
            imageAlt = "User profile picture";
            break;
        default:
            console.error("Unknow type in Imagebox: ", postType)
    }

    const fetchPhoto = async () => {
        try {
            // Get profile picture's path
            const result = await axios(urlAddress, { responseType: 'blob' });
            const ImageUrl = URL.createObjectURL(new Blob([result.data], {type: result.data.type}))
            setPhotoPath(ImageUrl)

            setFetchedPhoto(true)
        }
        catch(error) {
            // Picture not found
            setPhotoPath('');

            setFetchedPhoto(true)
        }
    };

    // Fetch picture 
    useEffect(() => {
        if(postType && id !== undefined) {
            fetchPhoto();
        }
    }, [id, postType]);

    const resolution = '300px'

    const showImage = photoPath === '' ? (
        <DeviceImageThumbnail height = {resolution} width = {resolution} />
    ) : (
        <img
            src = {photoPath}
            alt = {imageAlt}
            title = {imageAlt}
            width = "100%"
            id = {imageAlt.replace(/ /gi, '')}
            style = {{ borderRadius: borderRadius }}
        />
    )

    const imageOrSpinner = fetchedPhoto ? (
        showImage
    ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: 300, width: 300}}>
            <Spinner animation="border" variant="danger"/>
        </div>
    )

    return (
        <>
            <div className="d-flex justify-content-center align-items-center">
                {imageOrSpinner}
            </div>
            <ImageUploader 
                toggleMode = {toggleMode} 
                fetchPhoto = {fetchPhoto} 
                editMode = {editMode}
                id = {id}
                postType = {postType}
                formDataType = {formDataType}
            />
        </>
    );
}

export default ImageBox;
