// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Button from 'react-bootstrap/Button';
// Imports for APIs
import CompanyApi from '../../Api/CompanyApi'
// Imports of our custom components
import DeleteEmployeeModal from '../Modals/DeleteEmployeeModal';
import AddEmployeeModal from '../Modals/AddEmployeeModal';
import EmployeeList from './EmployeeList';
// Imports of styles
// - none
// Imports of contants
// - none

export default function EmployeeModul(props) {
    // Destructuring assignment from the props
    const { companyId, editMode, isCompanyOwner } = props

    // Employee list
    const [employeeList, setEmployeeList] = useState([])

    // Other
    const [isDeleteModalOpen, setDeleteIsModalOpen] = useState(false)
    const [selectedUser, setSelectedUser] = useState('')

    // New employee 
    const [isEmployeeAddClicked, setEmployeeAddClicked] = useState(false)
    const [isAddModalOpen, setAddModalOpen] = useState(false)

    useEffect(() => {
        // Fetch company'semployee list
        const fetchEmployeeList = async () => {
            try {
                const employeeListResult = await axios(CompanyApi(`${companyId}/Employee/List`));
                setEmployeeList(employeeListResult.data);
            }
            catch (error) {
                console.error("Error during employee list fetch process", error)
            }
        };

        // Fetch data
        if (companyId !== '') {
            fetchEmployeeList();
        }
    }, [companyId]);

    useEffect(() => {
        if (selectedUser !== '') {
            setDeleteIsModalOpen(true)
        }
    }, [selectedUser]);

    const ToggleEmployeeAdd = () => {
        setEmployeeAddClicked(!isEmployeeAddClicked);
        setAddModalOpen(!isAddModalOpen);
    }

    // Delete company's selected user from employees
    const deleteEmployee = async () => {
        // Create the parameters for axios
        const axiosParameters = {
            url: CompanyApi(`${companyId}/Employee`),
            method: 'delete',
            data: `"${selectedUser}"`,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        // Call API endpoint with axios
        axios(axiosParameters)
            .then(response => {
                if (response.status === 200) {
                    // Result holds the updated list, so adding it to employeeList will update the list automatically
                    setEmployeeList(response.data)
                }
            })
            .catch(error => {
                console.error(error);
            })
    }

    const fullModul = (
        <div className="p-3" style={{ backgroundColor: "white" }}>
            <AddEmployeeModal
                show={isAddModalOpen}
                companyId={companyId}
                handleClose={ToggleEmployeeAdd}
                updateList={update => setEmployeeList(update)}
            />
            <DeleteEmployeeModal
                show={isDeleteModalOpen}
                companyId={companyId}
                handleDelete={deleteEmployee}
                handleClose={() => setDeleteIsModalOpen(false)}
                username={selectedUser}
            />

            <div className="d-flex justify-content-between align-items-center">
                <div>
                    <h3>Alkalmazottak</h3>
                </div>
                {isCompanyOwner &&
                    <Button
                        variant="primary"
                        onClick={ToggleEmployeeAdd}
                        disabled={editMode}
                    >
                        Felvétel
                    </Button>
                }
            </div>
            <EmployeeList
                list={employeeList}
                handleDelete={name => setSelectedUser(name)}
                editMode={editMode}
                isCompanyOwner={isCompanyOwner}
            />
        </div>
    )

    return fullModul;
}