import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import '../../App.scss';

import Header from '../Header/Header';
import Footer from '../CommonComponents/Footer';

function SingleColumnPageTemplate(props) {

    const { headerRow, centralColumn, bottomRow } = props

    return (
        <div>
            <Header />
            <Container fluid style={{ backgroundColor: '#f3f5f7', paddingBottom: 20, paddingTop: 5, minHeight: '73vh' }}>
                {headerRow && headerRow}
                {centralColumn &&
                    <Row className="m-4" style={{ backgroundColor: 'white', paddingBottom: 10 }}>
                        <Col lg="12">{centralColumn}</Col>
                    </Row>
                }
                {bottomRow &&
                    <Row style={{ backgroundColor: '#f3f5f7',  margin: '1.5rem 1.5rem 0' }}>
                        <Col lg="12" style = {{ textAlign: 'center' }}>{bottomRow}</Col>
                    </Row>
                }
            </Container>
            <Footer/>
        </div>
    );
}

export default SingleColumnPageTemplate;
