// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
// react-bootstrap
import Spinner from 'react-bootstrap/Spinner';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
// Imports of our custom components 
import DeviceImageThumbnail from '../CommonComponents/DeviceImageThumbnail';
// Imports of styles
import "./Device.css";
// Imports of contants
// - none

function SingleDevice(props) {
  // Destructuring assignment from the props
  const { device } = props

  const [deviceImage, setDeviceImage] = useState('');
  const [fetchedPhoto, setFetchedPhoto] = useState(false)

  useEffect(() => {
    const fetchDeviceImageAsBlob = async () => {
      try {
        const result = await axios(DeviceApi('Photo', device.id), {responseType: 'blob'});
        const deviceImageUrl = URL.createObjectURL(result.data)
        setDeviceImage(deviceImageUrl);

        setFetchedPhoto(true)
      }
      catch(error) {
        //console.error("Error during device photo fetching process", error)
        setFetchedPhoto(true)
      }
    };

    // Fetch photo, if device has photo
    fetchDeviceImageAsBlob();

    // To avoid unmounted warning
    return () => {setDeviceImage(''); setFetchedPhoto(false)};
  }, []);

  const deviceMainProperties = (
    <>
      <div>
        <small className="text-muted">
          {<b>{device.manufacturerName ? device.manufacturerName : "-" }</b>}
          {" | "}
          { Object.prototype.hasOwnProperty.call(device, 'category') ? device.category.name : "-" }
        </small>
      </div>
      <div>
        <small className="text-muted">
          {<b>{device.itemNumber ? device.itemNumber : "-" }</b>}
          {" | "}
          {device.hadId ? device.hadId : "-" }
        </small>
      </div>
    </>
  )

  // If device does not have any image show a dummyImage
  const showImage = deviceImage === '' ? (
    <DeviceImageThumbnail />
  ) : (
    <Link to = {`/device/${device.id}`}>
      <img 
        src = {deviceImage}
        className = "linkStyle" 
        alt = {`${device.name} termék`} 
      />  
    </Link>
  )

  // While we are waiting for the image to load show a red spinner
  const imageOrSpinner = fetchedPhoto ? (
    showImage
  ) : (
    <div className="d-flex justify-content-center align-items-center" style={{ height: 150, width: 150}}>
        <Spinner animation="border" variant="danger"/>
    </div>
  )

  return (
    <div className = "text-center m-2" style = {{ width: '18%' }} key = {device.id}>
      <div
        className = "position-relative m-auto mb-3 d-flex justify-content-center align-items-center"
        style = {{ height: "200px" }}
      >
        {imageOrSpinner}
      </div>
      <div>
        <Link to= {`/device/${device.id}`}>
          {device.name}
        </Link>
      </div>
      <div>
        {deviceMainProperties}
      </div>
    </div>
  );
}

export default SingleDevice;
