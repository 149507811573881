// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
// react-bootstrap
import Card from 'react-bootstrap/Card';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
// Imports of our custom components
import DeviceImageThumbnail from '../CommonComponents/DeviceImageThumbnail';
// Imports of styles
// - none
// Imports of contants
// - none

function DeviceCard(props) {
  const [deviceImage, setDeviceImage] = useState('');
  const [hadId, setHadId] = useState();

  const widthAndHeight = 200

  useEffect(() => {
    const fetchDeviceImageAsBlob = async () => {
      try {
        const endpoint = `Photo?width=${widthAndHeight}&height=${widthAndHeight}`
        const result = await axios(DeviceApi(endpoint, props.id), { responseType: 'blob' });
        const deviceImageUrl = URL.createObjectURL(result.data)
        setDeviceImage(deviceImageUrl);
      } catch (error) {
        console.error("Error during device photo fetching process for Card", error)
      }
    };

    const fetchHadId = async () => {
      try {
        const result = await axios(DeviceApi('Basic', props.id));
        setHadId(result.data.hadId);
      } catch (error) {
        console.error("Error during device hadId fetching process for Card", error)
      }
    }

    if (props.hasPhoto) {
      fetchDeviceImageAsBlob();
      fetchHadId();
    }
  }, [props.id, props.hasPhoto]);

  // Can reload a different page without redux reset
  let linkPath
  if (props.pathname === 'had') {
    linkPath = `/device/${props.id}`
  }
  else {
    linkPath = `/had/${hadId}`
  }

  return (
    <Card className="text-center" style={{ width: 250 }}>
      <Card.Body className="d-flex justify-content-between flex-column">
        {deviceImage === '' ? (
          <DeviceImageThumbnail />
        ) : (
          <Card.Text>
            <Link to={linkPath}>
              <img src={deviceImage} alt={`${props.name} termék`} />
            </Link>
          </Card.Text>
        )}
        <Card.Title>
          <div className="d-flex justify-content-end flex-column">
            <Link to={linkPath}>
              {props.name}
            </Link>
            <small className="text-muted"><b>{props.manufacturer}</b> | {props.category}</small>
          </div>
        </Card.Title>
      </Card.Body>
    </Card>
  );
}

export default DeviceCard;
