import React from 'react';
import { setLanguage } from '../../actions/language.js';
import { connect } from 'react-redux';

import Dropdown from 'react-bootstrap/Dropdown';

import '../../App.scss';
import './Header.css';

import HU from '../../flags/hungary.svg'
import UK from '../../flags/united-kingdom.svg'
import SP from '../../flags/spain.svg'
import FR from '../../flags/france.svg'
import CZ from '../../flags/czech-republic.svg'
import DE from '../../flags/germany.svg'

function LanguageDropdown(props) {

    const getFlagSvg = (language) => {
        switch (language) {
            case 'HU': return HU;
            case 'UK': return UK;
            case 'SP': return SP;
            case 'FR': return FR;
            case 'CZ': return CZ;
            case 'DE': return DE;
            default: return HU;
        }
    }

    // Flag variable
    const flag = getFlagSvg(props.language)
    const resolution = 20;

    // Custom profile
    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <img
            style={{ width: 43, height: 43, margin: '0 5px', cursor: 'pointer' }}
            alt="language"
            src={flag}
            ref={ref}
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        />
    ));
    CustomToggle.displayName = "Language dropdown"

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic-language" />
                <Dropdown.Menu align="right">
                    <Dropdown.Item onClick={() => props.setLanguage('HU')} style={{ display: 'flex' }}>
                        <img src={HU} width={resolution} height={resolution} style={{ margin: '3px 10px 0 0' }} />
                        <span>Magyar</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setLanguage('UK')} style={{ display: 'flex' }}>
                        <img src={UK} width={resolution} height={resolution} style={{ margin: '3px 10px 0 0' }} />
                        <span>English</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setLanguage('DE')} style={{ display: 'flex' }}>
                        <img src={DE} width={resolution} height={resolution} style={{ margin: '3px 10px 0 0' }} />
                        <span>Deutsch</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setLanguage('SP')} style={{ display: 'flex' }}>
                        <img src={SP} width={resolution} height={resolution} style={{ margin: '3px 10px 0 0' }} />
                        <span>Español</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setLanguage('FR')} style={{ display: 'flex' }}>
                        <img src={FR} width={resolution} height={resolution} style={{ margin: '3px 10px 0 0' }} />
                        <span>Français</span>
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => props.setLanguage('CZ')} style={{ display: 'flex' }}>
                        <img src={CZ} width={resolution} height={resolution} style={{ margin: '3px 10px 0 0' }} />
                        <span>Češka</span>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
    const { language } = state.language;
    return { language };
};
// Store reducers to components props as callable functions
const mapDispatchToProps = dispatch => ({
    setLanguage: (language) => {
        dispatch(setLanguage(language));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(LanguageDropdown);