// Imports from packages
import React, { useState } from 'react';
import axios from 'axios';
// react-bootstrap
import Button from 'react-bootstrap/Button';
// Imports for APIs
import ProjectApi from '../../Api/ProjectApi';
// Imports of our custom components 
import CustomModal from './CustomModal';
// - none
// Imports of styles
import '../Device/Device.css';
// Imports of constants
// - none

function CloseSubprojectsModal(props) {
    // Destructuring assignment from the props
    const { show, subProjects, projectName, handleClose, refresh } = props

    // Mischevious variables
    const [updating, setUpdating] = useState(false)

    const putUpdate = () => {
        setUpdating(true)

        let promises = []
        try {
            subProjects.forEach(subProject => {
                if(subProject.status !== "Finished"){
                    // Create the parameters for axios
                    const axiosParameters = {
                        url: ProjectApi(`${subProject.id}/Status`),
                        method: 'put',
                        data: `"Finished"`,
                        headers: {
                            'Content-Type': 'application/json',
                            'accept': 'application/json'
                        }
                    }

                    axios(axiosParameters).then(resp => {
                        promises.push(resp)
                    })
                }
            })

            Promise.all(promises).then(() => {
                handleClose()
                refresh()
            });

            setUpdating(false)
        } catch (error) {
            console.warn("Error during project status process", error)

           // Enable new status change - tho toast will still disable it
            setUpdating(false)
            handleClose()
        }
    }

    // Modal parameters
    const title = `Projekt lezárása`

    const body = (
        <>
            <p>
                A projekt tartalmaz lezáratlan {subProjects.length > 1 ? "alprojekteket" : "alprojektet"}!
                Amennyiben a(z) <b>{projectName}</b> nevű projektet lezárja, úgy az össze még lezáratlan alprojekt 
                is lezárt státuszú lesz.
            </p>
            <p>
                Lezárt alprojekt csak a főprojekt újranyitásával lesz ismét szerkeszthető.
            </p>
            <p>
                <b>Biztos benne, hogy lezárja a főprojektet?</b>
            </p>
        </>
    )

    const footer = (
        <>
            <Button
                variant = "danger"
                onClick = {putUpdate}
                disabled = {updating}
            >
                Lezárás
            </Button>
            <Button
                variant = "primary"
                onClick = {handleClose}
                disabled = {updating}
            >
                Mégse
            </Button>
        </>
    );

    return (
        <>
            <CustomModal
                show = {show}
                size = "md"
                dropback = "static"
                keyboard = {false}
                handleClose = {handleClose}
                title = {title}
                body = {body}
                footer = {footer}
            />
        </>
    );
}

export default CloseSubprojectsModal;