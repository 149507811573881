// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Select from 'react-select';
// Imports for APIs
import CompanyApi from '../../../Api/CompanyApi'
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants
// - none

export default function YearFilterSelect(props) {
    // Destructuring assignment from the props
    const { companyId, handleYearChange, setYearsForDistribution } = props

    const [selectOptions, setSelectOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState()

    useEffect(() => {
        let mounted = true;

        const fetchYears = async() => {
            const result = await axios(CompanyApi(`${companyId}/Devices/Distributed/Years`));
            const yearsOptions = result.data.map(year => {
                const option = {
                    value: year,
                    label: year
                }
                
                return option
            })

            // Devices were fetched
            setSelectOptions(yearsOptions)            
            setSelectedOption(yearsOptions[0])

            // Set years for page
            setYearsForDistribution(yearsOptions)
        }

        // Fetch data
        if(mounted){
            fetchYears();
        }

        return () => mounted = false;
    }, [])

    const onChange = (selected) => {
        handleYearChange(selected.value)
        setSelectedOption(selected)
    }

    const customStyles = {
        control: provided => ({
            ...provided,
            width: 250
        }),
        option: provided => ({
            ...provided,
            textAlign: 'center'
        }),
        valueContainer: provided => ({
            ...provided,
            paddingLeft: selectOptions.length === 0 ? 20 : 105
        })
    }

    return (
        <Select
            styles={customStyles}
            options={selectOptions}
            placeholder = "Válassz forgalmazási évet"
            value = {selectedOption}
            onChange = {selected => onChange(selected)}
            isDisabled = {selectOptions.length === 0}
        />
    )
}