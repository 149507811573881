// Imports from packages
import React from 'react';
// react-bootstrap
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants

export default function CenteredHeading4(props) {
    // Destructuring assignment from the props
    const { message } = props

    return (
        <div className='m-2 my-4 d-flex justify-content-center'>
            <h4 style = {{ marginTop: 10 }}>
                {message}
            </h4>
        </div>
    )
}
