// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Select from 'react-select'
// Imports for APIs
import PropertyApi from '../../Api/PropertyApi';
import DeviceApi from '../../Api/DeviceApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import Asterisk from '../CommonComponents/Asterisk';
import AsteriskExplanation from '../CommonComponents/AsteriskExplanation';
// Imports of styles
import '../../App.css';
// Imports of contants
// - none

function AddLinkModal(props) {
  // Destructuring assignment from the props
  const { show, deviceId, setRefreshing, handleClose } = props

  const [linkURL, setLinkURL] = useState('');
  const [linkType, setLinkType] = useState([]);
  const [selectedTypeId, setSelectedTypeId] = useState('');
  const [posting, setPosting] = useState(false);
  // Alert
  const [response, setResponse] = useState(0);
  const [showResponse, setShowResponse] = useState(false);

  useEffect(() => {
    const fetchLinks = async () => {
      try {
        const result = await axios(PropertyApi("List?propertyType=Device&typeOf=Link"));
        setLinkType(result.data)
      } catch (error) {
        console.error("Error during Link modal while fetching")
      }
    }

    //Fetch link types
    fetchLinks();
  }, [])

  const linkOptions = linkType.map(link => {
    return { value: link.id, label: link.name }
  })

  const customStyles = {
    option: base => ({
      ...base,
      padding: '5px 0 5px 10px'
    }),
  }

  const cleanUp = () => {
    setLinkURL('')
    setSelectedTypeId('')
    handleClose(false)
  }
  
  const postLink = async() => {
    setPosting(true)

    const body = {
      "propertyId": `${selectedTypeId}`,
      "value": `${linkURL}`
    }
    
    // Create the parameters for axios
    const axiosParameters = {
      url: DeviceApi('Properties/Add', deviceId),
      method: 'post',
      data: body,
      headers: {
        'Content-Type': 'application/json',
        "accept": "application/json"
      }
    }

    // Call API endpoint with axios
    try {
      const response = await axios(axiosParameters);
      // Use this to refresh values
      setResponse(response.status)
      setShowResponse(true)

      setTimeout(() => {
        // Force trigger link fetching
        setRefreshing(response.data.result)
        setShowResponse(false)
        setPosting(false)

        cleanUp()
      }, 3000);
    } catch(error){
      console.warn("Error during Link posting", error);
      setResponse(error.response.status);
      setShowResponse(true)

      setTimeout(() => {
        setShowResponse(false)
        setPosting(false)
      }, 3000);
    }
  }

  const title = 'Link hozzáadása';

  const modalBody = (
    <>
      <Form>
          <Form.Group controlId="formLinkRef">
              <Form.Label>Link URL <Asterisk /></Form.Label>
              <Form.Control
                type="text"
                placeholder="Elvárt forma: https://www.hadocument.com/maradek_link"
                value = {linkURL}
                onChange={event =>  setLinkURL(event.target.value)}
              />
          </Form.Group>

          <Form.Group controlId="formLinkRefDescription">
              <Form.Label>Link típusa <Asterisk /></Form.Label>
              <Select
                options={linkOptions}
                styles={customStyles}
                placeholder="Válasz link típust"
                onChange = {(selected) => setSelectedTypeId(selected.value)}
              />
          </Form.Group>

          <Form.Text>
            <AsteriskExplanation />
          </Form.Text>
      </Form>
    </>
  )

  // Disable save button if any of the required fields is empty
  const isDisabledToSave = linkURL === '' || posting || selectedTypeId === ''

  const footer = (
    <>
      <Button
        variant="success"
        onClick={() => postLink()}
        disabled={isDisabledToSave}
      >
        Mentés
      </Button>
      { showResponse &&
        <div className = 'd-flex justify-content-center'>
          <Alert variant = {response === 200 ? 'success' : 'danger'} className='text-center m-0'>
            {response === 200 ? 'Sikeres link hozzáadás' : 'Sikertelen link hozzáadás'}
          </Alert>
        </div>
      }
      <Button
        variant="danger"
        onClick={() => cleanUp()}
        disabled={posting}
      >
        Mégse
      </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show = {show}
        size = "md"
        dropback="static"
        keyboard={false}
        handleClose = {() => handleClose(false)}
        title = {title}
        body = {modalBody}
        footer = {footer}
      />
    </>
  );
}

export default AddLinkModal;
