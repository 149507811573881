import React from 'react';

import Dropdown from 'react-bootstrap/Dropdown';

import { FaSearch } from 'react-icons/fa';
import { Link } from "react-router-dom";

import '../../App.scss';
import './Header.css';

export default function SearchDropdown() {

    // Custom profile
    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <div 
            ref={ref} 
            className="rounded-circle-icon-container"
            onClick={(e) => {
                        e.preventDefault();
                        onClick(e);
            }}
        >
            <FaSearch
                size='1.25rem'
                alt="search"
            />
        </div>
    ));
    CustomToggle.displayName = "Search dropdown"

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic-login"/>

                <Dropdown.Menu align="left">
                    <Link to = "/search" style={{ textDecoration: 'none' }}>
                        <Dropdown.Item as="button">
                            Egyszerű keresés
                        </Dropdown.Item>
                    </Link>
                    <Link to = "/search/detailed" style={{ textDecoration: 'none' }}>
                        <Dropdown.Item as="button">
                            Összetett keresés
                        </Dropdown.Item>
                    </Link>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}
