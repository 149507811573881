import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import "../App.css";
import "../App.scss";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Logo from "../cropped-logo.png";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import MissingQueryParameters from "../Components/CommonComponents/MissingQueryParameters";
import { useEffect } from "react";

function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ConfirmRegistrationPage() {
    // Parse query parameters
    const query = useQuery();
    const userId = query.get('userId');
    const code = query.get('code');
    // State
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    const confirmRegistration = async () => {
        try {
            const response = await axios(`Account/Confirmation`, {
                params: {
                    userId,
                    code
                }
            });
            console.warn("confirmRegistration", response.data);
            if (response.status === 200) {
                setError(false);
                setSuccess(true);
            }
        } catch (error) {
            console.error(error);
            setError(true);
            setSuccess(false);
        }
    }

    useEffect(() => {
        if (userId && code) {
            confirmRegistration();
        }
    }, [])

    if (userId === null || code === null) {
        return <MissingQueryParameters text="A megerősítéshoz tartozó link érvénytelen!" />
    }
    else {
        return (
            <Container fluid>
                <Row
                    className="justify-content-center align-items-center"
                    style={{ minHeight: "100vh" }}
                >
                    <Col className="col-6 text-center pt-4">
                        <img src={Logo} alt="HADocument logó" width="150px" />
                        <h3 className="my-4">Felhasználói regisztráció megerősítése</h3>
                        {error && (
                            <div className="w-100 text-center">
                                <Alert variant="danger" className="mb-4 mt-4 text-center mx-4">Hiba történt! Kérlek, próbáld újra!</Alert>
                            </div>
                        )}
                        {success && (
                            <div className="w-100 text-center">
                                <Alert variant="success" className="mb-4 mt-4 text-center mx-4">A regisztrációdat sikeresen megerősítetted!</Alert>
                                <Link to="/login">Tovább a bejelentkezéshez!</Link>
                            </div>
                        )}
                        <div className="d-flex w-100 justify-content-center my-4">
                            <Link to="/">Vissza a főoldalra!</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}

export default ConfirmRegistrationPage;
