// Imports from packages
import React from 'react';
// react-bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants
import { downloadTypeLabels } from '../../constants/bom'

export default function BOMDownloadTypeDropdown(props) {
    // Destructuring assignment from the props
    const { dropdownButtonLabel, handleTypeChange } = props
    return (
        <Dropdown className = "ml-3">
            <Dropdown.Toggle size="sm" variant="primary">
                {dropdownButtonLabel.label}
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {downloadTypeLabels.slice(1).map((downloadTypeLabel, index) => {
                    return (
                        <Dropdown.Item
                            as = "button"
                            key = {downloadTypeLabel.type}
                            eventKey = {index}
                            onClick = {() => handleTypeChange(downloadTypeLabel)}
                        >
                            {downloadTypeLabel.label}
                        </Dropdown.Item>
                    )
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}