// Imports from packages
import React from 'react';
import ReactPaginate from 'react-paginate';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
import '../../App.css';
import './Common.css';
// Imports of contants
// - none

export default function CustomPagination(props) {

    const forcePage = props.currentPage - 1;

    return (
        <div className = 'd-flex justify-content-center align-items-center'>
             <ReactPaginate
                pageCount = {props.pageCount}
                forcePage = {forcePage}
                marginPagesDisplayed = {1}
                pageRangeDisplayed = {3}
                onPageChange = {data  =>  props.setCurrentPage(data.selected + 1)}
                previousLabel = {'<'}
                previousClassName  = 'pagination-pages'
                previousLinkClassName = 'page-link'
                nextClassName  = 'pagination-pages'
                nextLabel = {'>'}
                nextLinkClassName = 'page-link'
                breakLabel = {'...'}
                breakClassName = 'break-class pagination-pages'
                breakLinkClassName = 'page-link'
                containerClassName = 'pagination'
                pageClassName = 'pagination-pages'
                pageLinkClassName = 'page-link'
                disabledClassName = 'disabled-item'
                activeClassName = 'customPaginationActive'
                activeLinkClassName = 'active-link'
            />
        </div>
    )
}
