// Imports from packages
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
// Imports for APIs
// - none
// Imports of our custom components
import PropertyItem from './PropertyItem';
import { alphabeticOrdering } from './detailedFunctions';
// Imports of styles
// - none
// Imports of contants
import { propertyType } from '../../../constants/propertyType';

export default function OtherPropSelect(props) {
    // Destructuring assignment from the props
    const { items, type, setPropertyFilters, deletePropFromFilterArray, categoryId } = props

    // Select option
    const [selectOptions, setSelectOptions] = useState(items)
    const [selectedOption, setSelectedOption] = useState('')
    const [chosenOtherProps, setChosenOtherProps] = useState([])

    // If we have selected propertiess previously, but now changed the category, all prop units should be deleted
    useEffect(() => {
        if(categoryId !== '' && chosenOtherProps.length !== 0){
            setChosenOtherProps([])
            setSelectOptions(items)
        }
    }, [categoryId])

    // Add new item to property container
    const makePropertyUnit = () => {
        // Get property from original item array
        const newUnit = selectOptions.filter(element => element.id === selectedOption.value)
        
        const newOtherPropArray = [
            ...chosenOtherProps, 
            ...newUnit
        ];
        setChosenOtherProps(newOtherPropArray);

        // Remove current item from selectableOptions
        const removedCurrentItemForSelectOptions = selectOptions.filter(element => element.id !== selectedOption.value)
        setSelectOptions(removedCurrentItemForSelectOptions)

        // Remove current item from selectedOoption
        setSelectedOption('')
    }

    // Delete specific item from property container
    const deletePropUnit = unitToBeDeleteId => {
        // Add current item from original array (items)
        const newSelectOptionArray =  items.filter(element => element.id === unitToBeDeleteId)
        const updateSelectOptions = [
            ...newSelectOptionArray,
            ...selectOptions
        ];
        setSelectOptions(alphabeticOrdering(updateSelectOptions))

        const filteredOherGrid = chosenOtherProps.filter(element => element.id !== unitToBeDeleteId)
        setChosenOtherProps(filteredOherGrid)

        // Delete prop from filterArray if it was in
        deletePropFromFilterArray(unitToBeDeleteId)
    }
    
    // Map property list for select
    const formatOptions = array => {
        const correctForm = array.map(property => {
            return { 
                value: property.id,
                label: property.name
            }
        })

        return correctForm;
    }

    return (
        <>   
            <Form className = "mt-3">
                <Form.Row>
                    <Form.Group controlId = "other-property-select" as = {Col} xs = {6}>
                        <Select
                            menuPortalTarget = {document.body}
                            options = {formatOptions(selectOptions)}
                            isClearable={true}
                            onChange = {selected => setSelectedOption(selected)}
                            placeholder = "Választható tulajdonságok"
                            value = {selectedOption}
                        />
                    </Form.Group>
                    <Form.Group controlId = "other-property-select-button" as = {Col} xs = {2}>
                        <Button
                            className = "ml-3"
                            style = {{ height: '39px' }} 
                            onClick = {() => makePropertyUnit()} 
                            disabled = {selectedOption === ''}
                        >
                            Hozzáadás
                        </Button>
                    </Form.Group>
                </Form.Row>
            </Form>
            { chosenOtherProps.map((property) => {
                return (
                    <PropertyItem
                        key = {`${property.id}`}
                        property = {property}
                        isMandatory = {type === propertyType.MANDATORY}
                        setPropertyFilters = {prop => setPropertyFilters(prop)}
                        deletePropFromFilterArray = {propToBeDeletedId => deletePropFromFilterArray(propToBeDeletedId)}
                        deletePropUnit = {unitToBeDeletedId => deletePropUnit(unitToBeDeletedId)}
                    />
                    )
                })
            }
        </>
    )
}
