// Imports from packages
import React, { useState, useEffect } from 'react';
import { FaTimes, FaCheck } from 'react-icons/fa';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants
// - none
export default function PasswordText(props) {
    const { content, password, regex, checkRegex, setFormTextStatus } = props;

    const TextState = {
        START: "#8e959b",
        NO_MATCH: "red",
        HAS_MATCH: "limegreen",
    }

    const [textStatus, setTextStatus] = useState(TextState.START)

    useEffect(() => {
        if(checkRegex){
            // Check regexp
            const foundMatch = password.match(regex);
            if(foundMatch){
                setTextStatus(TextState.HAS_MATCH)
                setFormTextStatus(true)
            }
            else{
                setTextStatus(TextState.NO_MATCH)
                setFormTextStatus(false)
            }
        }
    },[checkRegex])

    return (
        <div style={{ color: textStatus, fontSize: 14, width: "50%" }}>
            {textStatus === TextState.NO_MATCH &&
                <FaTimes size="0.6rem" className="mr-1"/>
            }
            {textStatus === TextState.HAS_MATCH &&
                <FaCheck size="0.6rem" className="mr-1"/>
            }
            {content}
        </div>
    );
}
