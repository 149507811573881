// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import Select from 'react-select'
// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
import StatisticsApi from '../../Api/StatisticsApi';
import CategoryApi from '../../Api/CategoryApi';
// Imports of our custom components 
import Loader from '../CommonComponents/Loader';
import SingleColumnPageTemplate from '../Pages/SingleColumnPageTemplate';
import DeviceGrid from './DeviceGrid';
import Statistics from './Statistics';
// Imports of styles
import '../../App.scss';
// Imports of contants
// - none

function MainPage() {
  // Statistics
  const [statistics, setStatistics] = useState({})

  // Latest devices
  const [latestDevices, setLatestDevices] = useState([])
  const [isFetching, setIsFetching] = useState(true)
  const [isFetched, setIsFetched] = useState(false)

  // Categories
  const [categories, setCategories] = useState([])
  const [categoryId, setCategoryId] = useState('')
  const [categoryName, setCategoryName] = useState('')

  // Manufacturers
  const [manufacturers, setManufacturers] = useState([])
  const [manufacturerName, setManufacturerName] = useState('')

  // Search filter
  const searchFilterOptions = [
    { value: 0, label: 'Név' },
    { value: 1, label: 'Típus szám' },
    { value: 2, label: 'HAD azonosító' }
  ]
  const [searchFilterName, setSearchFilterName] = useState(searchFilterOptions[0].label)

  // Search keyword
  const [searchKeyword, setSearchKeyword] = useState('')
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    // Statistics
    const fetchStatistics = async () => {
      const result = await axios(StatisticsApi('Home'));
      setStatistics(result.data)
    };
    // Latest devices
    const fetchLatestDevices = async () => {
      const result = await axios(DeviceApi('List/Newest'));
      setLatestDevices(result.data.devices)
      setIsFetching(false)
      setIsFetched(true)
    };

    // List of categories
    const fetchMainCategories = async () => {
      const result = await axios(CategoryApi('List'));

      // Flatten the array
      const flatArray = []
      result.data.forEach(mainCategory => {
        // First, get all subcategory's name for each main category
        let subCategories = ''
        if (mainCategory.children.length > 0) {
          mainCategory.children.forEach(subCategory => {
            subCategories += ' | ' + subCategory.name
          })
        }

        // Then, add main category first to array with all the subcategories in it's values
        flatArray.push({
          value: `${mainCategory.id + subCategories}`,
          label: mainCategory.name
        })

        // Lastly, the children with the parent's
        if (mainCategory.children.length > 0) {
          mainCategory.children.forEach(subCategory => {
            flatArray.push({
              value: `${subCategory.id} | ${mainCategory.name}`,
              label: ` >>  ${subCategory.name}`
            })
          })
        }
      })

      setCategories(flatArray)
    }

    // List of manufacturers
    const fetchManufacturers = async () => {
      const result = await axios(DeviceApi('Manufacturer/List'));
      setManufacturers(result.data)
    };

    // Fetch data
    fetchStatistics();
    fetchLatestDevices();
    fetchMainCategories();
    fetchManufacturers();

    return () => { setStatistics({}); setLatestDevices([]); setIsFetching(true); setIsFetched(false); setCategories([]); setManufacturers([]) }
  }, []);

  const manufacturerOptions = manufacturers.map((manufacturer, index) => {
    return { value: index, label: manufacturer }
  })

  const onChangeSelectedElement = (type, selected) => {
    switch (type) {
      case "category":
        if (selected === null) {
          setCategoryId('')
          setCategoryName('')
        }
        else {
          // [0] does the array flatting so ID is a string not an array
          setCategoryId(selected.value.split(" | ", 1)[0])
          setCategoryName(selected.label)
        }
        break;
      case "manufacturer":
        if (selected === null) {
          setManufacturerName('')
        }
        else {
          setManufacturerName(selected.label)
        }
        break;
      case "searchFilter":
        setSearchFilterName(selected.label)
        break;
      default:
        console.error('Unkown type: ' + type, selected)
    }
  }

  let centralColumn
  if (isFetching) {
    centralColumn = (
      <Loader />
    )
  }
  else {
    centralColumn = (
      <DeviceGrid
        title="Legutoljára feltöltött termékek"
        devices={latestDevices}
        isFetched={isFetched}
      />
    )
  }

  const searchInputs = () => {
    const filter = `?sF=${searchFilterName}`;
    const cat = categoryId !== '' ? `&c=${categoryId}_${categoryName}` : '';
    const man = manufacturerName !== '' ? `&mN=${manufacturerName}` : '';
    const key = searchKeyword !== '' ? `&sK=${searchKeyword}` : '';

    const values = filter + cat + man + key;
    return values;
  }

  const isKeyEnter = event => {
    // keyCode 13 is an identifier for the enter key
    const enterButton = 13
    if (event.keyCode === enterButton) {
      setRedirect(true)
    }
  }

  const customStyles = {
    option: (styles, { data }) => {
      const isMainCategory = !data.label.includes(">>");
      return {
        ...styles,
        backgroundColor: isMainCategory ? 'lightGray' : 'white',
        color: 'black',
        padding: '5px 0 5px 10px'
      };
    },
  }

  const headerRow = (
    <div className="pt-1">
      <Statistics
        numberOfDevices={statistics.numberOfDevices}
        createdDevices={statistics.createdDevices}
        numberOfUsers={statistics.numberOfUsers}
        madeSearches={statistics.madeSearches}
      />
      <Row className="m-4  search-search-bar">
        <Col lg="4">
          <Select
            options={categories}
            styles={customStyles}
            isClearable={true}
            placeholder="Összes kategória"
            onChange={(selected) => onChangeSelectedElement('category', selected)} />
        </Col>
        <Col lg="2">
          <Select
            options={manufacturerOptions}
            styles={customStyles}
            isClearable={true}
            placeholder="Összes gyártó"
            onChange={(selected) => onChangeSelectedElement('manufacturer', selected)} />
        </Col>
        <Col lg="2">
          <Select
            options={searchFilterOptions}
            styles={customStyles}
            defaultValue={searchFilterOptions[0]}
            onChange={(selected) => onChangeSelectedElement('searchFilter', selected)} />
        </Col>
        <Col lg="2">
          <input
            value={searchKeyword}
            onChange={e => setSearchKeyword(e.target.value)}
            type="text"
            placeholder="Keresési kifejezés..."
            className='search-keyword-input'
            onKeyPress={() => isKeyEnter(event)}
          />
        </Col>
        <Col lg="2" className="d-flex justify-content-center">
          <Link
            to={{
              pathname: "/search",
              search: `${searchInputs()}`,
              state: { fromDashboard: true }
            }}
            style={{ width: '100%' }}
          >
            <Button style={{ width: '100%' }}>
              Keresés
            </Button>
          </Link>
        </Col>
      </Row>
      {redirect &&
        <Redirect to={`/search${searchInputs()}`} />
      }
    </div>
  )

  return (
    <SingleColumnPageTemplate
      headerRow={headerRow}
      centralColumn={centralColumn}
    />
  );
}

export default MainPage;
