import React from 'react';
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { FaMapMarkedAlt } from 'react-icons/fa';

export default function AddressPropertyEditor(props) {
    const { value, onChange } = props;
    return (
        <Form.Group
            controlId="custom-time"
            className="d-flex justify-content-start align-items-center mb-0"
            style={{ width: "100%" }}
        >
            <InputGroup>
                <InputGroup.Prepend>
                    <InputGroup.Text><FaMapMarkedAlt /></InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    type="text"
                    value={value}
                    onChange={(event) => onChange({ value: event.target.value })}
                />
            </InputGroup>
        </Form.Group>
    )
}