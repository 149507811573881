export const basicEnumEndpoint = () => process.env.REACT_APP_BACKEND_API_BASE_URL + process.env.REACT_APP_BACKEND_API_VERSION_NUMBER + '/Enum/'

function EnumApi(endpoint, id) {
    
    

    const exceptionalNames = ['Property'];

    let isExceptional = false
    exceptionalNames.forEach(name => {
        if(endpoint.includes(name)) {
            isExceptional = true
        }
    })

    if(isExceptional) {
        return `/Enum/Property/${id}`
    }
    else{
        return `/Enum/${id}`
    }
}

export default EnumApi;
