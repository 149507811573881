// Imports from packages
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Imports for APIs
// -none
// Imports of our custom components
// -none
// Imports of styles
// -none// Imports of contants
// -none

export default function PropertyTitle(prop) {
    return (
        <Row>
            <Col className = "text-left my-3 ml-4">
                <h3>{prop.title}</h3>
            </Col>
        </Row>
    );
}