// Imports from packages
import React from 'react';
import { FaCheck, FaExchangeAlt, FaTrashAlt } from 'react-icons/fa';
// Imports for APIs
// - none
// Imports of our custom components
import isBetween from '../../../utilities/mathFunctions';
// Imports of styles
// - none
// Imports of contants
import { Intervals } from '../../../constants/distributorIntervals';

const reviewIconSize = "1.1em"
const reviewIcons = [
  { type: "approve", label: "jóváhagyás", icon: <FaCheck size={reviewIconSize} />, backgroundColor: "#33c92e" },
  { type: "recommend", label: "módosítás", icon: <FaExchangeAlt size={reviewIconSize} />, backgroundColor: "#fed961" },
  { type: "removal", label: "törlés", icon: <FaTrashAlt size={reviewIconSize} />, backgroundColor: "#fd3258" },
]

export const mapReviewTypeToIconAndColor = (reviewType) => {
  let review = reviewIcons.find(reviewIcon => reviewIcon.type === reviewType)
  if (!review) {
    review = reviewIcons[0]
  }
  return { icon: review.icon, label: review.label, backgroundColor: review.backgroundColor }
}

export const mapConnectedDevicesForAccordion = (connections) => {
  const connectedDevices = connections.map(connection => {
    return {
      id: `${connection.name} (${connection.itemNumber})`,
      value: `${connection.manufacturerName}, ${connection.category.name}`,
      meta: connection.deviceId,
      propertyId: connection.id,
    }
  })

  return connectedDevices
}

export default function getRange(number) {
  let result = ""
  if (number === 0) {
    // Show '-' without € sign
    result = '-';
  }
  else {
    Intervals.forEach(interval => {
      if (isBetween(number, interval.minimum, interval.maximum))
        result = interval.label + " €"
    })
  }

  if (result === "")
    console.error(`The number (${number}) was not in any interval`)

  return result
}