import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomModal from './CustomModal';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import '../../App.scss';

function CreateFromFileModal(props) {

    const { isLoading, apiResponse, error } = props;

    const [file, setFile] = useState("");

    const handleFileUpload = () => {
        props.handleFileUpload(props.selectedBom.id, file);
    }

    const body = (
        <div>
            <Form>
                <p>Válassz egy fájlt a BOM módosításához!</p>
                <Form.Group>
                    <Form.Label>Fájl</Form.Label>
                    <Form.Control
                        type="file"
                        accept="text/plain,text/csv"
                        // @ts-ignore
                        onChange={event => setFile(event.target.files[0])}
                    />
                </Form.Group>
            </Form>
            {apiResponse && (
                <Alert variant="info">
                    <ul>
                        <li>Sikeres importálások száma: <b>{apiResponse.numberOfSuccesses}</b>.</li>
                        <li>Sikertelen importálások száma: <b>{apiResponse.numberOfFails}</b>.</li>
                        <li>Ez az ablak automatikusan bezárul.</li>
                    </ul>
                </Alert>
            )}
            {error && (
                <Alert variant="danger">Hupsz! Valami baj van: <b>{Object.entries(error)[2][1].statusText}</b></Alert>
            )}
        </div>
    );

    return (
        <>
            <CustomModal
                show={props.show}
                size="md"
                dropback="static"
                keyboard={false}
                handleClose={props.handleClose}
                title={<h3>BOM módosítása fájl alapján: {props.selectedBom?.name}</h3>}
                body={body}
                footer={isLoading ? (
                    <Spinner color="primary" />
                ) : (
                    <>
                        <Button
                            variant="success"
                            onClick={() => handleFileUpload()}
                        >
                            Módosítás
                        </Button >
                        <Button
                            variant="danger"
                            onClick={() => props.handleClose()}
                        >
                            Mégse
                        </Button>
                    </>
                )}
            />
        </>
    );
}

export default CreateFromFileModal;
