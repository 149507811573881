import { filterSelectTypes } from "../../../constants/companyDeviceSelect"

export default function filteringDevicesByReviews(devices, filtering) {
    if (filtering === null) {
        return devices
    }
    else if (filtering === filterSelectTypes.Approved) {
        return devices.filter(device => device.summarizedReviews['numberOfApprovals'] > 0)
    }
    else if (filtering === filterSelectTypes.Modify) {
        return devices.filter(device => device.summarizedReviews['numberOfChangeRequests'] > 0)
    }
    else if (filtering === filterSelectTypes.Delete) {
        return devices.filter(device => device.summarizedReviews['numberOfRemovalRequests'] > 0)
    }
}