// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
// react-bootstrap
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import Asterisk from '../CommonComponents/Asterisk';
import AsteriskExplanation from '../CommonComponents/AsteriskExplanation';
// Imports of styles
// - none
// Imports of contants
// - none

export default function AddNewDeviceConnection(props) {
  // Destructuring assignment from redux props
  const { show, handleClose, deviceId, hadId, setRefreshing } = props

  const [connectionTypes, setConnectionTypes] = useState([]);
  const [selectedConnectionTypes, setSelectedConnectionTypes] = useState('');
  const [selectedDeviceId, setSelectedDeviceId] = useState('');

  // Mischevious variables
  const [showAlert, setShowAlert] = useState(false);
  const [connectionResponse, setConnectionResponse] = useState(0);
  const [posting, setPosting] = useState(false);

  const mappingValuesForSelect = array => {
    const mappedArray = array.map(element => {
      const option = {
        value: element.id,
        label: element.name
      }

      return option
    })

    return mappedArray
  }

  useEffect(() => {
    const fetchConnectionTypes = async () => {
        try{
            const response = await axios(PropertyApi('List?propertyType=Device&typeOf=DeviceReference'));
            setConnectionTypes(mappingValuesForSelect(response.data))
        } catch(error){
            console.warn("Error during connection type fetching process")
        }
    };

    if(deviceId !== undefined){
        fetchConnectionTypes();
    }
  }, [deviceId]);

  const closeModal = (id) => {
    // Reset values
    setSelectedConnectionTypes('')
    setSelectedDeviceId('')

    // Close modal through props
    if(id !== undefined){
        setRefreshing(id)
    }
    handleClose(false)
  }

  const checkIfHadId = uniqueId => {
    return uniqueId.includes("HAD");
  }

  const postConnection = async () => {
    setPosting(true)

    const dataBody = {
        propertyId: `${selectedConnectionTypes.value}`,
        value: `${selectedDeviceId}`
    }

    const config = {
      headers: {
        "accept": "*/*",
        "Content-Type": "application/json"
      }
    }

    try{
        const path = checkIfHadId(selectedDeviceId) ? DeviceApi('Properties/Add', hadId, true) : DeviceApi('Properties/Add', deviceId, false);
        const response = await axios.post(path, dataBody, config);

        setConnectionResponse(response.status)
        setShowAlert(true)

        setTimeout(() => {
            setShowAlert(false)
            setConnectionResponse(0)

            closeModal(response.data.result)
        }, 2000)

        setPosting(false)
    } catch(error) {
        console.warn("Error during connection type fetching process")

        setConnectionResponse(error.response.status)
        setShowAlert(true)

        setTimeout(() => {
            setShowAlert(false)
            setConnectionResponse(0)
        }, 2000)
        setPosting(false)
    }
  }
  
  const title = 'Kapcsolódó termék hozzáadása';
  
  const modalBody = (
    <>
        <Form>
            <Form.Text className = "mb-3">
            <AsteriskExplanation />
            </Form.Text>
            <Form.Group controlId = "formDeviceConnectionType">
                <Form.Label>Kapcsolat típusa <Asterisk /></Form.Label>
                <Select 
                    options = {connectionTypes}
                    onChange = {selected => setSelectedConnectionTypes(selected)}
                    placeholder = "Kapcsolat típusának választása"
                    value = {selectedConnectionTypes}
                />
            </Form.Group>
            { selectedConnectionTypes !== '' &&
                <Form.Group controlId="formDeviceConnectionId">
                    <Form.Label>Egyedi azonosító</Form.Label>
                    <Form.Control
                        type="text"
                        value = {selectedDeviceId}
                        placeholder = "12345678-1234-1234-1234-123456789abc / HAD012345678"
                        onChange={event => setSelectedDeviceId(event.target.value)}
                    />
                    <Form.Text className="text-muted">
                      {`Megtalálható az azonosító az url-ben vagy az eszköz profil "Alap információk" szekcióban.`}
                    </Form.Text>
                </Form.Group>
            }
        </Form>

        { showAlert && (
            <Alert variant = {connectionResponse === 200 ? "success" : "danger"}>
                { connectionResponse === 200 ? "Sikeres eszköz hozzáadás" : "Nem létezik ilyen azonosítóval rendelkező eszköz"}
            </Alert>
        )}
    </>
  )

  const disabled = selectedDeviceId === '' ||  selectedConnectionTypes === ''

  const footer = (
    <>
        <Button
            variant="success"
            onClick={() => postConnection()}
            disabled={disabled || posting}
        >
            Mentés
        </Button>
        <Button
            variant="danger" 
            onClick={() => closeModal()}
            disabled={posting}
        >
            Mégse
        </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show = {show}
        size="md"
        dropback="static"
        keyboard={false}
        handleClose = {() => closeModal()}
        title = {title}
        body = {modalBody}
        footer = {footer}
      />
    </>
  );
}
