// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
// react-bootstrap
import Button from 'react-bootstrap/Button';
// Imports for APIs
import ProjectApi from '../../Api/ProjectApi';
import PropertyApi from '../../Api/PropertyApi';
import BOMApi from '../../Api/BOMApi';
// Imports of our custom components
import TwoColumnPageTemplate from '../Pages/TwoColumnPageTemplate';
import ProjectPropertyDropdown from './ProjectPropertyDropdown';
import AddNewBomModal from '../Modals/AddNewBomModal';
import LoaderWithText from '../CommonComponents/LoaderWithText';
import CustomAlert from '../CommonComponents/CustomAlert';
import BOMList from '../BOM/BOMList';
import ProjectHeader from './ProjectHeader';
import { addSubProjectsToProfileObject } from './projectFunctions';
import ProfileCategories from '../Profile/ProfileCategories';
import CustomAccordion from '../CommonComponents/CustomAccordion';
import FileBox from '../CommonComponents/FileBox';
// Imports of styles
import './Project.css';
import CreateFromFileModal from '../Modals/CreateFromFileModal';
import useCreateBomFromFile from "../../hooks/useCreateBomFromFile";
// Imports of contants
// - none

function ProjectPage() {
    // Project variables
    const { companyId, projectId } = useParams();
    const [project, setProject] = useState({})
    const [subProjects, setSubProjects] = useState([])
    const [fetchedSubProjects, setFetchedSubProjects] = useState(false)
    const [hasData, setHasData] = useState(false)
    const [isFetchingProjectProfile, setIsFetchingProjectProfile] = useState(false)

    // Refresh after modification
    const [refresh, setRefresh] = useState(0)

    // All document / filetype a project can get
    const [documentTypes, setDocumentTypes] = useState([]);
    const [files, setFiles] = useState({})

    // BOMs of project
    const [bomList, setBomList] = useState([])
    const [fetchBoms, reFetchBoms] = useState(false)
    const [bomListComponent, setBomListComponent] = useState();
    const [isFetchingBomList, setIsFetchingBomList] = useState(false)

    // - Create new BOM modal
    const [isOpenCreateNewBomModal, setIsOpenCreateNewBomModal] = useState(false)
    const [updateBomWithFile, setUpdateBomWithFile] = useState(null)
    const [showCreateFromFileDialog, setShowCreateFromFileDialog] = useState(false)
    const { mutate: uploadFileToUpdateBom, data: apiData, isLoading, isSuccess, isError, error } = useCreateBomFromFile();
    const [responseFromBomUpdate, setResponseFromBomUpdate] = useState(null)

    useEffect(() => {
        if (isSuccess) {
            setResponseFromBomUpdate(apiData);
            setTimeout(() => inactivateCreateFromFile(), 5000);
        }
    }, [isSuccess, apiData])

    useEffect(() => {
        if (isError) {
            //setTimeout(() => inactivateCreateFromFile(), 5000);
        }
    }, [isError])

    // Mischievous variables
    const [projectNotFound, setProjectNotFound] = useState(false)
    const [isEditModeOn,] = useState(false)

    // Mandatory and Optional properties
    const [editMode, setEditMode] = useState(false)

    const activateCreateFromFile = (bom) => {
        setShowCreateFromFileDialog(true);
        setUpdateBomWithFile(bom);
        setResponseFromBomUpdate(null);
    }

    const inactivateCreateFromFile = () => {
        setShowCreateFromFileDialog(false);
        setUpdateBomWithFile(null);
        setResponseFromBomUpdate(null);
    }

    // Fetching BOMs
    // Show Loader only if we move from child to parent, or on page open
    const fetchBomsForProject = async () => {
        try {
            setIsFetchingBomList(true)

            const result = await axios(BOMApi(`Project/${projectId}`));
            if (result.status === 200) {
                setBomList(result.data)
            }
            setIsFetchingBomList(false)
        } catch (error) {
            //console.error('fetchBomsForProject', error);
            setIsFetchingBomList(false)
        }
    }

    // useEffect for BOM
    // Triggeres on: BOMItem delete, after modal is closed
    useEffect(() => {
        // Fetch BOMs
        if (isOpenCreateNewBomModal === false) {
            fetchBomsForProject();
        }
    }, [isOpenCreateNewBomModal, fetchBoms, projectId])

    // If bomList is modified component should be updated
    useEffect(() => {
        const component = (
            <BOMList
                bomList={bomList}
                isOpenCreateNewBomModal={isOpenCreateNewBomModal}
                setIsOpenCreateNewBomModal={setIsOpenCreateNewBomModal}
                showCreateFromFileDialog={showCreateFromFileDialog}
                setShowCreateFromFileDialog={setShowCreateFromFileDialog}
                projectId={projectId}
                updateBomList={() => reFetchBoms(!fetchBoms)}
                activateCreateFromFile={(bom) => activateCreateFromFile(bom)}
            />
        )
        setBomListComponent(component)
    }, [bomList])

    useEffect(() => {
        // Fetch all projects from company
        const fetchProjectProfile = async () => {
            setIsFetchingProjectProfile(true)

            try {
                const result = await axios(ProjectApi(`${projectId}/Profile`));
                setProject(result.data);

                setHasData(true);
                setIsFetchingProjectProfile(false)

                // There is an authorized user so download further informations
                fetchSubProjectProfile();
                fetchFileTypes();
                fetchFileInformation();
            } catch (error) {
                setHasData(true);
                setProjectNotFound(true);
                setIsFetchingProjectProfile(false)
            }
        }

        // Get subprojects if there is any
        const fetchSubProjectProfile = async () => {
            setFetchedSubProjects(false)

            try {
                const subProjectResult = await axios(ProjectApi(`Company/${companyId}/Subs`));

                // Get subProjects
                // - it will give back a single object in an array 
                const projectById = subProjectResult.data.filter(project => project.id === projectId)[0]

                // If project has subproject, then map them to correct format
                // - will acquire a new variable - linkToPage
                let projectProfile
                if (projectById !== undefined) {
                    projectProfile = addSubProjectsToProfileObject(projectById.subProjects, companyId)
                }
                else {
                    projectProfile = []
                }

                setSubProjects(projectProfile)
                setFetchedSubProjects(true)
            } catch (error) {
                console.error("Error during subproject's profile fetching", error)
            }
        }

        const fetchFileTypes = async () => {
            const result = await axios(PropertyApi(`List?propertyType=Project&typeOf=FileReference`));
            const fileOptions = result.data;
            setDocumentTypes(fileOptions)
        };

        const fetchFileInformation = async () => {
            const filesFromApi = await axios(ProjectApi(`${projectId}/Properties/Files`));
            setFiles(filesFromApi.data.properties)
        };

        // Fetch projects
        if (projectId !== undefined) {
            fetchProjectProfile();
        }
    }, [projectId, isEditModeOn, refresh])

    const toggleMode = () => {
        setEditMode(!editMode);
    }

    const handleCreateBomFromFile = (bomId, file) => {
        // eslint-disable-next-line no-console
        console.log("handleCreateBomFromFile", bomId, file);
        uploadFileToUpdateBom({ bomId, file });
        //updateBomFromFile(bomId, file);
    }

    const headerRow = (
        <div className="container-fluid">
            <div className="row">
                <div className="col-lg-12 d-flex justify-content-center text-center mt-2">
                    <h1>Projekt adatlapja</h1>
                </div>
            </div>
            {!projectNotFound ? (
                hasData &&
                <ProjectHeader
                    project={project}
                    setProject={newProject => setProject(newProject)}
                    projectHasParentProject={project.parentProjectId !== null && project.parentProjectNam !== null}
                    companyId={companyId}
                />
            ) : (
                <div className="mt-3">
                    <CustomAlert width="w-30" variant="danger" message="Nem létezik ilyen azonosítóval rendelkező projekt, vagy nem alkalmazottja a projekt cégének" />
                </div>
            )}
        </div>
    )

    const propertiesBox = (
        <div style={{ backgroundColor: "white", textAlign: 'left' }} id="project-properties">
            <div className="d-flex justify-content-between align-items-center">
                <h2 className="p-3 mr-1">Projekt tulajdonságai</h2>
                <div className="w-50 d-flex align-items-center justify-content-end">
                    <ProjectPropertyDropdown
                        project={project}
                        documentTypes={documentTypes}
                        setRefresh={newValue => setRefresh(newValue)}
                        isFetchingProjectProfile={isFetchingProjectProfile}
                    />
                    <Button
                        variant="outline-info"
                        className="m-1 mx-3"
                        onClick={toggleMode}
                    >
                        {editMode ? "Módosítás befejezése" : "Profil módosítása"}
                    </Button>
                </div>
            </div>
            <div className="px-3 pb-3">
                {Object.keys(project).length !== 0 && (
                    Object.keys(project.categorizedNormalProperties).length === 0 ?
                        (
                            <CustomAlert
                                variant="warning"
                                message="Nincsenek a projektnek tulajdonságai"
                                width="w-100"
                            />
                        ) : (
                            <ProfileCategories
                                profile={project}
                                editMode={editMode}
                                profileId={project.id}
                                setRefreshing={updatedProject => setProject(updatedProject)}
                                entity="project"
                            />
                        )
                )
                }
                {fetchedSubProjects ? (
                    <CustomAccordion
                        key={`${project.id}-profile-subprojects`}
                        title="Alprojektek"
                        items={subProjects}
                        type='subProjects'
                    />
                ) : (
                    <LoaderWithText content="Alprojekt(ek)" />
                )
                }
                {Object.keys(files).length !== 0 ?
                    (
                        <FileBox
                            files={files}
                            entityName="projekthez"
                            postType="Project"
                        />
                    ) : (
                        <FileBox
                            files={{}}
                            entityName="projekthez"
                            postType="Project"
                        />
                    )
                }
            </div>
        </div>
    )

    const BOMColumn = (
        <>
            {bomListComponent}
            <AddNewBomModal
                show={isOpenCreateNewBomModal}
                projectId={projectId}
                handleClose={() => setIsOpenCreateNewBomModal(false)}
            />
            <CreateFromFileModal
                show={showCreateFromFileDialog}
                handleClose={() => inactivateCreateFromFile()}
                handleFileUpload={(bomId, file) => handleCreateBomFromFile(bomId, file)}
                selectedBom={updateBomWithFile}
                apiResponse={responseFromBomUpdate}
                isLoading={isLoading}
                error={error}
            />
        </>
    )

    const conditionalLeftColumn = isFetchingProjectProfile ? <LoaderWithText content="Projekt adatok" /> : propertiesBox
    const conditionalRightColumn = isFetchingBomList ? <LoaderWithText content="BOM" /> : BOMColumn

    return (
        <TwoColumnPageTemplate
            headerRow={headerRow}
            leftColumn={!projectNotFound && conditionalLeftColumn}
            rightColumn={!projectNotFound && conditionalRightColumn}
        />
    );
}

export default ProjectPage;
