// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaRegFilePdf, FaInfoCircle } from 'react-icons/fa';
// react-bootstrap
import Button from 'react-bootstrap/Button';
// Imports for APIs
import FileApi from '../../Api/FileApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import FileDownload from './FileDownload';
import makeFlatArrayfromObject from './utils/makeFlatArrayfromObject';
import BoxListGroup from './BoxListGroup';
import BoxListGroupItem from './BoxListGroupItem';
import FileInformationModal from '../Modals/FileInformationModal';
import LoaderWithText from '../CommonComponents/LoaderWithText';
// Imports of styles
// - none
// Imports of contants
// - none

export default function FileBox(props) {
    // Destructuring assignment from redux props
    const { files, entityName, postType } = props

    // Own variables
    const [formatedFiles, setFormatedFiles] = useState([]);
    const [fileReferenceId, setFileReferenceId] = useState('');

    // When we have files object, put all 'sub' objects inside one array
    useEffect(()=> {
        const modifyFileArray = async() => {
            const result = await axios(PropertyApi(`List?propertyType=${postType}&typeOf=FileReference`));        
            const fileTypeArray = { 
                values: result.data,
                type: "file"
            }; 

            let allFilesArray = makeFlatArrayfromObject(files, fileTypeArray)
            setFormatedFiles(allFilesArray)
        }

        if(Object.keys(files).length !== 0){
            modifyFileArray();
        }
    }, [files])

    // Fetch file with FileApi
    const fetchFile = async (fileId, fileName) => {
        const result = await axios(FileApi(fileId), { responseType: 'blob' });

        // Download blob as PDF
        FileDownload(result, fileName);
    };

    const mappedFiles = formatedFiles.map((file, index) => {
        // The header's don't have value, only id
        const fullFileNameForDownload = `${file.value}.${file.extension}`
        const content = file.value !== "" ? (
            <>
                <Button onClick = {() => fetchFile(file.fileId, fullFileNameForDownload)}>
                    <FaRegFilePdf className = "mr-1"/>
                    {fullFileNameForDownload}
                </Button>
                <FaInfoCircle
                    size="1.5rem"
                    onClick={() => setFileReferenceId(file.fileId)}
                    style={{ cursor: "pointer" }}
                    className = "ml-2"
                />
            </>
        ) : (
            <b>{file.id}</b>
        )
        
        return (
            <BoxListGroupItem
                id= {file.id}
                content = {content}
                key = {`file-${index}`}
            />
        )
    })

    const showLoaderOrBoxListGroup = Object.keys(files).length !== 0 && formatedFiles.length !== 0 ? (
        <BoxListGroup
            title = "Letölthető fájlok"
            items = {mappedFiles}
            itemPluralName = "fájlok"
            entityName = {entityName}
        />
    ) : (
        <LoaderWithText content="Fájl(ok)" />
    )

    return (
        <>
            <FileInformationModal
                show={fileReferenceId !== ''}
                fileReferenceId={fileReferenceId}
                handleClose={() => setFileReferenceId('')}
            />
            { Object.keys(files).length === 0 ? (
                <BoxListGroup
                    title = "Letölthető fájlok"
                    items = {[]}
                    itemPluralName = "fájlok"
                    entityName = {entityName}
                />
            ) : (
                showLoaderOrBoxListGroup
            )}
        </>
    );
}
