// Imports from packages
import React, { useState } from 'react';
import axios from 'axios';
// react-bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import CompanyApi from '../../Api/CompanyApi'
// Imports of our custom components
import CustomModal from './CustomModal';
// Imports of styles
// - none
// Imports of contants
// - none

export default function AddEmployeeModal(props) {
    // Destructuring assignment from the props
    const { show, companyId, updateList, handleClose } = props

    // New employee 
    const [newEmployeeUsername, setNewEmployeeUsername] = useState('')
    const [showEmployeeResponseAlert, setShowEmployeeResponseAlert] = useState(false)
    const [employeeResponse, setEmployeeResponse] = useState(0)
    const [posting, setPosting] = useState(false)

    const onNewEmployeeUsernameChange = (event) => {
        setNewEmployeeUsername(event.target.value)
    }

    // Add new employee to company
    const postEmployee = async () => {
        // Double click protection
        setPosting(true);

        // Create the parameters for axios
        const axiosParameters = {
            url: CompanyApi(`${companyId}/Employee`),
            method: 'post',
            data: `"${newEmployeeUsername}"`,
            headers: {
                'Content-Type': 'application/json'
            }
        }
        // Call API endpoint with axios
        axios(axiosParameters)
        .then(response => {
        if(response.status === 200) {
            // Result holds the updated list, so adding it to employeeList will update the list automatically
            updateList(response.data)

            setEmployeeResponse(200)
            setShowEmployeeResponseAlert(true)
            setTimeout(() => {
                setShowEmployeeResponseAlert(false) 

                setPosting(false);
                handleClose();
            }, 2000)
        }
        })
        .catch(error => {
            console.error(error);

            setEmployeeResponse(error.response.status)
            setShowEmployeeResponseAlert(true)
            setTimeout(() => {
                setShowEmployeeResponseAlert(false)
                setPosting(false);
            }, 4000)
        })
    }

    // Modal parameters
    const title = "Új alkalmazott felvétele";

    const body = (
        <>
            <Form>
                <Form.Group controlId="formEmployeeAdd">
                    <Form.Label><b>Felhasználónév</b></Form.Label>
                    <Form.Control
                        type="text"
                        value = {newEmployeeUsername}
                        placeholder = "Új alkalmazott felhasználóneve"
                        onChange={event => onNewEmployeeUsernameChange(event)}
                    />
                </Form.Group>
                {showEmployeeResponseAlert && (
                    <Alert variant = {employeeResponse === 200 ? "success" : "danger"}>
                        {
                            employeeResponse === 200 ? 
                                "Sikeres alkalmazott hozzáadás"
                            :
                                "Sikertelen alkalmazott hozzáadás. Nincs ilyen nevű felhasználó"
                        }
                    </Alert>
                )}
            </Form>
        </>
    );

    const footer = (
        <>
            <Button
                variant="success"
                onClick={postEmployee}
                disabled={posting}
            >
                Felvétel
            </Button>
            <Button
                variant="danger"
                onClick={handleClose}
                disabled={posting}
            >
                Elvetés
            </Button>
        </>
    );

    return (
        <>
            <CustomModal
                show={show}
                size="md"
                dropback="static"
                keyboard={false}
                handleClose={handleClose}
                title={title}
                body={body}
                footer={footer}
            />
        </>
    );
}