import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default function PropertyEditorAlert(props) {
    const { type, message } = props.data;
    const isSuccess = type.includes('Success');
    if (type.includes('property')) {
        return <Alert variant={isSuccess ? "success" : "danger"}>{message}</Alert>;
    }
    else {
        return null;
    }
}
