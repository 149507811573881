export const allPagesForTest = [
    {
        category: 'Általános, vegyes oldalak',
        pages: [
            { path: '/HADocument', label: 'HADocument' },
            { path: '/HADstair', label: 'HADstair' },
            { path: '/partners', label: 'Partnerek' },
            { path: '/connections', label: 'Kapcsolatok' }
        ]
    },
    {
        category: 'Felhasználóhoz kapcsolódó oldalak',
        pages: [
            { path: '/user', label: 'Felhasználó profilja' },
            { path: '/user/confirm/zero2-vs-ichigo', label: 'Regisztráció megerősítése' },
            { path: '/user/login', label: 'Bejelentkezés' },
            { path: '/registration', label: 'Felhasználói regisztráció' },
            { path: '/user/companies', label: 'Felhasználó cégei' }
        ]
    },
    {
        category: 'Céghez kapcsolódó oldalak',
        pages: [
            { path: '/company/', label: 'Cég profilja', parameter: 'companyId' },
            { path: '/company/', label: 'Cég eszközei', parameter: 'companyId' },
            { path: '/company/', label: 'Cég disztribúciói', parameter: 'companyId' },
            { path: '/company-registration', label: 'Céges regisztráció' },
            { path: '/company/', label: 'Cég projektjei', parameter: 'companyId' }
        ]
    },
    {
        category: 'Eszközökhöz kapcsolódó oldalak',
        pages: [
            { path: '/device/', label: 'Eszköz adatlapja', parameter: 'deviceId' },
            { path: '/search', label: 'Egyszerű keresése' },
            { path: '/search/detailed', label: 'Összetett keresése' }
        ]
    },
    {
        category: 'Adminisztratív oldalak',
        pages: [
            { path: '/admin/', label: 'Adminisztrátori vezérlőpult' }
        ]
    }
];