// Imports from packages
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaPlus, FaUserTie, FaCheckDouble, FaSearch } from 'react-icons/fa';
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of contants
import { HADColors } from '../../constants/color'

function Metadates(props) {

  // Common properties
  const commonIconSize = "2em"
  const metaDataItemstyle = {
    position:'relative',
    top: 'calc(50% - 16px)'
  }

  // Items for rendering
  const metaDataItems = [
    {
      label: 'Létrehozta',
      value: props.creatorName || 0,
      icon: <FaPlus size = {commonIconSize} style = {metaDataItemstyle} />,
      color: HADColors.Grey
    },
    {
      label: 'Hitelesítés',
      value: props.completeValidations || 0,
      icon: <FaCheckDouble size = {commonIconSize} style = {metaDataItemstyle} />,
      color: HADColors.Green
    },
    {
      label: 'Tulajdonos',
      value: props.ownerName || 0,
      icon: <FaUserTie size = {commonIconSize} style = {metaDataItemstyle} />,
      color: HADColors.Yellow
    },
    {
      label: 'Keresés termékre',
      value: props.searches || 0,
      icon: <FaSearch size = {commonIconSize} style = {metaDataItemstyle} />,
      color: HADColors.Blue
    },
  ]

  return (
    <Row 
      className = "py-3 text-center align-items-center justify-content-center"  
      style={{ backgroundColor: 'white', margin: '15px 1.8rem' }}
    >
      {metaDataItems.map((item, index) => {
        return (
      <Col lg="3" style = {{ display: 'flex' }} key = {`statistics-item-${index}`}>
        <div style = {{
          height: '75px',
          width: '75px',
          borderRadius: '50%',
          backgroundColor: item.color,
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}>
          {item.icon}
        </div>
        <div style = {{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          paddingLeft: 20
        }}>
          <div style = {{
            order: 1,
            fontFamily: '"Open Sans", sans-serif',
            fontWeight: 600,
            fontSize: '20px'
          }}>
            {item.value}
            </div>
          <div style = {{
            order: 2,
            color: "gray"
          }}>
            {item.label}
          </div>
        </div>
      </Col>
      )})}
    </Row>
  )
}

export default Metadates;
