import React from 'react';
import Spinner from 'react-bootstrap/Spinner';

export default function Loader() {
    return (
        <div style={{ marginTop: 10, textAlign: 'center' }}>
            <Spinner animation="border" variant="info" role="status">
                <span className="sr-only">Betöltés...</span>
            </Spinner>
        </div>
    )
}