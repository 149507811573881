// Imports from packages
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function PropertyCustomCheckbox(props) {
    const { label, value, onChange } = props
    return (
        <Row>
            <Col className="d-flex justify-content-end align-items-center m-2">
                <div className="d-flex justify-content-end align-items-center">
                    {label}
                </div>
            </Col>
            <Col className="d-flex justify-content-start align-items-center m-2">
                <div className="d-flex justify-content-start align-items-center">
                <input
                    name="isMandatory"
                    type="checkbox"
                    checked={value}
                    onChange={onChange} />
                </div>
            </Col>
        </Row>
    )
}