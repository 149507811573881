// Imports from packages
import React, { useState } from 'react';
// react-bootstrap
import Container from 'react-bootstrap/Container';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
// Imports of our custom components 
import Header from '../../Components/Header/Header';
import Footer from '../../Components/CommonComponents/Footer';
import AdminEnumEditor from '../../Components/Admin/AdminEnumEditor';
import AdminPropertyEditor from '../../Components/Admin/AdminPropertyEditor';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

function AdminPage() {

    const [selectedTab, setSelectedTab] = useState('propertyEditor');
    const history = useHistory();
    const user = useSelector((state) => state.auth.user);

    if (user !== "Admin") {
        history.push("/home");
    }
    else {
        return (
            <div>
                <Header />
                <Container fluid style={{ backgroundColor: '#f3f5f7', paddingBottom: 20, paddingTop: 5, minHeight: '73vh' }}>
                    <h1 className="text-center">Adminisztrátori vezérlőpult</h1>
                    <Tabs
                        id="admin-panel-tabs"
                        activeKey={selectedTab}
                        onSelect={(tab) => setSelectedTab(tab)}
                    >
                        <Tab eventKey="enumEditor" title="Enum szerkesztő">
                            <AdminEnumEditor />
                        </Tab>
                        <Tab eventKey="propertyEditor" title="Property szerkesztő">
                            <AdminPropertyEditor />
                        </Tab>
                    </Tabs>
                </Container>
                <Footer />
            </div>
        )
    }
}

export default AdminPage;
