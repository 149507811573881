// Project related color constants, enums
export const HADColors = {
  Red: "#fd3258",
  DisabledRed: "#fe819a",
  Yellow: "#fed961",
  DisabledYellow: "#fee69f",
  Green: "#33c92e",
  DisabledGreen: "#99e396",
  Blue: "var(--primary)",
  Link: "#1daaac",
  Grey: "#8E8E8E",
};
