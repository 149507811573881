// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
// react-bootstrap
import Button from 'react-bootstrap/Button';
// Imports for APIs
import CategoryApi from '../../../Api/CategoryApi';
import DeviceApi from '../../../Api/DeviceApi';
// Imports of our custom components 
import { flattenGivenArray } from './detailedFunctions';
// Imports of styles
// - none
// Imports of constants
// - none
import { connect } from 'react-redux';
import { FaCopy } from 'react-icons/fa';

function CategorySearchBar(props) {
    // Destructuring assignment from the props
    const { currentPage, propertyFilters, setCategoryId, setIsSearching, setHasSearched, setSearchResults, setPageCount, role,
        manufacturerOptions, selectedManufacturer, onManufecturerChange
    } = props

    // Categories
    const [categories, setCategories] = useState([])
    const [id, setId] = useState('')
    const pageSize = 50

    const copyDetaildSearchContentToClipboard = () => {
        const searchRequestBody = {
            filterByCategory: id,
            propertyFilters: propertyFilters
        }
        navigator.clipboard.writeText(JSON.stringify(searchRequestBody)).then(function () { }, function (error) {
            console.error(error);
        });
    }

    const putSearch = async (newPageNumber) => {
        setIsSearching(true)
        setHasSearched(false)

        let requestBody = {
            "filterByCategory": `${id}`,
            "propertyFilters": propertyFilters
        }

        if (selectedManufacturer) {
            requestBody = {
                ...requestBody,
                filterByManufacturer: selectedManufacturer.label
            }
        }

        // Create the parameters for axios
        const axiosParameters = {
            url: DeviceApi(`DetailedSearch?page=${newPageNumber}&pageSize=${pageSize}`),
            method: 'put',
            data: requestBody,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }

        // Call API endpoint with axios
        axios(axiosParameters)
            .then(response => {
                if (response.status === 200) {
                    setIsSearching(false)
                    setHasSearched(true)
                    setSearchResults(response.data.devices)
                    setPageCount(response.data.pageCount)
                }
            })
            .catch(error => {
                console.error("Error during detailed search", error);

                setIsSearching(false)
                setHasSearched(true)
                setPageCount(1)
            })
    }

    // Get categories
    useEffect(() => {
        // List of categories
        const fetchCategories = async () => {
            const result = await axios(CategoryApi('List'));

            // Flatten the array
            const flatArray = flattenGivenArray(result.data)
            setCategories(flatArray)
        };

        // Fetch data
        fetchCategories();
    }, []);

    // Pagination triggered search
    useEffect(() => {
        if (currentPage !== undefined) {
            putSearch(currentPage);
        }
    }, [currentPage]);

    const onChange = (selected) => {
        if (selected === null) {
            setCategoryId('')
            setId('')
        }
        else {
            setCategoryId(selected.value.split(" | ", 1))
            setId(selected.value.split(" | ", 1))
        }
    }

    const customStyles = {
        control: base => ({
            ...base,
            width: 400
        }),
        option: (styles, { data }) => {
            const isMainCategory = !data.label.includes(">>");
            return {
                ...styles,
                backgroundColor: isMainCategory ? 'lightGray' : 'white',
                color: 'black',
                padding: '5px 0 5px 10px'
            };
        },
    }

    const customManufecturerSelectStyles = {
        control: base => ({
            ...base,
            width: 400,
            marginLeft: "8px"
        }),
    }

    return (
        <div className="pt-2 mx-4 d-flex justify-content-center align-items-center flex-column">
            <div className="text-center">
                <h1>Összetett keresés</h1>
            </div>
            <div
                className="w-100 mt-3 mb-1 p-2 px-3 d-flex justify-content-between align-items-center"
                style={{ backgroundColor: 'white' }}
            >
                <Select
                    styles={customStyles}
                    options={categories}
                    isClearable={true}
                    placeholder="Összes kategória"
                    onChange={(selected) => onChange(selected)}
                />
                <Select
                    options={manufacturerOptions}
                    styles={customManufecturerSelectStyles}
                    isClearable={true}
                    placeholder="Összes gyártó"
                    value={selectedManufacturer}
                    onChange={(selected) => onManufecturerChange(selected)}
                />
                <div className="w-100 d-flex justify-content-end">
                    <Button
                        style={{ width: "20%" }}
                        variant="primary"
                        disabled={id === ""}
                        onClick={() => putSearch(1)}
                    >
                        Keresés
                    </Button>
                    {role === "Administrator" && (
                        <Button
                            style={{ width: "20%" }}
                            variant="primary"
                            disabled={id === ""}
                            onClick={() => copyDetaildSearchContentToClipboard()}
                            className="ml-2"
                        >
                            <FaCopy /> Másolás
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const { role } = state.auth;
    return { role };
};

export default connect(mapStateToProps, null)(CategorySearchBar);
