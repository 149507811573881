import { SET_PROJECT } from '../actions/project.js';

const project = (state = {
    projectId: '',
    projectName: ''
}, action) => {
    switch (action.type) {
        case SET_PROJECT:
            return {
                ...state,
                projectId: action.projectId,
                projectName: action.projectName
            };             
        default:
            return state;
    }
};

export default project;
