// Imports from packages
import React, { useState } from 'react';
import { connect } from 'react-redux'
// react-bootstrap
// - none
// Imports for APIs
// - none
// Imports of our custom components 
import AddDistributorModal from '../Modals/AddDistributorModal';
import ModalOpeningButton from '../CommonComponents/ModalOpeningButton';
// Imports of styles
import './Device.css';
// Imports of constants
// - none

function ConditionallyRenderButton(props) {
    // Destructuring assignment from the props
    const { setDistributors, deviceId } = props

    // Props from redux
    const { companyId } = props

    const [isModalOpen, setModalOpen] = useState(false);

    const currentYear = new Date().getFullYear();

    return (
        <>
            { companyId ? (
                    <ModalOpeningButton 
                        disable = {false}
                        label = "Disztribútorrá válás"
                        modal = {
                            <AddDistributorModal 
                                show = {isModalOpen} 
                                handleClose = {() => setModalOpen(false)}
                                deviceId = {deviceId}
                                updateList = {newList => setDistributors(newList)}
                                years = { [currentYear, currentYear + 1] }
                            />
                        }
                        variable = "primary"
                        isModalOpen = {isModalOpen}
                        setModalOpen = {value => setModalOpen(value)}
                    />
                ) : (
                    <h6>Disztribútorrá váláshoz belépés szükséges!</h6>
                )
            }
        </>
    );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
    const { companyId, companyShortName } = state.company;
    return {
      companyId, companyShortName
    };
  };

export default connect(mapStateToProps)(ConditionallyRenderButton);
