// Company Device related constants, enums

// Different type for filtering company device
export const filterSelectTypes = {
    None: "None",
    WithApproves: "WithApproves",
    WithAnyRequests: "WithAnyRequests",
    WithChangeRequests: "WithChangeRequests",
    WithRemovalRequests: "WithRemovalRequests",
    WithoutAnyRequests: "WithoutAnyRequests"
}

// Labels and types for filtering options
export const filterLabels = [
    {
        value: filterSelectTypes.None,
        label: "Nincs szűrés"
    },
    {
        value: filterSelectTypes.WithApproves,
        label: "Elfogadott tulajdonságok"
    },
    {
        value: filterSelectTypes.WithAnyRequests,
        label: "Bármilyen javaslat"
    },
    {
        value: filterSelectTypes.WithChangeRequests,
        label: "Módosítási javaslatok"
    },
    {
        value: filterSelectTypes.WithRemovalRequests,
        label: "Törlési javaslatok"
    },
    {
        value: filterSelectTypes.WithoutAnyRequests,
        label: "Javaslatok nélkül"
    }
]

// Different type for sorting company device

export const sortSelectTypes = {
    None: "None",
    NameAscending: "NameAscending",
    NameDescending: "NameDescending",
    HadIdAscending: "HadIdAscending",
    HadIdDescending: "HadIdDescending",
    ItemNumberAscending: "ItemNumberAscending",
    ItemNumberDescending: "ItemNumberDescending",
    ManufacturerNameAscending: "ManufacturerNameAscending",
    ManufacturerNameDescending: "ManufacturerNameDescending"
}

// Labels and types for sorting options

export const sortLabels = [
    {
        value: sortSelectTypes.None,
        label: "Rendezés nélkül"
    },
    {
        value: sortSelectTypes.NameAscending,
        label: "Név A-Z"
    },
    {
        value: sortSelectTypes.NameDescending,
        label: "Név Z-A"
    },
    {
        value: sortSelectTypes.HadIdAscending,
        label: "HAD ID A-Z"
    },
    {
        value: sortSelectTypes.HadIdDescending,
        label: "HAD ID Z-A"
    },
    {
        value: sortSelectTypes.ItemNumberAscending,
        label: "Típusszám A-Z"
    },
    {
        value: sortSelectTypes.ItemNumberDescending,
        label: "Típusszám Z-A"
    },
    {
        value: sortSelectTypes.ManufacturerNameAscending,
        label: "Gyártó A-Z"
    },
    {
        value: sortSelectTypes.ManufacturerNameDescending,
        label: "Gyártó Z-A"
    }
]