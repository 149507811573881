import React from 'react';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import '../../App.scss';
import clientAppVersion from '../../constants/clientAppVersion';

export default function Footer() {

    const footerStyle = {
        textAlign: 'center',
        margin: '20px auto',
        backgroundColor: 'white'
    }

    return (
        <>
            <Row style={footerStyle}>
                <Col>
                    Copyright © {new Date().getFullYear()} <b>HAD</b>ocument. All rights reserved.
                </Col>
            </Row>
            <Row style={footerStyle}>
                <Col>
                    <div>
                        Icons made by
                        <a href="https://www.freepik.com" title="Freepik"> Freepik</a>,
                        <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect"> Pixel perfect </a>
                        from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
                    </div>
                </Col>
            </Row>
            <Row style={{ ...footerStyle, fontSize: "10px" }}>
                <Col>
                    <span>{clientAppVersion}</span>
                </Col>
            </Row>
        </>
    );
}
