import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// Redux imports
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
// Redux root reducer (combined reducers)
import rootReducer from './reducers';
// Web Vitals
import reportWebVitals from './reportWebVitals';
// Main Application component
import Application from './Application';
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient();

// Initialize Redux store
const store = createStore(rootReducer, composeWithDevTools(
  applyMiddleware(thunkMiddleware),
));

ReactDOM.render(
  <Provider store={store}>
     <QueryClientProvider client={queryClient}>
      <Application />
    </QueryClientProvider>
  </Provider>,
  document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
