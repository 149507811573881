import React from 'react';

import Button from 'react-bootstrap/Button';

import CustomModal from './CustomModal';

import '../../App.scss';

function DeleteConfirmationModal(props) {
  
  const onConfirm = () => {
    props.onConfirm();    
    props.handleClose(false);
  }

  const onCancel = () => {   
    props.handleClose(false);
  }

  const footer = (
    <>
      <Button variant="danger" onClick={() => onConfirm()}>
        Igen
      </Button>
      <Button variant="primary" onClick={() => onCancel()}>
        Mégse
      </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show = {props.show}
        size = "md"
        dropback="static"
        keyboard={false}
        handleClose = {() => onCancel()}
        title = {props.title}
        body = {props.body}
        footer = {footer}
      />
    </>
  );
}

export default DeleteConfirmationModal;
