import React from 'react';
import { Link } from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';

import { FaUser } from 'react-icons/fa';

import '../../App.scss';
import './Header.css';

export default function LoginDropdown() {

    // Custom profile
    const CustomToggle = React.forwardRef(({ onClick }, ref) => (
        <div
            ref={ref}
            className="rounded-circle-icon-container"
            onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            <FaUser
                style={{ margin: "-0.25em 0.4em 0" }}
                alt="login or register"
            />
        </div>
    ));
    CustomToggle.displayName = "Login or Register dropdown"

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle as={CustomToggle} id="dropdown-basic-login" />

                <Dropdown.Menu align="right">
                    <Link to="/registration" style={{ textDecoration: 'none' }}>
                        <Dropdown.Item as="button">
                            Felhasználói regisztráció
                        </Dropdown.Item>
                    </Link>
                    <Link to="/company-registration" style={{ textDecoration: 'none' }}>
                        <Dropdown.Item as="button">
                            Céges regisztráció
                        </Dropdown.Item>
                    </Link>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}
