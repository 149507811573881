// Imports from packages
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
// Imports for APIs
import ProjectApi from '../../Api/ProjectApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import DynamicSelect from '../CommonComponents/DynamicSelect';
import IconWithOverlay from '../CommonComponents/IconWithOverlay';
import Asterisk from '../CommonComponents/Asterisk';
import AsteriskExplanation from '../CommonComponents/AsteriskExplanation';
import { capitalizeFirstLetter } from '../../utilities/stringManipulations';
// Imports of styles
import '../../App.css';
// Imports of contants
import { propertyType } from '../../constants/propertyType';

function AddProjectModal(props) {
    // Destructuring assignment from the props
    const { projectId, subProject, newProject, handleClose, show } = props

    // Handle ID of the device (from props or URL parameter)
    const { companyId } = useParams();
  
    // Project properties
    const [projectName, setProjectName] = useState('')
    const [isProjectNameChecked, setIsProjectNameChecked] = useState(false)
    const [isProjectNameAvailable, setIsProjectNameAvailable] = useState(false)
    const [posting, setPosting] = useState(false)
    const [code, setCode] = useState('')

    // Filtered property to post from dynamic part
    const [propertyFilters, setPropertyFilters] = useState([])
    const [unselectedFields, setUnselectedFields] = useState(-1)    
    const [mandatoryProperties, setMandatoryProperties] = useState([])
    const [recommendedProperties, setRecommendedProperties] = useState([])

    // Alert
    const [showCreateResponseAlert, setShowCreateResponseAlert] = useState(false)
    const [createResponse, setCreateResponse] = useState(0)

    const onChange = (type, event) => {
        switch(type) {
            case 'projectName': 
                setProjectName(event.target.value); setIsProjectNameChecked(false);
                break;
            case 'code': 
                setCode(event.target.value);
                break;
            default: 
                console.error("Unknown type in add project modal's onChange");
        }
    }
    
    const fetchProjectNameValidness = async() => {
        const result = await axios(ProjectApi(`Company/${companyId}/Check/Name?projectName=${projectName}`));
        setIsProjectNameChecked(true);
        setIsProjectNameAvailable(result.data.result);
    };

    // Fetch available project properties
    const fetchMandatoryProjectProperties = async() => {
        try{
            const result = await axios(PropertyApi("List?propertyType=Project&typeOf=NormalValue&mandatoryFilter=OnlyMandatory"));
            setMandatoryProperties(result.data);
            setUnselectedFields(result.data.length)
        }
        catch(error){
            console.error("Error during mandatory property list fetch process", error)
        }
    };

    // Fetch available project properties
    const fetchOptionalProjectProperties = async() => {
        try{
            const result = await axios(PropertyApi("List?propertyType=Project&typeOf=NormalValue&mandatoryFilter=OnlyOptional"));
            setRecommendedProperties(result.data);
        }
        catch(error){
            console.error("Error during optional property list fetch process", error)
        }
    };

    useEffect(() => {
        // Fetch data
        fetchMandatoryProjectProperties();
        fetchOptionalProjectProperties();
    }, [])

    const postProject = async () => {

        setPosting(true)

        // Choosing enpoint
        let endpoint = subProject ? `Register/Project/${projectId}` : `Register/Company/${companyId}`;

        const data = {
            "name": projectName,
            "code": `${code}`,
            "properties": propertyFilters
        };

        // Create the parameters for axios
        const axiosParameters = {
            url: ProjectApi(endpoint),
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }

        // Call API endpoint with axios
        try {
            axios(axiosParameters)
            .then(response => {
                if(response.status === 200) {
                    // Show response from the project creation
                    setShowCreateResponseAlert(true)
                    setCreateResponse(response.status)

                    setTimeout(() => {
                        setShowCreateResponseAlert(false)
                        setCreateResponse(0)

                        // Close modal
                        setPosting(false)
                        closeModal();
                    }, 2000)

                    newProject(response.data.result)
                }
            })
        }
        catch(error) {
            console.error("Error during project creation process", error)
            // Show response from the project creation
            setShowCreateResponseAlert(true)
            setCreateResponse(error.response.status)

            setTimeout(() => {
                setShowCreateResponseAlert(false)
                setCreateResponse(0)
            }, 2000)
        }
    };
    
    const closeModal = () =>{
        // Delete every value before closed
        setIsProjectNameChecked(false);
        setIsProjectNameAvailable(false);
        setProjectName('');
        setCode('');
        setPropertyFilters([]);

        // Close modals visibility
        handleClose();
    }
    
    // Modal parameters
    const title = subProject ? "Alprojekt létrehozása" : "Projekt létrehozása";

    let alertContent
    const projectType = subProject ? "alprojektnév" : "projektnév"

    if(createResponse === 200){
        alertContent = "Sikeres " + projectType.replace('név', '') + " létrehozás"
    }
    else{
        alertContent = "Sikeretelen " + projectType.replace('név', '') + " létrehozás"
    }

    const commonIconStyle = {
        size: "1.5em"
    }

    const body = (
        <>
            <Form>
                <label>{capitalizeFirstLetter(projectType)}</label>
                <InputGroup className="mb-3">
                    <Form.Control
                        type = "text"
                        className = "w-95"
                        placeholder = {capitalizeFirstLetter(projectType)}
                        value = {projectName}
                        onChange = {event => onChange('projectName', event)}
                        onBlur = {() => {
                            fetchProjectNameValidness()
                        }}
                    />
                    <InputGroup.Append>
                        <InputGroup.Text>
                            {isProjectNameChecked === true ? (
                                    isProjectNameAvailable === true ? 
                                        <IconWithOverlay
                                            placement = "top"
                                            tooltip = {"Szabad " + projectType}
                                            icon = {<FaCheckCircle style = {commonIconStyle} color = "#28a745" />}
                                        />
                                    :
                                        <IconWithOverlay
                                            placement = "top"
                                            tooltip = {"Foglalt " + projectType}
                                            icon = {<FaTimesCircle style = {commonIconStyle} color = "#dc3545"/>}
                                        />
                                ) : (
                                    <div className = "p-2"></div>
                                )
                            }
                        </InputGroup.Text>
                    </InputGroup.Append>
                </InputGroup>

                <Form.Group controlId="formProjectCode">
                    <Form.Label>{subProject ? "Alprojekt kód" : "Projekt kód"}</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder={subProject ? "Alprojekt kód" : "Projekt kód"}
                        value = {code}
                        onChange={event => onChange('code', event)}
                    />
                </Form.Group>
            </Form>

            { mandatoryProperties.length !== 0 && 
                <>
                   <div className="mb-2">Kötelező tulajdonságok <Asterisk/></div>
                    <AsteriskExplanation/>
                    <DynamicSelect
                        controlId = 'new-project-properties-mandatory'
                        propertyFilters = {propertyFilters}
                        type = {propertyType.MANDATORY}
                        gridItems = {mandatoryProperties}
                        unselectedFields = {unselectedFields}
                        creatingNewEntity = {true}
                        setUnselectedFields = {newValue => setUnselectedFields(newValue)}
                        setPropertyFilters = {newPropFilters => setPropertyFilters(newPropFilters)}
                    />
                </>
            }

            <div className="mb-2">Ajánlott tulajdonságok</div>
            <DynamicSelect
                controlId = 'new-project-properties'
                propertyFilters = {propertyFilters}
                type = {propertyType.RECOMMENDED}
                creatingNewEntity = {true}
                propertyArray = {recommendedProperties}
                setPropertyFilters = {newPropFilters => setPropertyFilters(newPropFilters)}
            />
        </>
    );

    // Enable project posting only if selected Options has value
    const disableButton = (
        !isProjectNameAvailable ||
        !isProjectNameChecked ||
        code === '' ||
        posting ||
        unselectedFields !== 0 
    )

    const footer = (
        <>
            <Button 
                variant="success" 
                onClick={() => postProject()} 
                disabled = {disableButton}
            >
                Létrehozás
            </Button>
            {showCreateResponseAlert && (
                <Alert variant = {createResponse === 200 ? "success" : "danger"}>
                    {alertContent}
                </Alert>
            )}
            <Button 
                variant="danger" 
                onClick={closeModal}
                disabled={posting}
            >
                Elvetés
            </Button>
        </>
    );

    return (
        <>
            <CustomModal 
                show={show} 
                size="lg"
                dropback="static"
                keyboard={false}
                handleClose={closeModal}
                title={title}
                body={body} 
                footer={footer}
            />
        </>
    );
}

export default AddProjectModal;
