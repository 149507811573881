export default function FileDownload(result, fileName) {
  let blob = new Blob([result.data], {type: "application/octet-stream"});
  const url = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = url;
  link.download = fileName || 'download_file.pdf';

  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      link.removeEventListener('click', clickHandler);
    }, 150);
  };
  link.addEventListener('click', clickHandler, false);
  link.click();
}
