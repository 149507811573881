// Imports from packages
import React from 'react';
import axios from 'axios';
// react-bootstrap
import Button from 'react-bootstrap/Button';
import { FaDownload } from 'react-icons/fa';
// Imports for APIs
import FileApi from '../../Api/FileApi'
// Imports of our custom components 
import FileDownload from '../CommonComponents/FileDownload';
// Imports of styles
// - none
// Imports of constants
// - none

export default function BOMZipDownload(props) {
    // Destructuring assignment from the props
    const { downloadPropertyies, bom } = props

    // Download selected properties for all devices an put them in one .zip file
    const downloadDocuments = async () => {
        const justIdsArray =  downloadPropertyies.map(property => "propertyIds=" + property.value)
        const parameters = justIdsArray.join('&')

        const config = {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/octet-stream ',
              'accept': '*/*'
            }
        }

        const result = await axios(FileApi(`BillOfMaterial/${bom.id}?${parameters}`), config);

        // Download file
        const isMultipleFile = downloadPropertyies.length > 1 ? "_Multiple" : `_${downloadPropertyies[0].label}`
        const fileName = `${bom.name}_BOM` + isMultipleFile + ".zip"
        FileDownload(result, fileName)
    };

    return (
        <Button
            className = "ml-3"
            size = "sm"
            variant = "primary" 
            onClick = {() => downloadDocuments()}
            disabled = {downloadPropertyies.length === 0}
        >
            <FaDownload />
        </Button>
    )
}