import React from "react";
import Alert from "react-bootstrap/Alert";
import "../../App.css";
import "../../App.scss";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Logo from "../../cropped-logo.png";
import { Link } from "react-router-dom";

function MissingQueryParameters(props) {
    return (
        <Container fluid>
            <Row
                className="justify-content-center align-items-center"
                style={{ minHeight: "100vh" }}
            >
                <Col className="col-12 text-center">
                    <img src={Logo} alt="HADocument logó" width="200px" />
                    <Alert variant="danger" className="mb-4 mt-4 text-center mx-4">{props.text}</Alert>
                    <div className="d-flex w-100 justify-content-center mt-4">
                        <Link to="/">Vissza a főoldalra!</Link>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default MissingQueryParameters;
