// Imports from packages
import React from 'react';
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants
// - none

export default function SelectWithIcon(props) {
    // Destructuring assignment from the props
    const { icons, label, select } = props

    return (
        <div className = "d-flex align-items-center justify-content-between">
            {icons}
            <h4 className = "ml-2 mr-3 mt-1 mb-2">
                {label}
            </h4>
            {select}
        </div>
    )
}