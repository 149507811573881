// Imports from packages
import React from 'react';
// react-bootstrap
import ListGroup from 'react-bootstrap/ListGroup';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants

export default function BoxListGroup(props) {
    // Destructuring assignment from redux props
    const { items, itemPluralName, title, entityName } = props
    
    return (
        <div>
            <h4 className = "mb-2">{title}</h4>
            <ListGroup>
                { items.length === 0 ? (
                        <ListGroup.Item className = "d-flex justify-content-center align-items-center">
                            Nem találhatóak {itemPluralName} ehhez a {entityName}.
                        </ListGroup.Item>
                    ) : (
                        items
                    )
                }          
            </ListGroup>
        </div>
    )
}
