// Imports from packages
import React, { useState } from 'react';
import { FaTrashAlt, FaPlus, FaMinus } from 'react-icons/fa';
// react-bootstrap
import Button from 'react-bootstrap/Button';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants
import "./BOM.css";

export default function BOMItemQuantityEditor(props) {
    const { disableButtons, handleDelete, handleUpdate, disabled, onlyValue } = props;

    const [quantity, setQuantity] = useState(props.quantity)

    const handleQuantityUpdate = (newQuantity) => {
        if(newQuantity >= 0) {
            handleUpdate(newQuantity)
            setQuantity(newQuantity)
        }
    }

    const onlyValueDiv = (
        <div className = "d-flex justify-content-center align-items-center">
            <input
                value={quantity}
                className="inputStyle"
                disabled
            />
             <Button className="d-flex justify-content-center align-items-center rounded-circle p-2" variant="outline-dark" size="sm" disabled={disableButtons}>
                <FaTrashAlt style={{ cursor: 'pointer' }} size="1rem" onClick ={() => handleDelete()} />
            </Button>
        </div>
    )

    const fullFunctionalityDiv = (
        <div className = "d-flex justify-content-around align-items-center">
            <Button className="d-flex justify-content-center align-items-center rounded-circle p-2" variant="outline-dark" size="sm" disabled={disableButtons}>
                <FaMinus style={{ cursor: 'pointer' }} size="1rem" onClick={() => handleQuantityUpdate(quantity - 1)} />
            </Button>
            <input
                value={quantity}
                onChange={(event) => handleQuantityUpdate(parseInt(event.target.value))}
                className="inputStyle"
                disabled={disabled}
            />
             <Button className="d-flex justify-content-center align-items-center rounded-circle p-2" variant="outline-dark" size="sm" disabled={disableButtons}>
                <FaPlus style={{ cursor: 'pointer' }} size="1rem" onClick={() => handleQuantityUpdate(quantity + 1)} />
            </Button>
            <Button className="d-flex justify-content-center align-items-center rounded-circle p-2" variant="outline-danger" size="sm" disabled={disableButtons}>
                <FaTrashAlt style={{ cursor: 'pointer' }} size="1rem" onClick ={() => handleDelete()} />
            </Button>
        </div>
    )

    return onlyValue ? onlyValueDiv : fullFunctionalityDiv
}