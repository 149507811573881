function UserApi(endpoint, method, requestedUser) {

    let isExceptional
    let imageResolution
    let endpointPath = endpoint
    let parameter = ""
    
    if(endpoint.includes('Picture')) {
        isExceptional = true
        imageResolution = endpoint.includes('Thumbnail') ? 50 : 400
    }

    if(requestedUser !== "" && requestedUser !== undefined){
        endpointPath = `Request/${endpoint}`
        parameter = `?Username=${requestedUser}`
    }

    if(isExceptional && method === 'GET') {
        return `/User/${endpointPath}?width=${imageResolution}&height=${imageResolution}${parameter}`
    }
    else {
        return `/User/${endpointPath}${parameter}`
    }
}

export default UserApi;