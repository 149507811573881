import React from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

export default function IconWithOverlay(props) {
    // Destructuring assignment from the props
    const { placement, tooltip, icon, key } = props

    return (
        <OverlayTrigger
            key = {`change-item-${top}-${key}`}
            placement = {placement}
            overlay = {
                <Tooltip id="tooltip-change-value-type">
                    {tooltip}
                </Tooltip>
            }
        >
            {icon}
        </OverlayTrigger>
    )
}