// Imports from packages
import React from 'react';
// Imports for APIs
// - none
// Imports of our custom components 
import SingleDistributedDevices from './SingleDistributedDevices';
import CustomPagination from '../../CommonComponents/CustomPagination';
import Loader from '../../CommonComponents/Loader';
import CenteredHeading4 from '../../CommonComponents/CenteredHeading4';
// Imports of styles
// - none
// Imports of contants
// - none

function DistributedDeviceGrid(props) {
    // Destructuring assignment from the props
    const { title, years, filteredDevices, fetchedDevices, pageCount, currentPage } = props

    const pagination = (
        pageCount > 1 && filteredDevices.length !== 0 &&
            <CustomPagination 
                pageCount = {pageCount}
                currentPage = {currentPage}
                setCurrentPage = {newPageNumber => props.setCurrentPage(newPageNumber)}
            />
    )
 
    // If the page just loaded, until fetchCompanyDevices finishes, show Spinner/Loader
    let centralColumn
    if(!fetchedDevices) {
        centralColumn = (
            <div className = "mb-4">
                <Loader/>
            </div>
        )
    }
    else {
        if(years.length === 0) {
            // Company has zero devices
            centralColumn = <CenteredHeading4 message = "Ehhez a céghez nem lett még értékesített eszköz feltöltve" />
        }
        else {
            if(filteredDevices.length === 0) {
                // Company has zero devices with this filtering
                const year = new Date().getFullYear();
                centralColumn = <CenteredHeading4 message = {`Nincs értékesített eszköz a keresett évre (${year})`} />
            }
            else {
                // Company has devices with this filtering
                const mappedArray = filteredDevices.map(device => {
                    return <SingleDistributedDevices device = {device} key = {device.deviceId}/>
                })

                centralColumn = mappedArray
            }
        }
    }
        
    return (
        <div className = "d-flex justify-content-center flex-column text-center">
            <h2 className = "mb-4">
                {title}
            </h2>
            {pagination}
            <div className = "d-flex justify-content-center flex-row flex-wrap w-100">
                {centralColumn}
            </div> 
            {pagination}
        </div>
    );
}

export default DistributedDeviceGrid;
