// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Imports for APIs
// - none
// Imports of our custom components 
import SingleColumnPageTemplate from '../../Pages/SingleColumnPageTemplate';
import DeviceGrid from '../../MainPage/DeviceGrid';
import CenteredHeading4 from '../../CommonComponents/CenteredHeading4';
import Loader from '../../CommonComponents/Loader';
import CategorySearchBar from './CategorySearchBar';
import PropertySearchBar from './PropertySearchBar';
import DeviceApi from '../../../Api/DeviceApi';
// Imports of styles
// - none
// Imports of constants
import { propertyType } from '../../../constants/propertyType';

function DetailedSearch() {
  // Mischievous variables
  const [categoryId, setCategoryId] = useState('')
  const [propertyFilters, setPropertyFilters] = useState([])
  const [mandatoryPropertyFilters, setMandatoryPropertyFilters] = useState([])
  const [otherPropertyFilters, setOtherPropertyFilters] = useState([])
  const propertySearchBar = [
    {
      title: "Kötelező tulajdonságok",
      mandatoryFilter: propertyType.MANDATORY,
      propertyFilterUpdate: newFilterPropy => setMandatoryPropertyFilters(newFilterPropy)
    },
    {
      title: "Tetszőleges tulajdonságok",
      mandatoryFilter: propertyType.OTHERS,
      propertyFilterUpdate: newFilterPropy => setOtherPropertyFilters(newFilterPropy)
    }
  ]

  // Search keyword
  const [isSearching, setIsSearching] = useState(false)
  const [hasSearched, setHasSearched] = useState(false)
  const [searchResults, setSearchResults] = useState([])

  // Pagination
  const [currentPage, setCurrentPage] = useState()
  const [pageCount, setPageCount] = useState(undefined)

  const [manufacturers, setManufacturers] = useState([])
  const manufacturerOptions = manufacturers.map((manufacturer, index) => {
    return { value: index, label: manufacturer }
  })
  const [selectedManufacturer, setSelectedManufacturer] = useState(null)

  // Two different components update the same variable, both should be remembered
  useEffect(() => {
    const newArray = [
      ...mandatoryPropertyFilters,
      ...otherPropertyFilters
    ]
    setPropertyFilters(newArray)
  }, [mandatoryPropertyFilters, otherPropertyFilters])

  // When a category is selected, propfilter should be reseted and other properties list should be emptied
  const selectedCategoryForSearch = id => {
    // Set categoryid for props
    setCategoryId(id)

    // Other options will delete themselves but mandatory items should be deleted this way
    setPropertyFilters([])
  }

  const fetchManufacturers = async () => {
    const result = await axios(DeviceApi('Manufacturer/List'));
    setManufacturers(result.data)
  };

  useEffect(() => {
    fetchManufacturers();
  }, [])

  const headerRow = (
    <div>
      <CategorySearchBar
        currentPage={currentPage}
        propertyFilters={propertyFilters}
        setCategoryId={newId => selectedCategoryForSearch(newId)}
        setIsSearching={newValue => setIsSearching(newValue)}
        setHasSearched={newValue => setHasSearched(newValue)}
        setSearchResults={result => setSearchResults(result)}
        setPageCount={newPageCount => setPageCount(newPageCount)}
        manufacturerOptions={manufacturerOptions}
        selectedManufacturer={selectedManufacturer}
        onManufecturerChange={(selected) => setSelectedManufacturer(selected)}
      />
      {
        propertySearchBar.map((searchbar, index) => {
          return (
            <PropertySearchBar
              key={`searchbar-${index}`}
              title={searchbar.title}
              categoryId={categoryId}
              mandatoryFilter={searchbar.mandatoryFilter}
              setPropertyFilters={searchbar.propertyFilterUpdate}
            />
          )
        })
      }
    </div>
  )

  let centralColumn
  if (!hasSearched) {
    if (isSearching) {
      centralColumn = (
        <div className="mt-4 mb-3">
          <Loader />
        </div>
      )
    }
    else {
      centralColumn = <CenteredHeading4 message="Még nem keresett rá semmire" />
    }
  }
  else {
    centralColumn = (
      <DeviceGrid
        title="Keresési eredmények"
        devices={searchResults}
        isFetched={hasSearched}
        isPaginated={true}
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={newPageNumber => setCurrentPage(newPageNumber)}
      />
    )
  }

  return (
    <SingleColumnPageTemplate
      headerRow={headerRow}
      centralColumn={centralColumn}
    />
  );
}

export default DetailedSearch;
