// Imports from packages
import React, { useState } from 'react';
// react-bootstrap
import Select from 'react-select';
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants
import { sortLabels } from '../../../constants/companyDeviceSelect';

export default function SortSelect(props) {
    // Destructuring assignment from the props
    const { setSorting } = props

    const [selectedOption, ] = useState()

    // Select options
    const selectOptions = sortLabels.map(option => {
        return {
            value: option.value,
            label: option.label
        }
    })

    const onChange = (selected) => {
        if(selected) {
            setSorting(selected.value)
        }
        else {
            setSorting(selected)
        }
    }

    const customStyles = {
        control: provided => ({
            ...provided,
            maxWidth: 500,
            minWidth: 240
        })
    }

    return (
       <Select
            styles={customStyles}
            options={selectOptions}
            isClearable={true}
            placeholder = "Válassz rendezési módot"
            value = {selectedOption}
            onChange = {selected => onChange(selected)}
        />
    )
}