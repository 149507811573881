// Imports from packages
import React from 'react';
import Select from 'react-select';
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants
// - none

export default function DetailedSearchSelect(props) {
    // Destructuring assignment from the props
    const { propertyId, selectOption, measurementUnit, setSelectedOption, deletePropFromFilterArray } = props

    // Select fields handle function
    const onChange = selected => {
        if(selected === null) {
            setSelectedOption([])
            // Remove all values about this prop from filterArray
            deletePropFromFilterArray(propertyId)
        }
        else {
            setSelectedOption(selected)
        }
    }

    const customStyles = {
        control: base => ({
            ...base,
            width: "100%"
        }),
        option: base => ({
            ...base,
            padding: '5px 0 5px 10px',
        }),
    }

    return (
        <div className="d-flex justify-content-between align-items-center">
            <div className="w-75">
                <Select
                    menuPortalTarget={document.body}
                    styles = {customStyles}
                    options = {selectOption}
                    isClearable = {true}
                    isMulti = {true}
                    placeholder = "Összes érték"
                    onChange = {selected => onChange(selected)}
                />
            </div>
            <span className="mr-3">{ measurementUnit === "NotApplicable" ? "" : measurementUnit }</span>
        </div>
    );
}
