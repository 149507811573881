// Imports from packages
import React from 'react';
// Imports for APIs
// - none
// Imports of our custom components
import ProfileAccordion from './ProfileAccordion';
import { makeObjectEntry } from './makeObjectEntry';
import { mapExistence } from '../Company/utils/mapCompanyExistence';
// Imports of styles
import '../../App.css';
// Imports of contants
// - none

export default function ProfileCategories(props) {
    // Destructuring assignment from the props
    const { entity, profileId, profile, editMode, setRefreshing } = props

    const categoryEntries = Object.entries(profile.categorizedNormalProperties)

    let allProperties
    if(entity === "project"){
        allProperties = [
            ...categoryEntries
        ]
    }
    else{
        // Make Accordion out of profile
        let values
        if(entity === 'user'){
            values = [
                { id: null, value: profile.username },
                { id: "nickname", value: profile.nickname },
                { id: "email", value: profile.email }
            ]
        }
        else if(entity === 'company'){
            values = [
                { id: null, value: profile.name },
                { id: null, value: profile.shortName },
                { id: null, value: profile.creatorNickname },
                { id: null, value: mapExistence(profile.existenceType) }
            ]
        }

        const basicProperties = makeObjectEntry(entity, values)

        allProperties = [
            ["Profil tulajdonságok", basicProperties],
            ...categoryEntries
        ]
    }

    const propertiesAccordions = allProperties.map((category,idx) => {
        const categoryName = category[0]
        const properties = category[1]

        return (
            <ProfileAccordion 
                categoryName = {categoryName}
                key = {`${categoryName}-${idx}`}
                properties = {properties}
                editMode = {editMode}
                setRefreshing = {setRefreshing}
                entity = {entity}
                profileId = {profileId}
            />
        )
    })

    return propertiesAccordions;
}
