// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
// react-bootstrap
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// Imports for APIs
import PropertyApi from '../../Api/PropertyApi';
import FileApi from '../../Api/FileApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import Asterisk from '../CommonComponents/Asterisk';
import FileReferenceItem from '../CommonComponents/FileReferenceItem';
import AsteriskExplanation from '../CommonComponents/AsteriskExplanation';
// Imports of styles
// - none
// Imports of contants
// - none

function AddFileReferenceModal(props) {
  // Destructuring assignment from redux props
  const { entity, profileId, show, documentTypes, setRefreshing, handleClose } = props

  const [fileName, setFileName] = useState('');
  const [description, setDescription] = useState('');

  const [selectedDocumentType, setSelectedDocumentType] = useState();
  const [properties, setProperties] = useState([]);
  const [mandatoryProperties, setMandatoryProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [mandatoryPropsHasValue, setMandatoryPropsHasValue] = useState(false);

  const [posting, setPosting] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [extension, setExtension] = useState("");

   // File alert
   const [fileResponse, setFileResponse] = useState(0)
   const [showFileResponseAlert, setShowFileResponseAlert] = useState(false)
   

  const mappingValuesForSelect = array => {
    const mappedArray = array.map(element => {
      const option = {
        value: element.id,
        label: element.name
      }

      return option
    })

    return mappedArray
  }

  // Check mandatory properties if they have a value after each property update
  useEffect(() => {
    const checkIfEveryMandatoryPropHasValue = () => {
      let mandatoryCheckingArray = []
      mandatoryProperties.forEach(mandatoryProp => {
        const isMandatoryInSelectedProperties = selectedProperties.filter(selectedProps => selectedProps.propertyId === mandatoryProp.id).length > 0
        mandatoryCheckingArray.push(isMandatoryInSelectedProperties)
      })
      setMandatoryPropsHasValue(mandatoryCheckingArray.every(mandatoryProp => mandatoryProp === true))
    };

    checkIfEveryMandatoryPropHasValue();
    // To avoid unmounted warning
    return () => { setMandatoryPropsHasValue(false)}
  }, [selectedProperties]);

  useEffect(() => {
    const fetchFileReferenceProperties = async () => {
      const result = await axios(PropertyApi(`List?propertyType=FileReference`));
      setProperties(result.data)

      const mandatoryPropsArray = result.data.filter(property => property.isMandatory === true)
      setMandatoryProperties(mandatoryPropsArray)
    };

    fetchFileReferenceProperties();
    // To avoid unmounted warning
    return () => { setMandatoryProperties([])}
  }, []);

  const onFileUpload = (event) => {
    // Fixme: from old code, is it necessary?
    let file = event.target.files[0];

    if(file !== undefined){
      setUploadedFile(file)
      setExtension(file.type.split("/")[1])
    }
    else{
      setUploadedFile('')
    }
  }

  // Handlers
  const onChange = (event, type) => {
    switch(type){
      case "name":
        setFileName(event.target.value)
        break;
      case "description":
        setDescription(event.target.value)
        break;
      case "documentType":
        setSelectedDocumentType(event)
        break;
      case "file":
        onFileUpload(event)
        break;
      default:
        console.warn("There is no such type as ", type)
    }
  }

  const postFileWithMetaData = async () => {
    setPosting(true)

    // Create multipart/form-data config
    let formData = new FormData();
    formData.append('FileName', fileName)
    formData.append("Extension", extension)
    formData.append("Description", description)
    formData.append("File", uploadedFile)
    
    const formConfig = {
      headers: {
        "accept": "*/*",
        "Content-Type": "multipart/form-data",
      }
    }

    // Uploading file to BE
    let fileReferenceId
    try {
      let path
      switch(entity){
        case "company": path = `Company/${profileId}`; break; 
        case "device": path = `Device/${profileId}`; break; 
        case "project": path = `Project/${profileId}`; break; 
        case "user": path = `User`; break; 
      }

      const uploadingFile = await axios.post(FileApi(`${path}/Property/${selectedDocumentType.value}/Add?addToProperty=true`), formData, formConfig);
      fileReferenceId = uploadingFile.data.result

      // Add metadata(s) to recently added file
      try {
        const dataBody = selectedProperties
        const config = {
          headers: {
            "accept": "*/*",
            "Content-Type": "application/json"
          }
        }

        const metaDataResult = await axios.post(FileApi(`${fileReferenceId}/MetaData`), dataBody, config);

        // Show alert that upload was succesfull
        setFileResponse(metaDataResult.status)
        setShowFileResponseAlert(true)
        setRefreshing({fileReferenceId: fileReferenceId})

        setTimeout(() => {
          setShowFileResponseAlert(false)
          setFileResponse(0)

          closeModal()
        }, 2000)
      } catch(error) {
        console.warn("Error during file metadata uploading", error)
        setFileResponse(error.status)
        setShowFileResponseAlert(true)

        setTimeout(() => {
          setShowFileResponseAlert(false)
          setFileResponse(0)
        }, 2000)
      }
    } catch(error) {
      console.warn("Error during file uploading", error)

      setFileResponse(error.status)
      setShowFileResponseAlert(true)

      setTimeout(() => {
        setShowFileResponseAlert(false)
        setFileResponse(0)
      }, 2000)
    }

    setPosting(false)
  }

  // Refresh property array if any of the properties changed
  const refreshPropertyArray = newPropArray => {
    // Remove previously added values with this propertyId
    const filteredArray = selectedProperties.filter(property => property.propertyId !== newPropArray.propertyId)

    const newValues = newPropArray.values.map(value => {
      const item = {
        propertyId: newPropArray.propertyId,
        value: value
      }
      
      return item
    })
    const newArray = [
      ...filteredArray,
      ...newValues
    ]

    // Set updated/modified array
    setSelectedProperties(newArray)
  }
  
  const title = 'Fájl hozzáadása';
  
  const modalBody = (
    <>
      <Form>
        <Form.Text className = "mb-3">
          <AsteriskExplanation />
        </Form.Text>
        <Form.Group controlId = "formFileRefFileName">
          <Form.Label>Fájlnév <Asterisk /></Form.Label>
          <Form.Control
              type="text"
              placeholder="Írd be az új fájl nevét"
              value = {fileName}
              onChange={event => onChange(event, "name")}
          />
        </Form.Group>

        <Form.Group controlId = "formFileRefDescription">
          <Form.Label>Fájl leírás <Asterisk /></Form.Label>
          <Form.Control
              type="text"
              placeholder="Írd be az új fájl leírását"
              value = {description}
              onChange={event => onChange(event, "description")}
          />
        </Form.Group>

        <Form.Group controlId = "formFileRefDocType">
          <Form.Label>Fájl típusa <Asterisk /></Form.Label>
          <Select 
              options = {mappingValuesForSelect(documentTypes)}
              onChange = {selected => onChange(selected, "documentType")}
              placeholder="Dokumentum típusának választása"
              value = {selectedDocumentType}
          />
        </Form.Group>

        {properties.length !== 0 &&
          properties.map(property => 
            <FileReferenceItem
              key={property.id}
              item={property}
              refreshPropertyArray={newPropArray => refreshPropertyArray(newPropArray)}
            />
          )
        }

        <Form.Group>
          <Form.Label>Fájl feltöltés <Asterisk /></Form.Label>
          <Form.Control
              type = "file"
              onChange = {event => onChange(event, "file")}
              id = {1}
              accept = ".pdf"
          />
        </Form.Group>
      </Form>

      {showFileResponseAlert && (
        <Alert className = "text-center" variant = { fileResponse === 202 ? "success" : "danger"}>
          {fileResponse === 202 ? "Sikeres fájlfeltöltés" : "Sikertelen fájlfeltöltés"}
        </Alert>
      )}
    </>
  )

  const closeModal = () => {
    // Reset values
    setFileName("")
    setDescription("")
    setSelectedDocumentType();
    setUploadedFile("")
    setSelectedProperties([])

    // Close modal through props
    handleClose(false)
  }

  const disabled =  uploadedFile === "" || fileName === "" || description === "" || 
                    selectedDocumentType === undefined || mandatoryPropsHasValue === false

  const footer = (
    <>
      <Button
        variant="danger" 
        onClick={() => closeModal()}
        disabled={posting}
      >
        Mégse
      </Button>
      <Button
        variant="success"
        onClick={() => postFileWithMetaData()}
        disabled={posting || disabled}
      >
        Mentés
      </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show = {show}
        size="md"
        dropback="static"
        keyboard={false}
        handleClose = {() => handleClose(false)}
        title = {title}
        body = {modalBody}
        footer = {footer}
      />
    </>
  );
}

export default AddFileReferenceModal;
