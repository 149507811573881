import { SET_BOM } from '../actions/bom.js';

const bom = (state = {
    bomId: '',
    bomName: ''
}, action) => {
    switch (action.type) {
        case SET_BOM:
            return {
                ...state,
                bomId: action.bomId,
                bomName: action.bomName
            };         
        default:
            return state;
    }
};

export default bom;
