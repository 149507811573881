import { combineReducers } from 'redux';
import auth from './auth';
import bom from './bom';
import company from './company';
import language from './language';
import project from './project';

export default combineReducers({
  auth,
  bom,
  company,
  language,
  project
});
