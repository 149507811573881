import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import ListGroup from 'react-bootstrap/ListGroup';
import CustomModal from './CustomModal';
import { FaCheck, FaExchangeAlt, FaTrashAlt } from 'react-icons/fa';
import { HADColors } from "../../constants/color";
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

function DeviceOwnerReviewModal(props) {
    const {
        isLoading,
        summary,
        details,
        showModal,
        handleClose,
        acceptReview,
        declineReview
    } = props;

    const COLORS = [HADColors.Green, HADColors.Yellow, HADColors.Red];

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const modalBody = (
        summary && details && (
            <Container>
                <Row>
                    <Col className="align-items-center my-auto">
                        <Row>
                            <Col className="d-flex align-items-center justify-content-start mt-2 ml-4">
                                <div className="d-flex align-items-center justify-content-end ml-4">
                                    <div
                                        className="d-flex align-items-center justify-content-center rounded-circle p-2 mr-2"
                                        style={{ backgroundColor: HADColors.Green }}
                                    >
                                        <FaCheck />
                                    </div>
                                    Jóváhagyási kérelmek: {summary.numberOfApprovals}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex align-items-center justify-content-start mt-2 ml-4">
                                <div className="d-flex align-items-center justify-content-end ml-4">
                                    <div
                                        className="d-flex align-items-center justify-content-center rounded-circle p-2 mr-2"
                                        style={{ backgroundColor: HADColors.Yellow }}
                                    >
                                        <FaExchangeAlt />
                                    </div>
                                    Változtatási kérelmek: {summary.numberOfChangeRequests}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="d-flex align-items-center justify-content-start mt-2 ml-4">
                                <div className="d-flex align-items-center justify-content-end ml-4">
                                    <div
                                        className="d-flex align-items-center justify-content-center rounded-circle p-2 mr-2"
                                        style={{ backgroundColor: HADColors.Red }}
                                    >
                                        <FaTrashAlt />
                                    </div>
                                    Törlési kérelmek: {summary.numberOfRemovalRequests}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row>
                            <Col style={{ height: "200px" }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart width={200} height={200}>
                                        <Pie
                                            data={[
                                                { name: 'Jóváhagyási kérelmek', value: summary.numberOfApprovals },
                                                { name: 'Változtatási kérelmek', value: summary.numberOfChangeRequests },
                                                { name: 'Törlési kérelmek', value: summary.numberOfRemovalRequests },
                                            ]}
                                            cx="50%"
                                            cy="50%"
                                            labelLine={false}
                                            label={renderCustomizedLabel}
                                            outerRadius={80}
                                            fill="#8884d8"
                                            dataKey="value"
                                        >
                                            {[
                                                { name: 'Jóváhagyási kérelmek', value: summary.numberOfApprovals },
                                                { name: 'Változtatási kérelmek', value: summary.numberOfChangeRequests },
                                                { name: 'Törlési kérelmek', value: summary.numberOfRemovalRequests },
                                            ].map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                    </PieChart>
                                </ResponsiveContainer>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {details.reviewsOfProperties.map(propertyReview => {
                            const {
                                devicePropertyId,
                                reviews,
                                propertyCategory,
                                propertyMeasurementUnit,
                                propertyName,
                                devicePropertyCurrentValue
                            } = propertyReview;
                            return (
                                <div key={devicePropertyId} className="mt-2">
                                    <h5>Property: {propertyCategory} / <b>{propertyName}</b></h5>
                                    <ListGroup>
                                        {reviews.map(review => {
                                            const { id, recommendedNewValue } = review;
                                            const isRemoval = review.isRemovalRecommended;
                                            const isRecommendation = review.recommendedNewValue;
                                            if (isRemoval) {
                                                return (
                                                    <ListGroup.Item key={id} variant="danger">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">Törlési kérelem</div>
                                                            <div className="d-flex align-items-center">
                                                                <Button
                                                                    variant="danger"
                                                                    onClick={() => acceptReview(id)}
                                                                    className="mr-2"
                                                                >
                                                                    Property törlése
                                                                </Button>
                                                                <Button
                                                                    variant="outline-danger"
                                                                    onClick={() => declineReview(id)}
                                                                >
                                                                    Kérelem elutasítása
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                )
                                            }
                                            else if (isRecommendation) {
                                                return (
                                                    <ListGroup.Item key={id} variant="warning">
                                                        <div className="d-flex justify-content-between">
                                                            <div className="d-flex align-items-center">
                                                                <span>Javaslat: <b>{devicePropertyCurrentValue}</b> --&gt; <b>{recommendedNewValue}</b>
                                                                    {(
                                                                        propertyMeasurementUnit !== 'Unknown' &&
                                                                        propertyMeasurementUnit !== 'unknown' &&
                                                                        propertyMeasurementUnit !== 'NotApplicable'
                                                                    ) && ` (mértékegység: ${propertyMeasurementUnit})`}
                                                                </span>
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <Button
                                                                    variant="warning"
                                                                    onClick={() => acceptReview(id)}
                                                                    className="mr-2"
                                                                >
                                                                    Elfogadom
                                                                </Button>
                                                                <Button
                                                                    variant="outline-warning"
                                                                    onClick={() => declineReview(id)}
                                                                >
                                                                    Elutasítom
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </ListGroup.Item>
                                                )
                                            }
                                            else if (isRemoval === false && recommendedNewValue === "") {
                                                return (
                                                    <ListGroup.Item key={id} variant="success">
                                                        Jóváhagyás
                                                    </ListGroup.Item>
                                                )
                                            }
                                            else {
                                                return (
                                                    <ListGroup.Item key={id} variant="warning">
                                                        Unsupported review item: {id}
                                                    </ListGroup.Item>
                                                )
                                            }
                                        })}
                                    </ListGroup>
                                </div>
                            )
                        })}
                    </Col>
                </Row>
            </Container >
        )
    )
    const footer = (
        <>
            <Button variant="danger" onClick={handleClose}>
                Bezárás
            </Button>
        </>
    )

    return (
        <>
            <CustomModal
                show={showModal}
                size="lg"
                dropback="static"
                keyboard={false}
                handleClose={handleClose}
                title={"Eszköz ellenőrzése az eszköz tulajdonos cég tagjaként"}
                body={isLoading ? <Spinner animation="border" variant="primary" /> : modalBody}
                footer={footer}
            />
        </>
    );
}

export default DeviceOwnerReviewModal;
