// Imports from packages
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
// react-bootstrap
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import BOMApi from '../../Api/BOMApi';
// Imports of our custom components 
import AddNewBomModal from '../Modals/AddNewBomModal'
import CustomAlert from '../CommonComponents/CustomAlert'
import BOMItemQuantityEditor from './BOMItemQuantityEditor'
import BOMListDropdown from './BOMListDropdown'
import DeleteConfirmationModal from '../Modals/DeleteConfirmationModal'
import BOMDownloadTypeDropdown from './BOMDownloadTypeDropdown'
import BOMZipDownload from "./BOMZipDownload";
import BOMSelect from "./BOMSelect";
// Imports of styles
// - none
// Imports of constants
import { setBom } from '../../actions/bom.js';
import { downloadTypes, downloadTypeLabels } from '../../constants/bom';
import { inactivateBom, setActiveBom } from '../../auth';

const bomItemTdCellStyle = {
  fontSize: '0.8rem'
}

function BOMAccordions(props) {
  // Component-scoped state variables
  const [bomListWithDetails, setBomListWithDetails] = useState([])
  const [isUpdatingBomQuantity, setIsUpdatingBomQuantity] = useState(false)
  const [csvDataArrays, setCsvDataArrays] = useState([])
  // State variables for the BOMDownloadTypeDropdown
  const [dropdownButtonLabel, setDropdownButtonLabel] = useState(downloadTypeLabels[0])
  // Handle condition for ConditionallyRenderedCSVLink
  const renderCsvDownloadComponent = dropdownButtonLabel.type === downloadTypes.BomListCsv
  // Handle click events from BOMDownloadTypeDropdown
  const handleDownloadTypeChange = (clickedType) => {
    if (clickedType.type === downloadTypeLabels.Default) {
      console.error("This option should not be clicked!")
    }
    else {
      setDropdownButtonLabel(clickedType)
    }
  }
  // Global button disable-ing for 1 sec
  const [disableButtons, setDisableButtons] = useState(false)

  // Handle condition for ConditionallyRenderedSelect
  const renderSelectWithDownloadComponent = dropdownButtonLabel.type === downloadTypes.AttachedFiles
  // Handle condition for ConditionallyRenderedDownload
  const [downloadPropertyies, setDownloadPropertyies] = useState([])

  const getCsvDataForThisBom = (bomId) => {
    const foundBomAsCsv = csvDataArrays.filter(csvData => csvData.bomId === bomId)
    if (foundBomAsCsv.length === 1) {
      return foundBomAsCsv[0].itemsAsCsv
    }
    else {
      return ""
    }
  }

  const generateCsvDataFromBom = (bomItems) => {
    let csvData = []
    csvData.push(["Eszköznév", "Eszköz linkje", "Darabszám", "HAD ID", "Azonosító"])
    bomItems.forEach(bomItem => {
      let bomItemToCsv = [bomItem.deviceName, `http://localhost:3000/device/${bomItem.deviceId}`, bomItem.itemNumber, bomItem.deviceHadId, bomItem.deviceId]
      csvData.push(bomItemToCsv)
    })
    return csvData
  }

  useEffect(() => {
    const bomDetailsPromises = props.bomList.map(bomList => {
      return axios(BOMApi(bomList.id))
    })
    Promise.all(bomDetailsPromises).then(bomLists => {
      const bomDetails = bomLists.map(apiResponse => {
        return {
          id: apiResponse.data.id,
          name: apiResponse.data.name,
          items: apiResponse.data.items
        }
      })
      setBomListWithDetails(bomDetails)
    })
  }, [props.bomList, isUpdatingBomQuantity, props.updateParent])

  useEffect(() => {
    bomListWithDetails.forEach(bom => {
      const bomAsCsv = generateCsvDataFromBom(bom.items)
      //let bomsAsCsvs = [...csvDataArrays]
      //bomsAsCsvs.push({ bomId: bom.id, itemsAsCsv: bomAsCsv })
      setCsvDataArrays([{ bomId: bom.id, itemsAsCsv: bomAsCsv }])
    })
  }, [bomListWithDetails])

  const updateBomQuantity = async (bomId, bomItemId, newQuantity) => {
    const axiosParameters = {
      url: BOMApi(`${bomId}/Items/${bomItemId}`),
      method: 'put',
      data: newQuantity,
      headers: {
        'Content-Type': 'application/json'
      }
    }
    axios(axiosParameters);
    setIsUpdatingBomQuantity(false)
    props.callRefetchInParent(!props.updateParent)
  }

  const handleUpdate = (bomId, bomItemId, newQuantity) => {
    setDisableButtons(true)

    if (isUpdatingBomQuantity === false) {
      setIsUpdatingBomQuantity(true)
      updateBomQuantity(bomId, bomItemId, newQuantity)
      props.callRefetchInParent()
    }

    setTimeout(() => {
      setDisableButtons(false)
    }, 750);
  }

  let renderedBomAccordions = bomListWithDetails && bomListWithDetails.map(bom => {
    const bomAsCsv = getCsvDataForThisBom(bom.id)
    const isActiveBom = props.activeBom === bom.id
    const handleSetActiveBom = () => {
      props.activateBom(bom.id, bom.name);
      setActiveBom(bom.id, bom.name);
    }
    const handleUnsetActiveBom = () => {
      props.activateBom("", "");
      inactivateBom();
    }
    let activationButton
    if (isActiveBom) {
      activationButton = <Button size="sm" onClick={() => handleUnsetActiveBom()} variant="danger">Inaktiválás</Button>
    }
    else {
      activationButton = <Button size="sm" onClick={() => handleSetActiveBom()}>Aktiválás</Button>
    }
    return (<Accordion defaultActiveKey="0" key={`bom-list-${bom.id}`} className="m-2">
      <Card>
        <Card.Header className="d-flex justify-content-between p-0">
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            <span>{bom.name} {isActiveBom && "(aktív)"}</span>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <div className="d-flex justify-content-start align-items-center pb-3">
              {activationButton}
              <Button size="sm" onClick={() => props.showDeleteConfirmModal(bom.id)} variant="danger" className="ml-3">Törlés</Button>
              <BOMDownloadTypeDropdown
                dropdownButtonLabel={dropdownButtonLabel}
                handleTypeChange={clickedType => handleDownloadTypeChange(clickedType)}
              />
              <Button
                className="ml-3"
                size="sm"
                onClick={() => props.activateCreateFromFile(bom.id, bom.name)}
              >
                BOM módosítása fájl alapján
              </Button>
              {renderCsvDownloadComponent && (
                <CSVLink
                  data={bomAsCsv}
                  filename={`hadocument-bom-id-${bom.id}.csv`}
                  className="btn btn-primary btn-sm ml-3"
                  target="_blank"
                  separator="&#09;"
                >
                  Letöltés (.csv)
                </CSVLink>
              )}
              {renderSelectWithDownloadComponent && (
                <BOMZipDownload
                  downloadPropertyies={downloadPropertyies}
                  bom={bom}
                />
              )}
            </div>
            <div className="d-flex justify-content-start align-items-center pb-3">
              {renderSelectWithDownloadComponent && (
                <BOMSelect
                  handleDocumentProperties={downloadPropertyies => setDownloadPropertyies(downloadPropertyies)}
                />
              )}
            </div>
            {bom && bom.items && bom.items.length !== 0 ? (
              <Table striped bordered hover size="sm" className="text-center">
                <thead>
                  <tr>
                    <th>Eszköz</th>
                    <th>HAD ID</th>
                    <th>Azonosító</th>
                    <th>Mennyiség</th>
                  </tr>
                </thead>
                <tbody>
                  {bom && bom.items && bom.items.map(item => {
                    return (<tr key={`bom-list-item-${item.id}`}>
                      <td className="align-middle" style={bomItemTdCellStyle}>
                        <Link to={`/device/${item.deviceId}`}><b>{item.deviceName}</b></Link>
                      </td>
                      <td className="align-middle" style={bomItemTdCellStyle}>{item.deviceHadId}</td>
                      <td className="align-middle" style={bomItemTdCellStyle}>{item.deviceItemNumber}</td>
                      <td className="align-middle">
                        <BOMItemQuantityEditor
                          handleUpdate={(newQuantity) => handleUpdate(bom.id, item.id, newQuantity)}
                          quantity={item.itemNumber}
                          handleDelete={() => props.handleDelete(bom.id, item.id)}
                          disableButtons={disableButtons}
                          onlyValue={false}
                          disabled={isUpdatingBomQuantity}
                        /></td>
                    </tr>)
                  })}
                </tbody>
              </Table>
            ) : (
              <Alert variant="primary">A BOM még nem tartalmaz termékeket. Eszközt a keresésnél és az adatlapokon tud hozzáadnia jelenleg aktív BOM-hoz.</Alert>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion >)
  })

  useEffect(() => {
    renderedBomAccordions = bomListWithDetails && bomListWithDetails.map(bom => {
      const isActiveBom = props.activeBom === bom.id
      const handleSetActiveBom = () => {
        props.activateBom(bom.id, bom.name);
        setActiveBom(bom.id, bom.name);
      }
      const handleUnsetActiveBom = () => {
        props.activateBom("", "");
        inactivateBom();
      }
      let activationButton
      if (isActiveBom) {
        activationButton = <Button onClick={() => handleUnsetActiveBom()} variant="danger">BOM inaktiválása</Button>
      }
      else {
        activationButton = <Button onClick={() => handleSetActiveBom()}>BOM aktiválása</Button>
      }
      return (<Accordion defaultActiveKey="0" key={`bom-list-${bom.id}`} className="m-2">
        <Card>
          <Card.Header className="d-flex justify-content-between p-0">
            <Accordion.Toggle as={Button} variant="link" eventKey="0">
              <span>{bom.name} {isActiveBom && "(aktív)"}</span>
            </Accordion.Toggle>
            <div className="d-flex justify-content-center align-items-center pr-3">
              {activationButton}
            </div>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              {bom && bom.items && bom.items.length !== 0 ? (
                <Table striped bordered hover size="sm">
                  <thead>
                    <tr>
                      <th>Eszköz</th>
                      <th>Mennyiség</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bom && bom.items && bom.items.map(item => {
                      return (<tr key={`bom-list-item-${item.id}`}>
                        <td className="align-middle">{item.deviceName}</td>
                        <td className="align-middle"><BOMItemQuantityEditor handleUpdate={(newQuantity) => handleUpdate(bom.id, item.id, newQuantity)} quantity={item.itemNumber} handleDelete={() => props.handleDelete(bom.id, item.id)} onlyValue={false} disabled={isUpdatingBomQuantity} /></td>
                      </tr>)
                    })}
                  </tbody>
                </Table>
              ) : (
                <Alert variant="primary">A BOM még nem tartalmaz termékeket. Eszközt a keresésnél és az adatlapokon tud hozzáadnia jelenleg aktív BOM-hoz.</Alert>
              )}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>)
    })
  }, [bomListWithDetails])

  return renderedBomAccordions
}

function BOMList(props) {
  const [bomListElements, setBomListElements] = useState([])
  const [, setApiResponse] = useState(null)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [bomIdToDelete, setBomIdToDelete] = useState("")

  const showDeleteConfirmModal = (bomId) => {
    setBomIdToDelete(bomId)
    setShowConfirmationModal(true)
  }

  const handleBomDeletion = () => {
    deleteBom(bomIdToDelete)
    setShowConfirmationModal(false)
  }

  const deleteBom = async (bomId) => {
    const axiosParameters = {
      url: BOMApi(`${bomId}/Project/${props.projectId}`),
      method: 'delete'
    }
    const apiResponse = await axios(axiosParameters);
    setApiResponse(apiResponse)
    props.updateBomList()
  }

  const deleteItemFromActiveBomByBomItemId = async (bomId, bomItemId) => {
    const axiosParameters = {
      url: BOMApi(`${bomId}/Items/${bomItemId}`),
      method: 'delete'
    }
    const apiResponse = await axios(axiosParameters);
    setApiResponse(apiResponse)
    props.updateBomList()
  }

  useEffect(() => {
    setBomListElements(props.bomList)
  }, [props.bomList])

  const isEmptyBomList = bomListElements.length === 0

  const emptyBomListAlert = <CustomAlert variant="warning" message="Még nincs BOM a projekthez." width="w-100" />

  const bomListOrEmptyAlert = isEmptyBomList ? (
    emptyBomListAlert
  ) : (
    <BOMAccordions
      callRefetchInParent={() => props.updateBomList(!props.updateParent)}
      bomList={bomListElements}
      activeBom={props.bomId}
      activateBom={(id, name) => props.setBom(id, name)}
      handleDelete={deleteItemFromActiveBomByBomItemId}
      showDeleteConfirmModal={(bomId) => showDeleteConfirmModal(bomId)}
      activateCreateFromFile={((id, name) => props.activateCreateFromFile({ id, name }))}
    />
  )

  const centralColumn = (
    <div style={{ backgroundColor: "white", textAlign: 'left' }}>
      <div className="d-flex">
        <div className="container">
          <div className="row align-items-start">
            <div className="col">
              <h2 className="p-3">Bill of Materials</h2>
            </div>
          </div>
          <div className="row align-items-start pl-3 mb-3">
            <div className="d-flex" style={{ paddingLeft: "15px" }}>
              <Button onClick={() => props.setIsOpenCreateNewBomModal(true)} className="mr-3">Új BOM hozzáadása</Button>
              <div className="d-flex align-items-center">
                <span>Aktív BOM: </span>
                <BOMListDropdown bomList={bomListElements} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 pb-3">
        {bomListOrEmptyAlert}
      </div>
      <AddNewBomModal show={props.isOpenCreateNewBomModal} handleClose={() => props.setIsOpenCreateNewBomModal(false)} />
      <DeleteConfirmationModal
        show={showConfirmationModal}
        onConfirm={handleBomDeletion}
        handleClose={() => setShowConfirmationModal(false)}
        title="Biztosan törlöd a BOM-ot?"
        body="Ez egy vissza nem vonható művelet!"
      />
    </div >
  )

  return centralColumn
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
  const { bomId, bomName } = state.bom;
  return { bomId, bomName };
};
// Store reducers to components props as callable functions
const mapDispatchToProps = dispatch => ({
  setBom: (bomId, bomName) => {
    dispatch(setBom(bomId, bomName));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BOMList);
