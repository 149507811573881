import axios from "axios";
import { useQuery } from "react-query";

const useFetchEnumValues = (enumTypeId) => {
  return useQuery(
    `FetchEnumValuesFor${enumTypeId}`,
    async () => {
      const result = await axios(`Enum/${enumTypeId}`);
      return result.data;
    },
    {
      enabled: false,
      retry: 1,
    }
  );
};

export default useFetchEnumValues;