// Imports from packages
// - none
// import from 'react-bootstrap';
// - none
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants
// - none

export default function entityToHungarian(entity){
    let entityToHungarian
    switch(entity){
      case "user": entityToHungarian = "felhasználó"; break;
      case "company": entityToHungarian = "cég"; break;
      case "project": entityToHungarian = "projekt"; break;
    }

    return entityToHungarian
}