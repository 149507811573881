import { LOGIN, LOGOUT, SET_AUTH_TOKEN } from '../actions/auth.js';

const auth = (state = {
    isAuthenticated: false,
    user: '',
    thumbnail: '',
    token: '',
    role: '',
}, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.user,
                companyId: action.companyId,
                companyName: action.companyName,
                thumbnail: action.thumbnail,
                token: action.token,
                role: action.role,
            };
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: '',
                companyId: '',
                companyName: '',
                thumbnail: '',
                token: '',
                role: '',
            };
        case SET_AUTH_TOKEN:
            return {
                ...state,
                token: action.token
            };
        default:
            return state;
    }
};

export default auth;
