// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Select from 'react-select'
// Imports for APIs
import PropertyApi from '../../Api/PropertyApi'
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants
// - none

export default function BOMSelect(props) {
    // Destructuring assignment from the props
    const { handleDocumentProperties } = props
    const [selectOptions, setSelectOptions] = useState([]);

    useEffect(() => {
        let mounted = true;

        const fetchSelectOptions = async() => {
            const result = await axios(PropertyApi('List?propertyType=Device&typeOf=FileReference&filter=NoFilter&mandatoryFilter=Both'));
            
            const selectFormatedResult = result.data.map(fileType => {
                return {
                    value: fileType.id, 
                    label: fileType.name
                }
            })

            setSelectOptions(selectFormatedResult)
        }

        // Fetch data
        if(mounted){
            fetchSelectOptions();
        }

        return () => mounted = false;
    }, [])

    const onChange = (selected) => {
        if(selected != null){
            // Update values for download button
            handleDocumentProperties(selected)
        }
        else{
            // Delete all items button was invoked, list should be emptied
            handleDocumentProperties([])
        }
    }

    const customStyles = {
        control: provided => ({
            ...provided,
            maxWidth: 675,
            minWidth: 250
        }),
        clearIndicator: provided => ({
            ...provided,
            margin: '0 0 0 15px'
        })
    }

    return (
        <Select
            menuPortalTarget={document.body}
            styles={customStyles}
            options={selectOptions}
            isClearable={true}
            isMulti = {true}
            placeholder = "Összes fájl típus"
            defaultValue = {selectOptions[0]}
            onChange = {selected => onChange(selected)}
        />
    )
}