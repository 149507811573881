// Imports from packages
import React, { useState } from "react";
import axios from "axios";
import { connect, useSelector } from "react-redux";
import { login, setAuthToken } from "../../actions/auth.js";
import { setCompany } from "../../actions/company.js";
import { useHistory, useLocation } from "react-router-dom";
// Imports packages from react-bootstrap
// Imports for APIs
import UserApi from "../../Api/UserApi";
// Imports of our custom components
import CustomToast from "../CommonComponents/CustomToast";
// Imports of styles
import "../../App.css";
import "../../App.scss";
// Imports of contants
import { HADColors } from "../../constants/color";
import { setAuthDataToLocalStorage, localStorageTokenItemKey, authDataExistsInLocalStorage } from "../../auth/index";
import LoginForm from "../Login/LoginForm.jsx";

function LoginPage(props) {
  // Destructuring assignment from redux props
  const { setAuthToken } = props;
  // Toast
  const [showToast, setShowToast] = useState(false);
  const history = useHistory();
  const location = useLocation();
  let isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  if (authDataExistsInLocalStorage() === false) {
    isAuthenticated = false;
  }

  // PUT: set user's company active
  const putActiveCompany = async (id, name) => {
    try {
      // result is not used so this is enough
      await axios.put(UserApi(`Companies/${id}/Activate`));
    } catch (error) {
      // Show Toast
      console.error(`${name} for user could not be activated`);
    }
  };

  // Fetch deviceId
  const fetchCompanies = async (token) => {
    try {
      const result = await axios(UserApi("Companies"), {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      if (result.status === 200) {
        return result.data;
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Account
  const postAccount = async (username, password) => {
    const login = {
      username: username,
      password: password,
      rememberMe: false,
    };
    try {
      const resultLogin = await axios.post("/Account/Token", login);
      if (resultLogin.status === 200) {
        const token = resultLogin.data.token;
        const userRole = resultLogin.data.authenticatedUser.role;
        setAuthToken(token);
        localStorage.setItem(localStorageTokenItemKey, token);
        // Companies
        try {
          const companies = await fetchCompanies(token);
          const { id, name, shortName } = companies[0] || {
            id: "",
            name: "",
            shortName: "",
          };
          // Activate company if there is any
          putActiveCompany(id, name);

          // Profile picture
          // Get thumbnail path
          let thumbnailImageUrl = "";
          try {
            const userPictureThumbnailResult = await axios(
              UserApi(`Picture/Thumbnail`, "GET"),
              { responseType: "blob" }
            );
            thumbnailImageUrl = URL.createObjectURL(
              userPictureThumbnailResult.data
            );
            // Handle SUCCESSFUL login
            props.login(username, id, name, shortName, thumbnailImageUrl, userRole);
            setAuthDataToLocalStorage({
              username, id, name, shortName, thumbnail: thumbnailImageUrl, token: token, role: userRole
            })
            const { from } = location.state || { from: { pathname: "/" } };
            history.replace(from);
          } catch (error) {
            console.error("No Thumbnail for user");
            props.login(username, id, name, shortName, "", userRole);
            setAuthDataToLocalStorage({
              username, id, name, shortName, thumbnail: "", token: token, role: userRole
            })
            const { from } = location.state || { from: { pathname: "/" } };
            history.replace(from);
          }
        }
        catch (error) {
          console.error("112 catch (error)", error, Object.entries(error));
        }
      }
    } catch (error) {
      console.error("Error during login process", error, Object.entries(error));

      // Show Toast
      setShowToast(true);

      setTimeout(() => {
        setShowToast(false);
      }, 4000);
    }
  };

  const centralColumn = (
    <>
      <CustomToast
        showToast={showToast}
        setShowToast={() => setShowToast(false)}
        iconColor={HADColors.Red}
        header="Sikertelen belépés"
        headerTime="Épp most"
        body="Ellenőrizze, hogy a jelszó és/vagy a felhasználónév megfelelő-e!"
        setDisableButtonViaToast={() => null}
      />
      <LoginForm handleFormSubmit={(username, password) => postAccount(username, password)} />
    </>
  );

  if (isAuthenticated) {
    const { from } = location.state || { from: { pathname: "/home" } };
    history.replace(from);
    return null;
  }
  else {
    return centralColumn;
  }
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = (state) => {
  const { user, thumbnail, isAuthenticated, role } = state.auth;
  const { companyId, companyName } = state.company;
  return {
    user,
    companyId,
    companyName,
    thumbnail,
    isAuthenticated,
    role,
  };
};

// Store reducers to components props as callable functions
const mapDispatchToProps = (dispatch) => ({
  setAuthToken: (token) => dispatch(setAuthToken(token)),
  login: (name, companyId, companyName, shortName, thumbnail, userRole) => {
    dispatch(login(name, thumbnail, "", userRole));
    dispatch(setCompany(companyId, companyName, shortName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
