/* eslint-disable no-console */
// Imports from packages
import React from "react";
import { Link } from "react-router-dom";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FaPlus } from "react-icons/fa";
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
import "../../App.css";
// Imports of contants
// - none

function AddNewDeviceButton() {
  return (
    <OverlayTrigger
      key="new-device-bottom"
      placement="bottom"
      overlay={
        <Tooltip id="tooltip-header-new-device">
          Új eszköz hozzáadása
        </Tooltip>
      }
    >
      <Link to="/new-device">
        <div className="rounded-circle-icon-container">
          <FaPlus />
        </div>
      </Link>
    </OverlayTrigger>
  )
}

export default AddNewDeviceButton;
