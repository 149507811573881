// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select'
// react-bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import PropertyApi from '../../Api/PropertyApi';
import EnumApi from '../../Api/EnumApi';
import CompanyApi from '../../Api/CompanyApi';
import UserApi from '../../Api/UserApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import AddPropertyInputComponent from '../Property/AddPropertyInputComponent';
// Imports of styles
// - none
// Imports of contants
// - none

export default function AddNewProfileProperty(props) {
    // Destructuring assignment from the props
    const { show, profileId, handleClose, setRefreshing, entity } = props

    // Prop variables  
    const [properties, setProperties] = useState([])
    const [enumProperties, setEnumProperties] = useState([])
    const [selectedOption, setSelectedOption] = useState()
    const [newValue, setNewValue] = useState("")
    const [addingProperty, setAddingProperty] = useState(false)

    // Alert
    const [response, setResponse] = useState(0);
    const [showResponse, setShowResponse] = useState(false);

    useEffect(() => {
        const fetchProperties = async () => {
            let result
            if (entity === "user") {
                result = await axios(PropertyApi("List?propertyType=User&mandatoryFilter=OnlyOptional"));
            }
            else if (entity === "company") {
                result = await axios(PropertyApi("List?propertyType=Company&mandatoryFilter=OnlyOptional"));
            }

            const resultWithoutFileReference = result.data.filter(property => property.contentType !== "FileReference")
            setProperties(resultWithoutFileReference);
        };

        fetchProperties();
        // To avoid unmounted warning
        return () => { setProperties([]) }
    }, []);

    const fetchEnumProperties = async (id) => {
        const result = await axios(EnumApi("Property", id));
        setEnumProperties(result.data.values);
    };

    const setOption = selected => {
        // Above two lines are responsible for reseting the second select if main (enumCategory) select was changed
        setSelectedOption(undefined)
        setNewValue("")

        setSelectedOption(selected)

        // If prop is enum, get all enum values 
        if (selected.contentType === "Enum") {
            fetchEnumProperties(selected.value);
        }
    }

    const resetModal = () => {
        setSelectedOption()
        setNewValue("")
        setShowResponse(false)
    }

    const closeModal = () => {
        // Reset modal values
        resetModal()

        // Close modal
        handleClose()
    }

    const putMethod = () => {
        setAddingProperty(true)

        let calculatedValue;
        switch (selectedOption.contentType) {
            case "Date": calculatedValue = `${parseInt((new Date(newValue.value).getTime() / 1000).toFixed(0))}`; break;
            case "Time": calculatedValue = `${parseInt((new Date(`01 Jan 1970 ${newValue.value} GMT+0100`).getTime() / 1000).toFixed(0))}`; break;
            case "DateTime": calculatedValue = `${parseInt((new Date(newValue.value).getTime() / 1000).toFixed(0))}`; break;
            default: calculatedValue = newValue.value;
        }

        const body = {
            "propertiesToAdd": [
                {
                    "propertyId": `${selectedOption.value}`,
                    "value": calculatedValue
                }
            ],
            "propertiesToUpdate": [],
            "propertiesToDelete": []
        }

        // Create the parameters for axios
        const axiosParameters = {
            url: entity === "user" ? UserApi("Profile") : CompanyApi(`${profileId}/Profile`),
            method: 'put',
            data: body,
            headers: {
                'Content-Type': 'application/json',
                "accept": "application/json"
            }
        }

        // Call API endpoint with axios
        axios(axiosParameters)
            .then(response => {
                if (response.status === 200) {
                    // Use this to refresh values
                    setResponse(response.status)
                    setShowResponse(true)

                    setTimeout(() => {
                        // Force trigger link fetching
                        setRefreshing(response.data)

                        setShowResponse(false)
                        setAddingProperty(false)

                        closeModal()
                    }, 1500);
                }
            })
            .catch(error => {
                console.warn("Error during Link addingProperty", error);
                setResponse(error.response.status);
                setAddingProperty(false)
            })
    }

    const mapPropertiesForSelect = selectProperties => selectProperties.map(option => {
        let formattedOption = {
            label: option.name,
            value: option.id,
            contentType: option.contentType
        }

        return formattedOption
    })

    const title = `Új tulajdonság hozzáadása`;

    const modalBody = (
        <Form>
            <Select
                options={mapPropertiesForSelect(properties)}
                placeholder="Tulajdonság kiválasztása"
                onChange={(selected) => setOption(selected)}
            />
            {selectedOption !== undefined && (
                <AddPropertyInputComponent
                    isEnum={selectedOption.contentType === "Enum"}
                    contentType={selectedOption.contentType}
                    options={mapPropertiesForSelect(enumProperties)}
                    value={newValue}
                    onChange={(selected) => setNewValue(selected)}
                    placeholder="Érték kiválasztása"
                />
            )}
        </Form>
    );

    const footer = (
        <>
            <Button
                variant="success"
                onClick={putMethod}
                disabled={addingProperty || selectedOption === undefined}
            >
                Mentés
            </Button>
            {showResponse &&
                <div className='d-flex justify-content-center'>
                    <Alert variant={response === 200 ? 'success' : 'danger'} className='text-center m-0 p-2'>
                        {response === 200 ? 'Sikeres hozzáadás' : 'Sikertelen hozzáadás'}
                    </Alert>
                </div>
            }
            <Button
                variant="danger"
                onClick={closeModal}
                disabled={addingProperty}
            >
                Mégse
            </Button>
        </>
    )

    return (
        <>
            <CustomModal
                show={show}
                size="md"
                dropback="static"
                keyboard={false}
                handleClose={handleClose}
                title={title}
                body={modalBody}
                footer={footer}
            />
        </>
    );
}
