// Imports from packages
import React from 'react';
// import from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
import '../../../App.css';
// Imports of contants
// - none

export default function iconsWithTooltip(iconArray){
    const icons = iconArray.map((item, index) => {
        const cursorType = item.disabled ? "not-allowed" : "pointer";
        return (
            <Button
                key = {`profile-prop-modification-top-${index}`}
                className="d-flex justify-content-center align-items-center ml-2 p-0"
                variant={item.color}
                style = {{ cursor: cursorType, borderRadius: "50%", width: 28, height: 28 }}
                onClick = {item.onClickHandler && item.onClickHandler}
                disabled = {item.disabled}
            >
                <OverlayTrigger
                    placement = "top"
                    overlay = {
                        <Tooltip id="tooltip-profile-prop-modification">
                            {item.label}
                        </Tooltip>
                    }
                >
                    {item.icon}
                </OverlayTrigger>
            </Button>
        )
    })

    return icons
}