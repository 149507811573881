// Imports from packages
import React from 'react';
import Button from 'react-bootstrap/Button';
import { FaTrashAlt } from 'react-icons/fa';

export default function EditableInputList(props) {
    // Input data from parent component
    // Component-scoded logic
    const onListInputChange = ({ target }) => {
        const updatedValueIndex = (value, target) => value.id === target.name;
        let updateValues = [...props.selectedEnum.values]
        updateValues[updateValues.findIndex(value => updatedValueIndex(value, target))].name = target.value
        props.updateEnumValueList(updateValues)
    };
    return (
        <div>
            {props.selectedEnum.values.map(value => {
            return (
                <div key = {`div-${value.id}`} className = "d-flex m-2">
                    <input
                        key={value.id}
                        name={value.id}
                        placeholder={value.name}
                        value={value.name}
                        onChange={onListInputChange}
                    />
                    <div className = "d-flex justify-content-center align-items-center p-2">
                        <FaTrashAlt style = {{ cursor: 'pointer' }} color = "#eb5757" onClick = {() => props.deleteEnumFromList(value.id)}/>
                    </div>
                </div>
            )
        })}
        <Button variant = "primary" onClick = {() => props.addNewValueToEnumValueList()}>Új érték hozzáadása</Button>
        </div>
    )
}