// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select'
// import react-bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// Imports for APIs
import PropertyApi from '../../Api/PropertyApi';
import EnumApi from '../../Api/EnumApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import Asterisk from '../CommonComponents/Asterisk';
import AsteriskExplanation from '../CommonComponents/AsteriskExplanation';
import AddPropertyInputComponent from '../Property/AddPropertyInputComponent';
// Imports of styles
// - none
// Imports of contants
// - none

function AddPropertyModal(props) {
  const [properties, setProperties] = useState([]);
  const [selectedOptionsValues, setSelectedOptionsValues] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  const [propertyName, setPropertyName] = useState('');
  const [propertyId, setPropertyId] = useState('');
  const [propertyUnit, setPropertyUnit] = useState('');
  const [propertyIsEnum, setPropertyIsEnum] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState({ value: "" });

  useEffect(() => {
    const fetchProperties = async () => {
      const result = await axios(PropertyApi(`List?propertyType=Device`));
      const filteredResult = result.data.filter(property =>
        property.contentType === "NormalValue" ||
        property.contentType === "Enum" ||
        property.contentType === "Time" ||
        property.contentType === "Date" ||
        property.contentType === "DateTime" ||
        property.contentType === "Address")

      setProperties(filteredResult);
    };
    fetchProperties();
  }, []);

  const options = properties.map(property => {
    return {
      value: property.id,
      label: property.name,
      isEnum: property.contentType === "Enum",
      contentType: property.contentType,
      measurementUnit: property.measurementUnit
    }
  })

  const findMeasurementUnit = (propertyName) => {
    const indexInArray = properties.findIndex(property => property.name === propertyName);
    return properties[indexInArray].measurementUnit
  }

  const onChangeSelectedElement = async (selected) => {
    setPropertyName(selected.label)
    setPropertyId(selected.value)
    setPropertyUnit(findMeasurementUnit(selected.label) || selected.measurementUnit)
    setPropertyIsEnum(selected.isEnum)
    setSelectedProperty(selected)

    // Get values about property
    let values
    if (selected.isEnum) {
      const valueResult = await axios(EnumApi("Property", selected.value));
      const mappedValues = valueResult.data.values.map(value => {
        return { label: value.name, value: value.id };
      })

      values = mappedValues;
    }
    else {
      const valueResult = await axios(PropertyApi("Values", selected.value));
      const mappedValues = valueResult.data.values.map(value => {
        return { label: value, value: value };
      })

      values = mappedValues;
    }

    setSelectedOptionsValues(values)
  }

  const resetValues = () => {
    setPropertyIsEnum(false)
    setPropertyName("")
    setPropertyId("")
    setSelectedValue("")
  }

  const onPropertySave = () => {
    // Forward data to parent
    switch (selectedProperty.contentType) {
      case "Date": props.handlePropertySave(propertyId, parseInt((new Date(selectedValue.value).getTime() / 1000).toFixed(0))); break;
      case "Time": props.handlePropertySave(propertyId, parseInt((new Date(`01 Jan 1970 ${selectedValue.value} GMT+0100`).getTime() / 1000).toFixed(0))); break;
      case "DateTime": props.handlePropertySave(propertyId, parseInt((new Date(selectedValue.value).getTime() / 1000).toFixed(0))); break;
      default: props.handlePropertySave(propertyId, selectedValue.value);
    }
    // Reset local state for next user interaction
    resetValues()
  }

  const onCloseModal = () => {
    resetValues();
    props.handleClose(false);
  }

  const title = 'Normál tulajdonság hozzáadása';

  const modalBody = (
    <>
      <Select
        options={options}
        placeholder="Tulajdonság kiválasztása..."
        onChange={(selected) => onChangeSelectedElement(selected)}
      />
      {propertyName.length > 0 && (
        <Form className="mt-3">
          <Form.Group controlId="propertyForm" className="mb-0">
            <Form.Text>
              <AsteriskExplanation />
            </Form.Text>
            <Form.Text className="text-muted">
              A kiválasztott tulajdonság értékét itt adhatod meg.
            </Form.Text>
            <Form.Label className="mr-3 my-3">
              {propertyName} <Asterisk />
            </Form.Label>
            <AddPropertyInputComponent
              isEnum={propertyIsEnum}
              contentType={selectedProperty.contentType}
              options={selectedOptionsValues}
              value={selectedValue}
              onChange={selected => setSelectedValue(selected)}
              placeholder={"Értékkészlet"}
            />
            {!(propertyUnit === 'unknown' || propertyUnit === 'Unknown' || propertyUnit === " notapplicable") &&
              <small className="text-muted ml-2">
                (Mértékegység: {propertyUnit.toLowerCase()})
              </small>
            }
          </Form.Group>
        </Form>
      )}
    </>
  )

  // Disable save button if any of the required fields is empty
  const isDisabledToSave = propertyId === '' || propertyName === '' || selectedValue === ''

  const footer = (
    <>
      <Button variant="danger" onClick={onCloseModal}>
        Mégse
      </Button>
      <Button
        variant="success"
        onClick={() => onPropertySave()}
        disabled={isDisabledToSave}
      >
        Mentés
      </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show={props.show}
        size="lg"
        dropback="static"
        keyboard={false}
        handleClose={() => props.handleClose(false)}
        title={title}
        body={modalBody}
        footer={footer}
      />
    </>
  );
}

export default AddPropertyModal;
