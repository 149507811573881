// Imports from packages
import React, { useState, useEffect } from 'react';
// react-bootstrap
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
// Imports for APIs
// - none
// Imports of our custom components
import PropertyItem from './PropertyItem';
import OtherPropSelect from './OtherPropSelect';
import { filterArrayToJustHaveNormalValueOrEnumProps } from './detailedFunctions'
// Imports of styles
// - none
// Imports of contants
import { propertyType } from '../../../constants/propertyType';  

export default function PropertyAccordion(props) {
    // Destructuring assignment from the props
    const { title, categoryId, type, items, setPropertyFilters } = props

    const [propFilterArray, setPropFilterArray] = useState([])
    const [isAccordionOpen, setIsAccordionOpen] = useState(true)

    // Delete propFilterArray, if category was changed
    useEffect(() => {
      if(categoryId !== ''){
        setPropFilterArray([])
        setPropertyFilters([])
      }
    }, [categoryId])

    let content
    switch(type){
      case propertyType.MANDATORY: content = 'kötelező'; break;
      case propertyType.OTHERS: content = 'tetszőleges'; break;
    }

    // Make request's body's propertyFilters variable
    const makePropertyFilterArray = (newProp) => {
      // If value was added previously, update it, if not, add to end
      const filteredArrayWithoutNewProp = propFilterArray.filter(property => property.propertyId !== newProp.propertyId)
      const updateArray = [
        ...filteredArrayWithoutNewProp,
        newProp
      ]
      setPropFilterArray(updateArray)

      // Call setPropertyFilters function
      setPropertyFilters(updateArray)
    }

    // Remove property with given ID variable
    const deletePropFromFilterArray = propToBeDeletedId => {
      const arrayWithoutDeletedProp = propFilterArray.filter(property => property.propertyId !== propToBeDeletedId)
      setPropFilterArray(arrayWithoutDeletedProp)

      // Call setPropertyFilters function
      setPropertyFilters(arrayWithoutDeletedProp)
    }

    // Check if there is any item
    let itemsArray
    // Have selected a category
    if(categoryId !== ''){
      if(items.length === 0) {
        itemsArray = `Nem találhatóak ${content} tulajdonság ehhez a kategóriához`
      }
      else {
        if(type === propertyType.MANDATORY){
          itemsArray = items.map((property, index) => {
            return (
              <PropertyItem
                key = {`${property.id}-${index}-${categoryId}`}
                property = {property}
                isMandatory = {type === propertyType.MANDATORY}
                setPropertyFilters = {prop => makePropertyFilterArray(prop)}
                deletePropFromFilterArray = {propToBeDeletedId => deletePropFromFilterArray(propToBeDeletedId)}
              />
            )
          })
        }
        else if(type === propertyType.OTHERS){
          const justNormalValueOrEnumProps = filterArrayToJustHaveNormalValueOrEnumProps(items)

          itemsArray = (
            <OtherPropSelect
              categoryId={categoryId}
              items = {justNormalValueOrEnumProps}
              type = {type}
              setPropertyFilters = {prop => makePropertyFilterArray(prop)}
              deletePropFromFilterArray = {propToBeDeletedId => deletePropFromFilterArray(propToBeDeletedId)}
            />
          )
        }
      }
    }
    else {
      itemsArray = `Még nem választott kategóriát`
    }
  
    return (
      <Accordion defaultActiveKey="0" key={type} className = 'accordion-style w-100'>
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            className = 'accordion-toggle-style'
            variant="link"
            eventKey='0'
            onClick = {() => setIsAccordionOpen(!isAccordionOpen)}
          >
            {title + (isAccordionOpen ? "  ▲" : "  ▼")}
          </Accordion.Toggle>
          <Accordion.Collapse eventKey='0'>
            <Card.Body className = 'card-body-style'>
              {itemsArray}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    )
}