// Imports from packages
import React from 'react';
import { HADColors } from '../../constants/color'

export default function NewBlankPage(props){
    // Destructuring assignment from the props
    const { link, content } = props;

    const linkWithHTTPS = link.includes("http") ? link : `https://${link}`;

    return (
        <a
            target = '_blank'
            rel = "noreferrer"
            href = {linkWithHTTPS}
            style = {{ textDecoration: 'none', color: HADColors.Link }}
        >
            {content}
        </a>
    )
}