export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_AUTH_TOKEN = 'SET_AUTH_TOKEN';

export const login = (user, thumbnail, token, role) => ({
  type: LOGIN,
  user: user,
  thumbnail: thumbnail,
  token: token,
  role: role,
});

export const logout = () => ({
  type: LOGOUT,
  user: '',
  thumbnail: '',
  token: '',
  role: '',
});

export const setAuthToken = (token) => ({
  type: SET_AUTH_TOKEN,
  token: token,
});

