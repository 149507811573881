import React from 'react';
import CustomPagination from '../CommonComponents/CustomPagination';
import ConnectedDevicesWithCardDeck from './ConnectedDevicesWithCardDeck';
import '../../App.scss';

function DeviceConnections(props) {
  // Destructuring props
  const { connections, pageCount, connectionType, currentPage, setCurrentPage, devicesPerPage, pathname } = props
  // Calculate local variables
  // const devicesInThisPage = connections.slice((currentPage - 1) * devicesPerPage, (currentPage * devicesPerPage)).length
  const slicedConnectedDevices = connections.slice((currentPage - 1) * devicesPerPage, (currentPage * devicesPerPage))
  return (
    connections.length !== 0 && (
      <div>
        <h2 className="p-3">{connectionType}</h2>
        {pageCount > 1 && (
          <CustomPagination
            pageCount={pageCount}
            currentPage={currentPage}
            setCurrentPage={newPageNumber => setCurrentPage(newPageNumber)}
          />
        )}
        <ConnectedDevicesWithCardDeck
          connections={slicedConnectedDevices}
          path={pathname}
        />
      </div>
    )
  );
}

export default DeviceConnections;
