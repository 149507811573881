// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams, useLocation } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import DeviceCategories from './Categories';
import DeviceConnections from './Connections';
import EditorBox from '../CommonComponents/EditorBox';
import DeviceDistributors from './DeviceDistributors';
import Metadatas from './Metadatas';
import ThreeColumnPageTemplate from '../Pages/ThreeColumnPageTemplate';
import LeftColumn from '../CommonComponents/LeftColumn';
import PropertyList from './PropertyList';
import CustomAccordion from '../CommonComponents/CustomAccordion';
import DevicePageBOMHandler from '../BOM/DevicePageBOMHandler';
import { mapConnectedDevicesForAccordion } from './utils/deviceFunctions';
// Imports of styles
import '../../App.css';
import './Device.css';
import DeviceOwnerReviewModal from '../Modals/DeviceOwnerReviewModal';
import EditDeviceBasicInformationModal from '../Modals/EditDeviceBasicInformationModal';
import CategoryApi from '../../Api/CategoryApi';
import { flattenGivenArray } from '../Search/Detailed/detailedFunctions';
// Imports of contants
// - none

// Local configuration variable for this component
const devicesPerPage = 4

function DevicePage(props) {
  // Handle ID of the device (from props or URL parameter)
  const location = useLocation();
  const pathname = location.pathname.split('/')[1]

  const { companyId, role } = props;

  const { id } = useParams();
  const [deviceId, setDeviceId] = useState();

  const [device, setDevice] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [disableReviewButtons, setDisableReviewButtons] = useState(false);

  // Device's links
  const [allLinks, setAllLinks] = useState({});

  // Device's files
  const [files, setFiles] = useState([]);
  const [documentTypes, setDocumentTypes] = useState([]);

  // Link refreshing from outside
  const [refreshLeftColumn, setRefreshLeftColumn] = useState('');
  const [refreshCentralColumn, setRefreshCentralColumn] = useState('');
  const [refreshBottom, setRefreshBottom] = useState('');

  // Device connections with pagination
  const [connections, setConnections] = useState([]);
  const [pageCount, setPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const [substituteConnection, setSubstituteConnection] = useState([]);
  const [substitutePageCount, setSubstitutePageCount] = useState(0)
  const [substituteCurrentPage, setSubstituteCurrentPage] = useState(1)

  // Device review
  const [deviceOwnerCompanyId, setDeviceOwnerCompanyId] = useState(undefined);
  const [, setDeviceOwnerNickname] = useState(undefined);
  const [openDeviceReviewModal, setOpenDeviceReviewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [summary, setSummary] = useState(undefined);
  const [details, setDetails] = useState(undefined);

  const [showBasicInformationEditModal, setShowBasicInformationEditModal] = useState(false);
  const [apiResponse, setApiResponse] = useState("")

  const [categories, setCategories] = useState([]);

  // If we came from hadId url path, get deviceId
  useEffect(() => {
    const fetchDeviceId = async () => {
      if (pathname === 'had') { // url was had/:id
        try {
          const result = await axios(DeviceApi(`HAD/${id}/Id`));
          setDeviceId(result.data.result)
        } catch (error) {
          console.error(`There is no device with this HAD ID (${id})`)
        }
      }
      else { // url was device/:id
        setDeviceId(id)
      }
    }

    const fetchFileTypes = async () => {
      const result = await axios(PropertyApi(`List?propertyType=Device&typeOf=FileReference`));
      const fileOptions = result.data;

      setDocumentTypes(fileOptions)
    };

    fetchDeviceId();
    fetchFileTypes();
  }, [id])

  const fetchLinks = async () => {
    try {
      const result = await axios(DeviceApi('Properties/Links', deviceId));
      setAllLinks(result.data.properties)
    }
    catch (error) {
      // Link error
      console.warn("Error while fetching links", error.response.status)
    }
  };

  const fetchFiles = async () => {
    try {
      const result = await axios(DeviceApi('Properties/Files', deviceId));
      setFiles(result.data.properties)
    }
    catch (error) {
      // File error
      console.warn("Error while fetching files", error.response.status)
    }
  };

  useEffect(() => {
    let mounted = true;
    // Fetch data
    if (mounted) {
      if (deviceId !== undefined) {
        fetchLinks();
        fetchFiles();
      }
    }

    return () => mounted = false;
  }, [deviceId, refreshLeftColumn]);

  const fetchData = async () => {
    try {
      const result = await axios(DeviceApi('Basic', deviceId));
      setDeviceOwnerCompanyId(result.data.metadata.ownerCompanyId);
      setDeviceOwnerNickname(result.data.metadata.ownerNickname);
      setDevice(result.data);
    }
    catch (error) {
      console.warn("Error while fetching device Data", error)
    }
  };

  const fetchConnections = async () => {
    try {
      const result = await axios(DeviceApi('Properties/Connections', deviceId));
      let connectionResultArray = []
      if (Object.prototype.hasOwnProperty.call(result.data.properties, 'Kiegészítő eszköz')) {
        connectionResultArray = result.data.properties['Kiegészítő eszköz']
      }
      setPageCount(Math.ceil(connectionResultArray.length / devicesPerPage))
      setConnections(connectionResultArray)

      let substituteResultArray = []
      if (Object.prototype.hasOwnProperty.call(result.data.properties, 'Helyettesítő eszköz')) {
        substituteResultArray = result.data.properties['Helyettesítő eszköz']
      }
      setSubstitutePageCount(Math.ceil(substituteResultArray.length / devicesPerPage))
      setSubstituteConnection(substituteResultArray)
    } catch (error) {
      console.warn('Error during fetchConnections process', error)
    }
  };

  useEffect(() => {
    let mounted = true;
    // Fetch data
    if (mounted) {
      if (deviceId !== undefined) {
        fetchData();
        fetchConnections();
      }
    }
    return () => mounted = false;
  }, [deviceId, refreshBottom]);

  const fetchDeviceReviewSummary = async () => {
    try {
      const reviewSummary = await axios(`Review/Device/${deviceId}`);
      const reviewDetails = await axios(`Review/Device/${deviceId}/Details`);
      setSummary(reviewSummary.data);
      setDetails(reviewDetails.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    fetchDeviceReviewSummary();
  }, [deviceId]);

  const acceptDevicePropertyReviewById = async (devicePropertyReviewId) => {
    let response;
    try {
      setIsLoading(true);
      const { id, isReviewAcceptable, isRemovalRecommended } = devicePropertyReviewId;
      response = await axios.put(isReviewAcceptable || isRemovalRecommended ? `Review/${id}/Accept` : `Review/${id}/Recommend`);
      fetchDeviceReviewSummary();
      fetchData();
      fetchConnections();
      fetchLinks();
      setRefreshCentralColumn(response)
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const declineDevicePropertyReviewById = async (devicePropertyReviewId) => {
    try {
      setIsLoading(true);
      const response = await axios.put(`Review/${devicePropertyReviewId}/Decline`);
      fetchDeviceReviewSummary();
      fetchData();
      fetchConnections();
      fetchLinks();
      setRefreshCentralColumn(response)
    } catch (error) {
      console.error(error);
    }
    finally {
      setIsLoading(false);
    }
  };

  const handleReviewModeChange = () => {
    if (deviceOwnerCompanyId === companyId) {
      //setOpenDeviceReviewModal(true);
      setEditMode(!editMode);
    }
    else {
      setEditMode(!editMode);
    }
  }

  const addPropertyToDevice = async (propertyId, propertyValue) => {
    const property = {
      'propertyId': `${propertyId}`,
      'value': `${propertyValue}`
    }

    const result = await axios({
      method: 'post',
      url: DeviceApi('Properties/Add', deviceId),
      data: property,
      withCredentials: false, // default
    })

    // Tigger central column refresh
    setRefreshCentralColumn(result)
  };

  const handleAddProperty = (propertyId, propertyValue) => {
    addPropertyToDevice(propertyId, propertyValue)
  }

  const toggleMode = () => {
    handleReviewModeChange();
  }

  const fetchMainCategories = async () => {
    const result = await axios(CategoryApi('List'));
    const flatArray = flattenGivenArray(result.data)
    setCategories(flatArray);
  }

  useEffect(() => {
    fetchMainCategories();
  }, []);

  const headerRow = (
    device.metadata !== undefined &&
    <Metadatas
      creatorName={device.metadata.creatorNickname}
      ownerName={device.metadata.ownerNickname}
      completeValidations={device.metadata.completeValidations}
      searches={device.metadata.searches}
    />
  )

  const leftColumn = (
    <LeftColumn
      toggleMode={toggleMode}
      editMode={editMode}
      id={deviceId}
      borderRadius="0%"
      postType='device'
      formDataType='photo'
      links={allLinks}
      files={files}
      entityName="eszközhöz"
      refresh={refreshCentralColumn}
      isOwner={deviceOwnerCompanyId === companyId}
      isAdministrator={role === "Administrator"}
      acceptReview={(devicePropertyReviewId) => acceptDevicePropertyReviewById(devicePropertyReviewId)}
      declineReview={(devicePropertyReviewId) => declineDevicePropertyReviewById(devicePropertyReviewId)}
    />
  )

  const basicInformations = [
    { id: 'Kategória - alkategória', value: device?.category?.parentName ? `${device?.category?.parentName} - ${device?.category?.name}` : device?.category?.name },
    { id: 'Leírás', value: device.description },
    { id: 'Gyártó', value: device.manufacturerName },
    { id: 'Típus szám', value: device.itemNumber },
    { id: 'HAD azonosító', value: device.hadId }
  ]

  const putDeviceBasicInformation = async (name, description, manufacturerName, itemNumber, categoryId) => {
    try {
      await axios.put(`Device/${deviceId}/Basic`, {
        updateName: true,
        name,
        updateManufacturerName: true,
        manufacturerName,
        updateItemNumber: true,
        itemNumber,
        updateDescription: true,
        description,
        updateDeviceCategory: true,
        deviceCategoryId: categoryId
      })
      setApiResponse("");
      fetchData();
      setShowBasicInformationEditModal(false);
    }
    catch (error) {
      const statusText = Object.entries(error)[2][1].statusText;
      setApiResponse(statusText);
    }
  }

  const handleDeviceBasicInformationUpdate = (name, description, manufacturerName, itemNumber, categoryId) => {
    putDeviceBasicInformation(name, description, manufacturerName, itemNumber, categoryId);
  }

  const centralColumn = (
    <>
      {Object.keys(device).length !== 0 &&
        <>
          <Container>
            <Row className="justify-content-center align-items-center py-3">
              <Col className="col-lg-5 justify-content-center align-items-center text-left">
                <span style={{ fontSize: '1.6rem', fontWeight: 600 }}>{device.name}</span>
              </Col>
              <Col className="justify-content-center align-items-center text-center">
                <PropertyList
                  deviceId={deviceId}
                  hadId={device.hadId}
                  handleAddProperty={handleAddProperty}
                  documentTypes={documentTypes}
                  entity="device"
                  setRefreshing={value => setRefreshLeftColumn(value)}
                  setRefreshConnectedDevices={value => setRefreshBottom(value)}
                />
              </Col>
            </Row>
          </Container>
          <CustomAccordion
            title="Alap információk"
            items={basicInformations}
            type='basic'
          />
          <DeviceCategories
            id={deviceId}
            editMode={editMode}
            refresh={refreshCentralColumn}
            isOwner={deviceOwnerCompanyId === companyId}
            isAdministrator={role === "Administrator"}
            acceptReview={(devicePropertyReviewId) => acceptDevicePropertyReviewById(devicePropertyReviewId)}
            declineReview={(devicePropertyReviewId) => declineDevicePropertyReviewById(devicePropertyReviewId)}
          />
          {connections.length !== 0 &&
            <CustomAccordion
              title="Kiegészítő termékek"
              items={mapConnectedDevicesForAccordion(connections)}
              type='connectedDevices'
              editMode={editMode}
              refresh={refreshCentralColumn}
              isOwner={deviceOwnerCompanyId === companyId}
              isAdministrator={role === "Administrator"}
              acceptReview={(devicePropertyReviewId) => acceptDevicePropertyReviewById(devicePropertyReviewId)}
              declineReview={(devicePropertyReviewId) => declineDevicePropertyReviewById(devicePropertyReviewId)}
              disableReviewButtons={disableReviewButtons}
              setDisableReviewButtons={setDisableReviewButtons}
              id={deviceId}
            />
          }
          {substituteConnection.length !== 0 &&
            <CustomAccordion
              title="Helyettesítő termékek"
              items={mapConnectedDevicesForAccordion(substituteConnection)}
              type='connectedDevices'
              editMode={editMode}
              refresh={refreshCentralColumn}
              isOwner={deviceOwnerCompanyId === companyId}
              isAdministrator={role === "Administrator"}
              acceptReview={(devicePropertyReviewId) => acceptDevicePropertyReviewById(devicePropertyReviewId)}
              declineReview={(devicePropertyReviewId) => declineDevicePropertyReviewById(devicePropertyReviewId)}
              disableReviewButtons={disableReviewButtons}
              setDisableReviewButtons={setDisableReviewButtons}
              id={deviceId}
            />
          }
        </>
      }
    </>
  )

  const rightColumn = (
    <>
      <DevicePageBOMHandler deviceId={deviceId} />
      {(deviceOwnerCompanyId === companyId || role === "Administrator") && (
        <EditorBox
          showButton={true}
          toggleMode={() => setShowBasicInformationEditModal(true)}
          title="Alap információk szerkesztése"
          buttonText={"Szerkesztés"}
        />
      )}
      <EditorBox
        showButton={true}
        toggleMode={toggleMode}
        title="Hibát találtál?"
        body="Ha úgy gondolod, hogy hibás valamelyik adat, akkor az alábbi szerkesztő mód gomb megnyomása után, javíthatod."
        buttonText={editMode ? "Szerkesztés befejezése" : "Szerkesztő mód"}
      />
      <DeviceDistributors deviceId={deviceId} />
    </>
  )

  const bottomRow = (
    <Col>
      <DeviceConnections
        id={deviceId}
        connections={connections}
        connectionType="Kiegészítő termékek"
        pageCount={pageCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        devicesPerPage={devicesPerPage}
        pathname={pathname}
      />
      <DeviceConnections
        id={deviceId}
        connections={substituteConnection}
        connectionType="Helyettesítő termékek"
        pageCount={substitutePageCount}
        currentPage={substituteCurrentPage}
        setCurrentPage={setSubstituteCurrentPage}
        devicesPerPage={devicesPerPage}
        pathname={pathname}
      />
    </Col>
  )

  return (
    <>
      <ThreeColumnPageTemplate
        headerRow={headerRow}
        leftColumn={leftColumn}
        centralColumn={centralColumn}
        rightColumn={rightColumn}
        bottomRow={bottomRow}
      />
      <DeviceOwnerReviewModal
        showModal={openDeviceReviewModal}
        handleClose={() => setOpenDeviceReviewModal(false)}
        deviceId={deviceId}
        isLoading={isLoading}
        summary={summary}
        details={details}
        acceptReview={(devicePropertyReviewId) => acceptDevicePropertyReviewById(devicePropertyReviewId)}
        declineReview={(devicePropertyReviewId) => declineDevicePropertyReviewById(devicePropertyReviewId)}
      />
      <EditDeviceBasicInformationModal
        show={showBasicInformationEditModal}
        handleClose={() => setShowBasicInformationEditModal(false)}
        handleSubmit={(name, description, manufacturerName, itemNumber, categoryId) => handleDeviceBasicInformationUpdate(name, description, manufacturerName, itemNumber, categoryId)}
        device={device}
        error={apiResponse}
        categories={categories}
        currentCategory={device?.category}
      />
    </>
  );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
  const { projectId, projectName } = state.project;
  const { bomId, bomName } = state.bom;
  return {
    ...state.auth, ...state.company, projectId, projectName, bomId, bomName
  };
};

export default connect(mapStateToProps, null)(DevicePage);
