// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Imports for APIs
import CategoryApi from '../../../Api/CategoryApi';
// Imports of our custom components 
import PropertyAccordion from './PropertyAccordion';
// Imports of styles
// - none
// Imports of constants
// - none

function PropertySearchBar(props) {
    // Destructuring assignment from the props
    const { title, categoryId, mandatoryFilter, setPropertyFilters } = props

    // Categories
    const [properties, setProperties] = useState([])

    // Get categories
    useEffect(() => {
        // Fetch all properties
        const fetchProperties = async() => {
            // Depending on filter different calls needed
            try{
                const result = await axios(CategoryApi(`${categoryId}/Properties?type=${mandatoryFilter}`));
                if(result.status === 200){
                    setProperties(result.data)
                }
            } catch (error) {
                console.error(`Error during ${mandatoryFilter} property fetch`, error)
            }
        }

        //Fetch data
        if(categoryId !== ''){
            fetchProperties();
        }
    }, [categoryId]);

    return (
        <div className = "pt-2 mx-4 px-3 d-flex justify-content-center align-items-center flex-column">
            <PropertyAccordion 
                title = {title}
                categoryId = {categoryId}
                type = {mandatoryFilter}
                items = {properties}
                setPropertyFilters = {setPropertyFilters}
            />
        </div>
    );
}

export default PropertySearchBar;
