// Imports from packages
import React from "react";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
// react-bootstrap
import Dropdown from "react-bootstrap/Dropdown";
// Imports for APIs
// - none
// Imports of our custom components
import { logout } from "../../actions/auth.js";
import { setCompany } from "../../actions/company.js";
// Imports of styles
// - none
// Imports of contants
// - none
import "../../App.scss";
import { removeAuthDataToLocalStorage } from "../../auth";
import { FaUser } from 'react-icons/fa';

function UserBubble(props) {
  const history = useHistory();
  const handleLogout = () => {
    removeAuthDataToLocalStorage();
    history.push("/login");
    props.logout();
  }
  // Custom profile
  const CustomToggle = React.forwardRef(({ onClick }, ref) => (
    props.thumbnail ? (
      <img
        src={props.thumbnail}
        className="rounded-circle-icon-container"
        alt="profile"
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      />
    ) : (
      <div className="rounded-circle-icon-container"
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }} >
        <FaUser />
      </div >
    )

  ));
  CustomToggle.displayName = "Profile picture dropdown";

  const isThereActiveProject = props.projectName !== "";
  const selectBomUrl = isThereActiveProject
    ? `/company/${props.companyId}/projects/${props.projectId}`
    : `/company/${props.companyId}/projects`;

  return (
    <>
      <Dropdown alignRight style={{ justifyContent: "flex-end" }}>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components" />
        <Dropdown.Menu>
          <Dropdown.Header>Aktív felhasználó</Dropdown.Header>
          <Link to="/user" style={{ textDecoration: "none" }}>
            <Dropdown.Item eventKey="1" as="button">
              <b>{props.user}</b>
            </Dropdown.Item>
          </Link>
          <Dropdown.Divider />
          <Dropdown.Header>Aktív cég</Dropdown.Header>
          <Link to="/user/companies" style={{ textDecoration: "none" }}>
            <Dropdown.Item eventKey="2" as="button">
              <b>{props.companyName ? props.companyName : "Választok!"}</b>
            </Dropdown.Item>
          </Link>
          <Dropdown.Divider />
          <Dropdown.Header>Aktív projekt</Dropdown.Header>
          <Link
            to={`/company/${props.companyId}/projects`}
            style={{ textDecoration: "none" }}
          >
            <Dropdown.Item eventKey="3" as="button">
              <b>{props.projectName ? props.projectName : "Választok!"}</b>
            </Dropdown.Item>
          </Link>
          <Dropdown.Header>Aktív BOM</Dropdown.Header>
          <Link to={selectBomUrl} style={{ textDecoration: "none" }}>
            <Dropdown.Item eventKey="3" as="button">
              <b>{props.bomName ? props.bomName : "Választok!"}</b>
            </Dropdown.Item>
          </Link>
          <Dropdown.Divider />
          <Dropdown.Item eventKey="4" onClick={() => handleLogout()}>
            Kilépés
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = (state) => {
  const { user, thumbnail, isAuthenticated } = state.auth;
  const { companyId, companyName } = state.company;
  const { projectId, projectName } = state.project;
  const { bomName } = state.bom;
  return {
    user,
    companyId,
    companyName,
    thumbnail,
    isAuthenticated,
    projectId,
    projectName,
    bomName,
  };
};
// Store reducers to components props as callable functions
const mapDispatchToProps = (dispatch) => ({
  logout: () => {
    dispatch(logout());
    dispatch(setCompany("", "", ""));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserBubble);
