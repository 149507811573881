// Imports from packages
import React, { useState } from 'react';
import axios from 'axios';
// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
import CompanyApi from '../../Api/CompanyApi';
import UserApi from '../../Api/UserApi';
// Imports of our custom components
import ImageInput from'./ImageInput';
// Imports of styles
import './Common.css'
// Imports of contants
// - none

function ImageUploader(props) {
  // Destructuring assignment from the props
  const { toggleMode, fetchPhoto, editMode, id, postType, formDataType } = props

  // Variables
  const [selectedImage, setSelectedImage] = useState()
  const [disableButtons, setDisableButtons] = useState(false)
  
  // Photo alert
  const [photoResponse, setPhotoResponse] = useState(0)
  const [showPhotoResponseAlert, setShowPhotoResponseAlert] = useState(false)
  
  const handleImageSelection = image => {
    // Uploading was not canceled
    if(image !== '') {
      setDisableButtons(false);
      setSelectedImage(image)
    }
    else {
      setDisableButtons(true);
      setSelectedImage(undefined)
    }
  }

  const cancelUploading = () => {
    setDisableButtons(true);

    toggleMode();
  }

  const postPicture = async () => {
    setDisableButtons(true);
    try {
      // Create multipart/form-data config
      let formData = new FormData();
      formData.append(formDataType, selectedImage)

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
      
      let resultPictureUpload
      switch(postType){
        case 'device':
          resultPictureUpload = await axios.post(DeviceApi('Photo', id), formData, config);
          break;
        case 'company':
          resultPictureUpload = await axios.post(CompanyApi("Picture", id, "POST"), formData, config);
          break;
        case 'user': 
          resultPictureUpload = await axios.post(UserApi("Picture"), formData, config);
          break;
        default:
          console.error("Unknown post type in Imageuploader's postPicture's switch");
      }

      if (resultPictureUpload.status === 202) {
        toggleMode();
        
        setPhotoResponse(resultPictureUpload.status)
        setShowPhotoResponseAlert(true)

        setTimeout(() => {
          setShowPhotoResponseAlert(false)
          setPhotoResponse(0)
          setSelectedImage()

          // Force refresh on successful photo upload
          fetchPhoto()
        }, 2000)

        setDisableButtons(false)
      }
    }
    catch (error) {
      console.error("Error during device picture upload process", error)

      setPhotoResponse(error.response.status)
      setShowPhotoResponseAlert(true)

      setTimeout(() => {
        setShowPhotoResponseAlert(false)
        setPhotoResponse(0)
      }, 2000)
      
      setDisableButtons(false);
    }
  }

  const photoAlertContent = photoResponse === 202 ? "Sikeres képfeltöltés" : "Sikertelen képfeltöltés";

  return (
    <>
      {showPhotoResponseAlert && (
        <Alert variant = { photoResponse === 202 ? "success" : "danger"} className = "text-center">
          {photoAlertContent}
        </Alert>
      )}
      { editMode && 
        <Container fluid className = "d-flex flex-column mt-3">
          <Row>
            <Col className = "d-flex justify-content-center text-center">
              <ImageInput setSelectedImage = {(selectedImage) => handleImageSelection(selectedImage)} />
            </Col>
          </Row>
          <Row>
            <Col className = "d-flex justify-content-center">
              <Button
                className='photo-button'
                variant="success"
                onClick={postPicture}
                disabled={disableButtons || selectedImage === undefined}
              >
                Kép feltöltése
              </Button>
            </Col>
          </Row>
          <Row>
          <Col className = "d-flex justify-content-center">
              <Button
                className='photo-button'
                variant="danger"
                onClick={cancelUploading}
                disabled={disableButtons || selectedImage === undefined}
              >
                Feltöltése elvetése
              </Button>
            </Col>
          </Row>
        </Container>
      }
    </>
  );
}

export default ImageUploader;
