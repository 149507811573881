// Imports from packages
import React, { useState } from 'react';
// import from 'react-bootstrap';
import { FaExchangeAlt, FaTrashAlt } from 'react-icons/fa';
// Imports for APIs
// - none
// Imports of our custom components
import ModifyProfileProperty from '../Modals/ModifyProfileProperty';
import DeleteProfileProperty from '../Modals/DeleteProfileProperty';
import iconsWithTooltip from '../CommonComponents/utils/reviewIconWithTooltip';
// Imports of styles
import '../../App.css';
// Imports of contants
// - none

export default function PropertyModification(props){
    // Destructuring assignment from the props
    const { entity, profileId, property, propertyId, propertyValue, setRefreshing } = props

    const [showDelete, setShowDelete] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    // Items for rendering

    const reviewItems = [
        {
            label: 'Tulajdonság módosítása',
            icon: <FaExchangeAlt />,
            color: "warning",
            onClickHandler: () => setShowUpdate(true),
            disabled: false,
        },
        {
            label: property.isMandatory ? 'Kötelező tulajdonság nem törölhető' : 'Tulajdonság törlése',
            icon: <FaTrashAlt />,
            color: "danger",
            onClickHandler: () => setShowDelete(true),
            disabled:  property.isMandatory ? true : false,
        }
    ]

    return (
        <>
            <ModifyProfileProperty
                show = {showUpdate}
                handleClose = {() => setShowUpdate(false)}
                property = {property}
                propertyId = {propertyId}
                propertyValue = {propertyValue}
                entity = {entity}
                profileId = {profileId}
                setRefreshing = {setRefreshing}
            />
            <DeleteProfileProperty
                show = {showDelete}
                handleClose = {() =>  setShowDelete(false)}
                propertyName = {property.propertyName}
                propertyId = {propertyId}
                propertyValue = {propertyValue}
                entity = {entity}
                profileId = {profileId}
                setRefreshing = {setRefreshing}
            />
            <div className = 'd-flex justify-content-center align-items-center'>
                { iconsWithTooltip(reviewItems) }
            </div>
        </>
    )
}