import React from "react";
import Logo from "../../new-logo.png";
import "../../index.css";

const PageLoading = () => {
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh", width: "100vw" }}
    >
      <div>
        <img
          src={Logo}
          alt="HADocument logó"
          width="400px"
          className="loading-animation"
        />
      </div>
    </div>
  );
};

export default PageLoading;
