// Imports from packages
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
// Imports for APIs
// - none
// Imports of our custom components
import DynamicInputGrid from './DynamicInputGrid';
// Imports of styles
import '../../App.css';
// Imports of contants
import { propertyType } from '../../constants/propertyType';

function DynamicSelect(props) {

    // Variables for grid and property array
    const propertyForArray = props.propertyArray || [];
    const [propertyArray, setPropertyArray] = useState(propertyForArray)
    const itemsForGrid = props.gridItems || [];
    const [gridItems, setGridItems] = useState(itemsForGrid)
    // Select option
    const [selectedOption, setSelectedOption] = useState('')
    // Propert array for the POST
    const propertyFilters = props.propertyFilters;

    const addPropertyToPost = (property, type) => {
        // Check if property was already added to list with differente value
        let matchIndex;
        const newPropertyArray = [];
        propertyFilters.forEach((element, idx) => {
            if (element.propertyId === property.propertyId) {
                matchIndex = idx;
            }
        })

        // Delete matched element
        if (matchIndex !== undefined) {
            propertyFilters.splice(matchIndex, 1)
            // Value was added multiple times before
            if (type === propertyType.MANDATORY) {
                if (property.value === '') {
                    const newValue = props.unselectedFields + 1;
                    props.setUnselectedFields(newValue);
                }
            }

            // The inputfield was not cleared, update array with new value
            if (property.value !== '') {
                newPropertyArray.push(property);
            }
            else {
                // The inputfield was cleared, no item should be added
            }
        }

        // No match found
        else {
            // Value was added first to array, decrease props.unselectedFields' value
            if (type === propertyType.MANDATORY) {
                const newValue = props.unselectedFields - 1;
                props.setUnselectedFields(newValue);
            }

            // Add new property to array
            newPropertyArray.push(property);
        }

        const finalArray = [
            ...propertyFilters,
            ...newPropertyArray
        ];

        props.setPropertyFilters(finalArray);
    }

    const deletePropertyFromPost = (property, type) => {
        // Because of deleteItem the property gets updated with undefined
        if (property !== undefined) {
            // Search for property, which was already added to list and delete it
            propertyFilters.forEach((element, idx) => {
                if (element.propertyId === property.propertyId) {
                    propertyFilters.splice(idx, 1);

                    // There was previously a value, then range-to-text or text-to-range change happened
                    if (type === propertyType.MANDATORY) {
                        const newValue = props.unselectedFields + 1;

                        props.setUnselectedFields(newValue);
                    }
                }
            })

            // Update filterlist
            props.setPropertyFilters(propertyFilters);
        }
    }

    // Add new item to property container
    const makePropertyUnit = () => {
        if (selectedOption !== '') {
            // Get back normal object instead of a select option
            const searchedElement = propertyArray.filter(element => element.id === selectedOption.value);

            // Add new item to propertyGrid
            let items = [
                ...gridItems,
                searchedElement[0]
            ];
            setGridItems(items);

            // Update select list; remove selected property from list
            const newPropertyList = propertyArray.filter(element => element.id !== searchedElement[0].id);
            setPropertyArray(newPropertyList);

            // Empty selected option
            setSelectedOption('');
        }
    }

    // Delete specific item from property container
    const deletePropertyUnit = property => {
        // Search for the ID (.value) in the propGridItem list and give back it's position
        const newPropertyGridItems = gridItems.filter(element => element.id !== property.id);
        setGridItems(newPropertyGridItems);

        // Update propList with the the property
        let newList = [
            ...propertyArray,
            property
        ];

        // Get items in alphabetic order
        newList.sort(function (a, b) {
            var nameA = a.name.toUpperCase(); // ignore upper and lowercase
            var nameB = b.name.toUpperCase(); // ignore upper and lowercase
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            // names must be equal
            return 0;
        });

        setPropertyArray(newList);

        // Delete unit's value from propertyFilters' array
        deletePropertyFromPost(property)
    }

    // Map property list for select
    const formatOptions = () => {
        const correctForm = propertyArray.map(property => {
            return {
                value: property.id,
                label: property.name
            }
        })

        return correctForm;
    }

    return (
        <>
            {propertyArray.length !== 0 && props.type !== propertyType.MANDATORY &&
                <Form>
                    <Form.Row>
                        <Form.Group controlId={props.controlId} as={Col} xs={10}>
                            <Select
                                options={formatOptions()}
                                isClearable={true}
                                onChange={selected => setSelectedOption(selected)}
                                placeholder="Választható tulajdonságok"
                                value={selectedOption}
                            />
                        </Form.Group>
                        <Form.Group controlId={props.controlId + '-button'} as={Col} xs={2}>
                            <Button
                                style={{ marginLeft: '0.45rem', height: '39px' }}
                                onClick={() => makePropertyUnit()}
                                disabled={selectedOption === ''}
                            >
                                Hozzáadás
                            </Button>
                        </Form.Group>
                    </Form.Row>
                </Form>
            }
            <DynamicInputGrid
                items={gridItems}
                creatingNewEntity={props.creatingNewEntity}
                canDeleteItem={props.type === propertyType.MANDATORY ? false : true}
                addProperty={formatedProp => addPropertyToPost(formatedProp, props.type)}
                deleteProperty={formatedProp => deletePropertyFromPost(formatedProp, props.type)}
                deleteItem={property => deletePropertyUnit(property)}
            />
        </>
    )
}

export default DynamicSelect;