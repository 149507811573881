// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useParams, Link } from "react-router-dom";
import { FaArrowAltCircleRight } from 'react-icons/fa';
import Select from 'react-select'
// react-bootstrap
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// Imports for APIs
import ProjectApi from '../../Api/ProjectApi';
// Imports of our custom components
import SingleColumnPageTemplate from '../Pages/SingleColumnPageTemplate';
import Loader from '../CommonComponents/Loader';
import CustomAlert from '../CommonComponents/CustomAlert';
import AddProjectModal from '../Modals/AddProjectModal';
import { projectTableBorder, mapProjectStatusToHungarianLabel, filterProjectsByStatus } from './projectFunctions';
// Imports of styles
import './Project.css';
// Imports of contants
import { setProject } from '../../actions/project.js';
import { setBom } from '../../actions/bom.js';
import { projectStatuses } from '../../constants/project'
import { setActiveProject } from '../../auth';

function renderProjectTableRow(element, isActive, companyId, setProject, resetBom, openProject) {
    return (
        <tr key={element.id} className={isActive ? "table-primary" : undefined} style={projectTableBorder}>
            <td className={element.child ? "align-middle text-left pl-3" : "align-middle"} style={projectTableBorder}>
                {element.name &&
                    <>
                        <Link
                            to={`/company/${companyId}/projects/${element.id}`}
                            style={{ color: 'hsl(0,0%,40%)' }}
                        >
                            {element.child &&
                                <FaArrowAltCircleRight style={{ marginRight: "0.3em" }} />
                            }
                            <b>{element.name}</b>
                        </Link>
                    </>
                }
            </td>
            <td className="align-middle" style={projectTableBorder}>{element.code}</td>
            <td className="align-middle" style={projectTableBorder}>{mapProjectStatusToHungarianLabel(element.status)}</td>
            <td className="align-middle" style={projectTableBorder} id="active-project-td">
                {!isActive ? (
                    <Button
                        variant="primary"
                        onClick={() => { setProject(element.id, element.name); resetBom() }}
                        className="mx-1"
                        style={{ width: "110px", fontSize: '1.2rem' }}
                    >
                        Aktiválás
                    </Button>
                ) : (
                    <Button
                        variant="danger"
                        className="mx-1"
                        style={{ width: "110px", fontSize: '1.2rem' }}
                        onClick={() => { setProject('', ''); resetBom() }}
                    >
                        Inaktiválás
                    </Button>
                )}
                <Link to={`/company/${companyId}/projects/${element.id}`}>
                    <Button
                        variant="primary"
                        className="mx-1"
                        style={{ fontSize: '1.2rem' }}
                    >
                        Projekt megnyitása
                    </Button>
                </Link>
                {element.child === false && (
                    <Button
                        variant="primary"
                        className="mx-1"
                        style={{ fontSize: '1.2rem' }}
                        onClick={() => openProject(true, element.id)}
                    >
                        Új alprojekt
                    </Button>
                )}
            </td>
        </tr>
    )
}

function flatProjectsArray(projects) {
    // Make projectList into flat array
    const flatArray = []
    projects.forEach(element => {
        // Add parent first
        const modifiedElement = { ...element, child: false }
        flatArray.push(modifiedElement);

        // Finally if there is at least one child add it by looping through subProjects
        if (element.subProjects.length !== 0) {
            element.subProjects.forEach(subElement => {
                const modifiedSubElement = { ...subElement, child: true }
                flatArray.push(modifiedSubElement);
            })
        }
    })
    return flatArray
}

function ProjectList(props) {

    // Variables
    const { companyId } = useParams();
    const [openAddProjectModal, setOpenAddProjectModal] = useState(false)
    const [projectList, setProjectList] = useState([])
    const [projectId, setProjectId] = useState('');
    const [isFetched, setFetched] = useState(false);

    // Force useEffect to re-render the list
    const [newProject, setNewProject] = useState('')

    const [isSubProject, setIsSubProject] = useState(false)
    const [companynotFound, setCompanynotFound] = useState(false)

    // Current active project from Redux store
    const activeProjectId = props.projectId

    // Filter projects by status
    const [filteredStatus, setFilteredStatus] = useState(projectStatuses[0])

    useEffect(() => {
        // Fetch all projects from company
        const fetchAllProjects = async () => {
            try {
                const result = await axios(ProjectApi(`Company/${companyId}/Subs`));
                if (result.status === 200) {
                    setProjectList(flatProjectsArray(result.data));
                    setFetched(true);
                    setCompanynotFound(false);
                }
            }
            catch (error) {
                setFetched(true);
                setCompanynotFound(true);
            }
        }

        // Fetch projects
        if (openAddProjectModal === false) {
            fetchAllProjects();
        }
        // newProject --> Force useEffect to re-render the list
    }, [companyId, newProject, openAddProjectModal])


    const openProject = (ProjectIsSub, projectId) => {
        // Choose if we need to open the project or subproject modal
        setIsSubProject(ProjectIsSub)

        if (projectId.length !== 0) {
            setProjectId(projectId)
        }

        // Open modal
        setOpenAddProjectModal(true)
    }

    const headerRow = (
        <div style={{ paddingTop: '5px', textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <h1 style={{ marginTop: '5px' }}>Projektek</h1>
        </div>
    )

    const tableContent = filterProjectsByStatus(projectList, filteredStatus.value)
        .map(element => renderProjectTableRow(
            element,
            element.id === activeProjectId,
            companyId,
            props.setProject,
            props.resetBom,
            openProject
        ))

    const completeTable = (
        <div className="d-flex justify-content-center flex-column align-items-center">
            <span className="text-muted mb-2 mt-3">
                Az aktív projekt kiemelt színnel jelenik meg a táblázatban. Aktiválni a Lehetőségek oszlopban lehet.
            </span>
            <Table
                hover
                style={{ textAlign: "left", width: '60em', border: '1px solid #8c8e91' }}
                id="projects-table"
            >
                <thead style={projectTableBorder}>
                    <tr key="table-header" style={projectTableBorder}>
                        <th style={projectTableBorder}>Projektnév</th>
                        <th style={projectTableBorder}>Projektkód</th>
                        <th style={projectTableBorder}>Projekt állapota</th>
                        <th style={projectTableBorder}>Lehetőségek</th>
                    </tr>
                </thead>
                <tbody style={projectTableBorder}>
                    {tableContent}
                </tbody>
            </Table>
        </div>
    )

    let centralColumn
    let renderedProjects
    if (companynotFound) {
        centralColumn = (
            <div className="mt-4">
                <CustomAlert
                    variant="danger"
                    message="A projekt(ek) cégénél nem alkalmazott, vagy nem létezik ilyen azonosítójú cég!"
                />
            </div>
        )
    }
    else {
        if (isFetched) {
            if (tableContent.length !== 0) {
                renderedProjects = completeTable
            }
            else if (filteredStatus !== 'All') {
                renderedProjects = <CustomAlert variant="warning" message="Nincs a szűrésnek megfelelő projekt." />
            }
            else { // There are no projects
                renderedProjects = <CustomAlert variant="warning" message="Nem rendelkezik a cég egyetlen projekttel sem" />
            }
            centralColumn = (
                <div className="text-center" style={{ fontSize: '1.2rem' }}>
                    <AddProjectModal
                        show={openAddProjectModal}
                        handleClose={() => setOpenAddProjectModal(false)}
                        newProject={() => setNewProject(projectId)}
                        projectId={projectId}
                        subProject={isSubProject}
                    />
                    <div className="d-flex justify-content-center align-items-center">
                        <div style={{ width: '200px' }}>
                            <Select
                                className="basic-single"
                                classNamePrefix="select"
                                value={filteredStatus}
                                name="color"
                                options={projectStatuses}
                                onChange={(event) => setFilteredStatus(event)}
                            />
                        </div>
                        <Button
                            variant="primary"
                            className="m-3"
                            onClick={() => openProject(false, projectId)}
                            style={{ fontSize: '1.2rem' }}
                        >
                            Új projekt
                        </Button>
                    </div>
                    {renderedProjects}
                </div>
            )
        }
        else {
            centralColumn = <Loader />
        }
    }

    return (
        <SingleColumnPageTemplate
            headerRow={headerRow}
            centralColumn={centralColumn}
        />
    );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
    const { projectId, projectName } = state.project;
    return {
        projectId, projectName
    };
};
// Store reducers to components props as callable functions
const mapDispatchToProps = dispatch => ({
    setProject: (projectId, projectName) => {
        setActiveProject(projectId, projectName);
        dispatch(setProject(projectId, projectName));
    },
    resetBom: () => {
        dispatch(setBom('', ''));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);

