// Project related functions
import './Project.css'
// constants
import { HADColors } from '../../constants/color'
import { projectStatus } from '../../constants/project'

export const getIconColor = status => {
    let variant
    switch (status) {
        case 'New':
            variant = HADColors.Yellow
            break;
        case 'Active':
            variant = HADColors.Blue
            break;
        case 'Finished':
            variant = HADColors.Green
            break;
        case 'Prioritized':
            variant = HADColors.Red
            break;
        default:
            variant = HADColors.Red
            console.warn("Unknown status in getIconColor: ", status)
            break;
    }

    return variant;
}

export const getBackgroundColor = status => {
    let variant
    switch (status) {
        case 'New':
            variant = "info2";
            break;
        case 'Prioritized':
            variant = "danger";
            break;
        case 'Active':
            variant = "primary";
            break;
        case 'Finished':
            variant = "success";
            break;
        default:
            variant = "danger";
            console.error("Unknown status in project page's getBackgroundColor: ", status);
    }

    return variant;
}

export const mapProjectStatusToHungarianLabel = uiName => {
    switch (uiName) {
        case 'All': return projectStatus.ALL;
        case 'Open': return projectStatus.OPEN;
        case 'Parent': return projectStatus.PARENT;
        case 'Prioritized': return projectStatus.PRIORITIZED;
        case 'New': return projectStatus.NEW;
        case 'Active': return projectStatus.ACTIVE;
        case 'Finished': return projectStatus.FINISHED;
        default: return projectStatus.UNKNOWN;
    }
}

export const mapProjectStatusToHungarianLabelInProjectSubpage = uiName => {
    switch (uiName) {
        case 'New': return projectStatus.NEW;
        case 'Active': return projectStatus.ACTIVE;
        case 'Finished': return projectStatus.FINISHED;
        case 'Prioritized': return projectStatus.PRIORITIZED;
        default: return projectStatus.UNKNOWN;
    }
}

export const projectTableBorder = { border: '1px solid #8c8e91' }

export const filterProjectsByStatus = (projects, filteredStatus) => {
    switch(filteredStatus) {
        case 'All': return projects;
        case 'Open': return projects.filter(project => { return project.status !== 'Finished' } );
        case 'Prioritized': return projects.filter(project => { return project.status === 'Prioritized' } );
        case 'Parent': return projects.filter(project => { return project.child === false } );
        default: return projects.filter(project => { return project.status === filteredStatus } );
    }
}


export const addSubProjectsToProfileObject = (subProjects, companyId) => {
    // This format is necessary for CustomAccordion 
    const children = subProjects.map(child => {
        const newFormat = {
            propertyName: child.name,
            linkToPage: `/company/${companyId}/projects/${child.id}`,
            isNotEditable: true,
            unDeleteable: true,
            value: mapProjectStatusToHungarianLabel(child.status),
            meta: null
        }

        return newFormat
    })

    return children;
}