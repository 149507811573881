// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import Select from 'react-select';
// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
import CategoryApi from '../../Api/CategoryApi';
// Imports of our custom components 
import SingleColumnPageTemplate from '../Pages/SingleColumnPageTemplate';
import DeviceGrid from '../MainPage/DeviceGrid';
import CenteredHeading4 from '../CommonComponents/CenteredHeading4';
import Loader from '../CommonComponents/Loader';
import { flattenGivenArray } from './Detailed/detailedFunctions';
// Imports of styles
import '../../App.css';
import './Search.css';
// Imports of contants
// - none

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function SimpleSearchPage() {
    // Get values from url parameters
    let query = useQuery();
    const paramFilterName = query.get("sF") || ''
    // if category exist, it holds the id and name in 'categoryId_categoryName' form 
    const paramCategory = query.get("c") || ''
    const paramCategoryId = paramCategory ? paramCategory.split('_')[0] : ''
    const paramCategoryName = paramCategory ? paramCategory.split('_')[1] : ''
    const paramManufacturer = query.get("mN") || ''
    const paramKeyword = query.get("sK") || ''
    const fromMainPage = paramFilterName

    // Categories
    const [categories, setCategories] = useState([])
    const [categoryId, setCategoryId] = useState(paramCategoryId)
    // If there is data from MainPage show it, else show a burnt in value
    const defaultCategory = paramCategory ? { value: paramCategoryId, label: paramCategoryName } : categories[0]

    // Manufacturers
    const [manufacturers, setManufacturers] = useState([])
    const [manufacturerName, setManufacturerName] = useState(paramManufacturer)
    const manufacturerOptions = manufacturers.map((manufacturer, index) => {
        return { value: index, label: manufacturer }
    })
    // If there is data from MainPage show it, else show a burnt in value
    const defaultManufacturer = paramManufacturer ? { value: -1, label: paramManufacturer } : manufacturerOptions[0]

    // Search filter
    const searchFilterOptions = [
        { value: 0, label: 'Név' },
        { value: 1, label: 'Típus szám' },
        { value: 2, label: 'HAD azonosító' }
    ]
    const hasFilterValue = paramFilterName !== '' ? paramFilterName : searchFilterOptions[0].label;
    const [searchFilterName, setSearchFilterName] = useState(hasFilterValue)

    const mapSearchFilterUiNameToParameterName = (uiName) => {
        switch (uiName) {
            case 'Név':
                return 'DeviceName'
            case 'Típus szám':
                return 'ItemNumber'
            case 'HAD azonosító':
                return 'HadId'
            default:
                console.error("Unhandled name in simple search's ui mapping", uiName)
                return 'DeviceName'
        }
    }
    // If there is data from MainPage show it, else show a burnt in value
    const defaultFilterName = paramFilterName ? { value: -1, label: paramFilterName } : searchFilterOptions[0]

    // Search keyword
    const [searchKeyword, setSearchKeyword] = useState(paramKeyword)
    const [isSearching, setIsSearching] = useState(false)
    const [hasSearched, setHasSearched] = useState(false)
    const [searchResults, setSearchResults] = useState([])

    // Pagination
    const [pageCount, setPageCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)

    // Fetch categories and manufacturers
    useEffect(() => {
        // List of categories
        const fetchMainCategories = async () => {
            const result = await axios(CategoryApi('List'));

            // Flatten the array
            const flatArray = flattenGivenArray(result.data)
            setCategories(flatArray)
        }

        // List of manufacturers
        const fetchManufacturers = async () => {
            const result = await axios(DeviceApi('Manufacturer/List'));
            setManufacturers(result.data)
        };

        // Fetch data
        fetchMainCategories();
        fetchManufacturers();
    }, []);

    // Trigger search if the page was loaded from the MainPage
    useEffect(() => {
        if (fromMainPage) {
            handleSearch(currentPage);
        }
    }, [fromMainPage])

    const onChangeSelectedElement = (type, selected) => {
        if (type === 'category') {
            if (selected === null) {
                setCategoryId('')
            }
            else {
                // [0] does the array flatting so ID is a string not an array
                setCategoryId(selected.value.split(" | ", 1)[0])
            }
        }
        else if (type === 'manufacturer') {
            if (selected === null) {
                setManufacturerName('')
            }
            else {
                setManufacturerName(selected.label)
            }
        }
        else if (type === 'searchFilter') {
            setSearchFilterName(selected.label)
        }
        else {
            console.error('Unkown type: ' + type, selected)
        }
    }

    const newCurrentPage = (newPageNumber) => {
        setCurrentPage(newPageNumber)
        handleSearch(newPageNumber)
    }

    const handleSearch = async (newPageNumber) => {

        // If called from pagination, pagination should not be reseted, else it must be reseted (set to 1)
        // - newPageNumber will be always greater than 0, from search button we get 0
        let pageNumber
        if (newPageNumber > 0) {
            pageNumber = newPageNumber;
        }
        else {
            pageNumber = 1
            setCurrentPage(pageNumber)
        }


        setIsSearching(true)
        setHasSearched(false)

        let queryParameters = '?'

        if (searchFilterName !== '') {
            queryParameters += `searchFilter=${mapSearchFilterUiNameToParameterName(searchFilterName)}&`
        }
        if (searchKeyword !== '') {
            queryParameters += `search=${searchKeyword}&`
        }
        if (manufacturerName !== '') {
            queryParameters += `filterByManufacturer=${manufacturerName}&`
        }
        if (categoryId !== '') {
            queryParameters += `filterByCategory=${categoryId}&`
        }
        // Add page parameter
        queryParameters += `page=${pageNumber}&`

        // Handle search results
        const result = await axios(DeviceApi('Search' + queryParameters));
        setIsSearching(false)
        setHasSearched(true)
        setSearchResults(result.data.devices)
        setPageCount(result.data.pageCount)
    }

    const isKeyEnter = event => {
        // keyCode 13 is an identifier for the enter key
        const enterButton = 13
        if (event.keyCode === enterButton) {
            handleSearch(0)
        }
    }

    const customStyles = {
        // For the options
        option: (styles, { data }) => {
            const isMainCategory = !data.label.includes(">>");
            return {
                ...styles,
                backgroundColor: isMainCategory ? 'lightGray' : 'white',
                color: 'black',
                padding: '5px 0 5px 10px'
            };
        },
    };

    const headerRow = (
        <div className="pt-2">
            <div className="text-center">
                <h1>Egyszerű keresés</h1>
            </div>
            <Row className="m-4 search-search-bar">
                <Col lg="4">
                    <Select
                        options={categories}
                        styles={customStyles}
                        isClearable={true}
                        placeholder="Összes kategória"
                        defaultValue={defaultCategory}
                        onChange={(selected) => onChangeSelectedElement('category', selected)}
                    />
                </Col>
                <Col lg="2">
                    <Select
                        options={manufacturerOptions}
                        styles={customStyles}
                        isClearable={true}
                        placeholder="Összes gyártó"
                        defaultValue={defaultManufacturer}
                        onChange={(selected) => onChangeSelectedElement('manufacturer', selected)}
                    />
                </Col>
                <Col lg="2">
                    <Select
                        options={searchFilterOptions}
                        styles={customStyles}
                        defaultValue={defaultFilterName}
                        onChange={(selected) => onChangeSelectedElement('searchFilter', selected)}
                    />
                </Col>
                <Col lg="2">
                    <input
                        value={searchKeyword}
                        onChange={e => setSearchKeyword(e.target.value)}
                        type="text"
                        placeholder="Keresési kifejezés..."
                        className='search-keyword-input'
                        onKeyPress={() => isKeyEnter(event)}
                    />
                </Col>
                <Col lg="2" className="d-flex justify-content-center">
                    <Button onClick={() => handleSearch(0)} style={{ width: '100%' }}>
                        Keresés
                    </Button>
                </Col>
            </Row>
        </div>
    )

    let centralColumn
    if (!hasSearched) {
        if (isSearching) {
            centralColumn = (
                <div className="mt-4 mb-3">
                    <Loader />
                </div>
            )
        }
        else {
            centralColumn = <CenteredHeading4 message="Még nem keresett rá semmire" />
        }
    }
    else {
        centralColumn = (
            <DeviceGrid
                title="Keresési eredmények"
                devices={searchResults}
                isFetched={hasSearched}
                isPaginated={true}
                pageCount={pageCount}
                currentPage={currentPage}
                setCurrentPage={newPageNumber => newCurrentPage(newPageNumber)}
            />
        )
    }

    return (
        <SingleColumnPageTemplate
            headerRow={headerRow}
            centralColumn={centralColumn}
        />
    );
}

export default SimpleSearchPage;
