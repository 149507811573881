// Imports from packages
import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
// Imports from react-bootstrap
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants
// - none

function EmployeeList(props) {
    // Destructuring assignment from the props
    const { list, editMode, handleDelete, isCompanyOwner } = props

    const allEmployees = (
        <Table striped bordered hover size="sm" className="mt-3 text-left mb-0">
            <thead>
                <tr>
                    <th className="p-2">Teljes név</th>
                    {isCompanyOwner && <th className="p-2">Törlés</th>}
                </tr>
            </thead>
            <tbody>
                {list.map(employee => {
                    return (
                        <tr key={`key-for-employee-${employee.nickname}`}>
                            <td className="p-2">{employee.fullname}</td>
                            {isCompanyOwner && (
                                <td className="p-2">
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Button
                                            variant="danger"
                                            key={`button-list-group-item-${employee.username}`}
                                            onClick={() => handleDelete(employee.username)}
                                            disabled={editMode}
                                            className="d-flex justify-content-center align-items-center"
                                        >
                                            <FaTrashAlt />
                                        </Button>
                                    </div>
                                </td>
                            )}
                        </tr>
                    )
                })}
            </tbody>
        </Table>
    )

    return allEmployees;
}

export default EmployeeList;
