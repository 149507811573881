// Imports from packages
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaCheck, FaExchangeAlt, FaTrashAlt } from 'react-icons/fa';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants
import { HADColors } from "../../../constants/color"

function CompanyDeviceReview(props) {
    // Destructuring assignment from the props
    const { review, id, setDeviceId, setReviewMethod } = props

    // Items for rendering
    const reviewItems = [
        {
            label: 'Jóváhagyási kérelem',
            value: review.numberOfApprovals,
            icon: <FaCheck/>,
            color: HADColors.Green,
            onClickHandler: () => {},
            cursor: "default"
        },
        {
            label: 'Változtatási kérelem',
            value: review.numberOfChangeRequests,
            icon: <FaExchangeAlt/>,
            color: HADColors.Yellow,
            onClickHandler: () => {setDeviceId(id), setReviewMethod("modify")},
            cursor: "pointer"
        },
        {
            label: 'Törlési kérelem',
            value: review.numberOfRemovalRequests,
            icon: <FaTrashAlt/>,
            color: HADColors.Red,
            onClickHandler: () => {setDeviceId(id), setReviewMethod("delete")},
            cursor: "pointer"
        }
    ]

    const iconsWithTooltip = (
        <div className = "d-flex align-items-center justify-content-center">
            { reviewItems.map((item, index) => {
                return (
                    <div className = "d-flex align-items-center justify-content-between mx-2 mt-2" key = {`${id}-${index}`}>
                        <div 
                            className = "d-flex align-items-center justify-content-center rounded-circle p-2 mr-1"
                            style = {{ backgroundColor: item.color, cursor: item.cursor }}
                            onClick = {item.onClickHandler}
                        >
                            <OverlayTrigger
                                key={`company-device-review-${id}-top-${index}`}
                                placement="top"
                                overlay={
                                <Tooltip id="tooltip-company-device-review">
                                    {item.label}
                                </Tooltip>
                                }
                            >
                                {item.icon}
                            </OverlayTrigger>
                        </div>
                        {item.value}
                    </div>
                )
            })}
        </div>
    )

    return iconsWithTooltip;
}

export default CompanyDeviceReview;
