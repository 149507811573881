import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default function CustomAlert(props) {
    return (
        <div className = "d-flex justify-content-center">
            <Alert variant = {props.variant || "warning"} className = {props.width || "w-50"}>
                {props.message}
            </Alert>
        </div>
    )
}