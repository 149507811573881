// Imports from packages
import React from 'react';
// Imports from react-bootstrap 
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
// Imports for APIs
// - none
// Imports of our custom components
import AccordionBody from './ProfileAccordionBody';
// Imports of styles
import '../../App.css';
// Imports of contants
// - none

export default function ProfileAccordion(props){
    // Destructuring assignment from the props
    const { entity, profileId, categoryName, properties, editMode, setRefreshing, bothProps } = props

    return (
        categoryName !== 'Hivatkozások' && (
            <Accordion defaultActiveKey="0" className = 'accordion-style'>
                <Card>
                    <Accordion.Toggle as={Card.Header} className = 'accordion-toggle-style' variant="link" eventKey='0'>
                        {categoryName}
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='0'>
                        <Card.Body className = 'card-body-style'>
                            <AccordionBody
                                properties = {properties}
                                editMode = {editMode}
                                setRefreshing = {setRefreshing}
                                bothProps = {bothProps}
                                entity = {entity}
                                profileId = {profileId}
                            /> 
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        )
    )
}