// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
// Imports for APIs
import ProjectApi from '../../Api/ProjectApi';
// Imports of our custom components
import CustomToast from '../CommonComponents/CustomToast';
import CloseSubprojectsModal from '../Modals/CloseSubprojectsModal'
import { getBackgroundColor, getIconColor, mapProjectStatusToHungarianLabel, mapProjectStatusToHungarianLabelInProjectSubpage } from './projectFunctions';
// Imports of styles
// - none
// Imports of contants
import { statusOptions } from '../../constants/project'
import { HADColors } from '../../constants/color'

function ProjectStatus(props) {
    // Destructuring assignment from the props
    const { project, setProject, companyId } = props

    // Mischievous variables
    const [backgroundColor, setBackgroundColor] = useState('')
    const [updating, setUpdating] = useState(false)
    const [parentStatusIsFinished, setParentStatusIsFinished] = useState(true)
    const [showComfirmationModal, setShowComfirmationModal] = useState(false)
    const [subProjects, setSubProjects] = useState([])
    const [refreshSubs, setRefreshSubs] = useState(false)

    // Toast
    const [showToast, setShowToast] = useState(false)
    const [iconColor, setIconColor] = useState("")
    const [toastHeader, setToastHeader] = useState("")
    const [toastBody, setToastBody] = useState("")
    const [disableButtonViaToast, setDisableButtonViaToast] = useState(false)

    // If project is a subproject, and parent's status is 'Finished' diasble statuschanger dropdown
    useEffect(() => {
        const fetchParentStatus = async() => {
            try {
                const result = await axios(ProjectApi(`${project.parentProjectId}/Profile`));
                const parentStatus = result.data.status

                if(parentStatus !== "Finished"){
                    setParentStatusIsFinished(false)
                }
                else {
                    setParentStatusIsFinished(true)
                }                    
            } catch (error) {
                console.error("Error during parent project's status fetching", error)
            }
        }

        if(project.parentProjectId !== undefined){
            if(project.parentProjectId !== null){
                fetchParentStatus()
            }
            else{
                // Project is not child
                setParentStatusIsFinished(false)
            }
        }

        if(project.status !== undefined) {
            setBackgroundColor(getBackgroundColor(project.status))
        }
    }, [project])

    useEffect(() => {
        if(refreshSubs){
            updateProjectStatus("Finished")
            setRefreshSubs(false)
        }
    }, [refreshSubs])

    const updateProjectStatus = async(newStatus) => {
        // If project's current status is 'Finished' and has subprojects 
        //  all of them should be closed after modal's second confirmation
        if(newStatus === "Finished" && project.parentProjectId === null){
            // Get subs if there is any
            const result = await axios(ProjectApi(`Company/${companyId}/Subs`));
                
            // Get current project's child (filter's solution is an array need first item)
            const projectChildren = result.data.filter(element => element.id === project.id)

            // If item is main project filter will give back unempty array, 
            //  else item is subproject and filter gives back empty array
            const subProjectsFromParent = projectChildren[0].subProjects

            if(subProjectsFromParent.length !== 0){
                // Check if any of the subs' status is not finishes
                const hasUnfinishedSub = subProjectsFromParent.filter(element => element.status !== "Finished")

                setSubProjects(hasUnfinishedSub)

                // Has at least one subproject, which is unfinished, show modal
                if(hasUnfinishedSub.length > 0){
                    setShowComfirmationModal(true)
                }
                else {
                    // Is parent project and all subproject had been set to finished or already was in finished state
                    updateStatus(newStatus)
                }   
            }
            else{
                // Is parent project, but does not have child
                updateStatus(newStatus)
            }
        }
        else{
            // Is sub project, so update should be without Modal
            updateStatus(newStatus)
        }
    }

    const updateStatus = async(newStatus) => {
        setUpdating(true)

        // Create the parameters for axios
        const axiosParameters = {
            url: ProjectApi(`${project.id}/Status`),
            method: 'put',
            data: `"${newStatus}"`,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }

        // Call API endpoint with axios
        try {
            const result = await axios(axiosParameters)

            if (result.status === 200) {
                // Show response from the project status update
                setProject(result.data)
                setBackgroundColor(getBackgroundColor(project.status))

                setToastHeader("Sikeres állapot váltás")
                setToastBody(`Az új állapot: ${mapProjectStatusToHungarianLabel(newStatus).toUpperCase()}`)
                setIconColor(getIconColor(newStatus))
                setShowToast(true)
    
                setTimeout(() => {
                    setShowToast(false)
                }, 2500)

                setTimeout(() => {
                    // Enable new status change - tho toast will still disable it
                    setUpdating(false)
                }, 1000)
            }
        } catch (error) {
            console.warn("Error during project status process", error)

            setToastHeader("Sikertelen módosítás")
            setToastBody("Nem sikerült az állapot módosítás. Próbálja meg újra!")
            setIconColor(HADColors.Red)
            setShowToast(true)

            setTimeout(() => {
                setShowToast(false)
            }, 2500)

            // Enable new status change - tho toast will still disable it
            setUpdating(false)
        }
    }

    return (
        <div className="col-lg-6 d-flex justify-content-center align-items-center">
            <div className="d-flex align-items-center mr-3">
                A projekt aktuális állapota:
            </div>
            <Dropdown>
                <Dropdown.Toggle
                    variant = {backgroundColor}
                    id = "projectStatusDropdown"
                    disabled = {updating || disableButtonViaToast || parentStatusIsFinished}
                >
                    <span className = "pr-1">
                        {mapProjectStatusToHungarianLabelInProjectSubpage(project.status)}
                    </span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {statusOptions.map(status => {
                        return (
                            <Dropdown.Item
                                key = {`status-dropdown-${status.value}`}
                                onClick = {() => updateProjectStatus(status.value)}
                            >
                                {status.label}
                            </Dropdown.Item>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
            <CustomToast
                showToast = {showToast}
                setShowToast = {() => setShowToast(false)}
                iconColor = {iconColor}
                header = {toastHeader}
                headerTime = "Épp most"
                body = {toastBody}
                setDisableButtonViaToast = {newValue => setDisableButtonViaToast(newValue)}
            />
            { showComfirmationModal &&
                <CloseSubprojectsModal
                    show = {showComfirmationModal}
                    projectName = {project.name}
                    subProjects = {subProjects}
                    refresh = {() => setRefreshSubs(true)}
                    handleClose = {() => setShowComfirmationModal(false)}
                />
            }
        </div>
    )
}

export default ProjectStatus;
