// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import ProjectApi from '../../Api/ProjectApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import DynamicSelect from '../CommonComponents/DynamicSelect';
// Imports of styles
import '../../App.css';
// Imports of contants
import { propertyType } from '../../constants/propertyType';

function ModifyProjectPropertyModal(props) {

  const [newValue, setNewValue] = useState('')

  // Property list
  const [propertyFilters, setPropertyFilters] = useState([])
  const [projectProperties, setProjectProperties] = useState([])

  // Alert
  const [posting, setPosting] = useState(false)
  const [showCreateResponseAlert, setShowCreateResponseAlert] = useState(false)
  const [createResponse, setCreateResponse] = useState(0)

  // Fetch project properties
  const fetchProjectProperties = async () => {
    try {
      const result = await axios(PropertyApi('List?propertyType=Project'));
      if (result.status === 200) {
        setProjectProperties(
          result.data.filter(property => {
            return property.contentType !== "FileReference"
          })
        )
      }
    } catch (error) {
      console.warn('Error during ProjectProperties fetching process', error)
    }
  }

  useEffect(() => {
    fetchProjectProperties();

    // To avoid unmounted warning
    return () => { setProjectProperties([]) };
  }, [])

  const addProperty = () => {
    const requestBody = {
      "propertiesToAdd": propertyFilters,
      "propertiesToUpdate": [],
      "propertiesToDelete": []
    }

    // After data customization call endpoint
    putProjectProfile(requestBody);
  }

  const updateProperty = () => {
    //Should give back Form's new value
    const requestBody = {
      "propertiesToAdd": [],
      "propertiesToUpdate": [
        {
          "id": `${props.property.values[0].id}`,
          "value": `${newValue}`
        }
      ],
      "propertiesToDelete": []
    }

    // After data customization call endpoint
    putProjectProfile(requestBody);
  }

  const deleteProperty = () => {
    const requestBody = {
      "propertiesToAdd": [],
      "propertiesToUpdate": [],
      "propertiesToDelete": [
        `${props.property.values[0].id}`
      ]
    }

    // After data customization call endpoint
    putProjectProfile(requestBody);
  }

  const cleanUp = () => {
    // Reset project properties
    setPropertyFilters([]);

    // Get properties again 
    fetchProjectProperties()

    // Close modal
    props.handleClose(false)
  }

  // TODO: Refaqtor this file, because the modification and delete method is not used anymore
  const putProjectProfile = async (requestBody) => {
    setPosting(true)

    // Create the parameters for axios
    const axiosParameters = {
      url: ProjectApi(`${props.project.id}/Profile`),
      method: 'put',
      data: requestBody,
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json'
      }
    }

    // Call API endpoint with axios
    try {
      const response = await axios(axiosParameters)

      // Show response from the project creation
      setShowCreateResponseAlert(true)
      setCreateResponse(response.status)

      setTimeout(() => {
        setShowCreateResponseAlert(false)
        setCreateResponse(0)

        setPosting(false)

        // Force project refresh
        props.refresh(response.data);
        cleanUp();
      }, 2000)
    } catch (error) {
      console.warn("Error from putProjectProfile", error);
      // Show response from the project creation
      setShowCreateResponseAlert(true)
      setCreateResponse(error.response.status)

      setTimeout(() => {
        setShowCreateResponseAlert(false)
        setCreateResponse(0)

        setPosting(false)
      }, 2000)
    }
  }

  const onMethod = () => {
    if (props.method === 'add') {
      addProperty();
    }
    else if (props.method === 'update') {
      updateProperty();
    }
    else {
      deleteProperty();
    }
  }

  const onValueChange = event => {
    setNewValue(event.target.value)
  }

  const subtitle = props.method === 'add' ? 'hozzáadása' : props.method === 'update' ? 'módosítása' : 'törlés';
  const title = `Tulajdonság ${subtitle}`;

  // Alert content making
  let alertContent
  if (createResponse === 200) {
    switch (props.method) {
      case "add": alertContent = "Sikeres tulajdonság hozzáadás"; break;
      case "update": alertContent = "Sikeres tulajdonság módosítása"; break;
      case "delete": alertContent = "Sikeres tulajdonság törlés"; break;
    }
  }
  else if (createResponse === 401) {
    alertContent = "Nincs jogosultsága a művelet elvégzéséhez"
  }
  else {
    alertContent = "Sikertelen művelet"
  }

  const modalBody = (
    <div style={{ textAlign: "center" }}>
      {props.method === 'add' &&
        <DynamicSelect
          controlId='add-project-properties'
          propertyFilters={propertyFilters}
          type={propertyType.OTHERS}
          creatingNewEntity={true}
          propertyArray={projectProperties}
          setPropertyFilters={newPropFilters => setPropertyFilters(newPropFilters)}
        />
      }
      {props.method === 'update' &&
        <Form>
          <Form.Group key={props.property.values[0].id} controlId="formProjectModal" style={{ textAlign: 'left' }}>
            <Form.Label><b>{props.property.propertyName}</b></Form.Label>
            <InputGroup>
              <Form.Control
                type="text"
                style={{ textAlign: 'right', paddingRight: 10 }}
                defaultValue={props.property.values[0].value}
                onChange={event => onValueChange(event)}
              />
              <InputGroup.Prepend style={{ maxHeight: '38px' }}>
                <InputGroup.Text>{props.property.measurementUnit}</InputGroup.Text>
              </InputGroup.Prepend>
            </InputGroup>
          </Form.Group>
        </Form>
      }
      {props.method === 'delete' &&
        <span>
          Biztos véglegesen törölni szeretné a(z) <b>{props.property.propertyName}</b> nevű tulajdonságot
          a(z) <b>{props.project.name}</b> nevű projekt tulajdonságai közül?
        </span>
      }
      {showCreateResponseAlert && (
        <Alert variant={createResponse === 200 ? "success" : "danger"} style={{ margin: '16px 0 0' }}>
          {alertContent}
        </Alert>
      )}
    </div>
  );

  // Disable "submit" button
  let disableButton;
  // If value update was selected the input field should not be empty, but can be te same
  if (props.method === 'update') {
    if (newValue === '') {
      disableButton = true;
    } else {
      disableButton = false;
    }
  }
  // Else If we would like to add new value, it should have value
  else if (props.method === 'add') {
    if (propertyFilters.length === 0) {
      disableButton = true;
    } else {
      disableButton = false;
    }
  }
  // Delete branch → do nothing 
  else {
    disableButton = false;
  }

  const footer = (
    <>
      <Button
        variant={`${props.method === 'delete' ? 'danger' : props.method === 'update' ? 'info2' : 'success'}`}
        onClick={() => onMethod()}
        disabled={disableButton || posting}
      >
        {props.method === 'delete' ? 'Igen' : props.method === 'update' ? 'Módosítása' : 'Létrehozás'}
      </Button>
      <Button
        variant="danger"
        onClick={() => cleanUp()}
        disabled={posting}
      >
        Mégse
      </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show={props.show}
        size={props.method === 'add' ? "lg" : "md"}
        dropback="static"
        keyboard={false}
        handleClose={() => props.handleClose()}
        title={title}
        body={modalBody}
        footer={footer}
      />
    </>
  );
}

export default ModifyProjectPropertyModal;
