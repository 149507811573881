// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select'
// Imports from react-bootstrap 
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import EnumApi from '../../Api/EnumApi';
import AccountApi from '../../Api/AccountApi';
import UserApi from '../../Api/UserApi';
import CompanyApi from '../../Api/CompanyApi';
import ProjectApi from '../../Api/ProjectApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import CustomPropertyDisplayComponent from '../Device/CustomPropertyDisplayComponent';
import AddPropertyInputComponent from '../Property/AddPropertyInputComponent';
// Imports of styles
// - none
// Imports of contants
// - none

export default function ModifyProfileProperty(props) {
    // Destructuring assignment from the props
    const { show, handleClose, property, propertyId, propertyValue, entity, profileId, setRefreshing } = props

    // Prop variables  
    const [newValue, setNewValue] = useState('')
    const [selectedOption, setSelectedOption] = useState()
    const [isEnum, setIsEnum] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [enumProperties, setEnumProperties] = useState([])

    // Alert
    const [response, setResponse] = useState(0);
    const [showResponse, setShowResponse] = useState(false);
    const timeOut = 1500;

    // Fetch enum values if prop is enum
    useEffect(() => {
        const fetchEnumProperties = async (id) => {
            const result = await axios(EnumApi("EnumTypeId", id));

            setEnumProperties(result.data.values);
        };

        if (property !== undefined) {
            // If property is an enum, load all possible changes
            if (property.enumTypeId !== null) {
                setIsEnum(true)

                // Fetch enum properties
                fetchEnumProperties(property.enumTypeId)
            }
        }
    }, [property])

    // Set updating to false, and close modal
    const closeModal = () => {
        setUpdating(false)
        handleClose()
    }

    // Update Company's admin/contactperson
    const putCompanyAdmin = async () => {
        setUpdating(true)

        let value
        try {
            // Get ID from username
            const contactIdResult = await axios(UserApi(`ID?username=${newValue}`));

            // Set data
            value = `${contactIdResult.data.result}`;
        } catch (error) {
            console.error("No such contact name", error)
            setShowResponse(true)
            setResponse(error.response.status)

            setTimeout(() => {
                setShowResponse(false)
            }, timeOut)

            setUpdating(false)
            return;
        }

        const modifiedFields = {
            propertiesToAdd: [],
            propertiesToUpdate: [
                {
                    id: `${property.values[0].id}`,
                    value: `${value}`
                }
            ],
            propertiesToDelete: []
        }

        try {
            const resultPropUpdate = await axios.put(CompanyApi(`${profileId}/Profile`), modifiedFields);
            setShowResponse(true)
            setResponse(resultPropUpdate.status)

            setTimeout(() => {
                setShowResponse(false)
                closeModal()

                setRefreshing(resultPropUpdate.data)
            }, timeOut)
        }
        catch (error) {
            console.error(`Error during company admin/contact update process`, error)
            setShowResponse(true)
            setResponse(error.response.status)

            setTimeout(() => {
                setShowResponse(false)
                closeModal()
            }, timeOut)
        }

    }

    const putAccountEmail = async () => {
        try {
            const resultEmail = await axios.put(AccountApi(`Update/Email?email=${newValue}`));
            setShowResponse(true)
            setResponse(resultEmail.status)

            setTimeout(() => {
                setShowResponse(false)
                setRefreshing(resultEmail.data)
                closeModal()

            }, timeOut)
        }
        catch (error) {
            console.error("Error during email process", error)
            setShowResponse(true)
            setResponse(error.response.status)
            setTimeout(() => {
                setShowResponse(false)
                closeModal()
            }, timeOut)
        }
    }

    const putAccountNickname = async () => {
        try {
            const resultNick = await axios.put(AccountApi(`Update/Nickname?nickname=${newValue}`),);
            setShowResponse(true)
            setResponse(resultNick.status)

            setTimeout(() => {
                setShowResponse(false)
                setRefreshing(resultNick.data)

                closeModal()
            }, timeOut)
        }
        catch (error) {
            console.error("Error during nickname process", error)

            setShowResponse(true)
            setResponse(error.response.status)
            setTimeout(() => {
                setShowResponse(false)
                closeModal()
            }, timeOut)
        }
    }

    // Delete company's selected user from employees
    const updateProperty = async () => {
        let url
        switch (entity) {
            case "user": url = UserApi("Profile"); break;
            case "company": url = CompanyApi(`${profileId}/Profile`); break;
            case "project": url = ProjectApi(`${profileId}/Profile`); break;
        }

        let calculatedValue;
        switch (property.contentType) {
            case "Date": calculatedValue = `${parseInt((new Date(newValue.value).getTime() / 1000).toFixed(0))}`; break;
            case "Time": calculatedValue = `${parseInt((new Date(`01 Jan 1970 ${newValue.value} GMT+0100`).getTime() / 1000).toFixed(0))}`; break;
            case "DateTime": calculatedValue = `${parseInt((new Date(newValue.value).getTime() / 1000).toFixed(0))}`; break;
            default: calculatedValue = newValue.value;
        }

        const data = {
            propertiesToAdd: [],
            propertiesToUpdate: [
                {
                    id: `${propertyId}`,
                    value: `${isEnum ? selectedOption.value : calculatedValue}`
                }
            ],
            propertiesToDelete: []
        }

        // Create the parameters for axios
        const axiosParameters = {
            url: url,
            method: 'put',
            data: data,
            headers: {
                'Content-Type': 'application/json'
            }
        }

        // Call API endpoint with axios
        axios(axiosParameters)
            .then(response => {
                if (response.status === 200) {
                    // Response holds the updated profile's data, which should be given back to the page
                    setResponse(response.status)
                    setShowResponse(true)

                    setTimeout(() => {
                        setShowResponse(false)

                        closeModal()
                        setRefreshing(response.data)
                    }, timeOut)
                }
            })
            .catch(error => {
                console.error(error);
                setResponse(error.response.status)
                setShowResponse(true)

                setTimeout(() => {
                    setShowResponse(false)
                    setUpdating(false)
                }, timeOut)
            })
    }

    const onMethod = () => {
        setUpdating(true)

        // TODO: 'Kapcsolattartó' will be renamed soon
        if (property.propertyName === "Kapcsolattartó") {
            putCompanyAdmin()
        }
        else {
            if (property.values[0].id === "email") {
                putAccountEmail()
            }
            else if (property.values[0].id === "nickname") {
                putAccountNickname()
            }
            else {
                updateProperty()
            }
        }
    }

    const setOption = selected => {
        setSelectedOption(selected)
    }

    const mapPropertiesForSelect = selectProperties => selectProperties.map(option => {
        let formattedOption = {
            label: option.name,
            value: option.id
        }

        return formattedOption
    })

    const title = `${property.propertyName} módosítása`;

    const modalBody = (
        <div style={{ fontSize: "120%" }}>
            <div>
                <p>
                    <span className="mr-2">
                        Korábbi érték:
                    </span>
                    <CustomPropertyDisplayComponent contentType={property.contentType} value={propertyValue} />
                </p>
            </div>
            {isEnum ? (
                <Select
                    options={mapPropertiesForSelect(enumProperties)}
                    placeholder="Lehetőség kiválasztása"
                    onChange={(selected) => setOption(selected)}
                />
            ) : (
                <Form>
                    <Form.Group key={property.propertyName} controlId="formProjectModal" style={{ textAlign: 'left' }}>
                        <AddPropertyInputComponent
                            isEnum={isEnum}
                            contentType={property.contentType}
                            options={null}
                            value={newValue}
                            onChange={selected => setNewValue(selected)}
                            placeholder={newValue}
                        />
                    </Form.Group>
                    {property.propertyName === "Kapcsolattartó" &&
                        <Form.Text className="text-muted">
                            Csak pontos név során lesz sikeres a módosítás!
                        </Form.Text>
                    }
                </Form>
            )
            }
        </div>
    );

    const footer = (
        <>
            <Button
                variant='info2'
                onClick={onMethod}
                disabled={updating || (newValue === "" && selectedOption === undefined)}
            >
                Módosítás
            </Button>
            {showResponse &&
                <div className='d-flex justify-content-center'>
                    <Alert variant={response === 200 ? 'success' : 'danger'} className='text-center m-0 p-2'>
                        {response === 200 ? 'Sikeres módosítás' : 'Sikertelen módosítás'}
                    </Alert>
                </div>
            }
            <Button
                variant="primary"
                onClick={handleClose}
                disabled={updating}
            >
                Mégse
            </Button>
        </>
    )

    return (
        <CustomModal
            show={show}
            size="md"
            dropback="static"
            keyboard={false}
            handleClose={handleClose}
            title={title}
            body={modalBody}
            footer={footer}
        />
    );
}
