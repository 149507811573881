// Imports from packages
import React from 'react';
// react-bootstrap
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants

export default function NoDistributorTablerow() {
    return (
        <tr key='0'>
            <td>Ehhez a termékhez nincs disztribútor.</td>
        </tr>
    )
}