import React from 'react';

import SingleColumnPageTemplate from '../Pages/SingleColumnPageTemplate';

import '../../App.scss';

export default function SupportPage() {
   
    const headerRow = (
        <h1>Support</h1>
    )

    const centralColumn = (
        <h5>Content</h5>
    )

    return (
        <SingleColumnPageTemplate
            headerRow = {headerRow}
            centralColumn = {centralColumn}
        />
    );
}
