// Flatten given array
export const flattenGivenArray = (array) => {
    const flatArray = []

    array.forEach(mainCategory => {
        // First, get all subcategory's name for each main category
        let subCategories = ''
        if(mainCategory.children.length > 0) {
            mainCategory.children.forEach(subCategory => {
                subCategories += ' | ' + subCategory.name
            })
        }

        // Then, add main category first to array with all the subcategories in it's values
        flatArray.push( { value: `${mainCategory.id + subCategories}`, label: mainCategory.name } )

        // Lastly, the children with the parent's
        if(mainCategory.children.length > 0) {
            mainCategory.children.forEach(subCategory => {
                flatArray.push( { value: `${subCategory.id} | ${mainCategory.name}`, label: ` >>  ${subCategory.name}` } )
            })
        }
    })

    return flatArray;
}

// Filter out both Link and FileReference contentType properties
export const filterArrayToJustHaveNormalValueOrEnumProps = (arrayToBeFiltered) => {
    const arrayWithoutFileReferenceAndLink = arrayToBeFiltered.filter(element => element.contentType !== "FileReference")  
    return arrayWithoutFileReferenceAndLink
}

// Alphabetic ordering 
export const alphabeticOrdering = (arrayToBeSorted) => {
    const copyArray = arrayToBeSorted
    const sortedArray = copyArray.sort((a, b) => {
        if (a.label > b.label) {
          return 1;
        }
        
        if (a.label < b.label) {
          return -1;
        }

        return 0;
    });
    
    return sortedArray
}