// Imports from packages
import React from 'react';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants
// - none

export default function ShortLink(props) {
    const {link, maxLength, className} = props;

    let shortAdress
    // Example https://www.example.xyz/one/two
    // - First remove https://, if there is any
    let longAddress
    if(link.value.split('//')[1] !== undefined){
        longAddress = link.value.split('//')[1];
    }
    else {
        longAddress = link.value
    }

    // - Then remove the part after the first slash, if there is any
    let DoubleSlashRemoved
    if(longAddress.split('/')[0] !== undefined){
        DoubleSlashRemoved = longAddress.split('/')[0];
    }
    else {
        DoubleSlashRemoved = longAddress
    }

    // - Finally trim it after
    if(DoubleSlashRemoved.length > maxLength){
        shortAdress = DoubleSlashRemoved.slice(0, maxLength - 1) + '...';
    }
    else{
        shortAdress = DoubleSlashRemoved
    }

    return (
        <a
            className = {className}
            href={`${link.value}`}
            target='_blank'
            rel="noreferrer"
        >
            {shortAdress}
        </a>
    )
}