import filteringDevicesByReviews from './filteringDevices';
import sortingDevices from './sortingDevices';

export default function filterAndSortDevices(devices, filtering, sorting, isDistributedPage) {
    const originalArray = [...devices]
    if(filtering) {
        if(sorting) { // filtering && sorting
            const filteredDevices = filteringDevicesByReviews(originalArray, filtering)
            const sortedDevices = sortingDevices(filteredDevices, sorting, isDistributedPage)
            return sortedDevices
        }
        else { // filtering without sorting
            return filteringDevicesByReviews(originalArray, filtering)
        }
    }
    else if(sorting) { // no filtering, just sorting
        return sortingDevices(originalArray, sorting, isDistributedPage)
    }
    else {
        // no filtering && no sorting
        return devices
    }
}