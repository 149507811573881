import React from 'react';
import Form from "react-bootstrap/Form";

export default function TimePropertyEditor(props) {
    const { value, onChange } = props;
    return (
        <Form.Group controlId="custom-time" className="d-flex justify-content-start align-items-center mb-0">
            <Form.Control
                type="time"
                step="1"
                value={value}
                onChange={(event) => onChange({ value: event.target.value })}
                className="d-flex justify-content-start align-items-center" style={{ width: "120px" }}
            />
        </Form.Group>
    )
}