import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { FaFilter, FaSortAmountDown } from 'react-icons/fa';
import CompanyApi from '../../../Api/CompanyApi';
import SingleColumnPageTemplate from '../../Pages/SingleColumnPageTemplate';
import CompanyDeviceGrid from './CompanyDeviceGrid';
import SelectWithIcon from '../../CommonComponents/SelectWithIcon';
import SortSelect from './SortSelect';
import FilterSelect from './FilterSelect';
import { Button } from 'react-bootstrap';

function CompanyDevicesPage() {
  // Handle ID of the device (from URL parameter)
  let { companyId } = useParams();
  const [filtering, setFiltering] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [fetchedDevices, setFetchedDevices] = useState(false);
  const [companyDevices, setCompanyDevices] = useState([]);
  const [, setReviewMethod] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);

  const fetchCompanyDevices = async () => {
    setFetchedDevices(false)
    let url = `${companyId}/Devices/Owned?page=${currentPage}&pageSize=25`;
    if (sorting) {
      url += `&orderBy=${sorting}`;
    }
    if (filtering) {
      url += `&filterBy=${filtering}`;
    }
    const result = await axios(CompanyApi(url));
    setCompanyDevices(result.data.ownedDevices)
    setCurrentPage(result.data.currentPage)
    setPageCount(result.data.pageCount)
    setFetchedDevices(true)
  }

  useEffect(() => {
    fetchCompanyDevices();
  }, [currentPage]);

  // Select icons
  const iconStyle = {
    color: 'black',
    fontSize: '1.75rem'
  }

  const headerRow = (
    <div style={{ paddingTop: '5px' }}>
      <div className="text-center">
        <h1>Cég Eszközei</h1>
      </div>
      <div className="d-flex justify-content-between mx-5 mt-3 px-3">
        <SelectWithIcon
          icons={<FaFilter style={iconStyle} />}
          label="Szűrés"
          select={
            <FilterSelect
              setFiltering={(filtering) => setFiltering(filtering)}
            />
          }
        />
        <Button onClick={() => fetchCompanyDevices()}>
          Szűrés, rendezés
        </Button>
        <SelectWithIcon
          icons={<FaSortAmountDown style={iconStyle} />}
          label="Rendezés"
          select={
            <SortSelect
              setSorting={(sorting) => setSorting(sorting)}
            />
          }
        />
      </div>
    </div>
  )

  const centralColumn = (
    <CompanyDeviceGrid
      title="A kiválasztott cég eszközei"
      fetchedDevices={fetchedDevices}
      originalDevices={companyDevices}
      alteredDevices={companyDevices}
      pageCount={pageCount}
      currentPage={currentPage}
      setReviewMethod={setReviewMethod}
      setCurrentPage={newPageNumber => setCurrentPage(newPageNumber)}
    />
  )

  return (
    <SingleColumnPageTemplate
      headerRow={headerRow}
      centralColumn={centralColumn}
    />
  );
}

export default CompanyDevicesPage;
