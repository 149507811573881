import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import DeviceCard from './DeviceCard';
import '../../App.scss';

function ConnectedDevicesWithCardDeck(props) {
    return (
        <CardDeck className="pb-4">
            {props.connections.map(connection => {
                return (
                    <DeviceCard
                        key={connection.deviceId}
                        id={connection.deviceId}
                        name={connection.name}
                        hasPhoto={connection.isPhotoUploaded}
                        category={connection.category.name}
                        manufacturer={connection.manufacturerName}
                        pathname={props.pathname}
                    />
                )
            })}
        </CardDeck>
    );
}

export default ConnectedDevicesWithCardDeck;
