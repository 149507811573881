// Imports from packages
import React from 'react';

export default function EnumNameEditor(props) {
    // Input data from parent component
    // Component-scoded logic
    return (
        <div>
            <input
                placeholder={props.name || "Írd be az enum nevét!"}
                value={props.name}
                onChange={props.updateEnumNameFromInput}
            />
        </div>
    )
}