// BOM related constants, enums

// Different type of downloads for BOM lists:
export const downloadTypes = {
    Default: "Default",
    BomListCsv: "BomListCsv",
    AttachedFiles: "AttachedFiles",
}

// Labels and types for download type dropdown component
export const downloadTypeLabels = [
    {
        label: "Letöltendő típusa",
        type: downloadTypes.Default
    },
    {
        label: "BOM letöltése (.csv)",
        type: downloadTypes.BomListCsv
    },
    {
        label: "Kapcsolódó fájlok (.zip)",
        type: downloadTypes.AttachedFiles
    }
]