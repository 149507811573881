// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
// Imports for APIs
import FileApi from '../../Api/FileApi';
// Imports of our custom components
import CustomModal from './CustomModal';
import LoaderWithText from '../CommonComponents/LoaderWithText';
// Imports of styles
// - none
// Imports of contants
// - none

export default function FileInformationModal(props) {
    // Destructuring assignment from the props
    const { show, fileReferenceId, handleClose } = props

    const [metaDatas, setMetaDatas] = useState({});

    useEffect(() => {
        const fetchLinks = async () => {
          try {
            const result = await axios(FileApi(`${fileReferenceId}/MetaData`));
            setMetaDatas(result.data)
          } catch (error) {
            console.error("Error during File metadata fetching")
          }
        }
    
        //Fetch link types
        if(fileReferenceId !== ''){
            fetchLinks();
        }
    }, [fileReferenceId])

    const closeModal = () => {
        setMetaDatas({})
        handleClose()
    }

    // Modal parameters
    const title = `Fájl metaadatok`;

    let categoryRows
    if(Object.keys(metaDatas).length > 0){
        const categoryEntries = Object.entries(metaDatas.categorizedNormalProperties)
        categoryRows = categoryEntries.map((category,idx) => {
            const categoryName = category[0]
            const properties = category[1]
        
            const propertyRows = properties.map(property => {
                const values = property.values.map(value => value.value)

                return (
                    <Form.Group key={property.enumTypeId} controlId="formFileNormals">
                        <Form.Label><b>{property.propertyName}</b> {property.measurementUnit !== "NotApplicable" ? ` (${property.measurementUnit})` : ""}</Form.Label>
                        <Form.Control
                            type="text"
                            defaultValue = {values.join(', ')}
                            plaintext
                        />
                    </Form.Group>
                )
            })

            return (
                <div key={`${category}-${idx}`}>
                    <h5>{categoryName}</h5>
                    {propertyRows}
                </div>
            )
        })
    }

    const body = (
        <>
            <Form>
                <Form.Group controlId="formFileName">
                    <Form.Label><b>Fájlnév</b></Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue = {metaDatas.fileName}
                        plaintext
                        readOnly
                    />
                </Form.Group>
                <Form.Group controlId="formFileExtension">
                    <Form.Label><b>Kiterjesztés</b></Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue = {metaDatas.extension}
                        plaintext
                        readOnly
                    />
                </Form.Group>
                <Form.Group controlId="formFileDescription">
                    <Form.Label><b>Leírás</b></Form.Label>
                    <Form.Control
                        type="text"
                        defaultValue = {metaDatas.description}
                        plaintext
                        readOnly
                    />
                </Form.Group>
                { categoryRows }
            </Form>
        </>
    );

    const conditionalBody = Object.keys(metaDatas).length !== 0 ? body : <LoaderWithText content="Adatok" />

    const footer = (
        <div className="d-flex justify-content-center align-items-center w-100">
            <Button
                variant="primary"
                onClick={closeModal}
            >
                Bezárás
            </Button>
        </div>
    );

    return (
        <>
            <CustomModal
                show={show}
                size="md"
                dropback="static"
                keyboard={false}
                handleClose={handleClose}
                title={title}
                body={conditionalBody}
                footer={footer}
            />
        </>
    );
}
