// Project related constants, enums

// Different type of downloads for BOM lists:
export const projectStatus = {
    ALL: "Minden projekt",
    OPEN: "Nyitott projektek",
    NEW: "Új",
    ACTIVE: "Aktív",
    FINISHED: "Lezárt",
    PARENT: "Fő projektek",
    PRIORITIZED: "Magas prioritású",
    UNKNOWN: "Ismeretlen"
}

export const statusOptions = [
    { value: 'New', label: projectStatus.NEW },
    { value: 'Prioritized', label: projectStatus.PRIORITIZED },
    { value: 'Active', label: projectStatus.ACTIVE },
    { value: 'Finished', label: projectStatus.FINISHED}
]

// Used on ProjectList page
export const projectStatuses = [
    { value: 'All', label: projectStatus.ALL},
    { value: 'Open', label: projectStatus.OPEN},
    { value: 'Parent', label: projectStatus.PARENT},
    { value: 'Prioritized', label: projectStatus.PRIORITIZED + ' projektek'},
    { value: 'New', label: projectStatus.NEW + ' projektek'},
    { value: 'Active', label: projectStatus.ACTIVE + ' projektek'},
    { value: 'Finished', label: projectStatus.FINISHED + ' projektek'},
]