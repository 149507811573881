// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
// Imports for APIs
import UserApi from '../../Api/UserApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import ThreeColumnPageTemplate from '../Pages/ThreeColumnPageTemplate';
import EditorBox from '../CommonComponents/EditorBox';
import LeftColumn from '../CommonComponents/LeftColumn';
import ProfileCentralColumn from '../Profile/ProfileCentralColumn';
// Imports of styles
// - none
// Imports of contants
// - none

function UserPage() {
  const { username } = useParams();
  const usernameFromURL = username || "";
  const notRequestedUsername = usernameFromURL === "";
  const [hasData, setHasData] = useState(false)

  // Profile
  const [profile, setProfile] = useState()
  const [refreshing, setRefreshing] = useState({})

  const [files, setFiles] = useState({})
  const [documentTypes, setDocumentTypes] = useState([]);

  const [links, setLinks] = useState({})

  // Edit mode
  const [editMode, setEditMode] = useState(false)
  const [noSuchUserWithThisId, setNoSuchUserWithThisId] = useState(false)
  const [showLeftColumn, setShowLeftColumn] = useState(false)

  const toggleMode = () => {
    setEditMode(!editMode);
  }

  const fetchFileInformation = async () => {
    const filesFromApi = await axios(UserApi('Properties/Files', 'GET', usernameFromURL));
    setFiles(filesFromApi.data.properties)
  };

  // Fetch profile information
  useEffect(() => {
    const fetchProfileInformation = async () => {
      try{
        const profileInformation = await axios(UserApi('Profile', 'GET', usernameFromURL));
        setProfile(profileInformation.data)
        setHasData(true)
        setShowLeftColumn(true)

        // There is an authorized user so download further informations
        fetchFileInformation();
        fetchFileTypes();

        fetchLinkInformation();
      }
      catch(error){
        setHasData(true)
        setShowLeftColumn(false)
        setNoSuchUserWithThisId(true)
      }
    };

    const fetchFileTypes = async () => {
      const result = await axios(PropertyApi(`List?propertyType=User&typeOf=FileReference`));
      const fileOptions = result.data;
      setDocumentTypes(fileOptions)
    };

    const fetchLinkInformation = async () => {
      const linksFromApi = await axios(UserApi('Properties/Links', 'GET', usernameFromURL));
      setLinks(linksFromApi.data.properties)
    };

    fetchProfileInformation();
  }, []);

  // Fetch profile information because of new prop
  useEffect(() => {
    if(Object.prototype.hasOwnProperty.call(refreshing, "username")){
      setProfile(refreshing)
    }
    else if(Object.prototype.hasOwnProperty.call(refreshing, "fileReferenceId")){
      fetchFileInformation()
    }
  }, [refreshing]);

  let leftColumn = (
    <LeftColumn
      toggleMode = {toggleMode}
      editMode = {editMode}
      id = ""
      borderRadius = "50%"
      postType = 'user'
      formDataType = 'profilePicture'
      links = {links}
      files = {files}
      entityName = "felhasználóhoz"
    />
  )

  const centralColumn = (
    <ProfileCentralColumn
      containerTitle = "Felhasználói profil"
      profile = {profile}
      editMode = {editMode}
      setRefreshing = {result => setRefreshing(result)}
      documentTypes = {documentTypes}
      entity = "user"
      loadingText = "Adatok lekérdezése"
      hasData = {hasData}
      noSuchEntityWithThisId = {noSuchUserWithThisId}
      notRequestedUsername = {notRequestedUsername}
    />
  )

  const rightColumn = (
    notRequestedUsername &&
      <EditorBox
        showButton = {true}
        toggleMode = {toggleMode}
        title = "Profil módosítása"
        body = {
          `Ha úgy gondolod, hogy hibás valamelyik adat, vagy csak új képet töltenél fel, 
          akkor az alábbi módosítási gomb megnyomása után megteheted azt.`
        }
        buttonText = {editMode ? "Módosítás befejezése" : "Profil módosítása"}
      />
  )

  return (
    <ThreeColumnPageTemplate
      leftColumn = {showLeftColumn && leftColumn}
      centralColumn = {centralColumn}
      rightColumn = {rightColumn}
    />
  );
}

export default UserPage;
