import React, { useState } from "react";
import { connect } from "react-redux";
import { login, setAuthToken } from "../../actions/auth.js";
import { setCompany } from "../../actions/company.js";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "../../App.css";
import "../../App.scss";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Logo from "../../cropped-logo.png";

function LoginForm(props) {
   // Destructuring assignment from redux props
   const { user } = props;
   const usernameFromRegistration = user ? user : "";
   const [username, setUsername] = useState(usernameFromRegistration);
   const [password, setPassword] = useState("");
   const [showPassword, setShowPassword] = useState(false);
   const handleFormSubmit = (event) => {
      event.preventDefault();
      props.handleFormSubmit(username, password);
   }
   return (
      <Container fluid>
         <Row
            className="justify-content-center align-items-center"
            style={{ minHeight: "100vh" }}
         >
            <Col className="col-12 text-center">
               <img src={Logo} alt="HADocument logó" width="300px" />
               <h2 className="mb-4 mt-1">Bejelentkezés</h2>
               <Form style={{ width: "400px" }} className="mx-auto text-center">
                  <Form.Group controlId="LoginPage-username">
                     <Form.Control
                        type="text"
                        placeholder="Felhasználónév"
                        value={username}
                        onChange={(event) => setUsername(event.target.value)}
                     />
                  </Form.Group>
                  <Form.Group controlId="LoginPage-password">
                     <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Jelszó"
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                     />
                     <Form.Text className="text-muted text-center">
                        Elfelejtetted a jelszavad? Kattints{" "}
                        <a href="/forgotten-password" className="link-style-darker-smaller">
                           ide
                        </a>
                !
              </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-0 d-flex justify-content-center flex-column">
                     <Form.Check
                        className="mb-1"
                        type="checkbox"
                        label="Jelszó megjelenítése"
                        defaultChecked={showPassword}
                        onClick={() => setShowPassword(!showPassword)}
                     />
                  </Form.Group>
               </Form>
               <Button
                  className="mt-3"
                  variant="primary"
                  onClick={(event) => handleFormSubmit(event)}
               >
                  Bejelentkezés
                    </Button>
            </Col>
         </Row>
      </Container>
   );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = (state) => {
   const { user, thumbnail, isAuthenticated, role } = state.auth;
   const { companyId, companyName } = state.company;
   return {
      user,
      companyId,
      companyName,
      thumbnail,
      isAuthenticated,
      role,
   };
};

// Store reducers to components props as callable functions
const mapDispatchToProps = (dispatch) => ({
   setAuthToken: (token) => dispatch(setAuthToken(token)),
   login: (name, companyId, companyName, shortName, thumbnail, role) => {
      dispatch(login(name, thumbnail, role));
      dispatch(setCompany(companyId, companyName, shortName));
   },
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
