// Imports from packages
import React, { useState } from 'react';
// react-bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
// Imports for APIs
// - none
// Imports of our custom components
import ModifyProjectPropertyModal from '../Modals/ModifyProjectPropertyModal';
import AddFileReferenceModal from '../Modals/AddFileReferenceModal';
// Imports of styles
// - none
// Imports of contants
// - none

function ProjectPropertyDropdown(props) {
    // Destructuring assignment from the props
    const { project, documentTypes, setRefresh, isFetchingProjectProfile } = props

    // Own variables
    const [isFileReferenceModalOpen, setIsFileReferenceModalOpen] = useState(false)
    const [openModificationModal, setOpenModificationModal] = useState(false)

    return (
        <div className = "bg-white text-dark text-start" id = "project-properties">
            <ModifyProjectPropertyModal
                show = {openModificationModal}
                handleClose = {() => setOpenModificationModal(false)}
                refresh = {setRefresh}
                project = {project}
                method = "add"
            />
            <AddFileReferenceModal
                show = {isFileReferenceModalOpen}
                handleClose = {() => setIsFileReferenceModalOpen(false)}
                documentTypes = {documentTypes}
                setRefreshing = {setRefresh}
                profileId = {project.id}
                entity = "project"
            />
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic" disabled={isFetchingProjectProfile}>
                    Tulajdonság hozzáadása
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as="button" onClick={() => setOpenModificationModal(true)}>Normál tulajdonság</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => setIsFileReferenceModalOpen(true)}>Fájl</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default ProjectPropertyDropdown;
