// Imports from packages
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
// Imports for APIs
// - none
// Imports of our custom components
import AddNewProfileProperty from '../Modals/AddNewProfileProperty';
import AddFileReferenceModal from '../Modals/AddFileReferenceModal';
// Imports of styles
// - none
// Imports of contants
// - none

export default function ProfileDropdown(props) {
    // Destructuring assignment from the props
    const { entity, profileId, editMode, documentTypes, setRefreshing } = props

    const [isPropertyModalOpen, setPropertyIsModalOpen] = useState(false)
    const [isFileReferenceModalOpen, setFileReferenceIsModalOpen] = useState(false)

    return (
        <div>
            <AddNewProfileProperty
                show = {isPropertyModalOpen}
                handleClose = {() => setPropertyIsModalOpen(false)}
                setRefreshing = {setRefreshing}
                profileId = {profileId}
                entity = {entity}
            />
            <AddFileReferenceModal
                show = {isFileReferenceModalOpen}
                handleClose = {() => setFileReferenceIsModalOpen(false)}
                setRefreshing = {setRefreshing}
                documentTypes = {documentTypes}
                entity = {entity}
                profileId = {profileId}
            />
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-basic" disabled={editMode}>
                    Tulajdonság hozzáadása
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item as="button" onClick={() => setPropertyIsModalOpen(true)}>Normál tulajdonság</Dropdown.Item>
                    <Dropdown.Item as="button" disabled>Link</Dropdown.Item>
                    <Dropdown.Item as="button" onClick={() => setFileReferenceIsModalOpen(true)}>Fájl</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        </div>
    );
}
