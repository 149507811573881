import React, { useEffect } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { login } from "./actions/auth.js";
import { setProject } from './actions/project.js';
import { setCompany } from "./actions/company.js";
import axios from "axios";
import "./index.css";
import useFetchCurrentUser from "./hooks/useFetchCurrentUser";
import LoadingPage from "./Components/Auth/LoadingPage";
import Unauthorized from "./Components/Auth/Unauthorized";
import NotFoundPage from "./Pages/NotFoundPage";
import DevicePage from './Components/Device/DevicePage';
import NewDevicePage from './Components/Device/NewDevice/NewDevicePage';
import UserPage from './Components/User/UserPage';
import RegistrationConfirmationPage from './Components/User/RegistrationConfirmationPage';
import LoginPage from './Components/User/LoginPage';
import UserCompaniesPage from './Components/User/UserCompaniesPage';
import CompanyPage from './Components/Company/CompanyPage';
import CompanyRegistration from './Components/Company/CompanyRegistration';
import CompanyDevicesPage from './Components/Company/CompanyDevices/CompanyDevicesPage';
import DistributedDevicesPage from './Components/Company/DistributedDevices/DistributedDevicesPage';
import RegistrationPage from './Components/User/RegistrationPage';
import MainPage from './Components/MainPage/MainPage';
import DetailedSearch from './Components/Search/Detailed/DetailedSearch';
import SimpleSearchPage from './Components/Search/SimpleSearchPage';
import ProjectList from './Components/Project/ProjectList';
import ProjectPage from './Components/Project/ProjectPage';
import HADocumentPage from './Components/Header/HADocumentPage';
import HADstairPage from './Components/Header/HADstairPage';
import ConnectionsPage from './Components/Header/ConnectionsPage';
import PartnersPage from './Components/Header/PartnersPage';
import AllPages from './Components/AllPages';
import UserConditionPage from './Components/Header/UserConditionPage';
import SupportPage from './Components/Header/SupportPage';
import AdminPage from './Pages/Admin/AdminPage';
import {
  getAuthDataFromLocalStorage,
  authDataExistsInLocalStorage,
  getActiveProject,
  getActiveBom,
} from "./auth";
import useFetchUserPicture from "./hooks/useFetchUserPicture.js";
import { setBom } from "./actions/bom.js";
import ForgottenPassword from "./Pages/ForgottenPassword/ForgottenPassword.jsx";
import PublicResetPassword from "./Pages/PublicResetPassword/PublicResetPassword.jsx";
import ConfirmRegistrationPage from "./Pages/ConfirmRegistrationPage.js";

axios.defaults.baseURL =
  process.env.REACT_APP_BACKEND_API_BASE_URL +
  process.env.REACT_APP_BACKEND_API_VERSION_NUMBER;

axios.interceptors.request.use(
  (config) => {
    if (authDataExistsInLocalStorage()) {
      const { token } = getAuthDataFromLocalStorage();
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
    return config;
  },
  (error) => Promise.reject(error)
);

const ProtectedRoutes = ({ children }) => {
  const { refetch: fetchUser, data: currentUser, isLoading, isError } = useFetchCurrentUser();

  useEffect(() => {
    fetchUser();
  }, [fetchUser])

  if (isLoading) return <LoadingPage />;
  if (isError)
    return <Redirect to="/login" />
  if (currentUser && currentUser.email) return <>{children}</>;
  return <Unauthorized />;
};

function Application(props) {
  const { data: userPicture, isSuccess } = useFetchUserPicture();
  const userThumbnailInStore = useSelector((state) => state.auth.thumbnail);
  // Init auth token from localStorage
  const loadDataFromLocalStrage = async () => {
    if (authDataExistsInLocalStorage()) {
      const {
        username,
        id,
        name,
        shortName,
        role
      } = getAuthDataFromLocalStorage();
      props.login(username, id, name, shortName, "", role);
      const { activeProjectId, activeProjectName } = getActiveProject();
      props.setProject(activeProjectId, activeProjectName);
      const { activeBomId, activeBomName } = getActiveBom();
      props.setBom(activeBomId, activeBomName);
    }
  }
  useEffect(() => {
    //console.log("useEffect [isSuccess, userPicture]", isSuccess, userThumbnailInStore.length);
    if (isSuccess && userThumbnailInStore.length === 0) {
      // eslint-disable-next-line no-console
      //console.log("Save user picture in store.");
      const {
        username,
        id,
        name,
        shortName,
        role
      } = getAuthDataFromLocalStorage();
      props.login(username, id, name, shortName, userPicture, role);
    }
  }, [isSuccess, userPicture]);
  useEffect(() => {
    loadDataFromLocalStrage();
  }, []);
  return (
    <Router>
      <Switch>
        <Route exact path="/login">
          <LoginPage />
        </Route>
        <Route path="/registration/confirm">
          <ConfirmRegistrationPage />
        </Route>
        <Route path="/registration">
          <RegistrationPage />
        </Route>
        <Route path="/forgotten-password">
          <ForgottenPassword />
        </Route>
        <Route path="/reset-password">
          <PublicResetPassword />
        </Route>
        <ProtectedRoutes>
          <Route exact path="/new-device">
            <NewDevicePage />
          </Route>
          <Route exact path="/device/:id">
            <DevicePage />
          </Route>
          <Route path="/had/:id">
            <DevicePage />
          </Route>
          <Route path="/user/companies">
            <UserCompaniesPage />
          </Route>
          <Route path="/user/confirm/:secretHash">
            <RegistrationConfirmationPage />
          </Route>
          <Route path="/user/login">
            <LoginPage />
          </Route>
          <Route path="/user">
            <UserPage />
          </Route>
          <Route path="/user-conditions">
            <UserConditionPage />
          </Route>
          <Route exact path="/company/:companyId/projects/:projectId">
            <ProjectPage />
          </Route>
          <Route exact path="/company/:companyId/projects">
            <ProjectList />
          </Route>
          <Route exact path="/company/:companyId/owned">
            <CompanyDevicesPage />
          </Route>
          <Route exact path="/company/:companyId/distributed">
            <DistributedDevicesPage />
          </Route>
          <Route exact path="/company/:id">
            <CompanyPage />
          </Route>
          <Route path="/company-registration">
            <CompanyRegistration />
          </Route>
          <Route path="/search/detailed">
            <DetailedSearch />
          </Route>
          <Route path="/search">
            <SimpleSearchPage />
          </Route>
          <Route path="/HADocument">
            <HADocumentPage />
          </Route>
          <Route path="/HADstair">
            <HADstairPage />
          </Route>
          <Route path="/partners">
            <PartnersPage />
          </Route>
          <Route path="/connections">
            <ConnectionsPage />
          </Route>
          <Route path="/all-pages">
            <AllPages />
          </Route>
          <Route path="/support">
            <SupportPage />
          </Route>
          <Route path="/super-secret-admin-page">
            <AdminPage />
          </Route>
          <Route path="/home">
            <MainPage />
          </Route>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
        </ProtectedRoutes>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Router>
  );
}

// Store reducers to components props as callable functions
const mapDispatchToProps = (dispatch) => ({
  login: (name, companyId, companyName, shortName, thumbnail, role) => {
    dispatch(login(name, thumbnail, "", role));
    dispatch(setCompany(companyId, companyName, shortName));
  },
  setProject: (projectId, projectName) => {
    dispatch(setProject(projectId, projectName));
  },
  setBom: (bomId, bomName) => {
    dispatch(setBom(bomId, bomName));
  }
});

export default connect(undefined, mapDispatchToProps)(Application);
