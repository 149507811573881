/* eslint-disable no-console */
// Imports from packages
import React from "react";
import { connect } from "react-redux";
import { login } from "../../actions/auth.js";
import { setCompany } from "../../actions/company.js";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../../theme/hadstair-logo-black.png";
// Imports for APIs
// - none
// Imports of our custom components
import LoginDropdown from "./LoginDropdown";
import LanguageDropdown from "./LanguageDropdown";
import SearchDropdown from "./SearchDropdown";
import UserBubble from "./UserBubble";
import AddNewDeviceButton from "./AddNewDeviceButton";
import BetaAlert from "./BetaAlert";
// Imports of styles
import "../../App.css";

function Header(props) {
  // States from Redux store
  const { user, thumbnail, role } = props;

  return (
    <>
      <Navbar bg="white" variant="light">
        <Navbar.Brand style={{ width: 240, textAlign: "center" }}>
          <Link to="/">
            <img
              alt="HADocument logo"
              title="HADocument logo"
              src={logo}
              width="85"
              className="d-inline-block align-top"
            />
          </Link>
        </Navbar.Brand>

        <Nav className="mr-auto">
          <Link to={"/HADocument"} key={"/HADocument"} className="react-router-link">
            HADocument
          </Link>
          <Nav.Link href="https://home.hadstair.com" className="react-router-link">HADstair</Nav.Link>
          <Link to={"/partners"} key={"/partners"} className="react-router-link">
            Partnerek
          </Link>
          <Nav.Link href="http://home.hadstair.com/index.php/contact" className="react-router-link">Kapcsolatok</Nav.Link>
          <Link to={"/user-conditions"} key={"/user-conditions"} className="react-router-link">
            Felhasználói feltételek
          </Link>
          <Link to={"/support"} key={"/support"} className="react-router-link">
            Segítség
          </Link>
          {role && role === "Administrator" && (
            <Link to={"/super-secret-admin-page"} key={"/super-secret-admin-page"} className="react-router-link" style={{ color: "#e4241c", fontWeight: 700 }}>
              Super admin
            </Link>
          )}
        </Nav>

        <Navbar.Collapse className="justify-content-end">
          {user !== "" &&
            <AddNewDeviceButton />
          }
          <SearchDropdown />
          {user !== "" ? (
            <UserBubble thumbnail={thumbnail} />
          ) : (
            <LoginDropdown />
          )}
          <LanguageDropdown />
        </Navbar.Collapse>
      </Navbar>
      <BetaAlert />
    </>
  );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = (state) => {
  const { user, thumbnail, isAuthenticated, token, role } = state.auth;
  const { companyId, companyName } = state.company;
  return {
    user,
    companyId,
    companyName,
    thumbnail,
    isAuthenticated,
    token,
    role,
  };
};
// Store reducers to components props as callable functions
const mapDispatchToProps = (dispatch) => ({
  login: (name, companyId, companyName, thumbnail, token) => {
    dispatch(login(name, thumbnail, token));
    dispatch(setCompany(companyId, companyName));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
