// Imports from packages
import React from 'react';
// react-bootstrap
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// Imports for APIs
// - none
// Imports of our custom components
import ImageBox from './ImageBox';
import FileBox from './FileBox';
import LinkBox from './LinkBox';
// Imports of styles
// - none
// Imports of contants
// - none

export default function LeftColumn(props) {
    // Destructuring assignment from the props
    const { toggleMode, editMode, id, borderRadius, postType, formDataType, links, files, entityName, refresh, isOwner, isAdministrator, acceptReview, declineReview } = props

    return (
        <>
           <ImageBox
                borderRadius = {borderRadius}
                toggleMode = {toggleMode}
                editMode = {editMode}
                id = {id}
                postType = {postType}
                formDataType = {formDataType}
            />
            <Row className = "text-center mt-3">
                <Col>
                    <LinkBox
                        links = {links}
                        entityName = {entityName}
                        editMode={editMode}
                        postType={postType}
                        refresh={refresh}
                        isOwner={isOwner}
                        isAdministrator={isAdministrator}
                        deviceId={id}
                        acceptReview={(devicePropertyReviewId) => acceptReview(devicePropertyReviewId)}
                        declineReview={(devicePropertyReviewId) => declineReview(devicePropertyReviewId)}
                    />
                </Col>
            </Row>
            {!editMode &&
                <Row className = "text-center mt-3">
                    <Col>
                        <FileBox
                            files={files}
                            entityName = {entityName}
                            postType={postType}    
                        />
                    </Col>
                </Row>
            }
        </>
    );
}
