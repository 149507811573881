// Imports from packages
import React from 'react';
// react-bootstrap
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
// - none
// Imports of our custom components
import ProfileCategories from './ProfileCategories';
import ProfileDropdown from './ProfileDropdown';
import Loader from '../CommonComponents/Loader';
import entityToHungarian from "./utils/entityTranslater";
// Imports of styles
// - none
// Imports of contants
// - none

export default function ProfileCentralColumn(props){
  // Destructuring assignment from the props
  const { containerTitle, profile, profileId, editMode, setRefreshing, documentTypes, entity, noSuchEntityWithThisId, loadingText, hasData, notRequestedUsername } = props

  const centralColumn = (
    <>
      <Container className = "px-0">
        <Row className = "justify-content-center align-items-center py-3">
          <Col className = "col-lg-5 justify-content-center align-items-center text-left">
            <span style = {{ fontSize: '1.6rem', fontWeight: 600 }}>
                {containerTitle}
            </span>
          </Col>
          <Col className = "d-flex justify-content-end align-items-center text-center">
            { notRequestedUsername &&
              <ProfileDropdown
                profileId = {profileId}
                editMode = {editMode}
                setRefreshing = {setRefreshing}
                documentTypes = {documentTypes}
                entity = {entity}
              />
            }
          </Col>
        </Row>
      </Container>
      { profile !== undefined &&
        <ProfileCategories
          entity = {entity}
          profile = {profile}
          profileId = {profileId}
          editMode = {editMode}
          setRefreshing = {setRefreshing}
        />
      }
    </>
  )

  const centralWrapper = (
    hasData ? (
      noSuchEntityWithThisId ? (
        <div className = "d-flex flex-column justify-content-center align-items-center h-100 pt-2">
          <Alert variant ="danger" className = "mt-4 mb-4">
            Nem létezik ilyen azonosítóval rendelkező {entityToHungarian(entity)}, vagy nincs jogosultsága az oldal megtekintéséhez
          </Alert>
        </div>
      ) : (
        centralColumn
      )
    ) : (
      <div className = "d-flex flex-column justify-content-center align-items-center h-100">
        <Loader/>
        <h1>{loadingText}</h1>
      </div>
    )
  )

  return centralWrapper;
}
