// Imports from packages
import React, { useState, useEffect } from 'react';
import { Link /*, useParams */ } from "react-router-dom";
// Imports packages from react-bootstrap
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
// - none
// Imports of our custom components 
import ThreeColumnPageTemplate from '../Pages/ThreeColumnPageTemplate';
import Loader from '../CommonComponents/Loader';
// Imports of styles
// - none
// Imports of contants
// - none

function RegistrationConfirmationPage() {
    // Handle ID of the device (from props or URL parameter)
    // TODO: handle secretHash
    // let { secretHash } = useParams();

    // Backend result informations
    const [fetched, setFetched] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    useEffect(() => {
        let mounted = true;

        const fetchConfirmation = async () => {
            setFetched(false)

            try {
                // Todo: Update with correct endpoint call
                // const result = await axios(AccountApi('Confirm', confirmationHash));
                // setConfirmed(result.data);

                // Delete: generate random boolean
                setTimeout(() => {
                    const result =  Math.random() < 0.5
                    setConfirmed(result)

                    setFetched(true)
                }, 3000);
                
            }
            catch (error) {
                // Confirmation error
                console.warn("Error while fetching confirmation", error)

                setFetched(true)
            }
        };

        // Fetch data
        if(mounted) {
            fetchConfirmation();
        }

        return () => mounted = false;
    }, [])

    const alertMessage = confirmed ? "Regisztráció megerősítése sikeres" : "Regisztráció megerősítése sikertelen"

    const centralColumn = (
        <div className  = "d-flex justify-content-center flex-column text-center">
            <h1>HADocument regisztráció</h1>
            <div className = "my-3">
                {fetched ? (
                        <>
                            <Alert variant = {confirmed ? "success" : "danger"}>
                                {alertMessage}
                            </Alert>
                            { confirmed && 
                                <Link to = "/user/login">
                                    <Button variant = "primary" className = "mt-2">
                                        Tovább a bejelentkezéshez
                                    </Button>
                                </Link>
                            }
                        </>
                    ) : (
                        <>
                            <h4>Megerősítés folyamatban...</h4>
                            <Loader/>
                        </>
                    )
                }
            </div>
        </div>
    )
    
    return (
        <ThreeColumnPageTemplate
            centralColumn={centralColumn}
        />
    );
}

export default RegistrationConfirmationPage;
