// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Toast from 'react-bootstrap/Toast';
import { FaCheck, FaExchangeAlt, FaTrashAlt } from 'react-icons/fa';
// Imports for APIs
import ReviewApi from '../../Api/ReviewApi';
// Imports of our custom components
import ModifyDevicePropReview from '../Modals/ModifyDevicePropReview';
import { capitalizeFirstLetter } from '../../utilities/stringManipulations';
import { mapReviewTypeToIconAndColor } from './utils/deviceFunctions';
// Imports of styles
import '../../App.css';
import './Device.css';
// Imports of contants
import { HADColors } from '../../constants/color';
import useFetchDeviceReviews from '../../hooks/useFetchDeviceReviews';

function CustomizedToast(props) {
  const { icon, label, backgroundColor } = mapReviewTypeToIconAndColor(props.method)
  return (
    <div aria-live="assertive" aria-atomic="true" className="customized-toast">
      <Toast
        className='toast mt-2'
        show={props.showToast}
        onClose={() => props.setShowToast(false)}
      >
        <Toast.Header>
          <div style={{
            width: 25,
            height: 25,
            backgroundColor: backgroundColor,
            borderRadius: "50%",
            color: "black",
            marginRight: 10
          }}
            className="d-flex justify-content-center align-items-center"
          >
            {icon}
          </div>
          <strong className="mr-auto">{capitalizeFirstLetter(label)}</strong>
        </Toast.Header>
        <Toast.Body>
          Sikeres {label}i javaslat!
        </Toast.Body>
      </Toast>
    </div>
  )
}

function DeviceProperties(props) {
  const { id, name, measurementUnit, value, disableReviewButtons, setDisableReviewButtons, refresh, deviceId, isOwner, enumTypeId, acceptReview,
    declineReview, isAdministrator } = props;

  const [deviceProperty, setDeviceProperty] = useState({});
  // Response from modification proposal
  const [response, setResponse] = useState('');
  const [showToast, setShowToast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [method, setMethod] = useState('');

  const fetchReviews = async () => {
    // Get Reviews from DeviceApi
    const result = await axios(ReviewApi(`DeviceProperty/${id}`));
    setDeviceProperty(result.data);
  };

  useEffect(() => {
    fetchReviews();
    fetchDeviceReviews(deviceId);
  }, [id, response, refresh]);

  const valueApproved = () => {
    const selectedAction = {
      "isValueApproved": true,
      "isRemovalRecommended": false,
      "recommendedNewValue": ""
    }

    postReview(selectedAction);
  }

  const recommendedNewValue = (newValue, enumTypeId, selectedValue) => {
    const selectedAction = {
      "isValueApproved": false,
      "isRemovalRecommended": false,
      "recommendedNewValue": enumTypeId ? selectedValue.value : `${newValue}`
    }
    postReview(selectedAction);
  }

  const removalRecommended = () => {
    const selectedAction = {
      "isValueApproved": false,
      "isRemovalRecommended": true,
      "recommendedNewValue": ""
    }
    postReview(selectedAction);
  }

  const postReview = async (selectedAction) => {
    // Create the parameters for axios
    const axiosParameters = {
      url: ReviewApi(`DeviceProperty/${id}`),
      method: 'post',
      data: selectedAction,
      headers: {
        'Content-Type': 'application/json',
        "accept": "*/*"
      }
    }

    // Call API endpoint with axios
    axios(axiosParameters)
      .then(response => {
        if (response.status === 200) {
          // Super administrators can accept recommendations and removals automatically
          if (isAdministrator) {
            acceptReview({ id: response.data.result, isReviewAcceptable: true })
            setResponse(response.data.result)
          }
          // Device owner can accept recommendations automatically
          if (isOwner) {
            // Checking for recommendation
            if (selectedAction.isRemovalRecommended === false) {
              acceptReview({ id: response.data.result, isReviewAcceptable: selectedAction.recommendedNewValue.split("-").length >= 4 })
              setResponse(response.data.result)
            }
          }
          else {
            setResponse(response.data.result)
          }
        }
      })
      .catch(error => {
        console.warn("Error during property PUT review actions", error);
      })
      .finally(() => {
        setShowToast(true)
        setDisableReviewButtons(true)
        setTimeout(() => {
          setShowToast(false)
          setDisableReviewButtons(false)
        }, 3000);
      });
  }

  const onMethodChange = selectedMethod => {
    setMethod(selectedMethod)
    if (selectedMethod !== 'approve') {
      setShowModal(!showModal)
    }
    else {
      valueApproved()
    }
  }

  // Items for rendering
  //  Common properties
  const commonIconSize = "0.9em"
  const reviewItems = [
    {
      label: 'Jóváhagyási kérelem',
      value: deviceProperty.numberOfApprovals,
      icon: <FaCheck size={commonIconSize} />,
      color: disableReviewButtons ? HADColors.DisabledGreen : HADColors.Green,
      onClickHandler: disableReviewButtons ? (() => { }) : (() => onMethodChange('approve'))
    },
    {
      label: 'Változtatási kérelem',
      value: deviceProperty.numberOfChangeRequests,
      icon: <FaExchangeAlt size={commonIconSize} />,
      color: disableReviewButtons ? HADColors.DisabledYellow : HADColors.Yellow,
      onClickHandler: disableReviewButtons ? (() => { }) : (() => onMethodChange('recommend'))
    },
    {
      label: 'Törlési kérelem',
      value: deviceProperty.numberOfRemovalRequests,
      icon: <FaTrashAlt size={commonIconSize} />,
      color: disableReviewButtons ? HADColors.DisabledRed : HADColors.Red,
      onClickHandler: disableReviewButtons ? (() => { }) : (() => onMethodChange('removal'))
    }
  ]

  const handleClose = () => {
    setShowModal(!showModal)
  }

  // Device property reviews
  const [allDeviceReviews, setAllDeviceReviews] = useState([]);
  const { refetch: fetchDeviceReviews, data: deviceReviews, isSuccess } = useFetchDeviceReviews(deviceId);

  useEffect(() => {
    if (isSuccess) {
      if (deviceReviews) {
        setAllDeviceReviews(deviceReviews.reviewsOfProperties);
      }
    }
  }, [isSuccess, deviceReviews])

  const iconsWithTooltip = reviewItems.map((item, index) => {
    return (
      <div
        className="d-flex justify-content-start align-items-center mr-2"
        key={`statistics-item-${index}`}
      >
        <div
          className='property-icon d-flex justify-content-center align-items-center'
          style={{ backgroundColor: item.color }}
          onClick={item.onClickHandler && item.onClickHandler}
        >
          <OverlayTrigger
            key={`device-prop-modification-${id}-top-${index}`}
            placement="top"
            overlay={
              <Tooltip id="tooltip-device-prop-modification">
                {item.label}
              </Tooltip>
            }
          >
            {item.icon}
          </OverlayTrigger>
        </div>
        <div className="review-value">
          <div className="item-value">
            {item.value}
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className="d-flex flex-row align-items-center">
      <div className="d-flex flex-row justify-content-between align-items-center">
        {iconsWithTooltip}
      </div>
      <CustomizedToast
        method={method}
        showToast={showToast}
        setShowToast={value => setShowToast(value)}
      />
      <ModifyDevicePropReview
        show={showModal}
        method={method}
        propertyName={name}
        propertyValue={value}
        measurementUnit={measurementUnit}
        recommendedNewValue={(newValue, enumTypeId, selectedValue) => recommendedNewValue(newValue, enumTypeId, selectedValue)}
        removalRecommended={() => removalRecommended()}
        handleClose={() => handleClose()}
        deviceId={deviceId}
        isOwner={isOwner}
        isAdministrator={isAdministrator}
        propertyId={id}
        enumTypeId={enumTypeId}
        reviewsFromParent={allDeviceReviews.filter(review => review.devicePropertyId === id)}
        acceptReview={(devicePropertyReviewId) => acceptReview(devicePropertyReviewId)}
        declineReview={(devicePropertyReviewId) => declineReview(devicePropertyReviewId)}
      />
    </div>
  )
}

export default DeviceProperties;
