export const mapExistence = (type) => {
    let returnOption
    const existenceOptions = [
      { value: "PrivatePerson", label: "Magánszemély"},
      { value: "LegalPerson", label: "Jogi személy"}
    ]

    // Search for 'type' in existenceOptions array and give back object
    existenceOptions.forEach(option => {
      if(type === option.value) {
        returnOption = option
      }
    })

    return returnOption.label
}