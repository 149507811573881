import axios from "axios";
import { useQuery } from "react-query";

export const FetchUserPictureQueryKey = "FetchCurrentUserPicture";

const useFetchUserPicture = () => {
  return useQuery(
    FetchUserPictureQueryKey,
    async () => {
      const result = await axios.get("User/Picture/Thumbnail?width=50&height=50", { responseType: "blob" });
      const thumbnailImageUrl = URL.createObjectURL(
        result.data
      );
      return thumbnailImageUrl;
    },
    {
      refetchInterval: 30000, retry: 1
    }
  );
};

export default useFetchUserPicture;