export const isThereAnyValidValues = (array) => {
    if (array.length === 0) {
        return false
    }
    else {
        let isValid = false
        array.forEach(element => {
            if (element.name.length > 0) {
                isValid = true
            }
        })
        return isValid
    }
}

export const defaultApiResponse = { type: '', status: 0, message: '' }