// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import { FaFilter, FaSortAmountDown } from 'react-icons/fa';
// Imports for APIs
import CompanyApi from '../../../Api/CompanyApi';
// Imports of our custom components 
import SingleColumnPageTemplate from '../../Pages/SingleColumnPageTemplate';
import DistributedDeviceGrid from './DistributedDeviceGrid';
import SelectWithIcon from '../../CommonComponents/SelectWithIcon';
import YearFilterSelect from "./YearFilterSelect";
import SortSelect from '../CompanyDevices/SortSelect';
import filterAndSortDevices from '../CompanyDevices/filterAndSortDevices';
// Imports of styles
// - none
// Imports of contants
// - none

function DistributedDevicesPage() {
    // Handle ID of the device (from URL parameter)
    let { companyId } = useParams();

    // Get values
    const [fetchedDevices, setFetchedDevices] = useState(false);
    const [originalCompanyDevices, setOriginalCompanyDevices] = useState([]);
    const [alteredCompanyDevices, setAlteredCompanyDevices] = useState([]);

    // Filtering devices
    const [filtering, ] = useState(null);

    // Sorting devices
    const [sorting, setSorting] = useState(null);
    const [isDistributedPage, ] = useState(true);

    // For pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const pageSize = 500;
    const currentYear = new Date().getFullYear();
    const [year, setYear] = useState(currentYear);
    const [yearsForDistribution, setYearsForDistribution] = useState([]);

    // Handle filtering and sorting
    useEffect(() => {
        setAlteredCompanyDevices(filterAndSortDevices(originalCompanyDevices, filtering, sorting, isDistributedPage))
    }, [sorting])

    // Fetch categories and manufacturers
    useEffect(() => {
        let mounted = true;

        const fetchCompanyDevices = async() => {
            // Devices were fetched
            setFetchedDevices(false)
            
            const result = await axios(CompanyApi(`${companyId}/Devices/Distributed?page=${currentPage}&year=${year}&pageSize=${pageSize}`));

            setCurrentPage(result.data.currentPage)
            setPageCount(result.data.pageCount)

            // Set lists with devices
            setOriginalCompanyDevices(result.data.distributorDevices)
            setAlteredCompanyDevices(result.data.distributorDevices)

            // Devices were fetched
            setFetchedDevices(true)
        }

        // Fetch data
        if(mounted){
            fetchCompanyDevices();
        }

        return () => mounted = false;
    }, [currentPage, year]);

    // Select icons
    const iconStyle = {
        color: 'black',
        fontSize: '1.75rem'
    }

    const headerRow = (
        <div style = {{ paddingTop: '5px' }}>
            <div className = "text-center">
                <h1>Értékesítés  Eszközök</h1>
            </div>
            <div className = "d-flex justify-content-between mx-5 mt-3 px-3">
                <SelectWithIcon 
                    icons = {<FaFilter style={iconStyle} />}
                    label = "Szűrés"
                    select = {
                        <YearFilterSelect
                            companyId = {companyId}
                            handleYearChange = {newYear => setYear(newYear)}
                            setYearsForDistribution = {yearsArray => setYearsForDistribution(yearsArray)}
                        />
                    }
                />
                <SelectWithIcon 
                    icons = {<FaSortAmountDown style={iconStyle} />}
                    label = "Rendezés"
                    select = {
                        <SortSelect
                            setSorting = {(sorting) => setSorting(sorting)}
                        />
                    }
                />
            </div>
        </div>
    )

    const isPlural = alteredCompanyDevices.length > 1 ? "ök" : ""
    const title = "A kiválasztott értékesített eszköz"  + isPlural;

    let centralColumn = (
        <DistributedDeviceGrid
            title = {title}
            fetchedDevices = {fetchedDevices}
            years = {yearsForDistribution}
            filteredDevices = {alteredCompanyDevices}
            pageCount = {pageCount}
            currentPage = {currentPage}
            setCurrentPage = {newPageNumber => setCurrentPage(newPageNumber)}
        />
    )

    return (
        <SingleColumnPageTemplate
            headerRow = {headerRow}
            centralColumn = {centralColumn}
        />
    );
}

export default DistributedDevicesPage;
