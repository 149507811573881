import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';

import { connect } from 'react-redux';

import Button from 'react-bootstrap/Button';

import BOMApi from '../../Api/BOMApi';

import BOMItemQuantityEditor from './BOMItemQuantityEditor';

import '../../App.scss';
import { Link } from 'react-router-dom';


function DevicePageBOMHandler(props) {

    const [bomInfo, setBomInfo] = useState({ isAnyExists: false, count: null })
    const [apiResponse, setApiResponse] = useState(null)
    const [itemOnBom, setItemOnBom] = useState("")

    const fetchBomItemNumberData = async () => {
      try {
        const apiResponse = await axios(BOMApi(`${props.bomId}/ItemNumber`), { params: { deviceId: props.deviceId }})
        setBomInfo(apiResponse.data)
      }
      catch (error) {
        // BOM not found
        console.warn('Error during fetchBomItemNumberData process', error.response.status)
      }
      
    }

    const deleteItemFromActiveBom = async () => {
        const axiosParameters = {
            url: BOMApi(`${props.bomId}/Items/Device/${props.deviceId}`),
            method: 'delete'
        }
        const apiResponse = await axios(axiosParameters);
        setApiResponse(apiResponse)
    }
    
    useEffect(() => {
      if(props.bomId !== '') {
        fetchBomItemNumberData()
      }
    }, [props.bomId, props.deviceId, apiResponse, itemOnBom])

    const addItemToActiveBom = async (deviceId) => {
        const axiosParameters = {
            url: BOMApi(`${props.bomId}/Item`),
            method: 'post',
            data: {
                deviceId: deviceId,
                itemNumber: 1
            },
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }
        const apiResponse = await axios(axiosParameters);
        setItemOnBom(apiResponse.data.result)
    }

    let bomHandling
    let bomPath
    if(props.user === "") {
      bomHandling = <span>A BOM kezeléséhez belépés szükséges!</span>
    }
    else { // There is a logged in user 
      if(props.bomId === "") {
        bomHandling = <span>Válassz aktív BOM-ot!</span>
      }
      else { // There is an active BOM
        bomPath = (
          <div>
            <Link to = {`/user/companies`}>{props.companyName}</Link>
            {' / '}
            <Link to = {`/company/${props.companyId}/projects`}>{props.projectName}</Link>
            {' / '}
            <Link to = {`/company/${props.companyId}/projects/${props.projectId}`}>{props.bomName}</Link>
          </div>
        )
        if(bomInfo.isAnyExists === false && bomInfo.count === null) {
            bomHandling = <Button variant = "primary" onClick = {() => addItemToActiveBom(props.deviceId)}>Eszköz hozzáadása</Button>
        }
        else { // Item is already on BOM
            bomHandling = <BOMItemQuantityEditor quantity = {bomInfo.count} handleDelete = {deleteItemFromActiveBom} onlyValue = {true} />
        }
      }
    }

  return (
    <div 
      className = "d-flex justify-content-center flex-column p-3 mb-3"
      style={{ backgroundColor: 'white'}}
    >
        <Row className="m-1 d-flex justify-content-center">
            <h4>BOM</h4>
        </Row>
        <Row className="m-1 d-flex justify-content-center">
            {bomPath}
        </Row>
        <Row className="m-1 d-flex justify-content-center">
          {bomHandling}
        </Row>
      </div>
  );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
    const { user } = state.auth;
    const { companyId, companyName } = state.company;
    const { projectId, projectName } = state.project;
    const { bomId, bomName } = state.bom;
    return {
      user, companyId, companyName, projectId, projectName, bomId, bomName
    };
  };
  
export default connect(mapStateToProps, null)(DevicePageBOMHandler);