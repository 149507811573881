// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select'
// import react-bootstrap
import Button from 'react-bootstrap/Button';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// Imports for APIs
import PropertyApi from '../../Api/PropertyApi';
import EnumApi from '../../Api/EnumApi';
// Imports of our custom components
import { isInteger, isDouble } from "./utils/translateValueType"
// Imports of styles
import '../../App.css';
import './Common.css';
import AddPropertyInputComponent from '../Property/AddPropertyInputComponent';
// Imports of contants
// - none

export default function DynamicInputItem(props) {

    const { property, creatingNewEntity, addProperty, canDeleteItem, deleteProperty, deleteItem } = props

    // Can show datas
    const [valuesLoaded, setValuesLoaded] = useState(false)
    const [valueFormatWasCorrect, setValueFormatWasCorrect] = useState(true)

    // Select options
    const [selectOptions, setSelectOptions] = useState([])
    const [selectedOption, setSelectedOption] = useState('')

    // Value in input field
    const [newValue, setNewValue] = useState('')

    useEffect(() => {
        // Fetch values
        const fetchValues = async () => {
            try {
                let response
                // If Type is Enum the method is different
                if (property.contentType === 'Enum') {
                    const result = await axios(EnumApi('Property/', property.id));
                    // Map response for select (either enum or normalValue)
                    response = result.data.values.map(value => {
                        return { value: value.id, label: value.name }
                    })

                } else {
                    const result = await axios(PropertyApi('Values', property.id));
                    // Map response for select (either enum or normalValue)
                    response = result.data.values.map(value => {
                        return { value: value, label: value }
                    })
                }

                setSelectOptions(response)
                setValuesLoaded(true)
            } catch (error) {
                console.warn('Error during dynamic input item property value fetching process. Name: ', property.name)
            }
        }

        // Fetch data
        fetchValues();
    }, [])

    // Give back selected property(ies)
    const addLocalProperty = event => {
        let value
        if (event.value !== undefined) {
            value = event.value
            setSelectedOption(event)
        }
        else {
            value = event.target.value
            setNewValue(value)
        }

        let formattedProperty;
        switch (property.contentType) {
            case "Date": {
                formattedProperty = {
                    "propertyId": `${property.id}`,
                    "value": `${parseInt((new Date(value).getTime() / 1000).toFixed(0))}`
                }
                break;
            }
            case "Time": {
                formattedProperty = {
                    "propertyId": `${property.id}`,
                    "value": `${parseInt((new Date(`01 Jan 1970 ${value} GMT+0100`).getTime() / 1000).toFixed(0))}`
                }
                break;
            }
            case "DateTime": {
                formattedProperty = {
                    "propertyId": `${property.id}`,
                    "value": `${parseInt((new Date(value).getTime() / 1000).toFixed(0))}`
                }
                break;
            }
            default: {
                formattedProperty = {
                    "propertyId": `${property.id}`,
                    "value": `${value}`
                }
            }
        }

        // Add prop with value to detailed search
        addProperty(formattedProperty);
    }

    // For creatable, we should check if the value's format is correct
    const addLocalPropertyFromCreatable = event => {
        let correctFormat = false
        let valueObject = { label: event.value, value: event.value }

        if (property.valueType === "Int") {
            correctFormat = isInteger(valueObject.value)
        }
        else if (property.valueType === "Double") {
            // Check if value has comma instead of dot and replace it
            const correctDoubleFormat = valueObject.value.includes('.') ? valueObject.value.replace('.', ',') : valueObject.value
            valueObject = { label: correctDoubleFormat, value: correctDoubleFormat }
            correctFormat = isDouble(correctDoubleFormat)
        }
        else {
            // It is string
            correctFormat = true
        }

        if (correctFormat) {
            setValueFormatWasCorrect(true)
            setSelectedOption(valueObject)

            const formattedProperty = {
                propertyId: `${property.id}`,
                value: `${valueObject.value}`
            }

            // Add prop with value to detailed search
            addProperty(formattedProperty);
        }
        else {
            // Show that the value was in incorrect form
            setValueFormatWasCorrect(false)
        }
    }

    // Delete current property
    const deleteLocalItem = () => {
        const value = newValue !== undefined ? newValue : selectedOption.value;

        // Add new property
        const formatedProperty = {
            propertyId: `${property.id}`,
            values: [`${value}`]
        }
        deleteProperty(formatedProperty)

        // Delete item from array
        deleteItem(property);
    }

    // If the property is a link or new value can be added, but previous values are not needed 
    const useTextInput = creatingNewEntity || property.contentType === "Link"
    const isReference = property.contentType === 'Enum' || property.contentType === 'FileReference' ||
        property.contentType === 'DeviceReference' || property.contentType === 'UserReference'

    return (valuesLoaded &&
        <tr key={property.id}>
            <td>{property.name}</td>
            <td style={{ paddingRight: 0, minWidth: 225 }}>
                {useTextInput ? (
                    <AddPropertyInputComponent
                        isEnum={property.contentType === "Enum"}
                        contentType={property.contentType}
                        options={selectOptions}
                        value={selectedOption}
                        onChange={addLocalProperty}
                        placeholder="Érték kiválasztása"
                    />
                ) : (
                    (isReference ? (
                        <Select
                            options={selectOptions}
                            onChange={addLocalProperty}
                            placeholder="Értékkészlet"
                            value={selectedOption}
                        />
                    ) : (
                        <>
                            <AddPropertyInputComponent
                                isEnum={property.contentType === "Enum"}
                                contentType={property.contentType}
                                options={selectOptions}
                                value={selectedOption}
                                onChange={addLocalPropertyFromCreatable}
                                placeholder="Érték kiválasztása"
                            />
                            {!valueFormatWasCorrect &&
                                <span className="font-weight-bold text-center">
                                    Hibás volt a megadott formátum!
                                </span>
                            }
                        </>
                    )
                    )
                )
                }
            </td>
            <td style={{ paddingLeft: 0 }}>
                <div className="d-flex justify-content-center min-height-value unit">
                    {(property.measurementUnit === "Unknown" || property.measurementUnit === "NotApplicable") ?
                        '' : property.measurementUnit
                    }
                </div>
                {!valueFormatWasCorrect &&
                    <div className="mt-4" />
                }
            </td>
            <td>
                {canDeleteItem &&
                    <OverlayTrigger
                        key="delete-item-top"
                        placement="top"
                        overlay={
                            <Tooltip id="tooltip-delete-item">
                                Tulajdonság törlése
                            </Tooltip>
                        }
                    >
                        <Button
                            className='min-height-value w-100'
                            onClick={() => deleteLocalItem()}
                            variant='danger'
                        >
                            Törlés
                        </Button>
                    </OverlayTrigger>
                }
            </td>
        </tr>
    );
}
