// Imports from packages
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { FaTrashAlt } from 'react-icons/fa';
// react-bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/esm/Spinner';
// @material-ui
// - none
// Imports for APIs
import EnumApi from '../../../Api/EnumApi';
import PropertyApi from '../../../Api/PropertyApi';
// Imports of our custom components 
import RestrictedRangeSlider from '../../CommonComponents/RestrictedRangeSlider'
import DetailedSearchSelect from './DetailedSearchSelect';
import DetailedSearchSwitch from './DetailedSearchSwitch';
// Imports of styles
import './Detailed.css';
// Imports of constants
// - none

function PropertyItem(props) {
    // Destructuring assignment from the props
    const { property, isMandatory, setPropertyFilters, deletePropFromFilterArray, deletePropUnit } = props

    // Categories
    const [fetchedValues, setFetchedValues] = useState(false)
    const [showSelectableValues, setShowSelectableValues] = useState(true)
    const [isEnum, setIsEnum] = useState(false)

    // Slider
    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(0)
    const [sliderRange, setSliderRange] = useState(0)

    // Selects
    const [selectEnumOption, setSelectEnumOption] = useState('')
    const [selectedEnumOption, setSelectedEnumOption] = useState()
    const [selectPropertyOption, setSelectPropertyOption] = useState([])
    const [selectedPropertyOption, setSelectedPropertyOption] = useState([])

    const isNumber = (property.valueType === "Int" || property.valueType === "Double") ? true : false

    // Get all values for property
    useEffect(() => {
        const setAllValues = async () => {
            const isPropEnum = property.contentType === "Enum" ? true : false
            setIsEnum(isPropEnum)

            if (isPropEnum) {
                // Property is Enum, endpoint call need EnumApi
                try {
                    const result = await axios(EnumApi("Property", property.id));
                    if (result.status === 200) {
                        let formatedResult = []

                        result.data.values.forEach(enumValue => {
                            formatedResult.push({ value: enumValue.id, label: enumValue.name })
                        })

                        setSelectEnumOption(formatedResult)
                        setFetchedValues(true)
                    }
                } catch (error) {
                    console.error(`Error during enum value fetch`, error)
                }
            }
            else {
                // Property is not Enum, endpoint call need PropertyApi
                try {
                    const result = await axios(PropertyApi("Values", property.id));
                    if (result.status === 200) {
                        let formatedResult = []

                        result.data.values.forEach(propertyValue => {
                            formatedResult.push({ value: propertyValue, label: propertyValue })
                        })

                        // If values are number, sort them in ascending order
                        if (isNumber) {
                            formatedResult.sort(function (a, b) {
                                return a.value - b.value;
                            })
                        }

                        // Set minimum and maximum values
                        const numberOfData = formatedResult.length
                        if (numberOfData > 0) {
                            setMinValue(formatedResult[0].label)
                            setMaxValue(formatedResult[numberOfData - 1].label)
                        }

                        setSelectPropertyOption(formatedResult)
                        setFetchedValues(true)
                    }
                } catch (error) {
                    console.error(`Error during property value fetch`, error)
                }
            }
        }

        if (property.id !== undefined) {
            setAllValues()
        }
    }, [])

    // Add or update this item in propertyFilter list
    useEffect(() => {
        const giveBackCompletePropertyFilter = () => {
            let values, metaDataFilters
            // Check if property is enum
            if (isEnum) {
                // Value variable holds the enumvalue's Id
                values = selectedEnumOption.map(enumValue => enumValue.value)
                metaDataFilters = []
            }
            else {
                // If not, then do we check if it is a number or not
                if (!isNumber) {
                    values = selectedPropertyOption.map(enumValue => enumValue.value)
                    metaDataFilters = []
                }
                else {
                    // It is a number type, lastly check, if we choose from multiple values or interval
                    if (showSelectableValues) {
                        values = selectedPropertyOption.map(enumValue => enumValue.value)
                        metaDataFilters = []
                    }
                    else {
                        // Interval == Slider
                        values = []
                        metaDataFilters = [
                            {
                                "filterType": "Gte",
                                "value": `${sliderRange[0]}`
                            },
                            {
                                "filterType": "Lte",
                                "value": `${sliderRange[1]}`
                            }
                        ]
                    }
                }
            }

            // Give back value to DetailedSearch
            const propFilter = {
                "propertyId": `${property.id}`,
                "values": values,
                "metaDataFilters": metaDataFilters
            }
            setPropertyFilters(propFilter)
        }

        const enumValueIsValid = selectedEnumOption !== '' && selectedEnumOption !== undefined
        const propertyValueIsValid = selectedPropertyOption.length !== 0

        if (selectedEnumOption === '' || selectedPropertyOption.length !== 0) {
            deletePropFromFilterArray(property.id)
        }
        if (enumValueIsValid || propertyValueIsValid || sliderRange !== 0) {
            giveBackCompletePropertyFilter()
        }
    }, [selectedEnumOption, selectedPropertyOption, sliderRange])

    // Delete previous value, if switch was toggled
    useEffect(() => {
        deletePropFromFilterArray(property.id)
    }, [showSelectableValues])

    const propertyValueSelect = (
        <DetailedSearchSelect
            propertyId={property.id}
            selectOption={selectPropertyOption}
            setSelectedOption={setSelectedPropertyOption}
            measurementUnit={property.measurementUnit}
            deletePropFromFilterArray={deletePropFromFilterArray}
        />
    )

    // If prop is Number show switch, and select or slider
    // Else show select
    // - if there is no value from which the user can select, user can add
    const numberContent = (
        isNumber ? (
            (selectPropertyOption.length === 0 && selectEnumOption.length === 0) ? (
                <div style={{ color: "red" }}>Nincs érték az adatbázisban!</div>
            ) : (
                <DetailedSearchSwitch
                    showSelectableValues={showSelectableValues}
                    setShowSelectableValues={newValue => setShowSelectableValues(newValue)}
                    leftLabel="Intervallum"
                    rightLabel="Érték"
                />
            )
        ) : (
            propertyValueSelect
        )
    )

    const propertyOrEnumContent = (
        isEnum ? (
            <DetailedSearchSelect
                propertyId={property.id}
                selectOption={selectEnumOption}
                setSelectedOption={setSelectedEnumOption}
                measurementUnit={property.measurementUnit}
                deletePropFromFilterArray={deletePropFromFilterArray}
            />
        ) : (
            numberContent
        )
    )

    const justValueFormat = array => {
        // Label holds one of values of the property 
        const formatedArray = array.map(value => value.label)
        return formatedArray
    }

    // If property is not an enum, but valueType is Int or Double show Select or Slider depending on valueType
    // - if there is no value from which the user can select show it
    const valuePicker = (
        (!isEnum && isNumber) && (
            showSelectableValues ? (
                (selectPropertyOption.length === 0 && selectEnumOption.length === 0) ? (
                    null
                ) : (
                    propertyValueSelect
                )
            ) : (
                <RestrictedRangeSlider
                    minValue={minValue}
                    maxValue={maxValue}
                    allValues={justValueFormat(selectPropertyOption)}
                    measurementUnit={property.measurementUnit}
                    setSliderRange={setSliderRange}
                />
            )
        )
    )

    return (
        <Container fluid key={property.id}>
            <Row className="d-flex justify-content-start mr-3 align-items-center" style={{ minHeight: 47 }}>
                <Col sm={3}>
                    <b>{property.name}</b>
                </Col>
                {fetchedValues ? (
                    <>
                        <Col sm={4}>
                            {propertyOrEnumContent}
                        </Col>
                        <Col sm={4} className="pt-1">
                            {valuePicker}
                        </Col>
                        {!isMandatory &&
                            <Col sm={1}>
                                <FaTrashAlt className="trash-icon mb-1" onClick={() => deletePropUnit(property.id)} />
                            </Col>
                        }
                    </>
                ) : (
                    <Col sm={9} className="d-flex justify-content-center align-items-center">
                        <Spinner animation="border" variant="info" />
                    </Col>
                )
                }
            </Row>
        </Container>
    );
}

export default PropertyItem;
