import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import CustomModal from './CustomModal';
import '../../App.scss';
import { Alert } from 'react-bootstrap';

function EditCompanyBasicProfileModal(props) {

    const { handleEditBasicProfile, profile, error } = props;

    const [shortName, setShortName] = useState(profile.shortName || "");
    const [longName, setLongName] = useState(profile.name || "");

    const body = (
        <div>
            <Form>
                <Form.Label>Rövid név</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Rövid név"
                    value={shortName}
                    onChange={event => setShortName(event.target.value)}
                />
                <Form.Label>Hosszú név</Form.Label>
                <Form.Control
                    type="text"
                    placeholder="Hosszú név"
                    value={longName}
                    onChange={event => setLongName(event.target.value)}
                />
            </Form>
        </div>
    );

    const footer = (
        <>
            {error && (
                <div className="w-100 text-center">
                    <Alert variant="warning" className="mb-4 mt-4 text-center mx-4">Hiba történt! Kérlek, próbáld újra!<br /><i>{error}</i></Alert>
                </div>
            )}
            <Button
                variant="success"
                onClick={() => handleEditBasicProfile(shortName, longName)}
            >
                Módosítás
            </Button>
            <Button
                variant="danger"
                onClick={() => props.handleClose()}
            >
                Mégse
            </Button>
        </>
    );

    return (
        <>
            <CustomModal
                show={props.show}
                size="md"
                dropback="static"
                keyboard={false}
                handleClose={props.handleClose}
                title={<h3>Profil tulajdonságok módosítása</h3>}
                body={body}
                footer={footer}
            />
        </>
    );
}

export default EditCompanyBasicProfileModal;
