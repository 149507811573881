import React from 'react';
import Select from 'react-select';

export const GenericSelector = (props) => {
    const { placeholder, selected, setSelected, options } = props;
    return (
        <Select
            options={options}
            placeholder={placeholder}
            value={selected}
            onChange={(selected) => setSelected(selected)} />
    );
};
