// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// Imports for APIs
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import BoxListGroup from './BoxListGroup';
import BoxListGroupItem from './BoxListGroupItem';
import makeFlatArrayfromObject from './utils/makeFlatArrayfromObject';
import DeviceProperties from '../Device/DeviceProperties';
// Imports of styles
import '../../App.css';
// Imports of contants
// - none

export default function LinkBox(props) {
    // Destructuring assignment from redux props
    const { links, entityName, editMode, postType, deviceId, refresh, isOwner, acceptReview, declineReview, isAdministrator } = props

    // Own variables
    const [formatedLinks, setFormatedLinks] = useState([]);
    // Global disable for review "buttons"
    const [disableReviewButtons, setDisableReviewButtons] = useState(false);

    // When we have links object, put all 'sub' objects inside one array
    useEffect(() => {
        const modifyLinkArray = async () => {
            const result = await axios(PropertyApi(`List?propertyType=${postType}&typeOf=Link`));
            const linkTypeArray = {
                values: result.data,
                type: "link"
            };

            let allLinksArray = makeFlatArrayfromObject(links, linkTypeArray)
            setFormatedLinks(allLinksArray)
        }

        if (links.length !== 0) {
            modifyLinkArray();
        }
    }, [links])

    // Variable 'categoryName' responsible to hold category names
    let categoryName
    const mappedLinks = formatedLinks.map((link, index) => {
        let content, linkValues
        if (link.id !== '') {
            categoryName = link.id;
            content = <b>{link.id}</b>;
        }
        else {
            content = link.value;
            linkValues = link.value.props.link;
        }

        return (
            <div key={`link-${linkValues ? linkValues.id : index}`} >
                <BoxListGroupItem
                    id={link.id}
                    content={content}
                />
                {editMode && link.id === '' &&
                    <div className="w-100 d-flex justify-content-center align-items-center mt-2 mb-3 text-left">
                        <DeviceProperties
                            id={linkValues.id}
                            value={linkValues.value}
                            name={categoryName}
                            measurementUnit={null}
                            disableReviewButtons={disableReviewButtons}
                            setDisableReviewButtons={newValue => setDisableReviewButtons(newValue)}
                            refresh={refresh}
                            isOwner={isOwner}
                            isAdministrator={isAdministrator}
                            deviceId={deviceId}
                            enumTypeId={null}
                            acceptReview={(devicePropertyReviewId) => acceptReview(devicePropertyReviewId)}
                            declineReview={(devicePropertyReviewId) => declineReview(devicePropertyReviewId)}
                        />
                    </div>
                }
            </div>
        )
    })

    return (
        <BoxListGroup
            title="Hivatkozások"
            items={mappedLinks}
            itemPluralName="linkek"
            entityName={entityName}
        />
    );
}
