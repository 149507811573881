// Imports from packages
import React from 'react';
// react-bootstrap
import ListGroup from 'react-bootstrap/ListGroup';
// Imports for APIs
// - none
// Imports of our custom components
// - none
// Imports of styles
// - none
// Imports of contants

export default function BoxListGroupItem(props) {
  // Destructuring assignment from redux props
  const { id, content } = props
  
  return (
    <ListGroup.Item 
      className = "d-flex justify-content-center align-items-center"
      style={{ 
        backgroundColor: `${id !== '' ? '#f7f7f7' : 'white'}`,
        fontSize: `${id !== '' ? '18px' : '16px'}`,
        color: `${id !== '' ? 'black' : 'hsl(181, 71%, 35%) !important'}`,
        height: 50
      }}
    >
      {content}
    </ListGroup.Item>
  )
}
