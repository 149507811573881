import React, { useState } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import CustomModal from './CustomModal';
import CustomAlert from '../CommonComponents/CustomAlert';

import BOMApi from '../../Api/BOMApi';

import '../../App.scss';

function AddNewBomModal(props) {

    // State variables
    const { projectId } = useParams();
    // Project properties
    const [, setIsBomNameChecked] = useState(false)
    const [, setIsBomNameAvailable] = useState(false)
    const [posting, setPosting] = useState(false)
    const [bomName, setBomName] = useState('')
    // Alert
    const [showNameIsInvalidAlert, setShowNameIsInvalidAlert] = useState(false)
    const [showSuccessfulBomCreationAlert, setShowSuccessfulBomCreationAlert] = useState(false)

    const fetchBomNameValidness = async() => {
        const parameters = {
            projectId: projectId,
            billOfMaterialName: bomName
        }
        const result = await axios(BOMApi(`Project/${projectId}/Check/Name`), { params: parameters });
        return result.data.result
    };

    const createNewBom = async () => {
        setPosting(true)

        // Choosing enpoint
        let endpoint = `Register/Project/${projectId}`

        const data = {
            "name": bomName,
        };

        // Create the parameters for axios
        const axiosParameters = {
            url: BOMApi(endpoint),
            method: 'post',
            data: data,
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json'
            }
        }

        // Call API endpoint with axios
        try {
            axios(axiosParameters)
            .then(response => {
                if(response.status === 200) {
                    // Show response from the BOM creation
                    setShowSuccessfulBomCreationAlert(true)
                    setTimeout(() => {
                        setShowSuccessfulBomCreationAlert(false)
                        // Close modal
                        setPosting(false)
                        handleClose();
                    }, 2000)
                }
            })
        }
        catch(error) {
            console.error("Error during project creation process", error)
            setPosting(false)
        }
    };
    
    const handleClose = () =>{
        // Delete every value before closed
        setIsBomNameChecked(false);
        setIsBomNameAvailable(false);
        setBomName('');
        // Close modals visibility
        props.handleClose();
    }

    const handleCreateNewBom = async() => {
        setShowNameIsInvalidAlert(false)
        const isNameAvaliable = await fetchBomNameValidness()
        setIsBomNameChecked(true);

        if(isNameAvaliable === true) {
            await createNewBom()
        }
        else {
            setShowNameIsInvalidAlert(true)
        }
    }

    const title = <h1>Új BOM hozzáadása</h1>

    const body = (
        <div>
            <Form>
                <Form.Group controlId="form-new-BOM">
                    <Form.Label>BOM neve</Form.Label>
                    <Form.Control
                        type = "text"
                        placeholder = "BOM neve"
                        value = {bomName}
                        onChange = {event => setBomName(event.target.value)}
                    />
                </Form.Group>
            </Form>
            {showNameIsInvalidAlert && 
                <CustomAlert
                    variant = "danger"
                    message = {"Ilyen nevű BOM már létezik ennél a projektnél. Válassz másikat!"}
                    width = "w-100"
                />
            }
            {showSuccessfulBomCreationAlert &&
                <CustomAlert
                    variant = "success"
                    message = {`Létrejött az új ${bomName} BOM.`}
                    width = "w-100"
                />
            }    
        </div>
    );

    const footer = (
        <>
            <Button
                variant="success"
                onClick={handleCreateNewBom}
                disabled = {posting}
            >
                Létrehozás
            </Button>
            <Button
                variant="danger"
                onClick={handleClose}
                disabled = {posting}
            >
                Mégse
            </Button>
        </>
    );

    return (
        <>
            <CustomModal 
                show={props.show} 
                size="md"
                dropback="static"
                keyboard={false}
                handleClose={handleClose}
                title={title}
                body={body} 
                footer={footer}
            />
        </>
    );
}

export default AddNewBomModal;
