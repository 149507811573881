import React from 'react';
import Select from 'react-select';
import { mapPropertiesForSelector } from './mapPropertiesForSelector';

export function PropertySelector(props) {
    // Input data from parent component
    const { value, propertyList, selectProperty } = props;
    // Component-scoded logic
    const mappedProperties = mapPropertiesForSelector(propertyList);
    const handleSelectChange = (selectedProperty) => selectProperty(selectedProperty);
    return (
        <Select
            value = {value}
            options={mappedProperties}
            onChange={selectedProperty => handleSelectChange(selectedProperty)}
            placeholder="Válassz egy propertyt!"
            isClearable={true}
            isSearchable={true}
        />
    );
}
