import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import "../../App.css";
import "../../App.scss";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Logo from "../../cropped-logo.png";
import axios from "axios";
import { Link } from "react-router-dom";

function ForgottenPassword() {
    const [emailAddress, setEmailAddress] = useState("");
    const [apiResponse, setApiResponse] = useState("");
    const postResetSend = async () => {
        try {
            await axios.post("Account/Reset/Send", {
                email: emailAddress
            })
        }
        catch (error) {
            setApiResponse(Object.entries(error)[2][1].statusText)
        }
    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        postResetSend();
    }
    return (
        <Container fluid>
            <Row
                className="justify-content-center align-items-center"
                style={{ minHeight: "100vh" }}
            >
                <Col className="col-12 text-center">
                    <img src={Logo} alt="HADocument logó" width="200px" />
                    {apiResponse.length > 0 && (
                        <div className="w-100 text-center">
                            <Alert variant="warning" className="mb-4 mt-4 text-center mx-4">Hiba történt! Kérlek, próbáld újra!<br /><i>{apiResponse}</i></Alert>
                        </div>
                    )}
                    <h2 className="mb-4 mt-4">Elfelejtett jelszó</h2>
                    <Form style={{ width: "400px" }} className="mx-auto text-center">
                        <Form.Group>
                            <Form.Control
                                type="text"
                                placeholder="Email cím"
                                value={emailAddress}
                                onChange={(event) => setEmailAddress(event.target.value)}
                            />
                        </Form.Group>
                    </Form>
                    <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex w-100 justify-content-center">
                            <Button
                                className="mt-3"
                                variant="primary"
                                onClick={(event) => handleFormSubmit(event)}
                                disabled={emailAddress.length === 0}
                            >
                                Elfelejtettem a jelszavamat!
                    </Button>
                        </div>
                        <div className="d-flex w-100 justify-content-center mt-4">
                            <Link to="/">Vissza a főoldalra!</Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default ForgottenPassword;
