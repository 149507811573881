// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// Imports of our custom components 
import Loader from '../../Components/CommonComponents/Loader';
// Imports of API-related files
import CategoryApi from '../../Api/CategoryApi';
import PropertyEditorAlert from './PropertyEditorAlert';
import { defaultApiResponse } from './utils';
import PropertyEditor from './PropertyEditor';
import { propertyTypes, typeOfs, filters, mandatoryFilters, valueTypes, contentTypes, measurementUnits } from './propertyTypes';
import { mapForGenericSelector } from './mapForGenericSelector';
import { mapPropertiesForSelector } from './mapPropertiesForSelector';
import { GenericSelector } from './GenericSelector';
import { PropertySelector } from './PropertySelector';

function AdminPropertyEditor() {
    const [isFetching, setIsFetching] = useState(true)
    const [enumListWithData, ] = useState([])
    const [selectedEnum, selectEnum] = useState(null)
    const [selectedPropertyWithData, selectPropertyWithData] = useState(null)
    const [isNewProperty, setNewProperty] = useState(false)
    const [, setDisabledCreateNewEnumButton] = useState(true)
    const [apiResponse, setApiResponse] = useState(defaultApiResponse)
    // Property selectors
    const [propertyType, setPropertyType] = useState(mapForGenericSelector(propertyTypes)[0])
    const [typeOf, setTypeOf] = useState(mapForGenericSelector(typeOfs)[4])
    const [filter, setFilter] = useState(mapForGenericSelector(filters)[0])
    const [mandatoryFilter, setMandatoryFilter] = useState(mapForGenericSelector(mandatoryFilters)[2])
    // Queried data from API
    const [propertyList, setPropertyList] = useState([])
    const [categories, setCategories] = useState([])
    const [enums, setEnums] = useState([])
    // Select property for editing
    const [selectedProperty, selectProperty] = useState(null)
    const [newPropertyType, setNewPropertyType] = useState(propertyType)

    const flattenCategories = (deepArray) => {
        // Flatten the array
        const flatArray = []
        deepArray.forEach(mainCategory => {
            // First, get all subcategory's name for each main category
            let subCategories = ''
            if (mainCategory.children.length > 0) {
                mainCategory.children.forEach(subCategory => {
                    subCategories += ' | ' + subCategory.name
                })
            }

            // Then, add main category first to array with all the subcategories in it's values
            flatArray.push({
                value: `${mainCategory.id + subCategories}`,
                label: mainCategory.name
            })

            // Lastly, the children with the parent's
            if (mainCategory.children.length > 0) {
                mainCategory.children.forEach(subCategory => {
                    flatArray.push({
                        value: `${subCategory.id} | ${mainCategory.name}`,
                        label: ` >>  ${subCategory.name}`
                    })
                })
            }
        })
        return flatArray
    }

    const fetchProperties = async () => {
        setIsFetching(true)
        try {
            const result = await axios(`/Property/List?propertyType=${propertyType.value}&typeOf=${typeOf.value}&filter=${filter.value}&mandatoryFilter=${mandatoryFilter.value}`);
            setPropertyList(result.data)
            setIsFetching(false)
        } catch (error) {
            console.warn('Error during fetching enums', Object.entries(error))
        }
    }

    const resetStates = () => {
        fetchProperties()
        selectEnum(null)
        selectProperty(null)
        selectPropertyWithData(null)
        setNewProperty(false)
        setDisabledCreateNewEnumButton(true)
        setApiResponse(defaultApiResponse)
    }

    useEffect(() => {
        if (apiResponse.status === 200) {
            setTimeout(() => resetStates(), 1500)
        }
    }, [apiResponse])

    useEffect(async () => {
        // Fetch data
        const results = await Promise.all([
            axios(CategoryApi('List')),
            axios(`/Enum/All/`)
        ])
        results.forEach(result => {
            if (result.config.url.includes('Category/List')) {
                setCategories(flattenCategories(result.data))
            }
            else if (result.config.url.includes('Enum/All')) {
                setEnums(mapPropertiesForSelector(result.data))
            }
        })
        setIsFetching(false)
    }, [])

    useEffect(() => {
        getSelectedEnumData(selectedEnum)
    }, [selectedEnum])

    const getSelectedEnumData = (selectedEnum) => {
        if (selectedEnum === null) {
            selectPropertyWithData(null)
            setNewProperty(false)
            return null
        }
        else {
            const filteredEnums = enumListWithData.filter(enumItem => enumItem.id === selectedEnum.value)
            if (filteredEnums.length === 1) {
                selectPropertyWithData(filteredEnums[0])
                setNewProperty(false)
            }
        }
    }

    const createNewPropertyLocally = () => {
        setNewProperty(true)
        selectPropertyWithData({
            name: "",
            category: "",
            valueType: null,
            contentType: null,
            enumTypeId: null,
            measurementUnit: null,
            isMandatory: false
        })
    }

    const saveUpdatedProperty = async () => {
        const propertyId = selectedPropertyWithData.id
        const updatedProperty = {
            ...selectedPropertyWithData,
            updateName: true,
            updateCategory: true,
            updateValueType: true,
            updateContentType: true,
            updateEnumTypeId: true,
            updateMeasurementUnit: true,
            updateIsMandatory: true
        }
        delete updatedProperty.id
        if(Object.prototype.hasOwnProperty.call(selectedPropertyWithData, 'enumTypeId') === false) {
            delete updatedProperty.updateEnumTypeId
        }
        else {
            if(selectedPropertyWithData.enumTypeId === null) {
                delete updatedProperty.enumTypeId
            }
        }
        try {
            const res = await axios.put(`/Property/${propertyId}`, updatedProperty)
            setApiResponse({
                status: res.status,
                type: 'propertyUpdatingSuccess',
                message: 'Property frissítve!'
            })
        }
        catch (error) {
            console.error(error);
            setApiResponse({
                status: error.request.status,
                type: 'propertyUpdatingFailure',
                message: 'Property frissítése nem sikerült!'
            })
        }
    }

    const sendNewPropertyToApi = async (data) => {
        try {
            const res = await axios.post(`/Property/Add?propertyType=${newPropertyType.value}`, data)
            setApiResponse({
                status: res.status,
                type: 'propertyCreationSuccess',
                message: 'Property létrehozva!'
            })
        }
        catch (error) {
            setApiResponse({
                status: error.request.status,
                type: 'propertyCreationFailure',
                message: 'Property létrehozása nem sikerült!'
            })
        }
    }

    const createPropertyEnum = () => {
        sendNewPropertyToApi(selectedPropertyWithData)
    }

    const enabledPropertyButton = propertyType && typeOf && filter && mandatoryFilter

    const getProperties = () => {
        fetchProperties()
    }

    const propertySelectorIsEnabled = enabledPropertyButton && propertyList.length > 0

    const selectPropertyFromSelect = async (selected) => {
        if (selected) {
            try {
                selectProperty(selected)
                const result = await axios(`/Property/${selected.value}/Values`);
                selectPropertyWithData(result.data.details)
            } catch (error) {
                console.warn('Error during fetching enums', Object.entries(error))
            }
        }
        else {
            selectPropertyWithData(null)
        }
    }

    return (
        <div>
            <h2 className="text-center">Propertyk szerkesztése</h2>
            <Container fluid>
                <Row style={{ justifyContent: 'center' }}>
                    <Col lg={2}>
                        <GenericSelector
                            placeholder="Property típusa"
                            selected={propertyType}
                            setSelected={setPropertyType}
                            options={mapForGenericSelector(propertyTypes)}
                        />
                    </Col>
                    <Col lg={2}>
                        <GenericSelector
                            placeholder="Typeof"
                            selected={typeOf}
                            setSelected={setTypeOf}
                            options={mapForGenericSelector(typeOfs)}
                        />
                    </Col>
                    <Col lg={2}>
                        <GenericSelector
                            placeholder="Filter"
                            selected={filter}
                            setSelected={setFilter}
                            options={mapForGenericSelector(filters)}
                        />
                    </Col>
                    <Col lg={2}>
                        <GenericSelector
                            placeholder="Mandatory filter"
                            selected={mandatoryFilter}
                            setSelected={setMandatoryFilter}
                            options={mapForGenericSelector(mandatoryFilters)}
                        />
                    </Col>
                    <Col lg={2}>
                        <Button
                            variant="primary"
                            onClick={() => getProperties()}
                            disabled={!enabledPropertyButton}
                        >
                            Propertyk lekérdezése
                        </Button>
                    </Col>
                </Row>
            </Container>
            {isFetching && <div className="text-center"><Loader /><p>Ez egy hosszú ideig tartó lekérdezés...</p></div>}
            {!isFetching && (
                <Container fluid>
                    <Row>
                        <Col lg={6} className="mt-3">
                            {propertySelectorIsEnabled && (
                                <React.Fragment>
                                    <PropertySelector
                                        propertyList={propertyList}
                                        selectProperty={(selected) => selectPropertyFromSelect(selected)}
                                        value={selectedProperty}
                                    />
                                    <div className="text-center m-2">
                                        <Button
                                            variant="primary"
                                            onClick={() => createNewPropertyLocally()}
                                            disabled={selectedPropertyWithData !== null}
                                        >
                                            Új property
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                        </Col>
                        {selectedPropertyWithData && (
                            <Col lg={6} className="mt-3">
                                <div>
                                    <Row>
                                        <Col lg={12}>
                                            <PropertyEditorAlert data={apiResponse} />
                                        </Col>
                                    </Row>
                                </div>
                                <Row>
                                    <Col lg={12} style={{ textAlign: 'center' }}>
                                        <h3>Adatok szerkesztése</h3>
                                        <PropertyEditor
                                            selectedProperty={selectedPropertyWithData}
                                            updateSelectedProperty={selectPropertyWithData}
                                            categories={categories}
                                            valueTypes={mapForGenericSelector(valueTypes)}
                                            contentTypes={mapForGenericSelector(contentTypes)}
                                            measurementUnits={mapForGenericSelector(measurementUnits)}
                                            enums={enums}
                                            newPropertyType={newPropertyType}
                                            setNewPropertyType={setNewPropertyType}
                                        />
                                        {isNewProperty ? (
                                            <Row>
                                                <Col lg={12} style={{ textAlign: 'center' }}>
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => createPropertyEnum()}
                                                    >
                                                        Property létrehozása
                                                </Button>
                                                </Col>
                                            </Row>
                                        ) : (
                                            <div>
                                                <div className="m-2">
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => saveUpdatedProperty()}
                                                    >
                                                        Property frissítése
                                                </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Container>
            )}
        </div>
    );
}

export default AdminPropertyEditor;
