export const translateValueType = valueType => {
    switch(valueType){
        case "Int": return "egész szám";
        case "Double": return "tört szám";
        case "String": return "szöveg";
        default: return valueType;
    }
}

export const isInteger = value => {
    const regex = /^[0-9\b]+$/;
    return value.match(regex) !== null;
}

export const isDouble = value => {
    const regex = /[0-9\b]*[,][0-9\b]+$/;
    return value.match(regex) !== null;
}