import React from 'react';

import Button from 'react-bootstrap/Button';

import CustomModal from './CustomModal';

import '../../App.scss';

function DeleteEmployeeModal(props) {
  
  const onDeleteUser = () => {
    props.handleDelete();    
    props.handleClose(false);
  }

  const title = 'Alkalmazott törlése';

  const modalBody = (
    <p style={{ fontSize: "120%", textAlign: "center" }}>
        Biztos törölni szeretné <b>{props.username}</b> nevű alkalmazottat a cég alkalmazotti listájából véglegesen?
    </p>
  );

  const footer = (
    <>
      <Button variant="danger" onClick={() => onDeleteUser()}>
        Igen
      </Button>
      <Button variant="primary" onClick={() => props.handleClose(false)}>
        Mégse
      </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show = {props.show}
        size = "md"
        dropback="static"
        keyboard={false}
        handleClose = {() => props.handleClose(false)}
        title = {title}
        body = {modalBody}
        footer = {footer}
      />
    </>
  );
}

export default DeleteEmployeeModal;
