import React from 'react';

function ImageInput(props) {
    // Destructuring assignment from the props
    const { setSelectedImage } = props

    return (
        <input
            id = "image-file"
            type = "file"
            accept = "image/*"
            onChange = {event => setSelectedImage(event.target.files[0])}
        /> 
    );
}

export default ImageInput;
