
// Imports from packages
import React, { useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import Select from 'react-select';
// react-bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import DeviceApi from '../../Api/DeviceApi';
// Imports of our custom components 
import CustomModal from './CustomModal';
import Asterisk from '../CommonComponents/Asterisk';
import AsteriskExplanation from '../CommonComponents/AsteriskExplanation';
// Imports of styles
import '../Device/Device.css';
// Imports of constants
// - none

function AddEmployeeModal(props) {
    // Destructuring assignment from the props
    const { show, deviceId, handleClose, updateList, years } = props

    // Values from redux
    const { companyName } = props

    // Form variables
    const [partNumber, setPartNumber] = useState("");
    const [price, setPrice] = useState("");
    const validInYears = years.map(year => {
        return { label: year, value: year}
    })
    const [selectedValidInYear, setSelectedValidInYear] = useState("");
    const [directLink, setDirectLink] = useState("");

    // Posting variables 
    const [posting, setPosting] = useState(false);
    const [showDistributorResponse, setShowDistributorResponseAlert] = useState(false);
    const [distributorResponse, setDistributorResponse] = useState();

    const timeout = 3500;

    const ResetValues = () => {
        setPartNumber("")
        setPrice("");
        setSelectedValidInYear("");
        setDirectLink("");
    }

    const closeModal = () => {
        // Reset all values
        ResetValues();

        // Call close callback
        handleClose();
    }

    // Add new employee to company
    const postDistributor = async () => {
        // Double click protection
        setPosting(true);

        // Price is recommended, so if it is empty then add 0
        let newPrice
        if(price === ""){
            newPrice = 0
        }
        else{
            newPrice = Math.round(Number(price))
        }
        
        const postData = {
            "partNumber": `${partNumber}`,
            "directLink": `${directLink}`,
            "price": newPrice,
            "validInYear": selectedValidInYear.value
        }
        
        // Create the parameters for axios
        const axiosParameters = {
            url: DeviceApi('Distributors', deviceId),
            method: 'post',
            data: postData,
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        // Call API endpoint with axios
        axios(axiosParameters)
        .then(response => {
        if(response.status === 200) {
            // Result holds the updated list
            updateList(response.data)

            setDistributorResponse(200)
            setShowDistributorResponseAlert(true)

            setTimeout(() => {
                setShowDistributorResponseAlert(false) 

                // Close DistributorModal
                closeModal();

                setPosting(false);
            }, timeout)
        }
        })
        .catch(error => {
            console.error(error);

            setDistributorResponse(error.response.status)
            setShowDistributorResponseAlert(true)

            setPosting(false);

            setTimeout(() => {
                setShowDistributorResponseAlert(false)
            }, timeout)
        })
    }

    // Modal parameters
    const title = "Disztribútorrá válás";

    const onChange = (event, type) => {
        switch(type){
            case "partNumber":
                setPartNumber(event.target.value);  
                break;
            case "price":
                if(event.target.value < 0){
                    setPrice(-1 * event.target.value);
                }
                else{
                    setPrice(event.target.value);
                }
                break;
            case "validInYear":
                setSelectedValidInYear(event)
                break;
            case "directLink":
                setDirectLink(event.target.value);
                break;
            default:
                console.error("Unknown type during distributor onChange. Type: " + type)
        }
    }

    let distributorAlertContent 
    if(distributorResponse === 200) 
        distributorAlertContent = "Sikeres művelet"
    else{
        if(distributorResponse === 409)
            distributorAlertContent = `Ennél a terméknél már disztribútor a kiválasztott forgalmazási évben a(z) `
        else
            distributorAlertContent = "Sikertelen művelet"
    }

    const body = (
        <>
            <Form>
                <Form.Text className = "mb-3">
                    <AsteriskExplanation />
                </Form.Text>
                <Form.Group controlId="Distributor-partNumber">
                    <Form.Label><b>Rendelési cikkszám <Asterisk/></b></Form.Label>
                    <Form.Control
                        type="text"
                        value = {partNumber}
                        placeholder = "Rendelési cikkszám hozzáadása"
                        onChange={event => onChange(event, "partNumber")}
                    />
                </Form.Group>
                <Form.Group controlId="Distributor-price">
                    <Form.Label><b>Egységár</b></Form.Label>
                    <InputGroup>
                        <Form.Control
                            type="number"
                            min = "0"
                            value = {price} 
                            placeholder = "Egységár hozzáadása"
                            onChange={event => onChange(event, "price")}
                        />
                        <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">€</InputGroup.Text>
                        </InputGroup.Append>
                    </InputGroup>
                    <Form.Text className="text-muted">
                        Az oldalon sehol sem jelenik meg ez az információ.
                    </Form.Text>
                    <Form.Text className="text-muted">
                        Az ár egész értékre kerekítődik.
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="Distributor-validInYear">
                    <Form.Label><b>Forgalmazási év <Asterisk/></b></Form.Label>
                    <Select 
                        options = {validInYears}
                        onChange = {selected => onChange(selected, "validInYear")}
                        placeholder = "Forgalmazási év választás"
                        value = {selectedValidInYear}
                    />
                    <Form.Text className="text-muted">
                        Egy évben csak egyszer szerepelhet egy cég
                    </Form.Text>
                </Form.Group>
                <Form.Group controlId="Distributor-companyName">
                    <Form.Label><b>Disztribútor neve</b></Form.Label>
                    <Form.Control
                        type="text"
                        value = {companyName}
                        disabled
                    />
                </Form.Group>
                <Form.Group controlId="Distributor-directLink">
                    <Form.Label><b>Disztribútori link <Asterisk/></b></Form.Label>
                    <Form.Control
                        type="text"
                        value = {directLink}
                        placeholder = "Link hozzáadása"
                        onChange={event => onChange(event, "directLink")}
                    />
                </Form.Group>
            </Form>
            {showDistributorResponse && (
                <Alert variant = {distributorResponse === 200 ? "success" : "danger"} className = "text-center">
                    {distributorAlertContent}
                    { distributorResponse === 409 && (<b>{companyName}</b>)}
                </Alert>
            )}
        </>
    );
    
    const disableSave =  partNumber === "" || directLink === "" || selectedValidInYear === "";
    const footer = (
        <>
            <Button
                variant="success"
                onClick={postDistributor}
                disabled={posting || disableSave}
            >
                Felvétel
            </Button>
            <Button
                variant="danger"
                onClick={closeModal}
                disabled={posting}
            >
                Elvetés
            </Button>
        </>
    );

    return (
        <CustomModal
            show={show}
            size="md"
            dropback="static"
            keyboard={false}
            handleClose={closeModal}
            title={title}
            body={body}
            footer={footer}
        />
    );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
    const { companyId, companyName } = state.company;
    return {
      companyId, companyName
    };
  };

export default connect(mapStateToProps)(AddEmployeeModal);