function CompanyApi(endpoint, id, method) {

    
    
    let isExceptional
    let imageResolution

    // Return URL for uploading company picture
    if(endpoint.includes('Picture') && id && method === 'POST') {
        return `/Company/${id}/${endpoint}`
    }
    
    if(endpoint.includes('Picture')) {
        isExceptional = true
        imageResolution = endpoint.includes('Thumbnail') ? 50 : 400
    }

    if(isExceptional && method === 'GET') {
        return `/Company/${endpoint}?width=${imageResolution}&height=${imageResolution}`
    }
    else {
        return `/Company/${endpoint}`
    }
}

export default CompanyApi;
