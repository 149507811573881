// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
// Imports for APIs
import DeviceApi from '../Api/DeviceApi'
// Imports of our custom components
import ThreeColumnPageTemplate from './Pages/ThreeColumnPageTemplate';
// Imports of styles
import '../App.css';
// Imports of contants
import { allPagesForTest } from '../constants/allPagesForTest';

function AllPages(props) {

    const { user, companyId, companyName } = props
    const [deviceId, setDeviceId] = useState('');
    const [deviceItemNumber, setDeviceItemNumber] = useState('');

    useEffect(() => {
        // Fetch deviceId
        const fetchDeviceId = async () => {
            try {
                const maxNumber = 10;
                const result = await axios(DeviceApi(`List/Newest?number=${maxNumber}`));
                if (result.status === 200) {
                    const rand = Math.floor(Math.random() * Math.floor(maxNumber));
                    setDeviceId(result.data.devices[rand].id)
                    setDeviceItemNumber(result.data.devices[rand].itemNumber)
                }
            }
            catch (error) {
                console.error("Error during Pages device ID fetch process", error)
            }
        }

        // Fetch data
        fetchDeviceId();

        // To avoid unmounted warning
        return () => { setDeviceId(''); setDeviceItemNumber('') }
    }, [])



    const centralColumn = (
        <div id = "all-pages-for-test">
            <div className="text-center">
                <h1>Elérhető oldalak listája</h1>
            </div>
            <div>
                {allPagesForTest.map((category, categoryIndex) => {
                    return (
                        <div key={`category-${categoryIndex}`}>
                            <h2>{category.category}</h2>
                            <ul>
                                {category.pages.map((page, pageIndex) => {
                                    let { path, label } = page
                                    // Update path in unique cases
                                    if(Object.prototype.hasOwnProperty.call(page, "parameter")) {
                                        if(page.parameter === 'deviceId') {
                                            path = path + deviceId
                                        }
                                        else if(page.parameter === 'companyId' && label.includes("profilja")) {
                                            path = `${path}${companyId}`
                                        }
                                        else if(page.parameter === 'companyId' && label.includes("projektjei")) {
                                            path = `${path}${companyId}/projects`
                                        }
                                        else if(page.parameter === 'companyId' && label.includes("eszközei")) {
                                            path = `${path}${companyId}/owned`
                                        }
                                        else if(page.parameter === 'companyId' && label.includes("disztribúciói")) {
                                            path = `${path}${companyId}/distributed`
                                        }
                                    } 
                                    // Update labels in unique cases
                                    if(path === '/user' || path === '/user/companies') {
                                        const badge = user === '' ? <Badge variant="danger">Belépés szükséges!</Badge> : <span>(Belépett felhasználó: <b>{user}</b>)</span>
                                        return (
                                            <li key={`page-${pageIndex}`}>
                                                <Link to={path}>{label}</Link> {badge}
                                            </li>
                                        )
                                    }
                                    else if(label === 'Cég profilja' || label === 'Cég projektjei' || label === 'Cég eszközei' || label === 'Cég disztribúciói') {
                                        const badge = companyId === '' ? <Badge variant="danger">Belépés szükséges!</Badge> : <span>(Jelenlegi cég: <b>{companyName}</b>)</span>
                                        return (
                                            <li key={`page-${pageIndex}`}>
                                                <Link to={path}>{label}</Link> {badge}
                                            </li>
                                        )
                                    }
                                    else if(path.includes('/device/')) {
                                        return (
                                            <li key={`page-${pageIndex}`}>
                                                <Link to={path}>{label}</Link> (véletlenszerűen: {deviceItemNumber})
                                            </li>
                                        )
                                    }
                                    else if(path.includes('/bom-list/')) {
                                        return (
                                            <li key={`page-${pageIndex}`}>
                                                <Link to={path}>{label}</Link> <Badge variant="warning">WIP</Badge>
                                            </li>
                                        )
                                    }
                                    // None of the ifs above
                                    return (
                                        <li key={`page-${pageIndex}`}>
                                            <Link to={path}>{label}</Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    )
                })}
            </div>
        </div>
    )

    return (
        <ThreeColumnPageTemplate
            centralColumn={centralColumn}
        />
    );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
    const { user } = state.auth;
    const { companyId, companyName } = state.company;
    return {
      user, companyId, companyName
    };
  };

export default connect(mapStateToProps)(AllPages);