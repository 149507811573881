// Imports from packages
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
// react-bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';
// Imports for APIs
import DeviceApi from '../../../Api/DeviceApi';
import CategoryApi from '../../../Api/CategoryApi';
// Imports of our custom components
import ThreeColumnPageTemplate from '../../Pages/ThreeColumnPageTemplate';
import ImageInput from '../../CommonComponents/ImageInput';
import DynamicSelect from '../../CommonComponents/DynamicSelect';
import CustomToast from '../../CommonComponents/CustomToast';
import PropertyTitle from './PropertyTitle'
// Imports of styles
import '../../../App.css';
// Imports of contants
import { propertyType } from '../../../constants/propertyType';
import { HADColors } from '../../../constants/color'
import useNavigation from '../../../hooks/useNavigation';

function NewDevicePage() {

    // SetTimeout's delay
    const delayTime = 4000;

    // Device informations
    const [name, setName] = useState('')
    const [itemNumber, setItemNumber] = useState('')
    // Photo
    const [selectedImage, setSelectedImage] = useState(null)
    // Manufacturers
    const [existingManufacturers, setExistingManufacturers] = useState([])
    const [manufacturerName, setManufacturerName] = useState('')
    // Description
    const [description, setDescription] = useState('')
    // Categories
    const [existingCategories, setExistingCategories] = useState([])
    const [categoryId, setCategoryId] = useState('')
    const [categoryName, setCategoryName] = useState('')

    // Properties
    const [mandatoryProps, setMandatoryProps] = useState([])
    const [unselectedFields, setUnselectedFields] = useState(-1)
    const [recommendedProps, setRecommendedProps] = useState([])
    const [otherProps, setOtherProps] = useState([])
    
    // Properties for the POST
    const [propertyFilters, setPropertyFilters] = useState([])
    const [posting, setPosting] = useState(false)

    // Toast
    const [showToast, setShowToast] = useState(false)
    const [iconColor, setIconColor] = useState("")
    const [toastHeader, setToastHeader] = useState("")
    const [toastBody, setToastBody] = useState("")
    const [disableButtonViaToast, setDisableButtonViaToast] = useState(false)

    const { navigateToDevicePage } = useNavigation();

    // Fetch existing Manufacturers and categories
    useEffect(() => {
        // List of existing Manufacturers
        const fetchManufacturers = async () => {
            const result = await axios(DeviceApi('Manufacturer/List'));
            setExistingManufacturers(result.data)
        };

        // List of existing Categories
        const fetchCategories = async () => {
            const result = await axios(CategoryApi('List'));

            // Flatten the array
            const flatArray = []
            result.data.forEach(mainCategory => {
                // First, get all subcategory's name for each main category
                let subCategories = ''
                if(mainCategory.children.length > 0) {
                  mainCategory.children.forEach(subCategory => {
                    subCategories += ' | ' + subCategory.name
                  })
                }
        
                // Then, add main category first to array with all the subcategories in it's values
                flatArray.push({
                  value: `${mainCategory.id + subCategories}`,
                  label: mainCategory.name,
                  isDisabled: true
                })
        
                // Lastly, the children with the parent's
                if(mainCategory.children.length > 0) {
                  mainCategory.children.forEach(subCategory => {
                    flatArray.push({
                      value: `${subCategory.id} | ${mainCategory.name}`,
                      label: ` >>  ${subCategory.name}`
                    })
                  })
                }
            })
            setExistingCategories(flatArray)
        };
        // Fetch data
        fetchManufacturers();
        fetchCategories();
    }, []);

    // Fetch properties
    useEffect(() => {
        // Clear all properties
        const clearPropertyArrays = () => {
            setMandatoryProps([])
            setUnselectedFields(0)
            setRecommendedProps([])
            setOtherProps([])
        }

        const fetchProperty = async(type) => {
            try{
                const result = await axios(CategoryApi(`${categoryId}/Properties?type=${type}`));
                switch(type) {
                    case propertyType.MANDATORY:
                        setMandatoryProps(result.data)
                        setUnselectedFields(result.data.length)
                        break;
                    case propertyType.RECOMMENDED:
                        setRecommendedProps(result.data)
                        break;
                    case propertyType.OTHERS:
                        setOtherProps(result.data)
                        break;
                }
            } catch(error) {
                console.warn(`Error during ${type} property fetching process`)
            }
        }

        // Fetch data
        clearPropertyArrays()
        if(categoryId !== '') {
            fetchProperty(propertyType.MANDATORY);
            fetchProperty(propertyType.RECOMMENDED);
            fetchProperty(propertyType.OTHERS);
        }
    }, [categoryId])

    const manufacturerOptions = existingManufacturers.map((manufacturer, index) => {
        return { value: index, label: manufacturer }
    })

    const valueToOption = value => {
        // Value don't matter now
        let selectFormat 
        if(value !== '') {
            selectFormat = { 
                value: 0, 
                label: value
            }
        }
        else {
            selectFormat = value
        }

        return selectFormat;
    }

    // Handlers
    const onChange = (variable, event) => {
        switch(variable){
            case 'name': 
                setName(event.target.value);
                break;
            case 'manufacturer':
                if(event === null) {
                    setManufacturerName('')
                }
                else {
                    setManufacturerName(event.label)
                }
                break;
            case 'itemNumber': 
                setItemNumber(event.target.value);
                break;
            case 'description': 
                setDescription(event.target.value);
                break;
            case 'category': 
                if(event === null) {
                    setCategoryId('')
                    setCategoryName('')
                    setPropertyFilters([])
                }
                else {
                    // [0] does the array flatting so ID is a string not an array
                    setCategoryId(event.value.split(" | ",1)[0])
                    setCategoryName(event.label)
                    setPropertyFilters([])
                }
                break;            
            default: 
                console.error("Unknown type in new device's onChange");
        }
    }

    const deletePreviousValues = () => {
        setName('')
        setManufacturerName('')
        setItemNumber('')
        setDescription('')
        setCategoryName('')
        // Properties
        setMandatoryProps([])
        setRecommendedProps([])
        setOtherProps([])
        setPropertyFilters([])

        // Delete previously chose image
        setSelectedImage()
        document.getElementById('image-file').value = null;
    }

    // Device POSTing processes
    // Picture POST
    const postDevicePicture = async (deviceId) => {
        try {
          // Create multipart/form-data config
          let formData = new FormData();
          formData.append('photo', selectedImage)

          const config = {
              headers: {
                  'Content-Type': 'multipart/form-data'
              }
          }

          const resultPictureUpload = await axios.post(DeviceApi('Photo', deviceId), formData, config);
    
          if(resultPictureUpload.status === 202) {
            // show Toast
            setToastHeader("Sikeres művelet")
            setToastBody(
                <h6>
                    A létrehozott termék megtekinthető
                    <Link to = {`/device/${deviceId}`} className="ml-1">
                        itt
                    </Link>    
                </h6>
            )
            setIconColor(HADColors.Green)
            setShowToast(true)

            setTimeout(() => {
                setShowToast(false)
            }, delayTime)

            // Get Form ready for another device
            deletePreviousValues()
          }
        }
        catch(error) {
            console.error("Error during device picture upload process", error)
            
            setToastHeader("Sikertelen művelet")
            setToastBody("Nem sikerült képet feltölteni az eszközhöz, de az eszköz feltöltésre került")
            setIconColor(HADColors.Red)
            setShowToast(true)

            setTimeout(() => {
                setShowToast(false)
            }, delayTime)

            setPosting(false)
        }
        finally {
            setTimeout(() => {
                navigateToDevicePage(deviceId);
            }, 2000)
        }
    }

    // Device POST
    const postDevice = async () => {
        setPosting(true)
        const device = {
            name: name,
            manufacturerName: manufacturerName,
            itemNumber: itemNumber,
            description: description,
            categoryId: categoryId,
            properties: propertyFilters
        };

        const axiosParameters = {
            url: DeviceApi('Add'),
            method: 'post',
            data: device,
            headers: {
              'Content-Type': 'application/json',
              'accept': 'application/json'
            }
        }

        // Call API endpoint with axios
        axios(axiosParameters)
        .then(response => {
          if(response.status === 200) {
            // Add created device ID to picture uploading 
            postDevicePicture(response.data.result)
          }
        })
        .catch(error => {
            console.error("Error during device uploading process", error)
            setPosting(false)
        })
    };

    const enablePostButton = (
        name !== '' &&
        manufacturerName !== '' &&
        itemNumber !== '' &&
        categoryId !== '' &&
        selectedImage !== undefined &&
        description !== '' &&
        unselectedFields === 0 &&
        posting !== true
    )

    const customStyles = {
       // For the options
        option: (styles, { isDisabled }) => {
          return {
            ...styles,
            backgroundColor: isDisabled ? 'lightGray' : 'white',
            color: 'black',
          };
        },
      };

    const centralColumn = (
        <>
            <h1>Új eszköz létrehozása</h1>
            <Form>
                <Form.Group controlId="formDeviceAddName">
                    <Form.Label>Termék neve</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Írd be az új termék nevét"
                        value={name}
                        onChange={event => onChange('name', event)}
                    />
                </Form.Group>
                <Form.Group controlId="formDeviceAddManufacturer">
                    <Form.Label>Termék gyártója</Form.Label>
                    <CreatableSelect
                        key={`creatable-select-${manufacturerName}`}
                        isClearable
                        options={manufacturerOptions}
                        placeholder="Gyártó kiválasztása"
                        onChange={selected => onChange('manufacturer', selected)}
                        value = {valueToOption(manufacturerName)}
                    />
                </Form.Group>
                <Form.Group controlId="formDeviceAddItemNumber">
                    <Form.Label>Termék típus száma</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Írd be az új termék típus számát"
                        value={itemNumber}
                        onChange={event => onChange('itemNumber', event)}
                    />
                </Form.Group>
                <Form.Group controlId="formDeviceAddCategory">
                    <Form.Label>Termék kategóriája</Form.Label>
                    <Select
                        options = {existingCategories}
                        styles = {customStyles}
                        isClearable = {true}
                        placeholder = "Kategória kiválasztása"
                        onChange = {selected => onChange('category', selected)}
                        value = {valueToOption(categoryName)}
                    />
                </Form.Group>
                <Form.Group controlId="formDeviceAddPhoto" className = "d-flex flex-column">
                    <Form.Label>Termék képe</Form.Label>
                    <ImageInput setSelectedImage = {(selectedImage) => setSelectedImage(selectedImage)}/>
                </Form.Group>
                <Form.Group controlId="formDeviceAddDescription">
                    <Form.Label>Termék leírása</Form.Label>
                    <Form.Control
                        as="textarea"
                        placeholder='Termék részletes leírása'
                        rows={3}
                        value={description}
                        onChange={event => onChange('description', event)}
                    />
                </Form.Group>
            </Form>

            { mandatoryProps.length !== 0 &&
                <>
                    <PropertyTitle title='Kötelező tulajdonságok'/>
                    <DynamicSelect
                        controlId = 'new-device-mandatory-property'
                        propertyFilters = {propertyFilters}
                        type = {propertyType.MANDATORY}
                        gridItems = {mandatoryProps}
                        creatingNewEntity = {false}
                        unselectedFields = {unselectedFields}
                        setUnselectedFields = {newValue => setUnselectedFields(newValue)}
                        setPropertyFilters = {newPropFilters => setPropertyFilters(newPropFilters)}
                    />
                </>  
            }
            { recommendedProps.length !== 0 &&
                <>
                    <PropertyTitle title='Ajánlott tulajdonságok'/>
                    <DynamicSelect
                        controlId = 'new-device-recommended-property'
                        propertyFilters = {propertyFilters}
                        type = {propertyType.RECOMMENDED}
                        creatingNewEntity = {false}
                        propertyArray = {recommendedProps}
                        setPropertyFilters = {newPropFilters => setPropertyFilters(newPropFilters)}
                    />
                </>  
            }
            { otherProps.length !== 0 &&
                <>
                    <PropertyTitle title='Tetszőleges tulajdonságok'/>
                    <DynamicSelect
                        controlId = 'new-device-other-property'
                        propertyFilters = {propertyFilters}
                        type = {propertyType.OTHERS}
                        creatingNewEntity = {false}
                        propertyArray = {otherProps}
                        setPropertyFilters = {newPropFilters => setPropertyFilters(newPropFilters)}
                    />
                </> 
            }

            <div className ="d-flex justify-content-center align-items-center pt-3">
                <Button variant='info' onClick={() => postDevice()} disabled={!enablePostButton || disableButtonViaToast}>
                    Létrehozás
                </Button>
            </div>
            <CustomToast
                showToast = {showToast}
                setShowToast = {() => setShowToast(false)}
                iconColor = {iconColor}
                header = {toastHeader}
                headerTime = "Épp most"
                body = {toastBody}
                setDisableButtonViaToast = {newValue => setDisableButtonViaToast(newValue)}
            />
        </>
    )

    return (
        <ThreeColumnPageTemplate
            centralColumn = {centralColumn}
        />
    );
}

export default NewDevicePage;
