// Imports from packages
import React, { useState } from 'react';
import axios from 'axios';
// Imports from react-bootstrap 
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import UserApi from '../../Api/UserApi'
import CompanyApi from '../../Api/CompanyApi'
import ProjectApi from '../../Api/ProjectApi'
// Imports of our custom components
import CustomModal from './CustomModal';
// Imports of styles
// - none
// Imports of contants
// - none

export default function DeleteProfileProperty(props) {
  // Destructuring assignment from the props
  const { show, handleClose, propertyName, propertyId, propertyValue, entity, profileId, setRefreshing } = props

  const [posting, setPosting] = useState(false)
  
  // Alert
  const [showCreateResponseAlert, setShowCreateResponseAlert] = useState(false)
  const [createResponse, setCreateResponse] = useState(0)
  
  // Delete company's selected user from employees
  const deleteProperty = async () => {
    setPosting(true)

    let url
    switch(entity){
      case "user": url = UserApi("Profile"); break;
      case "company": url = CompanyApi(`${profileId}/Profile`); break;
      case "project": url = ProjectApi(`${profileId}/Profile`); break;
    }

    const data = {
      propertiesToAdd: [],
      propertiesToUpdate: [],
      propertiesToDelete: [
        `${propertyId}`
      ]
    }

    // Create the parameters for axios
    const axiosParameters = {
        url: url,
        method: 'put',
        data: data,
        headers: {
          'Content-Type': 'application/json'
        }
    }

    // Call API endpoint with axios
    try{
      const response = await axios(axiosParameters)
      // Response holds the updated profile's data, which should be given back to the page
      setShowCreateResponseAlert(true)
      setCreateResponse(response.status)

      setRefreshing(response.data)

      setTimeout(() => {
        setShowCreateResponseAlert(false)
        setCreateResponse(0)

        setPosting(false)

        handleClose()
      }, 1000)
    } catch(error){
      console.warn("Error from deleteProperty", error);
      // Show response from the project creation
      setShowCreateResponseAlert(true)
      setCreateResponse(error.response.status)

      setTimeout(() => {
        setShowCreateResponseAlert(false)
        setCreateResponse(0)

        setPosting(false)
      }, 2000)
    }
  }

  const onCancel = () => {   
    handleClose();
  }

  const title = 'Tulajdonság törlése'
  
  // Alert content making
  const alertContent = createResponse === 200 ? "Sikeres tulajdonság törlés" : "Nincs jogosultsága a művelet elvégzéséhez"

  const body = (
   <div className="text-center">
     <p>
      Biztosan törlöd a(z) <b>{propertyName}</b> tulajdonság <b>{propertyValue}</b> értékét?
    </p>
    <p className="mb-0">
      Ez egy vissza nem vonható művelet!
    </p>
    {showCreateResponseAlert && (
      <Alert variant = {createResponse === 200 ? "success" : "danger"} style={{ margin: '16px 0 0'}}>
        {alertContent}
      </Alert>
    )}
   </div>
  )

  const footer = (
    <>
      <Button
        variant="danger"
        onClick={deleteProperty}
        disabled = {posting}
      >
        Igen
      </Button>
      <Button
        variant="primary"
        onClick={() => onCancel()}
        disabled = {posting}
      >
        Mégse
      </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show = {show}
        size = "md"
        dropback="static"
        keyboard={false}
        handleClose = {() => onCancel()}
        title = {title}
        body = {body}
        footer = {footer}
      />
    </>
  );
}
