import { SET_LANGUAGE } from '../actions/language.js';

const language = (state = {
    language: 'HU'
}, action) => {
    switch (action.type) {
        case SET_LANGUAGE:
            return {
                ...state,
                language: action.language
            };      
        default:
            return state;
    }
};

export default language;
