// Imports from packages
import React from 'react';
// Imports for APIs
// - none
// Imports of our custom components 
import SingleDevice from '../Device/SingleDevice';
import CustomPagination from '../CommonComponents/CustomPagination';
// Imports of styles
// - none
// Imports of contants
// - none

function DeviceGrid(props) {
    // Destructuring assignment from the props
    const { devices, isFetched, title, isPaginated, pageCount, currentPage, setCurrentPage } = props

    let renderedContent
    if(isFetched) {
        if(devices.length > 0) {
            renderedContent = devices.map(device => {
                return <SingleDevice device = {device} key = {device.id} />
            })
        }
        else {
            renderedContent = <h3>Nincs elérhető termék.</h3>
        }
    }
    else {
        return null;
    }

    const pagination = (
        isPaginated && (
            pageCount > 1 && devices.length !== 0 &&
                <CustomPagination 
                    pageCount = {pageCount}
                    currentPage = {currentPage}
                    setCurrentPage = {newPageNumber => setCurrentPage(newPageNumber)}
                />  
        )
    )

    return (
        <div className = "d-flex justify-content-center flex-column text-center">
            <h2>
                {title}
            </h2>
            {pagination}
            <div className = "d-flex justify-content-center flex-row flex-wrap w-100 mb-3 mt-1">
                {renderedContent}
            </div> 
            {pagination}
        </div>
    );
}

export default DeviceGrid;
