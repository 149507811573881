import axios from "axios";
import { useQuery } from "react-query";
import { useHistory } from "react-router";
import UserApi from '../Api/UserApi';
import { removeAuthDataToLocalStorage } from "../auth";

export const FetchCurrentUserQueryKey = "FetchCurrentUser";

const useFetchCurrentUser = (callback) => {
  const history = useHistory();
  return useQuery(
    FetchCurrentUserQueryKey,
    async () => {
      const result = await axios.get(UserApi(`Profile`, 'GET'));
      return result.data;
    },
    {
      enabled: false,
      retry: 1,
      onSuccess: () => {
        callback && callback();
      },
      onError: (res) => {
        if(res.response.status === 401) {
          removeAuthDataToLocalStorage();
          history.push("/login");
        }
      }
    }
  );
};

export default useFetchCurrentUser;