// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
// react-bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
// Imports of our custom components 
import Loader from '../../Components/CommonComponents/Loader';
// Imports of API-related files
import EnumSelector from './EnumSelector';
import EditableInputList from './EditableInputList';
import EnumNameEditor from './EnumNameEditor';
import EnumEditorAlert from './EnumEditorAlert';
import { isThereAnyValidValues, defaultApiResponse } from './utils';

function AdminEnumEditor() {

    const [isFetching, setIsFetching] = useState(false)
    const [enumListWithData, setEnumListWithData] = useState([])
    const [selectedEnum, selectEnum] = useState(null)
    const [selectedEnumWithData, selectEnumWithData] = useState(null)
    const [isNewEnum, setNewEnum] = useState(false)
    const [disabledCreateNewEnumButton, setDisabledCreateNewEnumButton] = useState(true)
    const [apiResponse, setApiResponse] = useState(defaultApiResponse)

    const fetchAllEnums = async () => {
        setIsFetching(true)
        // setTimeout(() => {
        //     setEnumListWithData(mockEnumResponse)
        //     setIsFetching(false)
        // }, 500)
        try {
            const result = await axios(`/Enum/All/Full/`);
            setEnumListWithData(result.data)
            setIsFetching(false)
        } catch (error) {
            console.warn('Error during fetching enums', error)
        }
    }

    const resetStates = () => {
        fetchAllEnums()
        selectEnum(null)
        selectEnumWithData(null)
        setNewEnum(false)
        setDisabledCreateNewEnumButton(true)
        setApiResponse(defaultApiResponse)
    }

    useEffect(() => {
        if (apiResponse.status === 200) {
            setTimeout(() => resetStates(), 1500)
        }
    }, [apiResponse])

    useEffect(() => {
        // Fetch data
        fetchAllEnums();
    }, [])

    useEffect(() => {
        getSelectedEnumData(selectedEnum)
    }, [selectedEnum])

    const getSelectedEnumData = (selectedEnum) => {
        if (selectedEnum === null) {
            selectEnumWithData(null)
            setNewEnum(false)
            return null
        }
        else {
            const filteredEnums = enumListWithData.filter(enumItem => enumItem.id === selectedEnum.value)
            if (filteredEnums.length === 1) {
                selectEnumWithData(filteredEnums[0])
                setNewEnum(false)
            }
        }
    }

    const deleteEnumFromList = (enumId) => {
        let updateValues = [...selectedEnumWithData.values]
        selectEnumWithData({
            ...selectedEnumWithData,
            values: updateValues.filter(enumValue => enumValue.id !== enumId)
        })
        setDisabledCreateNewEnumButton(true)
    }

    const updateEnumValueList = (newArray) => {
        if (isNewEnum) {
            if (selectedEnumWithData.name.length > 0 && newArray.length > 0) {
                const isValid = isThereAnyValidValues(newArray)
                setDisabledCreateNewEnumButton(!isValid)
            }
            else {
                setDisabledCreateNewEnumButton(true)
            }
        }
        selectEnumWithData({
            ...selectedEnumWithData,
            values: newArray
        })
    }

    const createNewEnumLocally = () => {
        setNewEnum(true)
        selectEnumWithData({
            id: 'newEnumId',
            name: '',
            values: []
        })
    }

    const saveNewEnumName = async () => {
        try {
            const res = await axios.put(`/Enum/${selectedEnumWithData.id}?newName=${selectedEnumWithData.name}`)
            setApiResponse({
                status: res.status,
                type: 'enumRenamingSuccess',
                message: 'Enum átnevezve!'
            })
        }
        catch (error) {
            console.error(error);
            setApiResponse({
                status: error.request.status,
                type: 'enumRenamingFailure',
                message: 'Enum átnevezése nem sikerült!'
            })
        }
    }

    const updateEnumNameFromInput = (event) => {
        if (isNewEnum) {
            if (event.target.value.length > 0 && selectedEnumWithData.values.length > 0) {
                setDisabledCreateNewEnumButton(false)
            }
            else {
                setDisabledCreateNewEnumButton(true)
            }
        }
        selectEnumWithData({
            ...selectedEnumWithData,
            name: event.target.value
        })
    }

    const sendNewEnumWithValuesToApi = async (data) => {
        try {
            const res = await axios.post(`/Enum/Add/`, data)
            setApiResponse({
                status: res.status,
                type: 'enumCreationSuccess',
                message: 'Enum létrehozva!'
            })
        }
        catch (error) {
            setApiResponse({
                status: error.request.status,
                type: 'enumCreationFailure',
                message: 'Enum létrehozása nem sikerült!'
            })
        }
    }

    const createNewEnum = () => {
        const processedData = {
            name: selectedEnumWithData.name,
            values: selectedEnumWithData.values.filter(value => value.name.length > 0).map(value => value.name)
        }
        sendNewEnumWithValuesToApi(processedData)
    }

    const addNewValueToEnumValueList = () => {
        let valuesWithNewValue = [...selectedEnumWithData.values]
        valuesWithNewValue.unshift({
            id: `newEnumValueId-${valuesWithNewValue.length}`,
            name: ''
        })
        selectEnumWithData({
            ...selectedEnumWithData,
            values: valuesWithNewValue
        })
    }

    const deleteEnum = async () => {
        try {
            const res = await axios.delete(`/Enum/${selectedEnumWithData.id}/`)
            setApiResponse({
                status: res.status,
                type: 'enumDeletionSuccess',
                message: 'Enum törölve!'
            })
        }
        catch (error) {
            setApiResponse({
                status: error.request.status,
                type: 'enumDeletionFailure',
                message: 'Enum törlése nem sikerült!'
            })
        }
    }

    const sendUpdatedValuesToApi = async () => {
        const processedData = selectedEnumWithData.values.filter(value => value.name.length > 0).map(value => value.name)
        try {
            const res = await axios.put(`/Enum/${selectedEnumWithData.id}/Values`, processedData)
            setApiResponse({
                status: res.status,
                type: 'enumValuesUpdateSuccess',
                message: 'Enum értékek frissítve!'
            })
        }
        catch (error) {
            setApiResponse({
                status: error.request.status,
                type: 'enumValuesUpdateFailure',
                message: 'Enum értékek frissítése nem sikerült!'
            })
        }
    }

    return (
        <div>
            <h2 className="text-center">Enumok szerkesztése</h2>
            {isFetching && <div className="text-center"><Loader /><p>Ez egy hosszú ideig tartó lekérdezés...</p></div>}
            {!isFetching && (
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <EnumSelector enumList={enumListWithData} selectEnum={selectEnum} />
                            <div className="text-center m-2">
                                <Button
                                    variant="primary"
                                    onClick={() => createNewEnumLocally()}
                                    disabled={selectedEnumWithData !== null}
                                >
                                    Új enum
                                </Button>
                            </div>
                        </Col>
                        {selectedEnumWithData && (
                            <Col lg={6}>
                                <div>
                                    <Row>
                                        <Col lg={12}>
                                            <EnumEditorAlert data={apiResponse} />
                                        </Col>
                                    </Row>
                                    {isNewEnum && (
                                        <Row>
                                            <Col lg={12} style={{ textAlign: 'center' }}>
                                                {apiResponse.status === 0 && <Alert variant="info">Új enum létrehozásához kötelező a név és az értékek megadása!</Alert>}
                                                <Button
                                                    variant="primary"
                                                    onClick={() => createNewEnum()}
                                                    disabled={disabledCreateNewEnumButton}
                                                >
                                                    Enum létrehozása
                                                </Button>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                                <Row>
                                    <Col lg={6} style={{ textAlign: 'center' }}>
                                        <h3>Név</h3>
                                        <EnumNameEditor
                                            name={selectedEnumWithData.name}
                                            updateEnumNameFromInput={updateEnumNameFromInput}
                                        />
                                        {!isNewEnum && (
                                            <div>
                                                <div className="m-2">
                                                    <Button
                                                        variant="primary"
                                                        onClick={() => saveNewEnumName()}
                                                        disabled={selectedEnumWithData.name.length === 0}
                                                    >
                                                        Név módosítása
                                                </Button>
                                                </div>
                                                <div className="m-2">
                                                    <Button
                                                        variant="danger"
                                                        onClick={() => deleteEnum()}
                                                    >
                                                        Enum törlése
                                                </Button>
                                                </div>
                                            </div>
                                        )}
                                    </Col>
                                    <Col lg={6} style={{ textAlign: 'center' }}>
                                        <h3>Értékek</h3>
                                        <EditableInputList
                                            selectedEnum={selectedEnumWithData}
                                            updateEnumValueList={updateEnumValueList}
                                            deleteEnumFromList={deleteEnumFromList}
                                            addNewValueToEnumValueList={addNewValueToEnumValueList}
                                        />
                                        <div className="m-2">
                                            <Button
                                                variant="primary"
                                                onClick={() => sendUpdatedValuesToApi()}
                                            >
                                                Értékek módosítása
                                                </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        )}
                    </Row>
                </Container>
            )}
        </div>
    );
}

export default AdminEnumEditor;
