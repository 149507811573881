export const localStorageTokenItemKey = "hadocument-ui-auth-token"
export const localStorageUsernameItemKey = "hadocument-ui-auth-user"
export const localStorageCompany = "hadocument-ui-auth-company-name"
export const localStorageCompanyShortName = "hadocument-ui-auth-company-shortname"
export const localStorageId = "hadocument-ui-auth-id"
export const localStorageActiveProjectId = "hadocument-ui-active-project-id"
export const localStorageActiveProjectName = "hadocument-ui-active-project-name"
export const localStorageActiveBomId = "hadocument-ui-active-bom-id"
export const localStorageActiveBomName = "hadocument-ui-active-bom-name"
export const localStorageUserRole = "hadocument-ui-user-role"

export const getAuthDataFromLocalStorage = () => {
    const username = localStorage.getItem(localStorageUsernameItemKey)
    const id = localStorage.getItem(localStorageId)
    const name = localStorage.getItem(localStorageCompany)
    const shortName = localStorage.getItem(localStorageCompanyShortName)
    const token = localStorage.getItem(localStorageTokenItemKey)
    const role = localStorage.getItem(localStorageUserRole)
    return { username, id, name, shortName, token, role }
}

export const setAuthDataToLocalStorage = (authData) => {
    localStorage.setItem(localStorageTokenItemKey, authData.token)
    localStorage.setItem(localStorageUsernameItemKey, authData.username)
    localStorage.setItem(localStorageId, authData.id)
    localStorage.setItem(localStorageCompany, authData.name)
    localStorage.setItem(localStorageCompanyShortName, authData.shortName)
    localStorage.setItem(localStorageTokenItemKey, authData.token)
    localStorage.setItem(localStorageUserRole, authData.role)
}

export const removeAuthDataToLocalStorage = () => {
    localStorage.removeItem(localStorageTokenItemKey)
    localStorage.removeItem(localStorageUsernameItemKey)
    localStorage.removeItem(localStorageId)
    localStorage.removeItem(localStorageCompany)
    localStorage.removeItem(localStorageCompanyShortName)
    localStorage.removeItem(localStorageTokenItemKey)
    localStorage.removeItem(localStorageActiveBomId)
    localStorage.removeItem(localStorageActiveBomName)
    localStorage.removeItem(localStorageActiveProjectId)
    localStorage.removeItem(localStorageActiveProjectName)
    localStorage.removeItem(localStorageUserRole)
}

export const authDataExistsInLocalStorage = () => {
    const { token } = getAuthDataFromLocalStorage()
    return (token !== null && token !== undefined)
}

export const setActiveProject = (id, name) => {
    localStorage.setItem(localStorageActiveProjectId, id)
    localStorage.setItem(localStorageActiveProjectName, name)
}

export const getActiveProject = () => {
    const activeProjectId = localStorage.getItem(localStorageActiveProjectId)
    const activeProjectName = localStorage.getItem(localStorageActiveProjectName)
    return { activeProjectId, activeProjectName }
}

export const inactiveProject = () => {
    localStorage.removeItem(localStorageActiveProjectId)
    localStorage.removeItem(localStorageActiveProjectName)
}

export const setActiveBom = (id, name) => {
    localStorage.setItem(localStorageActiveBomId, id)
    localStorage.setItem(localStorageActiveBomName, name)
}

export const getActiveBom = () => {
    const activeBomId = localStorage.getItem(localStorageActiveBomId)
    const activeBomName = localStorage.getItem(localStorageActiveBomName)
    return { activeBomId, activeBomName }
}

export const inactivateBom = () => {
    localStorage.removeItem(localStorageActiveBomId)
    localStorage.removeItem(localStorageActiveBomName)
}
