// Imports from packages
import React from 'react';
import { Link } from "react-router-dom";
// Imports for APIs
// - none
// Imports of our custom components
import PropertyModification from './PropertyModification';
// Imports of styles
import '../../App.css';
import CustomPropertyDisplayComponent from '../Device/CustomPropertyDisplayComponent';
// Imports of contants
// - none

function OneAccordionBodyRow(props) {
    // Destructuring assignment from the props
    const { entity, profileId, fontColor, property, eachValue, editMode, setRefreshing } = props

    // If property does not have 'isNotEditable' property it will give back undefined, which 
    // equals with 'false'
    const canBeEdited = !property.isNotEditable
    const showEditButton = canBeEdited && editMode

    return (
        <div className='each-property-div'>
            <div className="d-flex justify-content-start align-items-center">
                <div style={{ color: `${fontColor}`, marginRight: "0.5em" }}>
                    {property.linkToPage ? (
                        <Link to={property.linkToPage}>
                            <b>{property.propertyName}:</b>
                        </Link>
                    ) : (
                        <b>{property.propertyName}:</b>
                    )
                    }
                </div>
                <CustomPropertyDisplayComponent contentType={property.contentType} value={eachValue.value} />
            </div>
            {showEditButton &&
                <PropertyModification
                    property={property}
                    propertyId={eachValue.id}
                    propertyValue={eachValue.value}
                    setRefreshing={setRefreshing}
                    entity={entity}
                    profileId={profileId}
                />
            }
        </div>
    )
}

export default function AccordionBody(props) {
    // Destructuring assignment from the props
    const { entity, profileId, properties, editMode, setRefreshing } = props

    const formattedProperties = properties.map(property => {
        const formattedProperty = {
            propertyName: property.propertyName,
            values: property.values,
            enumTypeId: property.enumTypeId ? property.enumTypeId : null,
            isNotEditable: property.isNotEditable ? property.isNotEditable : false,
            linkToPage: property.linkToPage ? property.linkToPage : false,
            isMandatory: property.isMandatory,
            contentType: property.contentType
        }

        const eachValueInsideValues = formattedProperty.values.map((eachValue, index) => {
            return (
                <OneAccordionBodyRow
                    property={formattedProperty}
                    fontColor={index === 0 ? "black" : "white"}
                    key={`${eachValue}-${index}`}
                    eachValue={eachValue}
                    editMode={editMode}
                    setRefreshing={setRefreshing}
                    entity={entity}
                    profileId={profileId}
                />
            )
        })

        return eachValueInsideValues;
    })

    return formattedProperties
}
