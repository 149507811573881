import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import "../../App.css";
import "../../App.scss";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Logo from "../../cropped-logo.png";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import MissingQueryParameters from "../../Components/CommonComponents/MissingQueryParameters.jsx";
import { formTexts } from '../../constants/user';
import PasswordText from "../../Components/User/PasswordText.js";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function PublicResetPassword() {
    // Parse query parameters
    const query = useQuery();
    const userId = query.get('userId');
    const token = query.get('token');
    // Form state
    const [showPassword, setShowPassword] = useState(false)
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordAgain, setNewPasswordAgain] = useState("");
    const [checkRegex, setCheckRegex] = useState(false)
    const [formTextStatus, setFormTextStatus] = useState([false, false, false, false, false]);
    const criteriasNotMatched = formTextStatus.some(element => element === false);

    const setFormStatusesAccordingToValue = (value, idx) => {
        const tmpArray = formTextStatus
        tmpArray[idx] = value
        setFormTextStatus(tmpArray)
    }

    const checkPaswordWithRegexps = (event) => {
        setCheckRegex(true)
        setNewPassword(event.target.value)
        setTimeout(() => {
            setCheckRegex(false)
        }, 100)
    }

    const [apiResponse, setApiResponse] = useState("");
    const postResetSend = async () => {
        try {
            const result = await axios.put(`Account/Reset`, {
                newPassword
            }, {
                params: {
                    userId,
                    token
                }
            });
            setApiResponse(result.statusText)
        }
        catch (error) {
            setApiResponse(Object.entries(error)[2][1].statusText)
        }
    }
    const handleFormSubmit = (event) => {
        event.preventDefault();
        postResetSend();
    }

    if (userId === null || token === null) {
        return <MissingQueryParameters text="Hiba történt! Érvénytelen jelszó visszaállítási próbálkozás!" />
    }

    const disablePasswordResetButton = (
        newPassword.length === 0 ||
        criteriasNotMatched ||
        newPassword !== newPasswordAgain
    )

    return (
        <Container fluid>
            <Row
                className="justify-content-center align-items-center"
                style={{ minHeight: "100vh" }}
            >
                <Col className="col-6 text-center pt-4">
                    <img src={Logo} alt="HADocument logó" width="100px" />
                    {apiResponse.length > 0 && (
                        <div className="w-100 text-center">
                            <Alert
                                variant={apiResponse.includes("OK") ? "success" : "warning"}
                                className="mb-4 mt-4 text-center mx-4">
                                {apiResponse.includes("OK") ? "Sikeres jelszó módosítás!" : "Hiba történt! Kérlek, próbáld újra!"}
                                <br />
                                <i>
                                    {apiResponse}
                                </i>
                            </Alert>
                        </div>
                    )}
                    <h2 className="my-2">Jelszó módosítása</h2>
                    <Form style={{ width: "400px" }} className="mx-auto text-center">
                        <Form.Group>
                            <Form.Label>Új jelszó</Form.Label>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Új jelszó"
                                value={newPassword}
                                onChange={(event) => checkPaswordWithRegexps(event)}
                            />
                        </Form.Group>
                        <div className="text-left">
                            {formTexts.map((text, idx) => {
                                return (
                                    <PasswordText
                                        key={`${text}-${idx}`}
                                        setFormTextStatus={value => setFormStatusesAccordingToValue(value, idx)}
                                        content={text.content}
                                        password={newPassword}
                                        regex={text.regex}
                                        checkRegex={checkRegex}
                                    />
                                )
                            })}
                        </div>
                        <Form.Group>
                            <Form.Label>Új jelszó még egyszer</Form.Label>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Új jelszó még egyszer"
                                value={newPasswordAgain}
                                onChange={(event) => setNewPasswordAgain(event.target.value)}
                            />
                        </Form.Group>
                        <Form.Check
                            id="password-check"
                            type="checkbox"
                            label="Jelszó megjelenítése"
                            onClick={() => setShowPassword(!showPassword)}
                            className="mb-1"
                        />
                    </Form>
                    <div className="d-flex flex-column justify-content-center">
                        <div className="d-flex w-100 justify-content-center">
                            <Button
                                className="mt-3"
                                variant="primary"
                                onClick={(event) => handleFormSubmit(event)}
                                disabled={disablePasswordResetButton}
                            >
                                Jelszó módosítása
                            </Button>
                        </div>
                        <div className="d-flex w-100 justify-content-center my-4">
                            <Link to="/">Vissza a főoldalra!</Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default PublicResetPassword;
