import React from 'react';
import Alert from 'react-bootstrap/Alert';

export default function BetaAlert() {
    return (
        <div className="text-center">
            <Alert variant="warning" style={{ marginBottom: 0 }}>Az alkalmazás béta állapotban van! This app is in beta mode!</Alert>
        </div>
    )
}
