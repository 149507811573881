// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, Redirect } from "react-router-dom";
import { FaTimesCircle, FaCheckCircle } from 'react-icons/fa';
// react-bootstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import InputGroup from 'react-bootstrap/InputGroup';
// Imports for APIs
import AccountApi from '../../Api/AccountApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import ThreeColumnPageTemplate from '../Pages/ThreeColumnPageTemplate';
import IconWithOverlay from '../CommonComponents/IconWithOverlay';
import AsteriskExplanation from '../CommonComponents/AsteriskExplanation';
import Asterisk from '../CommonComponents/Asterisk';
import PasswordText from './PasswordText';
// Imports of styles
// - none
// Imports of contants
import { HADColors } from '../../constants/color';
import { formTexts } from '../../constants/user';

const RegistrationResponse = (props) => {
  const { statusCode, errorList } = props;
  switch (statusCode) {
    case 200: return <Alert variant="success" className="my-3 text-center">Sikeres regisztráció</Alert>;
    case 400: return (
      <Alert variant="danger" className="my-3 text-center">
        <ul className="mb-0">
          {errorList.map((error, index) => <li key={index} className="text-left">{error}</li>)}
        </ul>
      </Alert>
    )
    default: return <Alert variant="danger" className="my-3 text-center">Hupsz, valami hiba történt! Kérlek próbáld meg később!</Alert>;
  }
}

function RegistrationPage() {

  // Username
  const [username, setUsername] = useState('')
  const [isUsernameChecked, setIsUsernameChecked] = useState(false)
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false)
  // Password
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  // Nickname
  const [nickname, setNickname] = useState('')
  const [isNicknameChecked, setIsNicknameChecked] = useState(false)
  const [isNicknameAvailable, setIsNicknameAvailable] = useState(false)
  // Email address
  const [emailAddress, setEmailAddress] = useState('')
  // First name
  const [firstname, setFirstname] = useState('')
  const [firstnameId, setFirstnameId] = useState('')
  // Last name
  const [lastname, setLastname] = useState('')
  const [lastnameId, setLastnameId] = useState('')
  // Terms checkbox
  const [agreeWithTermsAndConditions, setAgreeWithTermsAndConditions] = useState(false)
  // Alert
  const [showAccountResponseAlert, setShowAccountResponseAlert] = useState(false)
  const [accountResponse, setAccountResponse] = useState(0)
  const [registrationErrorList, setRegistrationErrorList] = useState([])
  const [postingFinished, setPostingFinished] = useState(false)
  // Regexp check
  const [checkRegex, setCheckRegex] = useState(false)
  const [formTextStatus, setFormTextStatus] = useState([false, false, false, false, false])
  const criteriasNotMatched = formTextStatus.some(element => element === false)

  const setFormStatusesAccordingToValue = (value, idx) => {
    const tmpArray = formTextStatus
    tmpArray[idx] = value

    setFormTextStatus(tmpArray)
  }

  const checkPaswordWithRegexps = (event) => {
    setCheckRegex(true)
    onChange(event, 'password')
    setTimeout(() => {
      setCheckRegex(false)
    }, 100)
  }

  const fetchUsernameValidness = async () => {
    const result = await axios(AccountApi(`Check/UserName?username=${username}`));
    setIsUsernameChecked(true)
    setIsUsernameAvailable(result.data.result);
  };

  const fetchNicknameValidness = async () => {
    const result = await axios(AccountApi(`Check/NickName?nickname=${nickname}`));
    setIsNicknameChecked(true)
    setIsNicknameAvailable(result.data.result);
  };

  const onChange = (event, type) => {
    switch (type) {
      case 'username':
        setUsername(event.target.value);
        setIsUsernameChecked(false);
        setIsUsernameAvailable(false);
        break;
      case 'nickname':
        setNickname(event.target.value);
        setIsNicknameChecked(false);
        setIsNicknameAvailable(false);
        break;
      case 'email':
        setEmailAddress(event.target.value);
        break;
      case 'firstname':
        setFirstname(event.target.value);
        break;
      case 'lastname':
        setLastname(event.target.value)
        break;
      case 'password':
        setPassword(event.target.value)
        break;
      default:
        console.error("Unknown type in user registration's onChange");
    }
  }

  useEffect(() => {
    // Get first and last name ID
    const fetchFirstAndLastnameIds = async () => {
      const idResult = await axios(PropertyApi(`List?propertyType=User&typeOf=NormalValue&filter=OnlyValid`));
      if (idResult.status === 200) {
        idResult.data.forEach(element => {
          if (element.name === "Keresztnév") {
            setFirstnameId(element.id);
          }
          else if (element.name === "Vezetéknév") {
            setLastnameId(element.id);
          }
        })
      }
      else {
        console.warn(" Error from fetchFirstAndLastnameIds response");
      }
    }

    // Fetch data
    fetchFirstAndLastnameIds();
  }, [])

  const postRegistration = async () => {
    // Prevent from double cliking on userregistration
    setIsUsernameChecked(false)
    setIsNicknameChecked(false)

    try {
      const registrationData = {
        "username": `${username}`,
        "email": `${emailAddress}`,
        "nickname": `${nickname}`,
        "password": `${password}`,
        "properties": [
          {
            "propertyId": `${lastnameId}`,
            "value": `${lastname}`
          },
          {
            "propertyId": `${firstnameId}`,
            "value": `${firstname}`
          }
        ]
      }

      const postAccountResult = await axios.post(AccountApi(`Register`), registrationData);
      // Show response from the login
      setShowAccountResponseAlert(true)
      setAccountResponse(postAccountResult.status)

      if (postAccountResult.status === 200) {
        // Execute post-login actions after some delay
        setTimeout(() => {
          setShowAccountResponseAlert(false)
          setAccountResponse(0)
        }, 10000)

        setTimeout(() => {
          // Open login modal
          setPostingFinished(true)
        }, 1500)
      }
    }
    catch (error) {
      console.error("Error during registration process", error.response.data.errors);
      // Show response from the login
      setShowAccountResponseAlert(true)
      setAccountResponse(error.response.status)
      if (error.response.status === 400) {
        if (error.response.data.errors) {
          setRegistrationErrorList(Object.entries(error.response.data.errors).map(errorItem => errorItem[1]))
        }
        else {
          setRegistrationErrorList(Object.entries(error.response.data[0]).map(errorItem => errorItem[1]))
        }
      }
      setTimeout(() => {
        setShowAccountResponseAlert(false)
        setAccountResponse(0)
      }, 10000)
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      postRegistration();
    }

    event.preventDefault();
    event.stopPropagation();
  };

  const commonIconStyle = {
    size: '1.5em',
    margin: '0 4px'
  }

  const linkToUserConditions = <Link to="/user-conditions">adatvédelmi nyilatkozatot</Link>

  const disableRegistrationButton = (
    !isUsernameChecked ||
    !isUsernameAvailable ||
    !isNicknameChecked ||
    !isNicknameAvailable ||
    password.length === 0 ||
    emailAddress.length === 0
  )

  const centralColumn = (
    <div className="d-flex justify-content-center flex-column w-50 m-auto">
      <h1 className="text-center">Regisztráció</h1>
      <span className="mt-3 text-muted">
        Szabad felhasználónév és jelszó ellenőrzés mezőből való kilépést követően történik
      </span>
      <div className="mt-2 mb-3">
        <AsteriskExplanation />
      </div>

      <Form onSubmit={handleSubmit} className="d-flex justify-content-center flex-column">
        <Form.Group controlId="form-reg-BasicUsername">
          <Form.Label>Felhasználónév <Asterisk /></Form.Label>
          <InputGroup>
            <Form.Control
              type="text"
              placeholder="Felhasználónév"
              onChange={event => onChange(event, 'username')}
              onBlur={() => fetchUsernameValidness()}
              value={username}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                {isUsernameChecked === true ? (
                  isUsernameAvailable === true ?
                    <IconWithOverlay
                      key="check-icon"
                      placement="top"
                      tooltip={"Szabad felhasználónév"}
                      icon={<FaCheckCircle style={commonIconStyle} color={HADColors.Green} />}
                    />
                    :
                    <IconWithOverlay
                      key="times-icon"
                      placement="top"
                      tooltip={"Foglalt felhasználónév"}
                      icon={<FaTimesCircle style={commonIconStyle} color={HADColors.Red} />}
                    />
                ) : (
                  <div className=" pl-4"></div>
                )
                }
              </InputGroup.Text>
            </InputGroup.Append>
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="form-reg-BasicPassword">
          <Form.Label>Jelszó <Asterisk /></Form.Label>
          <Form.Control
            type={showPassword ? "text" : "password"}
            placeholder="Jelszó"
            value={password}
            onChange={event => checkPaswordWithRegexps(event)} // onChange(event, 'password')
          />
          <Form.Check
            id="password-check"
            type="checkbox"
            label="Jelszó megjelenítése"
            onClick={() => setShowPassword(!showPassword)}
            className="mb-1"
          />
          {formTexts.map((text, idx) => {
            return (
              <PasswordText
                key={`${text}-${idx}`}
                setFormTextStatus={value => setFormStatusesAccordingToValue(value, idx)}
                content={text.content}
                password={password}
                regex={text.regex}
                checkRegex={checkRegex}
              />
            )
          })}
        </Form.Group>

        <Form.Group controlId="form-reg-EmailAddress">
          <Form.Label>Email cím <Asterisk /></Form.Label>
          <Form.Control
            type="email"
            placeholder="Email cím"
            value={emailAddress}
            onChange={event => onChange(event, 'email')}
          />
        </Form.Group>

        <Form.Group controlId="formLastname">
          <Form.Label>Vezetéknév <Asterisk /></Form.Label>
          <Form.Control
            type="text"
            placeholder="Vezetéknév"
            value={lastname}
            onChange={event => onChange(event, 'lastname')}
          />
        </Form.Group>

        <Form.Group controlId="formFirstname">
          <Form.Label>Keresztnév <Asterisk /></Form.Label>
          <Form.Control
            type="text"
            placeholder="Keresztnév"
            value={firstname}
            onChange={event => onChange(event, 'firstname')}
          />
        </Form.Group>

        <Form.Group controlId="formBasicNickname">
          <Form.Label>Becenév <Asterisk /></Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              placeholder="Becenév"
              value={nickname}
              onChange={event => onChange(event, 'nickname')}
              onBlur={() => fetchNicknameValidness()}
            />
            <InputGroup.Append>
              <InputGroup.Text>
                {isNicknameChecked === true ? (
                  isNicknameAvailable === true ?
                    <IconWithOverlay
                      key="check-icon"
                      placement="top"
                      tooltip={"Szabad felhasználónév"}
                      icon={<FaCheckCircle style={commonIconStyle} color={HADColors.Green} />}
                    />
                    :
                    <IconWithOverlay
                      key="times-icon"
                      placement="top"
                      tooltip={"Foglalt felhasználónév"}
                      icon={<FaTimesCircle style={commonIconStyle} color={HADColors.Red} />}
                    />
                ) : (
                  <div className=" pl-2 pr-3"></div>
                )
                }
              </InputGroup.Text>
            </InputGroup.Append>
            <Form.Text className="text-muted">
              Szabad becenév ellenőrzés mezőből való kilépést követően történik
            </Form.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="formBasicCheckbox">
          <Form.Check type="checkbox">
            <Form.Check.Input
              type="checkbox"
              checked={agreeWithTermsAndConditions}
              onClick={() => setAgreeWithTermsAndConditions(!agreeWithTermsAndConditions)}
            />
            <Form.Check.Label>Elolvastam és elfogadom az {linkToUserConditions}</Form.Check.Label>
          </Form.Check>
        </Form.Group>

        <Button
          type='submit'
          variant="info"
          className="m-auto"
          disabled={disableRegistrationButton || !agreeWithTermsAndConditions || criteriasNotMatched}
        >
          Regisztráció
        </Button>
      </Form>

      {showAccountResponseAlert && <RegistrationResponse statusCode={accountResponse} errorList={registrationErrorList} />}

      {postingFinished === true &&
        <Redirect to="/login" />
      }
    </div>
  )

  return (
    <ThreeColumnPageTemplate
      centralColumn={centralColumn}
    />
  );
}

export default RegistrationPage;
