import React from 'react';
import { setBom } from '../../actions/bom.js';
import { connect } from 'react-redux';

import Dropdown from 'react-bootstrap/Dropdown';

import '../../App.scss';
import { setActiveBom } from '../../auth/index.js';

function BOMListDropdown(props) {

    const handleActivateBom = (id, name) => {
        props.setBom(id, name);
        setActiveBom(id, name);
    }

    const isThereAnyBom = props.bomList.length !== 0

    let activeBomLabel
    if (isThereAnyBom) {
        activeBomLabel = props.bomName === '' ? "Válassz!" : props.bomName
    }
    else {
        activeBomLabel = "Nincs még BOM a projekthez"
    }

    let bomListItems
    if (isThereAnyBom) {
        bomListItems = props.bomList.map(bomListItem => {
            return (
                <Dropdown.Item
                    as="button"
                    onClick={() => handleActivateBom(bomListItem.id, bomListItem.name)}
                    key={`bom-list-item-${bomListItem.id}`}
                >
                    {bomListItem.name}
                </Dropdown.Item>
            )
        })
    }

    return (
        <>
            <Dropdown>
                <Dropdown.Toggle variant="primary" id="projectStatusDropdown" style={{ marginLeft: 10 }}>
                    <span>{activeBomLabel}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu align="right">
                    {isThereAnyBom && bomListItems}
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
}


// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
    const { bomId, bomName } = state.bom;
    return { bomId, bomName };
};
// Store reducers to components props as callable functions
const mapDispatchToProps = dispatch => ({
    setBom: (bomId, bomName) => {
        dispatch(setBom(bomId, bomName));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(BOMListDropdown);