import { SET_COMPANY } from '../actions/company.js';

const company = (state = {
    companyId: '',
    companyName: '',
    companyShortName: ''
}, action) => {
    switch (action.type) {
        case SET_COMPANY:
            return {
                ...state,
                companyId: action.companyId,
                companyName: action.companyName,
                companyShortName: action.companyShortName
            };      
        default:
            return state;
    }
};

export default company;
