export const propertyTypes = [
    'Device',
    'User',
    'Company',
    'Project',
    'FileReference'
];
export const typeOfs = [
    'FileReference',
    'Link',
    'Enum',
    'DeviceReference',
    'NormalValue',
    'UserReference',
    'CompanyReference',
    'Address',
    'Time',
    'Date',
    'DateTime',
    'EmployeeReference',
];
export const filters = [
    'NoFilter',
    'OnlyValid',
    'OnlyInvalid',
];
export const mandatoryFilters = [
    'OnlyMandatory',
    'OnlyOptional',
    'Both',
];
export const valueTypes = [
    'ID',
    'Int',
    'Long',
    'Double',
    'Float',
    'String',
    'Char',
    'UnixTime'
];
export const contentTypes = [
    'FileReference',
    'Link',
    'Enum',
    'DeviceReference',
    'NormalValue',
    'UserReference',
    'Address',
    'Time',
    'Date',
    'DateTime',
];
export const measurementUnits = [
    'NotApplicable', 'Piece', 'Percentage', 'Gramm', 'GrammPerMeter', 'GrammPerMeter2', 'GrammPerMeter3', 'Newton', 'NewtonPerMeter', 'NewtonPerMeter2', 'Pascal', 'Liter', 'Meter', 'RUnit', 'MUnit', 'Meter2', 'Meter3', 'Volt', 'Amper', 'Watt', 'Ah', 'VAR', 'VoltPerMeter', 'Farad', 'Henry', 'Coulumb', 'Ohm', 'OhmPerMeter', 'bit', 'bitPerSec', 'Mpps', 'Pixel', 'PixelPerMeter', 'Fps', 'Secundum', 'Celsius', 'MeterPerSecundum', 'MeterPerSecundum2', 'KelvinColor', 'Hercz', 'Lux', 'Lumen', 'Kit', 'Degree', 'Decibel', 'DecibelPerMeter', 'Other'
];
