export const basicPropertyEndpoint = () => process.env.REACT_APP_BACKEND_API_BASE_URL + process.env.REACT_APP_BACKEND_API_VERSION_NUMBER + '/Property/'

function PropertyApi(endpoint, id) {

    

    const exceptionalNames = ['List'];

    let isExceptional = false
    exceptionalNames.forEach(name => {
        if(endpoint.includes(name)) {
            isExceptional = true
        }
    })

    if(isExceptional) {
        return `/Property/${endpoint}`
    }
    else{
        return `/Property/${id}/${endpoint}`
    }
}

export default PropertyApi;
