export const UserProperties = [
    {
        propertyName: "Felhasználónév",
        isNotEditable: true,
    },
    {
        propertyName: "Becenév",
        isNotEditable: false,
    },
    {
        propertyName: "Email cím",
        isNotEditable: false,
    }
]

export const CompanyProperties = [
    {
        propertyName: "Teljes cégnév",
        isNotEditable: true,
    },
    {
        propertyName: "Rövid cégnév",
        isNotEditable: true,
    },
    {
        propertyName: "Adminisztrátor",
        isNotEditable: true,
    },
    {
        propertyName: "Cég típusa",
        isNotEditable: true,
    }
]