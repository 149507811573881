// Imports from packages
import React from 'react';
// Imports for APIs
// - none
// Imports of our custom components 
import SingleCompanyDevice from './SingleCompanyDevice';
import CustomPagination from '../../CommonComponents/CustomPagination';
import Loader from '../../CommonComponents/Loader';
import CenteredHeading4 from '../../CommonComponents/CenteredHeading4';
// Imports of styles
// - none
// Imports of contants
// - none

function CompanyDeviceGrid(props) {
    // Destructuring assignment from the props
    const { title, fetchedDevices, originalDevices, alteredDevices, pageCount, currentPage, setDeviceId, setReviewMethod } = props

    const pagination = (
        pageCount > 1 && alteredDevices.length !== 0 &&
            <CustomPagination 
                pageCount = {pageCount}
                currentPage = {currentPage}
                setCurrentPage = {newPageNumber => props.setCurrentPage(newPageNumber)}
            />
    )

    // If the page just loaded, until fetchCompanyDevices finishes, show Spinner/Loader
    let centralColumn
    if(!fetchedDevices) {
        centralColumn = (
            <div className = "mb-4">
                <Loader/>
            </div>
        )
    }
    else {
        if(originalDevices.length === 0) {
            // Company has zero devices
            centralColumn = <CenteredHeading4 message = "Ehhez a céghez nem lett még eszköz feltöltve" />
        }
         else {
            if(alteredDevices.length === 0) {
                // Company has zero devices with this filtering
                centralColumn = <CenteredHeading4 message = "A kiválasztott szűrésnek nincs megfelelő eszköz a cégnél" />
            }
            else {
                // Company has devices with this filtering
                const mappedArray = alteredDevices.map(device => {
                    return (
                        <SingleCompanyDevice
                            device = {device}
                            key = {device.basicDevice.id}
                            setDeviceId={setDeviceId}
                            setReviewMethod={setReviewMethod}
                        />
                    )
                })

                centralColumn = mappedArray
            }
        }
    }
        
    return (
        <div className = "d-flex justify-content-center flex-column text-center">
            <h2 className = "mb-4">
                {title}
            </h2>
            {pagination}
            <div className = "d-flex justify-content-center flex-row flex-wrap w-100">
                {centralColumn}
            </div> 
            {pagination}
        </div>
    );
}

export default CompanyDeviceGrid;
