// Imports from packages
import React from 'react';
// Imports for APIs
// - none
// Imports of our custom components
import ShortLink from '../ShortLink';
// Imports of styles
import '../../../App.css';
// Imports of contants
// - none

const mappingItems = (array, type) => {
    // Make array from double nested links array
    const mappedItems = array.map((element, ) => {
        let oneItem
        // Categories have their name in value, id is empty so the filtering is more easy
        if( element.id === '') {
            oneItem = { 
                id: element.value,
                value: ''
            }
        } else {
            // Depending on the the result should be different
            if(type === "link"){
                oneItem = { 
                    id: '',
                    value: <ShortLink link = {element} maxLength = {40} className = 'link-style-darker'/>
                }
            }
            else if(type === "file"){
                oneItem = { 
                    id: '',
                    fileId: element.fileId,
                    value: element.fileName,
                    extension: element.extension
                }
            }
        }

        return oneItem;
    })

    return mappedItems;
}

export default function makeFlatArrayfromObject(givenArray, typeArray){

    let flattenedArray = []
    typeArray.values.forEach(type => {
        if(Array.isArray(givenArray[type.name])) {
            const newArray = [
                { id: '', value: type.name },
                ...givenArray[type.name]
            ]

            flattenedArray = [
                ...flattenedArray,
                ...newArray
            ]
        }
    })

    const mappedArray = [...mappingItems(flattenedArray, typeArray.type)]

    return mappedArray
}