// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
// react-bootstrap
import Form from 'react-bootstrap/Form';
// Imports for APIs
import PropertyApi from '../../Api/PropertyApi';
import EnumApi from '../../Api/EnumApi';
// Imports of our custom components
import Asterisk from '../CommonComponents/Asterisk';
// Imports of styles
// - none
// Imports of contants
// - none

function FileReferenceItem(props) {
    // Destructuring assignment from redux props
    const { item, refreshPropertyArray } = props

    const [values, setValues] = useState([]);
    const [selectedValues, setSelectedValues] = useState();

    const mappingValuesForSelect = array => {
        const mappedArray = array.map(element => {
            let value, label
            if(item.contentType === "Enum"){
                value = element.id;
                label = element.name;
            }
            else {
                // During property value mapping there is only the value no id
                value = element;
                label = element;
            }

            return { value: value, label: label }
        })

        return mappedArray
    }

    useEffect(() => {
        const fetchValues = async () => {
            let path = item.contentType === "Enum" ? EnumApi("Property", item.id) : PropertyApi("Values", item.id);
            const result = await axios(path);
            setValues(mappingValuesForSelect(result.data.values));
        };

        fetchValues();
    }, []);

    const onRefreshPropertyArrayChange = (selected) => {
        setSelectedValues(selected)

        let valuesArray = []
        // If we deleted all previously selected values
        if(selected !== null){
            valuesArray = selected.map(selectedElement => selectedElement.value)
        }

        const propArray = {
            propertyId: item.id,
            values: valuesArray
        }

        // Change property list
        refreshPropertyArray(propArray)
    }

    return (
        <Form.Group controlId = "formFileRefLanguage">
            <Form.Label>{item.name} {item.isMandatory && <Asterisk />}</Form.Label>
            <Select 
                options = {values}
                isMulti={true}
                onChange = {selected => onRefreshPropertyArrayChange(selected)}
                placeholder = "Érték(ek) választása"
                value = {selectedValues}
            />
        </Form.Group>
    );
}

export default FileReferenceItem;
