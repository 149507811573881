// Imports from packages
import React from 'react';
// react-bootstrap
import Button from 'react-bootstrap/Button';
// - none
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants
// - none

function ModalOpeningButton(props) {
    // Destructuring assignment from the props
    const { modal, disable, label, variable, isModalOpen, setModalOpen } = props

    return (
        <div className = "d-flex justify-content-center w-100">
            <Button
                disabled = {disable}
                onClick = {() => setModalOpen(true)}
                variable = {variable}
            >
                {label}
            </Button>
            {isModalOpen && modal}
        </div>
    );
}

export default ModalOpeningButton;
