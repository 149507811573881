// Imports from packages
import React from 'react';
import PropertyStringEditor from './PropertyStringEditor';
import PropertyCustomSelector from './PropertyCustomSelector';
import PropertyCustomCheckbox from './PropertyCustomCheckbox';
import { mapForGenericSelector } from './mapForGenericSelector';
import { GenericSelector } from './GenericSelector';
import { propertyTypes } from './propertyTypes';

export default function PropertyEditor(props) {
    const { newPropertyType, setNewPropertyType, selectedProperty, updateSelectedProperty, valueTypes, contentTypes, measurementUnits, enums } = props
    const onInputChange = ({ target }) => {
        const updatedProperty = {
            ...selectedProperty,
            [target.name]: target.value
        }
        updateSelectedProperty(updatedProperty)
    };
    const onSelectorChange = (selected) => {
        if (Object.prototype.hasOwnProperty.call(selected, 'value')) {
            const updatedProperty = {
                ...selectedProperty,
                [selected.name]: selected.value.split('|')[0].trim()
            }
            updateSelectedProperty(updatedProperty)
        }
        else {
            const updatedProperty = {
                ...selectedProperty,
                [selected.name]: null
            }
            updateSelectedProperty(updatedProperty)
        }
    };
    const onCheckboxChange = ({ target }) => {
        const updatedProperty = {
            ...selectedProperty,
            [target.name]: target.checked
        }
        updateSelectedProperty(updatedProperty)
    }
    return (
        <div>
            <GenericSelector
                placeholder="Property típusa"
                selected={newPropertyType}
                setSelected={setNewPropertyType}
                options={mapForGenericSelector(propertyTypes)}
            />
            <PropertyStringEditor
                key="propertyNameInput"
                name="name"
                label="Név"
                placeholder={selectedProperty.name || "Név"}
                value={selectedProperty.name}
                onChange={onInputChange}
            />
            <PropertyStringEditor
                name="category"
                label="Kategória"
                placeholder="Kategória"
                value={selectedProperty.category}
                onChange={onInputChange}
                onChangeCustomName="category"
            />
            <PropertyCustomSelector
                label="valueType"
                options={valueTypes}
                value={valueTypes.filter(vt => vt.label === selectedProperty.valueType)[0]}
                placeholder="valueType"
                onChange={onSelectorChange}
                onChangeCustomName="valueType"
            />
            <PropertyCustomSelector
                label="contentType"
                options={contentTypes}
                value={contentTypes.filter(vt => vt.label === selectedProperty.contentType)[0]}
                placeholder="contentType"
                onChange={onSelectorChange}
                onChangeCustomName="contentType"
            />
            <PropertyCustomSelector
                label="measurementUnit"
                options={measurementUnits}
                value={measurementUnits.filter(vt => vt.label === selectedProperty.measurementUnit)[0]}
                placeholder="measurementUnit"
                onChange={onSelectorChange}
                onChangeCustomName="measurementUnit"
            />
            <PropertyCustomSelector
                label="enumTypeId"
                options={enums}
                value={enums.filter(vt => vt.value === selectedProperty.enumTypeId)[0]}
                placeholder="enumTypeId"
                onChange={onSelectorChange}
                onChangeCustomName="enumTypeId"
            />
            <PropertyCustomCheckbox
                label="isMandatory"
                onChange={onCheckboxChange}
                onChangeCustomName="isMandatory"
                value={selectedProperty.isMandatory}
            />
        </div>
    )
}