// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Redirect } from "react-router-dom";
import Select from 'react-select';
// react-botstrap
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// Imports for APIs
import CompanyApi from '../../Api/CompanyApi';
import UserApi from '../../Api/UserApi';
import PropertyApi from '../../Api/PropertyApi';
// Imports of our custom components
import ThreeColumnPageTemplate from '../Pages/ThreeColumnPageTemplate';
import Asterisk from '../CommonComponents/Asterisk'
import AsteriskExplanation from '../CommonComponents/AsteriskExplanation'
// Imports of styles
import '../../App.css';
// Imports of contants
// - none

function CompanyRegistration() {

  // Name
  const [name, setName] = useState('')
  const [nameAvailable, setNameAvailable] = useState(true)
  // ShortName
  const [shortName, setShortName] = useState('')
  const [isShortNameAvailable, setIsShortNameAvailable] = useState(true)
  // ExistenceType 
  const [existenceType, setExistenceType] = useState()
  const existenceOptions = [
    { value: "PrivatePerson", label: "Magánszemély" },
    { value: "LegalPerson", label: "Jogi személy" }
  ]

  // Contact Person - current user
  const [contactPersonID, setContactPersonID] = useState('')
  const [contactPersonPropId, setContactPersonPropId] = useState('')
  const [username, setUsername] = useState(undefined)
  // Headquarter address
  const [headquartersAddress, setHeadquartersAddress] = useState('')
  const [headquartersAddressId, setHeadquartersAddressId] = useState('')
  // Alert
  const [showCompanyResponseAlert, setShowCompanyResponseAlert] = useState(false)
  const [companyResponse, setCompanyResponse] = useState(0)
  // Validation and POSTing
  const [validated, setValidated] = useState(false);
  const [posting, setPosting] = useState(false);
  // Redirect to MainPage
  const [redirect, setRedirect] = useState(false)

  const fetchCompanyNameValidness = async (name) => {
    const result = await axios(CompanyApi(`Check/Name?companyName=${name}`));
    setNameAvailable(result.data.result);
  };

  const fetchCompanyShortNameValidness = async (shortName) => {
    const result = await axios(CompanyApi(`Check/ShortName?companyShortName=${shortName}`));
    setIsShortNameAvailable(result.data.result);
  };

  useEffect(() => {
    let mounted = true;
    // Fetch current User's username and set for username
    const fetchCurrentUsername = async () => {
      let result = await axios(UserApi(''));
      setUsername(result.data.authenticatedUser.username);
    };

    // Fetch current User's ID and set for contact person
    const fetchCurrentUserID = async () => {
      const result = await axios(UserApi(`ID?username=${username}`));
      setContactPersonID(result.data.result);
    };

    // Fetch data
    if (username === undefined) {
      if (mounted) {
        fetchCurrentUsername();
      }
    }
    // Fetch ID only if we have username
    if (username !== undefined) {
      if (mounted) {
        fetchCurrentUserID();
      }
    }

    return () => mounted = false;
  }, [username]);

  useEffect(() => {
    let mounted = true;

    const fetchCompanyMandatoryPropIds = async () => {
      try {
        const idResult = await axios(PropertyApi(`List?propertyType=Company`));
        idResult.data.forEach(element => {
          if (element.name === "Székhely címe") {
            setHeadquartersAddressId(element.id);
          }
          else if (element.name.includes("Kapcsolattartó")) {
            setContactPersonPropId(element.id);
          }
        })
      } catch (error) {
        console.warn("Error from Company's mandatory prop id fetch", error);
      }
    }

    // Fetch data
    if (mounted) {
      fetchCompanyMandatoryPropIds();
    }

    return () => mounted = false;
  }, [])

  const onChange = (event, type) => {
    switch (type) {
      case 'name':
        setName(event.target.value);
        break;
      case 'shortname':
        setShortName(event.target.value);
        break;
      case 'hq':
        setHeadquartersAddress(event.target.value)
        break;
      case 'existence':
        setExistenceType(event)
        break;
      default:
        console.error("Unknown type in company registration's onChange");
    }
  }

  const redirectPage = () => {
    // Trigger MainPage redirection
    setRedirect(true)
    setTimeout(() => {
      setRedirect(false)
    }, 10)
  }

  const postRegistration = async () => {
    setPosting(true)
    try {
      const registrationData = {
        "name": `${name}`,
        "shortName": `${shortName}`,
        "existenceType": `${existenceType.value}`,
        "properties": [
          {
            "propertyId": `${contactPersonPropId}`,
            "value": `${contactPersonID}`
          },
          {
            "propertyId": `${headquartersAddressId}`,
            "value": `${headquartersAddress}`
          }
        ]
      }

      const postCompanyResult = await axios.post(CompanyApi(`Register`), registrationData);

      // Show response from the login
      setShowCompanyResponseAlert(true)
      setCompanyResponse(postCompanyResult.status)

      // Execute post-login actions after some delay
      setTimeout(() => {
        setShowCompanyResponseAlert(false)
        setCompanyResponse(0)
        redirectPage()
      }, 2000)
    }
    catch (error) {
      console.error("Error during company post process", error)

      // Show response from the login
      setShowCompanyResponseAlert(true)
      setCompanyResponse(error.response.status)
      setTimeout(() => {
        setShowCompanyResponseAlert(false)
        setCompanyResponse(0)

        setPosting(false)
      }, 2000)
    }
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      postRegistration();
    }

    event.preventDefault();
    event.stopPropagation();

    setValidated(true);
  };

  const companyAlertContent = companyResponse === 200 ? "Sikeres regisztráció" : "Sikertelen regisztráció"

  const customStyles = {
    control: provided => ({
      ...provided,
      witdh: '100%'
    })
  }

  const helperText = (
    <div className="mb-2">
      <span className="text-muted">
        A cég <b>teljes</b> és <b>rövid</b> nevét automatikusan ellenőrzi a rendszer mezőből való kikattintás során.
      </span>
      <br />
      <span className="text-muted">
        Amennyiben valamelyik nem egyedi, úgy addig nem lehetséges a regisztráció, míg az nem kerül módosításra
      </span>
    </div>
  )

  const disableLogic = posting || name === '' || !nameAvailable || shortName === '' || !isShortNameAvailable || !existenceType;

  const centralColumn = (
    <>
      <h1 className="p-3">Céges regisztráció</h1>
      {helperText}
      <AsteriskExplanation />
      <Form noValidate validated={validated} onSubmit={handleSubmit} style={{ marginTop: 10 }}>
        <Form.Group controlId="fromName">
          <Form.Label>A cég teljes neve <Asterisk /></Form.Label>
          <Form.Control
            type="text"
            placeholder="Teljes név"
            value={name}
            onChange={event => onChange(event, 'name')}
            onBlur={() => {
              if (name !== '') {
                fetchCompanyNameValidness(name)
              }
            }}
            isInvalid={!nameAvailable}
          />
          <Form.Control.Feedback type="valid">
            Szabad teljes cégnév
          </Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {name === '' ? "Szabad teljes cégnév" : "Foglalt a beírt teljes cégnév"}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formShortName">
          <Form.Label>A cég rövid neve <Asterisk /></Form.Label>
          <Form.Control
            type="text"
            placeholder="Rövidített név"
            value={shortName}
            onChange={event => onChange(event, 'shortname')}
            onBlur={() => {
              if (shortName !== '') {
                fetchCompanyShortNameValidness(shortName)
              }
            }}
            isInvalid={!isShortNameAvailable}
          />
          <Form.Control.Feedback type="valid">
            Szabad rövidített cégnév
          </Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {shortName === '' ? "Rövidített cégnév kitöltése kötelező!" : "Foglalt a beírt rövidített cégnév"}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="formHeadquartersAddress">
          <Form.Label>Cég típusa <Asterisk /></Form.Label>
          <Select
            styles={customStyles}
            options={existenceOptions}
            placeholder="Válassz cég típust"
            value={existenceType}
            onChange={selected => onChange(selected, 'existence')}
          />
        </Form.Group>

        <Form.Group controlId="formHeadquartersAddress">
          <Form.Label>Székhely címe <Asterisk /></Form.Label>
          <Form.Control
            type="text"
            placeholder="Székhely címe"
            value={headquartersAddress}
            onChange={event => onChange(event, 'hq')}
            required
          />
          <Form.Control.Feedback type="invalid">
            Székhely cím megadása kötelező!
          </Form.Control.Feedback>
        </Form.Group>

        {showCompanyResponseAlert && (
          <Alert variant={companyResponse === 200 ? "success" : "danger"} style={{ margin: "10px 0", textAlign: "center" }}>
            {companyAlertContent}
          </Alert>
        )}
        <Form.Row className="justify-content-center align-items-center">
          <Button
            type='submit'
            variant="info"
            disabled={disableLogic}
            className="mb-2"
          >
            Regisztráció
          </Button>
        </Form.Row>
      </Form>
      {redirect && <Redirect to='/user/companies' />}
    </>
  )

  return (
    <ThreeColumnPageTemplate
      centralColumn={centralColumn}
    />
  );
}

export default CompanyRegistration;
