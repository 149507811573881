import { UserProperties, CompanyProperties } from '../../constants/propertyProfiles';

export const makeObjectEntry = (type, profileValue) => {
    let arrayType
    
    switch(type){
        case "user": 
            arrayType = UserProperties
            break;
        case "company":
            arrayType = CompanyProperties
            break;
    }

    const object = arrayType.map((prop, index) => {
        const arrayElement = {
            propertyName: prop.propertyName,
            values: [
                {
                    id: profileValue[index].id, 
                    value: profileValue[index].value
                }
            ],
            isNotEditable: prop.isNotEditable,
            isMandatory: true,
        }
        
        return arrayElement;
    });

    return object;
}