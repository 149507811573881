import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import CustomModal from './CustomModal';
import { useEffect } from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import useFetchEnumValues from '../../hooks/useFetchEnumValues';
import AddPropertyInputComponent from '../Property/AddPropertyInputComponent';

export default function ModifyDevicePropReview(props) {
  // Prop variables  
  const { show, method, propertyName, propertyValue, recommendedNewValue, removalRecommended, handleClose, measurementUnit, isOwner, enumTypeId, reviewsFromParent, acceptReview, declineReview, isAdministrator } = props;

  const [newValue, setNewValue] = useState('')
  const [enumValues, setEnumValues] = useState([])
  const [selectedValue, setSelectedValue] = useState("");
  const [fetched1, setFetched1] = useState(false);

  const { refetch: fetchEnumValues, data: enumValuesData, isSuccess: successEnum } = useFetchEnumValues(enumTypeId);

  useEffect(() => {
    if (successEnum && fetched1 === false) {
      if (enumValuesData) {
        const mappedValues = enumValuesData.values.map(value => {
          return { label: value.name, value: value.id };
        })
        setEnumValues(mappedValues);
        setFetched1(true);
      }
    }
  }, [successEnum, enumValues])

  useEffect(() => {
    if (enumTypeId) {
      fetchEnumValues(enumTypeId);
    }
  }, [enumTypeId])


  const onValueChange = event => {
    setNewValue(event.target.value)
  }

  const onMethod = () => {
    if (method === 'recommend') {
      recommendedNewValue(newValue, enumTypeId, selectedValue);
    }
    else {
      removalRecommended()
    }
    handleClose(false)
  }

  const subtitle = method === 'recommend' ? 'módosításának' : 'törlésének';
  const title = `Tulajdonság ${subtitle} javaslása`;

  const showPrepend = measurementUnit !== 'NotApplicable' && measurementUnit !== ''

  const isRecommendationAvailable = reviewsFromParent
    .filter(review => review.propertyName === propertyName)?.[0]?.reviews
    .some(propertyReview => !propertyReview.isRemovalRecommended);

  const isRemovalAvailable = reviewsFromParent
    .filter(review => review.propertyName === propertyName)?.[0]?.reviews
    .some(propertyReview => propertyReview.isRemovalRecommended);

  const modalBody = (
    <div style={{ fontSize: "120%", textAlign: "center" }}>
      {
        props.method === 'removal' && (
          <div>
            {isAdministrator && (
              <div className="text-left mb-4">
                <ListGroup>
                  <ListGroup.Item variant="danger">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                        <span>Super admin jogosultsággal property törlése</span>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          variant="danger"
                          onClick={() => {
                            removalRecommended();
                            setTimeout(() => {
                              handleClose(false);
                            }, 500);
                          }}
                          className="mr-2"
                        >
                          Property érték törlése
                        </Button>
                      </div>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            )}
            <div className="text-left mb-4">
              <p><strong>Új törlési javaslat leadása</strong></p>
              <span>
                Biztos szeretné a <b>{propertyName}</b> nevű tulajdonság <b>{propertyValue}</b> értéket törlésre javasolni?
              </span>
            </div>
          </div>
        )
      }
      <div>
        {isOwner && reviewsFromParent.filter(review => review.propertyName === propertyName).length > 0 && (
          <div className="text-left">
            {isRecommendationAvailable && props.method === 'recommend' && (
              <p><strong>Javaslatok a felhasználóktól</strong></p>
            )}
            {isRemovalAvailable && props.method === 'removal'  && (
              <p><strong>Törlési javaslatok a felhasználóktól</strong></p>
            )}
            {reviewsFromParent
              .filter(review => review.propertyName === propertyName)
              .map(propertyReview => {
                const {
                  devicePropertyId,
                  reviews,
                  propertyMeasurementUnit,
                  devicePropertyCurrentValue
                } = propertyReview;
                return (
                  <ListGroup key={devicePropertyId} className="mb-2">
                    {reviews.map(review => {
                      const { id, recommendedNewValue } = review;
                      const isRecommendation = review.recommendedNewValue;
                      const isRemovalRecommended = review.isRemovalRecommended;
                      if (isRecommendation && method === "recommend") {
                        return (
                          <ListGroup.Item key={id} variant="warning">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <span><b>{devicePropertyCurrentValue}</b> --&gt; <b>{recommendedNewValue}</b>
                                  {(
                                    propertyMeasurementUnit !== 'Unknown' &&
                                    propertyMeasurementUnit !== 'unknown' &&
                                    propertyMeasurementUnit !== 'NotApplicable'
                                  ) && ` (mértékegység: ${propertyMeasurementUnit})`}
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <Button
                                  variant="warning"
                                  onClick={() => {
                                    acceptReview(review);
                                    setTimeout(() => {
                                      handleClose(false);
                                    }, 500);
                                  }}
                                  className="mr-2"
                                >
                                  Elfogadom
                                </Button>
                                <Button
                                  variant="outline-warning"
                                  onClick={() => declineReview(id)}
                                >
                                  Elutasítom
                                </Button>
                              </div>
                            </div>
                          </ListGroup.Item>
                        )
                      }
                      else if (isRemovalRecommended && method === "removal") {
                        return (
                          <ListGroup.Item key={id} variant="danger">
                            <div className="d-flex justify-content-between">
                              <div className="d-flex align-items-center">
                                <span>Törlésre javasolt: {devicePropertyCurrentValue}
                                  {(
                                    propertyMeasurementUnit !== 'Unknown' &&
                                    propertyMeasurementUnit !== 'unknown' &&
                                    propertyMeasurementUnit !== 'NotApplicable'
                                  ) && ` (mértékegység: ${propertyMeasurementUnit})`}
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                {isAdministrator && (
                                  <Button
                                    variant="danger"
                                    onClick={() => {
                                      acceptReview(review);
                                      setTimeout(() => {
                                        handleClose(false);
                                      }, 500);
                                    }}
                                    className="mr-2"
                                  >
                                    Törlés elfogadása
                                  </Button>
                                )}
                                <Button
                                  variant="outline-danger"
                                  onClick={() => declineReview(id)}
                                >
                                  Elutasítom
                                </Button>
                              </div>
                            </div>
                          </ListGroup.Item>
                        )
                      }
                    })}
                  </ListGroup>
                )
              })}
          </div>
        )}
        {
          props.method === 'recommend' && (
            <Form>
              <Form.Group key={propertyName} controlId="formProjectModal" style={{ textAlign: 'left' }}>
                <Form.Label><b>{propertyName}</b></Form.Label>
                {enumTypeId ? (
                  <AddPropertyInputComponent
                    isEnum={enumTypeId !== null && enumTypeId !== undefined}
                    contentType={null}
                    options={enumValues}
                    value={selectedValue}
                    onChange={selected => setSelectedValue(selected)}
                    placeholder={"Értékkészlet"}
                  />
                ) : (
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder={propertyValue}
                      onChange={event => onValueChange(event)}
                    />
                    {showPrepend &&
                      <InputGroup.Prepend style={{ maxHeight: '38px' }}>
                        <InputGroup.Text>{measurementUnit}</InputGroup.Text>
                      </InputGroup.Prepend>
                    }
                  </InputGroup>
                )}
              </Form.Group>
            </Form>
          )}
      </div>
    </div>
  );

  const footer = (
    <>
      {method === 'removal' ? (
        <Button
          variant="danger"
          onClick={() => onMethod()}
        >
          Javaslom!
        </Button>
      ) : (
        <Button
          variant='info2'
          onClick={() => onMethod()}
          disabled={newValue.length === 0 && selectedValue === ""}
        >
          Javaslom!
        </Button>
      )}
      <Button variant="primary" onClick={() => handleClose(false)}>
        Mégse
      </Button>
    </>
  )

  return (
    <>
      <CustomModal
        show={show}
        size="lg"
        dropback="static"
        keyboard={false}
        handleClose={() => handleClose(false)}
        title={title}
        body={modalBody}
        footer={footer}
      />
    </>
  );
}
