function DeviceApi(endpoint, id, isHadId) {

    

    const exceptionalNames = ['Newest', 'Manufacturer', 'Search', 'DetailedSearch', 'Add', 'HAD']

    let isExceptional = false
    exceptionalNames.forEach(name => {
        if(endpoint.includes(name)) {
            isExceptional = true
        }
    })

    if(isExceptional) {
        if(isHadId){
            // Was neccessary for device property adding 
            return `/Device/HAD/${id}/${endpoint}`
        }
        else if(endpoint.includes('Properties')){
            // Was neccessary for device property adding 
            return `/Device/${id}/${endpoint}`
        }
        else {
            return `/Device/${endpoint}`
        }
    }
    else {
        return `/Device/${id}/${endpoint}`
    }
}

export default DeviceApi;
