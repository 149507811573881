// Imports from packages
import React from 'react';
import { Link } from "react-router-dom";
// Imports for APIs
// - none
// Imports of our custom components
import ProjectStatus from './ProjectStatus'
// Imports of styles
// - none
// Imports of contants
// - none

function ProjectHeader(props) {
    // Destructuring assignment from the props
    const { project, setProject, projectHasParentProject, companyId } = props

    const separator = <span className ="mx-2">/</span>

    return (
        <div className = "row">
            <div className = "col-md-12 d-flex justify-content-center text-center">
                <div className = "col-lg-6 d-flex justify-content-center align-items-center">
                    <div className="d-flex justify-content-center align-items-center">
                        <Link to={`/company/${companyId}/projects`}>Projektek</Link>
                        {separator}
                        {projectHasParentProject && (
                            <>
                                <Link to={`/company/${companyId}/projects/${project.parentProjectId}`}>
                                    {project.parentProjectName}
                                </Link>
                                {separator}
                            </>
                        )}
                        <span className="font-weight-bold">{project.name} ({project.code})</span>
                    </div>
                </div>
                <ProjectStatus
                    project = {project}
                    companyId = {companyId}
                    setProject = {updatedProject => setProject(updatedProject)}
                />
            </div>
        </div>
    )
}

export default ProjectHeader;
