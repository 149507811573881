import React from 'react';

import Modal from 'react-bootstrap/Modal';

import '../../App.scss';

export default function CustomModal(props) {
  
    const handleClose = () => props.handleClose();
  
    return (
      <>
        <Modal
          show={props.show}
          onHide={handleClose}
          backdrop={props.dropback}
          keyboard={props.keyboard}
          size={props.size}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title className = "w-100">{props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{props.body}</Modal.Body>
          <Modal.Footer style = {{ justifyContent: 'space-between' }}>{props.footer}</Modal.Footer>
        </Modal>
      </>
    );
  }