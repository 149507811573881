// Imports from packages
import React from 'react';
// @material-ui
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// Imports for APIs
// - none
// Imports of our custom components 
import { AntSwitch } from '../../CommonComponents/AntSwitch'
// Imports of styles
// - none
// Imports of constants
// - none

export default function DetailedSearchSwitch(props) {
    // Destructuring assignment from the props
    const { showSelectableValues, setShowSelectableValues, leftLabel, rightLabel } = props

    return (
        <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>{leftLabel}</Grid>
                <Grid item>
                    <AntSwitch 
                        checked = {showSelectableValues} 
                        onChange={event => setShowSelectableValues(event.target.checked)}
                    />
                </Grid>
                <Grid item>{rightLabel}</Grid>
            </Grid>
        </Typography>
    );
}
