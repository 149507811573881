import React from 'react';
import { format } from 'date-fns';
import {
    dateFormatHoursMinutesSeconds,
    dateFormatYearMonthDay,
    dateFormatYearMonthDayHoursMinutesSeconds
} from '../../constants/dateFormats';
import { FaMapMarkedAlt } from 'react-icons/fa';

export default function CustomPropertyDisplayComponent(props) {
    const { contentType, value } = props;
    if (contentType === "Time") {
        const parsedDatetime = new Date(value * 1000);
        if (parsedDatetime.toString() !== "Invalid Date") {
            return <span>{format(parsedDatetime, dateFormatHoursMinutesSeconds)}</span>;
        }
        return <span>Unsupported time value: {value}</span>;
    }
    else if (contentType === "Date") {
        const parsedDatetime = new Date(value * 1000);
        if (parsedDatetime.toString() !== "Invalid Date") {
            return <span>{format(parsedDatetime, dateFormatYearMonthDay)}</span>;
        }
        return <span>Unsupported date value: {value}</span>;
    }
    else if (contentType === "DateTime") {
        const parsedDatetime = new Date(value * 1000);
        if (parsedDatetime.toString() !== "Invalid Date") {
            return <span>{format(parsedDatetime, dateFormatYearMonthDayHoursMinutesSeconds)}</span>;
        }
        return <span>Unsupported date value: {value}</span>;
    }
    else if (contentType === "Address") {
        const slicedAddress = value.length >= 32 ? `${value.slice(0, 32)}...` : value;
        return (
            <div className="d-flex justify-content-start align-items-center">
                <FaMapMarkedAlt
                    size="1.5rem"
                    style={{ marginRight: "12px" }}
                />
                <a
                    href={`https://www.google.com/maps/place/${value}`}
                    target="_blank"
                    rel="noreferrer">
                    {slicedAddress}
                </a>
            </div>
        )
    }
    else {
        return <span>{value}</span>
    }
}