// Imports from packages
import React from 'react';
// react-bootstrap
// - none
// Imports for APIs
// - none
// Imports of our custom components
import Loader from './Loader';
// Imports of styles
// - none
// Imports of contants
// - none

export default function LoaderWithText(props) {
    // Destructuring assignment from redux props
    const { content } = props

    return (
        <>
            <Loader />
            <h5 className="text-center mt-2">{content} betöltése folyamatban...</h5>
        </>
    );
}
