// Imports from packages
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';

const customStyles = {
    control: provided => ({
        ...provided,
        maxWidth: 675,
        minWidth: 250
    }),
    clearIndicator: provided => ({
        ...provided,
        margin: '0 0 0 15px'
    })
}

export default function PropertyCustomSelector(props) {
    const { label, options, placeholder, onChange, onChangeCustomName, value } = props
    return (
        <Row>
            <Col className="d-flex justify-content-end align-items-center m-2">
                <div className="d-flex justify-content-end align-items-center">
                    {label}
                </div>
            </Col>
            <Col className="d-flex justify-content-start align-items-center m-2">
                <div className="d-flex justify-content-start align-items-center">
                <Select 
                    options={options}
                    isClearable={true}
                    isSearchable={true}
                    placeholder = {placeholder}
                    onChange = {(selected) => onChange({
                        name: onChangeCustomName,
                        ...selected
                    })}
                    styles = {customStyles}
                    value = {value}
                />
                </div>
            </Col>
        </Row>
    )
}