// Imports from packages
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function PropertyStringEditor(props) {
    return (
        <Row>
            <Col className="d-flex justify-content-end align-items-center m-2">
                <div className="d-flex justify-content-end align-items-center">
                    {props.label}
                </div>
            </Col>
            <Col className="d-flex justify-content-start align-items-center m-2">
                <div className="d-flex justify-content-start align-items-center">
                    <input
                        key="name"
                        name={props.name}
                        placeholder={props.value || "Név"}
                        value={props.value}
                        onChange={props.onChange}
                    />
                </div>
            </Col>
        </Row>
    )
}