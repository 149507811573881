// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
// react-bootstrap
import Spinner from 'react-bootstrap/Spinner';
// Imports for APIs
import DeviceApi from '../../../Api/DeviceApi';
// Imports of our custom components 
import DeviceImageThumbnail from '../../CommonComponents/DeviceImageThumbnail';
import ShortLink from '../../CommonComponents/ShortLink';
// Imports of styles
import '../Company.css';
// Imports of contants
import '../../../App.css'

function SingleDistributedDevices(props) {
    // Destructuring assignment from the props
    const { device } = props

    const [deviceImage, setDeviceImage] = useState('');
    const [fetchedPhoto, setFetchedPhoto] = useState(false)

    useEffect(() => {
        let mounted = true;
        
        const fetchDeviceImageAsBlob = async () => {
            try {
                const result = await axios(DeviceApi('Photo', device.deviceId), {responseType: 'blob'});
                const deviceImageUrl = URL.createObjectURL(result.data)
                setDeviceImage(deviceImageUrl);

                setFetchedPhoto(true)
            }
            catch(error) {
                console.error("Error during SingleDistributedDevices's device photo fetching process", error)
                
                setFetchedPhoto(true)
            }
        };

        // Fetch photo, if device has photo
        if(mounted){
            fetchDeviceImageAsBlob();
        }
        
        return () => mounted = false;
    }, []);

    const link = {
        value: device.directLink
    }

    const deviceMainProperties = (
        <>
            <div>
                <small className="text-muted">
                    {<b>{device.deviceManufacturerName ? device.deviceManufacturerName : "-" }</b>}
                    {" | "}
                    {device.deviceHadId ? device.deviceHadId : "-" }
                </small>
            </div>
            <div>
                <small className="text-muted">
                    {<b>{device.partNumber ? device.partNumber : "-"}</b>}
                    {" | "}
                    {device.directLink ? <ShortLink link = {link} maxLength = {25} className = 'link-style-darker-smaller'/> : "-" }
                </small>
            </div>
        </>
    )

    // If device does not have any image show a dummyImage
    const showImage = deviceImage === '' ? (
        <DeviceImageThumbnail />
    ) : (
        <Link to = {`/device/${device.id}`}>
            <img 
                src = {deviceImage}
                className = "linkStyle" 
                alt = {`${device.name} termék`} 
            />  
        </Link>
    )

    // While we are waiting for the image to load show a red spinner
    const imageOrSpinner = fetchedPhoto ? (
        showImage
    ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: 150, width: 150}}>
            <Spinner animation="border" variant="danger"/>
        </div>
    )

    return (
        <div className = "text-center m-2" style = {{ width: '18%' }} key = {device.deviceId}>
            <div
                className = "position-relative m-auto mb-3 d-flex justify-content-center align-items-center"
                style = {{ height: "200px" }}
            >
                {imageOrSpinner}
            </div>
            <div>
                <Link to= {`/device/${device.deviceId}`}>
                    {device.deviceName}
                </Link>
            </div>
            <div>
                {deviceMainProperties}
            </div>
        </div>
    );
}

export default SingleDistributedDevices;
