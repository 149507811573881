export const formTexts = [
    {
      content: "Tartalmaz legalább 6 karaktert",
      regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?a-zA-Z0-9]{6}/g
    },
    {
      content: "Tartalmaz kis betűt",
      regex: /[a-z]/g
    },
    {
      content: "Tartalmaz nagy betűt",
      regex: /[A-Z]/g
    },
    {
      content: "Tartalmaz számot",
      regex: /[0-9]/g
    },
    {
      content: "Tartalmaz szimbólumot",
      regex: /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g
    },
]