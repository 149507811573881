// Imports from packages
import React, { useState, useEffect } from 'react';
// react-bootstrap
// - none
// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';
// Imports for APIs
// - none
// Imports of our custom components 
// - none
// Imports of styles
// - none
// Imports of constants
// - none

export default function RestrictedRangeSlider(props) {
    // Destructuring assignment from the props
    const { minValue, maxValue, allValues, measurementUnit, setSliderRange } = props

    const useStyles = makeStyles({
        root: {
          width: "90%",
        },
    });
    
    const marks = allValues.map((value, index) => {
        let formattedValue

        if(index === 0){
            formattedValue = {
                value: 0,
                label: `${minValue} ${measurementUnit}`,
                itemValue: minValue
            }
        }
        else if(index > 0 && index !== (allValues.length - 1)){
            formattedValue = {
                value: (index / (allValues.length - 1)) * 100,
                label: '',
                itemValue: value
            }
        }
        else if(index === (allValues.length - 1)){
            formattedValue = {
                value: 100,
                label: `${maxValue} ${measurementUnit}`,
                itemValue: maxValue
            }
        }

        return formattedValue
    })
    // Select first mark as start mark and last mark for end 
    const [value, setValue] = useState([marks[0].value, marks[marks.length - 1].value]);

    // Give back basic when slider is showed
    useEffect(() => {
        if(minValue !== undefined && maxValue !== undefined)
            setSliderRange([minValue, maxValue])
    }, [])

    const handleChange = (event, newValue) => {
        // If there was no change, don't update item 
        if(value[0] !== newValue[0] || value[1] !== newValue[1]){
            // Search for values, before given back
            const startValue = newValue[0]
            const endValue = newValue[1]

            const range = [
                marks[marks.findIndex((mark) => mark.value === startValue)].itemValue,
                marks[marks.findIndex((mark) => mark.value === endValue)].itemValue
            ]
            setSliderRange(range)
        }

        // set local value
        setValue(newValue)
    }

    const valueLabelFormat = (value) => {
        const markIdex = marks.findIndex((mark) => mark.value === value);
        const labelWithoutMeasementUnit = marks[markIdex].itemValue

        return labelWithoutMeasementUnit;
    }
    
    return (
        <div className = "mr-3 d-flex justify-content-center align-items-center">
            <div className = {useStyles().root}>
                <Slider
                    valueLabelFormat={valueLabelFormat}
                    valueLabelDisplay = "auto"
                    aria-labelledby = "discrete-slider-restrict-with-range"
                    value = {value}
                    onChange = {handleChange}
                    step = {null}
                    marks = {marks}
                    className="mt-1"
                />
            </div>
        </div>
    );
}
