import axios from "axios";
import { useQuery } from "react-query";

export const FetchDeviceReviewsQueryKey = "FetchDeviceReviews";

const useFetchDeviceReviews = (deviceId) => {
  return useQuery(
    `FetchDeviceReviewsFor${deviceId}`,
    async () => {
      const result = await axios(`Review/Device/${deviceId}/Details`);
      return result.data;
    },
    {
      enabled: false,
      retry: 1,
    }
  );
};

export default useFetchDeviceReviews;