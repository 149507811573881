// Imports from packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// react-bootstrap
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
// Imports for APIs
import UserApi from '../../Api/UserApi';
// Imports of our custom components
import ThreeColumnPageTemplate from '../../Components/Pages/ThreeColumnPageTemplate';
import CustomToast from '../CommonComponents/CustomToast';
import { setBom } from '../../actions/bom.js';
import { setCompany } from '../../actions/company.js';
import { setProject } from '../../actions/project.js';
// Imports of styles
import '../../App.css';
// Imports of contants
import { HADColors } from '../../constants/color'

const CompanyCard = ({ id, isActive, name, companyName, shortName, activate, disableButtonViaToast }) => {
    return (
        <Card key={id} bg={isActive ? "primary" : "light"} className="mb-2">
            <Card.Header>{name} {isActive && `(aktív cég)`}</Card.Header>
            <Card.Body>
                <Card.Text>
                    <Link to={`/company/${id}`}>
                        <Button variant={isActive ? "light" : "primary"}>
                            Cég adatlap megnyitása
                        </Button>
                    </Link>
                    {(!isActive && companyName) && (
                        <Button
                            variant="primary"
                            disabled={disableButtonViaToast}
                            onClick={() => activate(id, name, shortName)}
                            className="ml-1"
                        >
                            Aktiválom!
                        </Button>
                    )}
                    {(isActive && companyName) && (
                        <>
                            <Link to={`/company/${id}/owned`}>
                                <Button variant="light" className="ml-1">
                                    Saját eszközök
                                </Button>
                            </Link>
                            <Link to={`/company/${id}/distributed`}>
                                <Button variant="light" className="ml-1">
                                    Értékesített eszközök
                                </Button>
                            </Link>
                        </>
                    )}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

function UserCompaniesPage(props) {
    // Destructuring assignment from the props
    const { companyId, companyName, setCompany, resetProject, resetBom } = props

    const [companies, setCompanies] = useState([])

    // Toast
    const [showToast, setShowToast] = useState(false)
    const [disableButtonViaToast, setDisableButtonViaToast] = useState(false)

    useEffect(() => {
        // Fetch deviceId
        const fetchCompanies = async () => {
            try {
                const result = await axios(UserApi('Companies'));
                if (result.status === 200) {

                    setCompanies(result.data)
                }
            }
            catch (error) {
                console.warn("Error during User companies fetch process", error)
            }
        }

        // Fetch data
        fetchCompanies();
    }, [])

    const activateCompanyOnBackend = async (id, name, shortName) => {
        try {
            // result is not used so this is enough
            await axios.put(UserApi(`Companies/${companyId}/Activate`))

            setCompany(id, name, shortName)
            // We have to clear the project and the BOM state when the user selects an other company
            resetProject()
            resetBom()
        } catch (error) {
            // Show Toast
            console.error('Error occured during company activation')
            setShowToast(true)

            setTimeout(() => {
                setShowToast(false)
            }, 4000)
        }
    }

    const centralColumn = (
        <div id="user-companies-div">
            <div className="text-center">
                <h1>Felhasználó cégei</h1>
            </div>
            <div className="d-flex justify-content-center text-left w-100 px-5">
                <ListGroup defaultActiveKey={(companies && companies.length > 0) ? companies[0].id : ''} className="w-100">
                    {companies.map(element => {
                        const isActive = element.name === companyName
                        return (
                            <CompanyCard
                                key={element.id}
                                id={element.id}
                                isActive={isActive}
                                name={element.name}
                                companyName={companyName}
                                shortName={element.shortName}
                                activate={() => activateCompanyOnBackend(element.id, element.name, element.shortName)}
                                disableButtonViaToast={disableButtonViaToast}
                            />
                        )
                    })}
                </ListGroup>
            </div>
            <div className="d-flex justify-content-center align-items-center">
                <Link to={`/company-registration`}>
                    <Button
                        variant="primary"
                        className="m-3"
                        style={{ marginTop: 10 }}
                    >
                        Új cég létrehozása
                    </Button>
                </Link>
            </div>
            <CustomToast
                showToast={showToast}
                setShowToast={() => setShowToast(false)}
                iconColor={HADColors.Red}
                header="Sikertelen cégaktiválás"
                headerTime="Épp most"
                body="Hiba történt a cég aktiválás során. Kérjük, próbálja újra!"
                setDisableButtonViaToast={newValue => setDisableButtonViaToast(newValue)}
            />
        </div>
    )

    return (
        <ThreeColumnPageTemplate
            centralColumn={centralColumn}
        />
    );
}

// Integrate Redux store with this component
// Store states to component props
const mapStateToProps = state => {
    const { companyId, companyName } = state.company;
    return {
        companyId, companyName
    };
};
// Store reducers to components props as callable functions
const mapDispatchToProps = dispatch => ({
    setCompany: (companyId, companyName, shortName) => {
        dispatch(setCompany(companyId, companyName, shortName));
    },
    // We have to clear the project and BOM state when the user selects an other company
    resetProject: () => {
        dispatch(setProject('', ''));
    },
    resetBom: () => {
        dispatch(setBom('', ''));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(UserCompaniesPage);