// Distributor related constants, enums
export const Intervals = [
    {
        minimum: 0,
        maximum: 1,
        label: "<1"
    },
    {
        minimum: 1,
        maximum: 5,
        label: "1...5"
    },
    {
        minimum: 5,
        maximum: 10,
        label: "5...10"
    },
    {
        minimum: 10,
        maximum: 50,
        label: "10...50"
    },
    {
        minimum: 50,
        maximum: 100,
        label: "50...100"
    },
    {
        minimum: 100,
        maximum: 500,
        label: "100...500"
    },
    {
        minimum: 500,
        maximum: 1000,
        label: "500...1000"
    },
    {
        minimum: 1000,
        maximum: 100000000,
        label: "1000<"
    },
]
